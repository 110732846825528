import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProductionService = /** @class */ (function () {
    function ProductionService(http) {
        this.http = http;
        this.productionUrl = environment.apiUrl + "/production";
    }
    // API: Get
    ProductionService.prototype.getProduction = function (operatorId) {
        var url = this.productionUrl + "/getProduction?operatorId=" + operatorId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    ProductionService.prototype.getProductionByYear = function (operatorId, year) {
        var url = this.productionUrl + "/getProduction?operatorId=" + operatorId + "&year=" + year;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // GET
    ProductionService.prototype.getProductionByPropertyId = function (operatorId, propertyId) {
        return this.http.get(this.productionUrl + "/getProduction?operatorId=" + operatorId + "&propertyId=" + propertyId);
    };
    // GET
    ProductionService.prototype.getProdChangeReasons = function () {
        console.log('GetProdChangeReasons Url: ', this.productionUrl + "/getProductionChangeReasons");
        return this.http.get(this.productionUrl + "/getProductionChangeReasons").toPromise();
    };
    // POST
    ProductionService.prototype.postProductionFile = function (fd) {
        var url = this.productionUrl + "/upload";
        return this.http.post(url, fd, { responseType: 'text' });
    };
    // POST
    ProductionService.prototype.save = function (production) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        return this.http.post(this.productionUrl + "/save", production, httpOptions).toPromise();
    };
    // POST
    ProductionService.prototype.getReport = function (productions, reportType) {
        var httpOptions = {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.productionUrl + "/productionReport/" + reportType;
        return this.http.post(url, productions, httpOptions);
    };
    /** @nocollapse */ ProductionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductionService_Factory() { return new ProductionService(i0.ɵɵinject(i1.HttpClient)); }, token: ProductionService, providedIn: "root" });
    return ProductionService;
}());
export { ProductionService };
