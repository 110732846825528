import * as tslib_1 from "tslib";
import { OnInit, ElementRef, EventEmitter } from '@angular/core';
import { DatePipe, Location, formatDate } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as GC from '@grapecity/spread-sheets';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { Well } from 'src/app/model/well';
import { ProductionChangeReason } from 'src/app/model/production_change_reason';
import { ProductionSearch } from 'src/app/model/production_search';
import { ProductionService } from 'src/app/production.service';
import { LookupService } from 'src/app/lookup.service';
import { MessagesService } from 'src/app/messages.service';
import { ValidatorService } from 'src/app/validator.service';
import { DatePickerCellType } from 'src/app/shared/spread/DatePickerCellType';
import { map } from 'rxjs/operators';
var clone = function (obj) { return JSON.parse(JSON.stringify(obj)); };
var ɵ0 = clone;
var ProductionContentComponent = /** @class */ (function () {
    function ProductionContentComponent(fb, productionService, lookupService, messagesService, validatorService, datePipe, location, router) {
        this.fb = fb;
        this.productionService = productionService;
        this.lookupService = lookupService;
        this.messagesService = messagesService;
        this.validatorService = validatorService;
        this.datePipe = datePipe;
        this.location = location;
        this.router = router;
        this.displayAddProductionChange = new EventEmitter();
        this.displayUploadProductionChange = new EventEmitter();
        this.prodSearchForm = this.fb.group({
            searchStr: [''],
            state: ['']
        });
        this.blockchainExplorerUrl = "" + environment.blockchainExplorerUrl;
        this.sheet = null;
        this.msg = this.messagesService.messages;
        this.tabStripVisible = false;
        this.spreadBackColor = 'white';
        this.sheetName = 'Production List';
        this.hostStyle = {
            height: '100%',
            width: '100%'
        };
        this.autoGenerateColumns = false;
        // Define column indexes
        this.propertyNoColumn = 0;
        this.propertyNameColumn = 1;
        this.wellApiColumn = 2;
        this.wellNameColumn = 3;
        this.blockChainColumn = 4;
        this.blockChainTransIdColumn = 5;
        this.productColumn = 6;
        this.prodDateColumn = 7;
        this.prodVolumeColumn = 8;
        this.btuColumn = 9;
        this.gravityColumn = 10;
        this.tempratureColumn = 11;
        this.percentChangeColumn = 12;
        this.prodChangeReasonColumn = 13;
        this.saveColumn = 14;
        this.gridColumnCount = 15;
        // This is the percentage change we wish to highlight if NO reason for the change is selected
        this.percentVolumeChangeHighlight = -20.0;
        this.maxStartDate = false;
        this.maxEndDate = true;
        this.lastUpdated = new Date();
    }
    ProductionContentComponent.prototype.closeAddProduction = function () {
        this.displayAddProduction = false;
        this.displayAddProductionChange.emit(false);
    };
    ProductionContentComponent.prototype.openAddProduction = function () {
        this.displayAddProduction = true;
        this.displayAddProductionChange.emit(true);
    };
    ProductionContentComponent.prototype.closeUploadProduction = function () {
        this.displayUploadProduction = false;
        this.displayUploadProductionChange.emit(false);
    };
    ProductionContentComponent.prototype.openUploadProduction = function () {
        this.displayUploadProduction = true;
        this.displayUploadProductionChange.emit(true);
    };
    ProductionContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        // initialise Search criteria
        var today = new Date();
        this.prodSearch = new ProductionSearch();
        this.prodSearch.prodType = 0;
        this.prodSearch.startDateMonth = 1;
        this.prodSearch.startDateYear = today.getFullYear();
        this.prodSearch.startStr = this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear);
        this.prodSearch.endDateMonth = today.getMonth() + 1;
        this.prodSearch.endDateYear = today.getFullYear();
        this.prodSearch.endStr = this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear);
        this.prodSearch.searchStr = [];
        this.prodSearch.state = '';
        var user = JSON.parse(localStorage.getItem('user'));
        var operatorId = user.operator.id;
        console.log('Today FullYear: ' + today.getFullYear());
        Promise.all([
            this.getProducts(),
            this.getProductionChangeReasons(),
            this.getOperators(),
            this.getUSStates(),
            // For download transfer speed we get this years production only - we will load the rest of production async
            this.getProductionByYear(operatorId, today.getFullYear())
        ]).then(function (values) {
            _this.getProduction(operatorId);
        });
        this.lastUpdated.setDate(today.getDate() - 2);
    };
    ProductionContentComponent.prototype.getProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.lookupService.getProducts()];
                    case 1:
                        _a.products = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductionContentComponent.prototype.getProductionChangeReasons = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.productionService.getProdChangeReasons()];
                    case 1:
                        _a.productionChangeReasons = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductionContentComponent.prototype.getOperators = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, operatorId, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        user = JSON.parse(localStorage.getItem('user'));
                        operatorId = user.operator.id;
                        _a = this;
                        return [4 /*yield*/, this.lookupService.getOperators(operatorId)];
                    case 1:
                        _a.operators = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductionContentComponent.prototype.getUSStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.lookupService.getStates()];
                    case 1:
                        _a.states = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    ProductionContentComponent.prototype.getProduction = function (operatorId) {
        var _this = this;
        console.log('getProduction - start: ' + new Date().toISOString());
        return this.productionService.getProduction(operatorId).pipe(map(function (production) {
            console.log('getProduction - Back from API: ' + new Date().toISOString());
            console.log({ production: production });
            var tempProduction = JSON.parse(JSON.stringify(production));
            tempProduction = _this.setProductionValues(tempProduction);
            // this.production = tempProduction;
            _this.allProduction = tempProduction;
            // this.initialiseGrid();
            console.log('getProduction - complete: ' + new Date().toISOString() + ' No. of record: ' + _this.allProduction.length);
            return production;
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    _this.router.navigate(['/login']);
                }
            }
        })).toPromise();
    };
    ProductionContentComponent.prototype.getProductionByYear = function (operatorId, year) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                console.log('getProductionByYear - start: ' + new Date().toISOString());
                return [2 /*return*/, this.productionService.getProductionByYear(operatorId, year).pipe(map(function (production) {
                        console.log('getProductionByYear - Back from API: ' + new Date().toISOString());
                        console.log({ production: production });
                        var tempProduction = JSON.parse(JSON.stringify(production));
                        tempProduction = _this.setProductionValues(tempProduction);
                        _this.production = tempProduction;
                        _this.allProduction = tempProduction;
                        _this.initialiseGrid();
                        console.log('getProductionByYear - complete: ' + new Date().toISOString() + ' No. of record: ' + _this.allProduction.length);
                        _this.filter();
                        return production;
                    }, function (err) {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                _this.router.navigate(['/login']);
                            }
                        }
                    })).toPromise()];
            });
        });
    };
    ProductionContentComponent.prototype.getOperatorById = function (operatorId) {
        return this.operators.find(function (o) { return o.id === operatorId; });
    };
    ProductionContentComponent.prototype.getProduct = function (productName) {
        return this.products.find(function (p) { return p.name.trim() === productName.trim(); });
    };
    ProductionContentComponent.prototype.getProdChangeReason = function (reason) {
        // console.log('Reason: ' + reason);
        // console.log({ ProductionChangeReasons: this.productionChangeReasons });
        var productionChangeReason = this.productionChangeReasons.find(function (p) { return p.name.trim() === reason.trim(); });
        // console.log('ProductionChangeReason: ', { productionChangeReason });
        if (productionChangeReason == null) {
            productionChangeReason = new ProductionChangeReason();
            if (reason != null && reason !== '') {
                productionChangeReason.id = 7;
                productionChangeReason.freeText = true;
                productionChangeReason.name = reason;
            }
            else {
                productionChangeReason.id = 0;
                productionChangeReason.freeText = false;
                productionChangeReason.name = '';
            }
        }
        return productionChangeReason;
    };
    ProductionContentComponent.prototype.transformDate = function (date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    };
    ProductionContentComponent.prototype.setProductionValue = function (productionRec) {
        console.log('Production: ', { productionRec: productionRec });
        if (productionRec.productionDate != null) {
            productionRec.prodDate = formatDate(productionRec.productionDate, 'MM/dd/yyyy', 'en');
            productionRec.propertyNo = productionRec.well.property.propertyNo;
            productionRec.propertyName = productionRec.well.property.name;
            productionRec.wellApiNo = productionRec.well.apiNo;
            productionRec.wellName = productionRec.well.name;
            productionRec.productName = productionRec.product.name;
            productionRec.percentChange = parseFloat(productionRec.percentChange.toFixed(2));
            if (productionRec.btu < 0.001) {
                productionRec.btuStr = '';
            }
            else {
                productionRec.btuStr = productionRec.btu.toFixed(2);
            }
            if (productionRec.gravity < 0.001) {
                productionRec.gravityStr = '';
            }
            else {
                productionRec.gravityStr = productionRec.gravity.toFixed(2);
            }
        }
        return productionRec;
    };
    ProductionContentComponent.prototype.setProductionValues = function (filteredProduction) {
        filteredProduction.forEach(function (productionRec) {
            if (productionRec.productionDate != null) {
                productionRec.prodDate = formatDate(productionRec.productionDate, 'MM/dd/yyyy', 'en');
            }
            productionRec.propertyNo = productionRec.well.property.propertyNo;
            productionRec.propertyName = productionRec.well.property.name;
            productionRec.wellApiNo = productionRec.well.apiNo;
            productionRec.wellName = productionRec.well.name;
            productionRec.productName = productionRec.product.name;
            productionRec.percentChange = parseFloat(productionRec.percentChange.toFixed(2));
            if (productionRec.btu < 0.001) {
                productionRec.btuStr = '';
            }
            else {
                productionRec.btuStr = productionRec.btu.toFixed(2);
            }
            if (productionRec.gravity < 0.001) {
                productionRec.gravityStr = '';
            }
            else {
                productionRec.gravityStr = productionRec.gravity.toFixed(2);
            }
        });
        // console.log({ filteredProduction });
        return filteredProduction;
    };
    ProductionContentComponent.prototype.addValidators = function (row) {
        // Must select Well API
        var validator = this.validatorService.cellNotBlank(this.wellApiColumn, row, this.msg.production.wellApiNo);
        this.sheet.setDataValidator(row, this.wellApiColumn, 1, 1, validator);
        // Must Select Product
        validator = this.validatorService.cellNotBlank(this.productColumn, row, this.msg.production.product);
        this.sheet.setDataValidator(row, this.productColumn, 1, 1, validator);
        // Must select production date
        validator = this.validatorService.cellNotBlank(this.prodDateColumn, row, this.msg.production.prodDate);
        this.sheet.setDataValidator(row, this.prodDateColumn, 1, 1, validator);
        // Must input production Amount
        validator = this.validatorService.cellNotBlank(this.prodVolumeColumn, row, this.msg.production.prodVol);
        this.sheet.setDataValidator(row, this.prodVolumeColumn, 1, 1, validator);
    };
    ProductionContentComponent.prototype.validateProduction = function (row) {
        // console.log('Validate Production - row: ' + row);
        if (!this.sheet.isValid(row, this.wellApiColumn, this.sheet.getCell(row, this.wellApiColumn).value())) {
            alert(this.msg.production.wellApiNo);
            return false;
        }
        if (!this.sheet.isValid(row, this.productColumn, this.sheet.getCell(row, this.productColumn).value())) {
            alert(this.msg.production.product);
            return false;
        }
        if (!this.sheet.isValid(row, this.prodDateColumn, this.sheet.getCell(row, this.prodDateColumn).value())) {
            alert(this.msg.production.prodDate);
            return false;
        }
        if (!this.sheet.isValid(row, this.prodVolumeColumn, this.sheet.getCell(row, this.prodVolumeColumn).value())) {
            alert(this.msg.production.prodVol);
            return false;
        }
        return true;
    };
    ProductionContentComponent.prototype.saveProduction = function (origProduction) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var production, well, prodDate, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        production = clone(origProduction);
                        console.log('Cloned Production: ', { production: production });
                        // Default operator for the moment - Operator will probably be stored globally for the session
                        production.operator = this.getOperatorById(1);
                        production.operatorId = 1;
                        if (production.productName != null) {
                            production.product = this.getProduct(production.productName);
                        }
                        if (production.reason != null) {
                            production.productionChangeReason = this.getProdChangeReason(production.reason);
                            production.prodChangeReasonId = production.productionChangeReason.id;
                            production.reason = production.productionChangeReason.name;
                        }
                        well = new Well();
                        well.id = 0;
                        well.apiNo = production.wellApiNo;
                        well.name = production.wellName;
                        well.operator = production.operator;
                        well.products = new Array();
                        well.products.push(production.product);
                        production.well = well;
                        console.log('Prod Date from Grid: ' + production.prodDate);
                        if (production.prodDate.length < 10) {
                            console.log('Production date is null or empty');
                        }
                        prodDate = production.prodDate.substr(6, 4) +
                            '-' +
                            production.prodDate.substr(0, 2) +
                            '-' +
                            production.prodDate.substr(3, 2);
                        console.log('Date: ' + prodDate);
                        production.productionDate = prodDate;
                        if (production.btuStr === '') {
                            production.btu = 0;
                        }
                        else {
                            production.btu = production.btuStr;
                        }
                        if (production.gravityStr === '') {
                            production.gravity = 0;
                        }
                        else {
                            production.gravity = production.gravityStr;
                        }
                        // prodDate, prodVolume, percentChange, prodChangeReason are all in the production object
                        console.log('Save Cloned Production: ', { production: production });
                        // Null the insertDate/modifiedDate because they get lost in translation and caused Type-mismatch
                        production.insertDate = null;
                        production.modifiedDate = null;
                        return [4 /*yield*/, this.productionService.save(production)];
                    case 1:
                        result = (_a.sent());
                        // Assign the display values back to the save object
                        result = this.setProductionValue(result);
                        return [2 /*return*/, result];
                }
            });
        });
    };
    ProductionContentComponent.prototype.changeSaveButtonColor = function (color) {
        var saveBtnCellType = new GC.Spread.Sheets.CellTypes.Button();
        // saveBtnCellType.buttonBackColor('#CFCFCF');
        saveBtnCellType.buttonBackColor(color);
        saveBtnCellType.text('Save');
        saveBtnCellType.marginBottom(1);
        saveBtnCellType.marginTop(1);
        saveBtnCellType.marginLeft(10);
        saveBtnCellType.marginRight(10);
        return saveBtnCellType;
    };
    ProductionContentComponent.prototype.getProductionLastMonth = function (currProd) {
        // Get production last month
        var prodDateArr = currProd.productionDate.toString().split('-');
        console.log('Date: ' + prodDateArr);
        var currYear = Number(prodDateArr[0]);
        var currMonth = Number(prodDateArr[1]);
        console.log('Current Year:' + currYear + '    Month: ' + currMonth);
        var prevYear;
        var prevMonth;
        if (currMonth === 1) {
            prevMonth = 12;
            prevYear = currYear - 1;
        }
        else {
            prevMonth = currMonth - 1;
            prevYear = currYear;
        }
        console.log('Previous Year:' + prevYear + '    Month: ' + prevMonth);
        // Search global array for production of previous month
        var prodVolumeLastMonth = 0;
        var prodDaysLastMonth = 0;
        this.allProduction.forEach(function (prodObj, rowIndex) {
            if (prodObj.wellApiNo === currProd.wellApiNo &&
                prodObj.productId === currProd.productId) {
                var dateArr = prodObj.productionDate.toString().split('-');
                var dateYear = Number(dateArr[0]);
                var dateMonth = Number(dateArr[1]);
                if (dateYear === prevYear && dateMonth === prevMonth) {
                    // Previous month production
                    prodVolumeLastMonth += prodObj.amount;
                    prodDaysLastMonth += 1;
                    console.log('Prod Date: ', prodObj.productionDate + ' Last Month Days: ' + prodDaysLastMonth + ' Last Month Amount: ' + prodObj.amount);
                }
            }
        });
        console.log('Total Production Last month: ' + prodVolumeLastMonth);
        console.log('Total Production Days Last month: ' + prodDaysLastMonth);
        console.log('Total Production This month: ' + currProd.amount);
        var percentChange = 0.0;
        if (prodDaysLastMonth > 0) {
            var avgProdVolumeLastMonth = prodVolumeLastMonth / prodDaysLastMonth;
            percentChange =
                (100 * (currProd.amount - avgProdVolumeLastMonth)) / avgProdVolumeLastMonth;
            percentChange = parseFloat(percentChange.toFixed(2));
            console.log('Percentage Change: ' + percentChange);
        }
        return percentChange;
    };
    ProductionContentComponent.prototype.updateGlobalProduction = function (prodObj) {
        for (var i = 0; i < this.allProduction.length; i++) {
            if (this.allProduction[i].id === prodObj.id) {
                this.allProduction[i] = prodObj;
            }
        }
    };
    ProductionContentComponent.prototype.createGridEvents = function () {
        var _this = this;
        // Bind click-event of Blockchain cell
        this.spread.bind(GC.Spread.Sheets.Events.CellClick, function (e, args) {
            var row = args.row;
            var col = args.col;
            // let cell = this.sheet.getCell(row, col);
            // Get selected Production from array
            var selectedProduction = _this.production[row];
            console.log("[Row: " + row + "] Property with Id " + selectedProduction.id);
            if (selectedProduction) {
                console.log({ selectedProduction: selectedProduction });
                console.log("[Row: " + row + "] Production with Id " + selectedProduction.id + " found BlockId: " + selectedProduction.bcBlockId);
            }
            else {
                console.log("[Row: " + row + "] Production not found");
            }
            if (row === _this.production.length) {
                if (col >= _this.propertyNoColumn && col <= _this.wellNameColumn) {
                    // We need to do a modal Well search
                }
            }
            else if (col === _this.blockChainColumn ||
                col === _this.blockChainTransIdColumn) {
                var bcTransId = _this.sheet.getValue(row, _this.blockChainTransIdColumn);
                if (bcTransId != null && bcTransId !== '') {
                    var url = '';
                    if (selectedProduction.bcBlockId != null) {
                        url = _this.blockchainExplorerUrl + selectedProduction.bcBlockId;
                    }
                    else {
                        url = _this.blockchainExplorerUrl;
                    }
                    window.open(url);
                }
            }
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.EnterCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColumnCount - 1; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainColumn) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridHighlight);
                    }
                }
            }
            // console.log('Row Enter Cell: ' + row);
            _this.sheet.resumePaint();
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Un-highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColumnCount - 1; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainColumn) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridBackground);
                    }
                }
            }
            _this.sheet
                .getCell(row, -1)
                .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            _this.sheet
                .getCell(row, -1)
                .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            // console.log('Row Leave Cell: ' + row);
            _this.sheet.resumePaint();
        });
        this.spread.bind(GC.Spread.Sheets.Events.ValueChanged, function (e, args) {
            var row = args.row;
            var col = args.col;
            _this.sheet.suspendPaint();
            // Value changed - clear blockchain transId, change the colour of the save button
            _this.production[row].bcTransId = null;
            // Clear Blockchain graphic to the cell
            var cell = _this.sheet.getCell(row, _this.blockChainColumn);
            cell.text('');
            cell.backgroundImage(null);
            // Cell has changed - enable save button and change its colour
            _this.sheet.getRange(row, _this.saveColumn, 1, 1).locked(false);
            console.log('Save button unlocked');
            var saveSingleBtnCellType = _this.changeSaveButtonColor(environment.buttonEnabled);
            _this.sheet.getCell(row, _this.saveColumn).cellType(saveSingleBtnCellType);
            _this.sheet
                .getCell(row, _this.saveColumn)
                .foreColor(environment.buttonEnabledText);
            // Change the back-ground color to reflect a change in grid data
            _this.sheet.getCell(row, col).backColor(environment.gridCellChanged);
            // If change in production volume
            if (col === _this.prodVolumeColumn) {
                var percentChange = _this.getProductionLastMonth(_this.production[row]);
                console.log('Percentage Change: ' + percentChange);
                _this.production[row].percentChange = percentChange;
                // Update the global array with this new record also
                _this.updateGlobalProduction(_this.production[row]);
                // Highlight cells with percentage < [this.percentVolumeChangeHighlight](= -20%) and no reason selected
                if (percentChange < _this.percentVolumeChangeHighlight) {
                    var reasonId = _this.production[row].prodChangeReasonId;
                    if (reasonId === 0) {
                        var pcCell = _this.sheet.getCell(row, _this.percentChangeColumn);
                        pcCell.backColor(environment.gridCellChanged);
                        pcCell = _this.sheet.getCell(row, _this.prodChangeReasonColumn);
                        pcCell.backColor(environment.gridCellChanged);
                    }
                }
            }
            if (col === _this.prodChangeReasonColumn) {
                if (_this.production[row].reason === 'Other (free text)') {
                    _this.production[row].reason = '';
                }
            }
            if (col === _this.prodDateColumn) {
                _this.data[row]['prodDate'] = '' + _this.data[row]['prodDate'];
            }
            _this.sheet.resumePaint();
        });
        // Define save button action
        this.spread.bind(GC.Spread.Sheets.Events.ButtonClicked, function (e, args) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var newProduction, row, col, cellType, production, result, cell, imgBlockChain, lastRow, colIndex, saveBtnCellType2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sheet.suspendPaint();
                        newProduction = false;
                        row = args.row;
                        col = args.col;
                        cellType = this.sheet.getCellType(row, col);
                        if (!(cellType instanceof GC.Spread.Sheets.CellTypes.Button)) return [3 /*break*/, 2];
                        this.spread.options.highlightInvalidData = true;
                        // Add validators to last row
                        if (row === this.sheet.getRowCount() - 1) {
                            console.log('New production');
                            this.addValidators(row);
                            newProduction = true;
                        }
                        production = this.production[row];
                        console.log('Production (b4 validation): ', { production: production });
                        if (!this.validateProduction(row)) return [3 /*break*/, 2];
                        console.log('Valid Production: ', { production: production });
                        return [4 /*yield*/, this.saveProduction(production)];
                    case 1:
                        result = (_a.sent());
                        console.log('Save result: ', { result: result });
                        cell = this.sheet.getCell(row, 0);
                        cell.text(result.id.toString());
                        console.log('PropertyId: ' + result.id.toString());
                        // Assign bcTransId to grid also
                        cell = this.sheet.getCell(row, this.blockChainTransIdColumn);
                        cell.text(result.bcTransId);
                        console.log('bcTransId: ' + result.bcTransId);
                        // Assign Blockchain graphic to the cell
                        cell = this.sheet.getCell(row, this.blockChainColumn);
                        imgBlockChain = './assets/images/blockchain_cell@2x.png';
                        cell.text('');
                        cell.backgroundImage(imgBlockChain);
                        // Assign the saved production to the Global Grid Array
                        this.production[row] = result;
                        if (newProduction) {
                            console.log('Result - new: ', { result: result });
                            // Assign new property to the end of the global array with all production
                            this.allProduction[this.allProduction.length - 1] = result;
                            lastRow = this.sheet.getRowCount();
                            this.sheet.addRows(lastRow, 1);
                        }
                        else {
                            console.log('Result - not new: ', { result: result });
                            // Assign the saved property to the global instance of all production
                            this.updateGlobalProduction(result);
                        }
                        console.log('Production: ', this.production);
                        console.log('All Production: ', this.allProduction);
                        // Change the color of all the cells back to highlighted
                        for (colIndex = 0; colIndex < 15; colIndex++) {
                            this.sheet
                                .getCell(row, colIndex)
                                .backColor(environment.gridHighlight);
                        }
                        console.log('Change cells back to highlighted');
                        saveBtnCellType2 = this.changeSaveButtonColor(environment.buttonDisabled);
                        this.sheet.getCell(row, this.saveColumn).cellType(saveBtnCellType2);
                        this.sheet
                            .getCell(row, this.saveColumn)
                            .foreColor(environment.buttonDisabledText);
                        console.log('Make button grey - disabled');
                        _a.label = 2;
                    case 2:
                        this.sheet.resumePaint();
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ProductionContentComponent.prototype.initialiseGrid = function () {
        // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
        // And grid events that are NOT data specific
        // console.log('Initialise Grid');
        this.sheet.suspendPaint();
        // Add Product dropdowns
        var productComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
        var productStrArr = [];
        this.products.forEach(function (product) {
            productStrArr.push(product.name);
        });
        productComboBoxType.items(productStrArr).editable(true);
        productComboBoxType.maxDropDownItems(10);
        this.sheet.getCell(-1, this.productColumn).cellType(productComboBoxType);
        var datePickerCellType = new DatePickerCellType();
        this.sheet.getCell(-1, this.prodDateColumn).cellType(datePickerCellType);
        // Add Production Change Reason dropdowns
        var prodChangeReasonComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
        var prodChangeReasonStrArr = [];
        this.productionChangeReasons.forEach(function (productionChangeReason) {
            prodChangeReasonStrArr.push(productionChangeReason.name);
        });
        prodChangeReasonComboBoxType.items(prodChangeReasonStrArr).editable(true);
        prodChangeReasonComboBoxType.maxDropDownItems(10);
        this.sheet
            .getCell(-1, this.prodChangeReasonColumn)
            .cellType(prodChangeReasonComboBoxType);
        var saveBtnCellType = this.changeSaveButtonColor(environment.buttonDisabled);
        this.sheet.getCell(-1, this.saveColumn).cellType(saveBtnCellType);
        this.sheet.getCell(-1, this.saveColumn).cellPadding('1px');
        this.sheet
            .getCell(-1, this.saveColumn)
            .vAlign(GC.Spread.Sheets.VerticalAlign.center);
        this.sheet
            .getCell(-1, this.saveColumn)
            .hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet
            .getCell(-1, this.saveColumn)
            .foreColor(environment.buttonDisabledText);
        // Define columns
        var prodPropertyNoColInfo = {
            name: 'propertyNo',
            displayName: 'Property #',
            size: 120
        };
        var prodPropertyNameColInfo = {
            name: 'propertyName',
            displayName: 'Property Name',
            size: 220
        };
        var prodWellApiColInfo = {
            name: 'wellApiNo',
            displayName: 'Well API #',
            size: 140
        };
        var prodWellNameColInfo = {
            name: 'wellName',
            displayName: 'Well Name',
            size: 180
        };
        var prodBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
        var prodBCTransIdColInfo = {
            name: 'bcTransId',
            displayName: 'BC #',
            size: 150
        };
        var prodProductColInfo = {
            name: 'productName',
            displayName: 'Product #',
            size: 130
        };
        var prodDateColInfo = {
            name: 'prodDate',
            displayName: 'Prod. Date',
            size: 100
        };
        var prodVolumeColInfo = {
            name: 'amount',
            displayName: 'Prod. Volume',
            size: 100
        };
        var prodBtuColInfo = { name: 'btuStr', displayName: 'BTU', size: 90 };
        var prodGravityColInfo = {
            name: 'gravityStr',
            displayName: 'Gravity',
            size: 90
        };
        var prodTempColInfo = {
            name: 'temperature',
            displayName: 'Temperature',
            size: 90
        };
        var prodPercentChangeColInfo = {
            name: 'percentChange',
            displayName: '% Change 30 Days',
            size: 115
        };
        var prodReasonColInfo = {
            name: 'reason',
            displayName: 'Reason',
            size: 200
        };
        var prodSaveColInfo = { name: '', displayName: '', size: 80 };
        this.sheet.autoGenerateColumns = false;
        this.sheet.setDataSource(this.production);
        this.sheet.bindColumn(this.propertyNoColumn, prodPropertyNoColInfo);
        this.sheet.bindColumn(this.propertyNameColumn, prodPropertyNameColInfo);
        this.sheet.bindColumn(this.wellApiColumn, prodWellApiColInfo);
        this.sheet.bindColumn(this.wellNameColumn, prodWellNameColInfo);
        this.sheet.bindColumn(this.blockChainColumn, prodBCTransImgColInfo);
        this.sheet.bindColumn(this.blockChainTransIdColumn, prodBCTransIdColInfo);
        this.sheet.bindColumn(this.productColumn, prodProductColInfo);
        this.sheet.bindColumn(this.prodDateColumn, prodDateColInfo);
        this.sheet.bindColumn(this.prodVolumeColumn, prodVolumeColInfo);
        this.sheet.bindColumn(this.btuColumn, prodBtuColInfo);
        this.sheet.bindColumn(this.gravityColumn, prodGravityColInfo);
        this.sheet.bindColumn(this.tempratureColumn, prodTempColInfo);
        this.sheet.bindColumn(this.percentChangeColumn, prodPercentChangeColInfo);
        this.sheet.bindColumn(this.prodChangeReasonColumn, prodReasonColInfo);
        this.sheet.bindColumn(this.saveColumn, prodSaveColInfo);
        this.sheet.setColumnCount(this.gridColumnCount, GC.Spread.Sheets.SheetArea.viewport);
        // Lock/Readonly all columns
        this.sheet.options.isProtected = true;
        this.sheet.options.protectionOptions = {
            allowSelectUnlockedCells: true,
            allowSelectLockedCells: false
        };
        // Unlock from columns 6 for 6 columns (columns 6, 7, 8, 9, 10, 11) - [Product - Temprature]
        this.sheet.getRange(-1, 6, -1, 6).locked(false);
        // Unlock from columns 13 for 1 columns (columns 13) - [Reason]
        this.sheet.getRange(-1, 13, -1, 1).locked(false);
        // set cell alignment
        this.sheet
            .getCell(-1, this.prodDateColumn)
            .hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet
            .getCell(-1, this.prodVolumeColumn)
            .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet
            .getCell(-1, this.btuColumn)
            .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet
            .getCell(-1, this.gravityColumn)
            .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet
            .getCell(-1, this.tempratureColumn)
            .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet
            .getCell(-1, this.percentChangeColumn)
            .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        // Create all Grid events required
        this.createGridEvents();
        this.sheet.resumePaint();
        console.log('Initialise Grid - complete');
    };
    ProductionContentComponent.prototype.applyDataBinding = function (filteredProduction) {
        // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);
        var _this = this;
        // console.log('Start data binding');
        this.sheet.suspendPaint();
        this.sheet.setDataSource(filteredProduction);
        // console.log('Row Binding - start');
        filteredProduction.forEach(function (rowProductionObject, rowIndex) {
            // Remove vertical grid-lines
            _this.sheet
                .getCell(rowIndex, -1)
                .borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
            var cell = _this.sheet.getCell(rowIndex, _this.blockChainColumn);
            var imgBlockChain = null;
            if (filteredProduction[rowIndex].bcTransId != null) {
                imgBlockChain = './assets/images/blockchain_cell@2x.png';
            }
            cell.text('');
            cell.backgroundImage(imgBlockChain);
            _this.addValidators(rowIndex);
            // get Percent Change 30 Days
            var percentChange = rowProductionObject.percentChange;
            var reasonId = rowProductionObject.prodChangeReasonId;
            // Highlight cells with percentage < [this.percentVolumeChangeHighlight](= -20%) and no reason selected
            if (percentChange < _this.percentVolumeChangeHighlight) {
                if (reasonId === 0) {
                    var pcCell = _this.sheet.getCell(rowIndex, _this.percentChangeColumn);
                    pcCell.backColor(environment.gridCellChanged);
                    pcCell = _this.sheet.getCell(rowIndex, _this.prodChangeReasonColumn);
                    pcCell.backColor(environment.gridCellChanged);
                }
            }
        });
        // Highlight first row as selected - except blockchain graphic cell
        for (var col = 0; col < this.gridColumnCount - 1; col++) {
            if (col !== this.blockChainColumn) {
                this.sheet.getCell(0, col).backColor(environment.gridHighlight);
            }
        }
        // Add empty row at end of sheet
        var lastRow = this.sheet.getRowCount();
        this.sheet.addRows(lastRow, 1);
        this.sheet.resumePaint();
        var today = new Date();
        this.maxStartDate =
            this.prodSearch.startDateMonth === today.getMonth() + 1 &&
                this.prodSearch.startDateYear === today.getFullYear();
        this.maxEndDate =
            this.prodSearch.endDateMonth === today.getMonth() + 1 &&
                this.prodSearch.endDateYear === today.getFullYear();
        console.log('Binding Complete - Last row: ' + lastRow);
    };
    ProductionContentComponent.prototype.uploadProduction = function () {
        console.log('Production-Content: Upload Production - clicked');
        this.openUploadProduction();
    };
    ProductionContentComponent.prototype.getLastDayOfMonth = function (year, month) {
        // get first day of month
        var date = new Date(year, month, 1);
        // Add 1 month and subtract 1 day
        date = new Date(date.setMonth(date.getMonth() + 1) - 1);
        return date;
    };
    ProductionContentComponent.prototype.filter = function () {
        var _this = this;
        // Filters the "allProduction" based on selected filters from the screen
        this.production = [];
        var today = this.transformDate(new Date());
        // console.log('Todays Date: ' + today);
        this.allProduction.forEach(function (prodObj, rowIndex) {
            // console.log(prodObj);
            // If prodObj is null, undefined or empty - ignore
            if (prodObj != null && Object.keys(prodObj).length !== 0) {
                var includeProd_1 = true;
                var startDate = new Date(_this.prodSearch.startDateYear, _this.prodSearch.startDateMonth - 1, 1);
                var endDate = _this.getLastDayOfMonth(_this.prodSearch.endDateYear, _this.prodSearch.endDateMonth - 1);
                // console.log('Production date: ' + this.transformDate(prodObj.productionDate) + '   Start: ' + this.transformDate(startDate)
                //   + '   End Date: ' + this.transformDate(endDate));
                if (_this.transformDate(prodObj.productionDate) <
                    _this.transformDate(startDate)) {
                    includeProd_1 = false;
                }
                if (includeProd_1 &&
                    _this.transformDate(prodObj.productionDate) >
                        _this.transformDate(endDate)) {
                    includeProd_1 = false;
                }
                // console.log('Production Search State: ', this.prodSearch.state);
                if (includeProd_1 && _this.prodSearch.state !== '') {
                    includeProd_1 = (prodObj.well.property.county.USState.id === Number(_this.prodSearch.state));
                }
                if (includeProd_1 && _this.prodSearch.searchStr.length > 0) {
                    // Check property No, lease No, Property Name and Property Desc against any part of the search string
                    includeProd_1 = true; // Assuem property to be included
                    _this.prodSearch.searchStr.forEach(function (searchStr) {
                        includeProd_1 =
                            includeProd_1 &&
                                (prodObj.propertyNo.indexOf(searchStr) !== -1 ||
                                    prodObj.wellApiNo.indexOf(searchStr) !== -1 ||
                                    prodObj.propertyName
                                        .toUpperCase()
                                        .indexOf(searchStr.toUpperCase()) !== -1 ||
                                    prodObj.well.property.description
                                        .toUpperCase()
                                        .indexOf(searchStr.toUpperCase()) !== -1 ||
                                    prodObj.wellName
                                        .toUpperCase()
                                        .indexOf(searchStr.toUpperCase()) !== -1 ||
                                    prodObj.well.property.county.name
                                        .toUpperCase()
                                        .indexOf(searchStr.toUpperCase()) !== -1 ||
                                    prodObj.well.property.county.USState.name
                                        .toUpperCase()
                                        .indexOf(searchStr.toUpperCase()) !== -1);
                    });
                }
                // console.log('Production Search Type: ', this.prodSearch.prodType);
                if (includeProd_1 && _this.prodSearch.prodType > 0) {
                    if (_this.prodSearch.prodType === 2) {
                        // Complete - EffectiveTo date has passed
                        if (prodObj.well.property.effectiveTo !== null) {
                            includeProd_1 = prodObj.well.property.effectiveTo < today;
                            // console.log('Effective To: ' + prodObj.well.property.effectiveTo);
                        }
                        else {
                            // Property effective to date not set  => Still in Progress (NOT complete)
                            includeProd_1 = false;
                        }
                        // console.log('Complete: Include: ' + includeProd);
                    }
                    else {
                        // In Progress (= In production) => effectiveTo date not reached yet
                        if (prodObj.well.property.effectiveTo !== null) {
                            includeProd_1 = prodObj.well.property.effectiveTo > today;
                        }
                        else {
                            includeProd_1 = true;
                        }
                        // console.log('In Progress: Include: ' + includeProd + '   prod Object: ' + prodObj);
                    }
                }
                // console.log('IncludeProduction: ' + includeProd);
                if (includeProd_1) {
                    _this.production.push(prodObj);
                }
            }
        });
        // console.log('All Production: ', this.allProduction);
        // console.table('Production: ', this.production);
        this.applyDataBinding(this.production);
    };
    ProductionContentComponent.prototype.doSearch = function () {
        var str = this.prodSearchForm.get('searchStr').value;
        // console.log('Str: ' + str);
        this.prodSearch.searchStr = str.split(' ');
        // console.log('searchStr: ', this.prodSearch.searchStr);
        this.filter();
    };
    ProductionContentComponent.prototype.onStateChange = function () {
        console.log('State changed: ' + this.prodSearchForm.get('state').value);
        this.prodSearch.state = this.prodSearchForm.get('state').value;
        this.filter();
    };
    ProductionContentComponent.prototype.btnAllProduction = function () {
        console.log('All Production');
        this.prodSearch.prodType = 0;
        this.filter();
    };
    ProductionContentComponent.prototype.btnInProgressProduction = function () {
        console.log('In Progress');
        this.prodSearch.prodType = 1;
        this.filter();
    };
    ProductionContentComponent.prototype.btnCompleteProduction = function () {
        console.log('Complete');
        this.prodSearch.prodType = 2;
        this.filter();
    };
    ProductionContentComponent.prototype.dateStr = function (month, year) {
        return this.messagesService.monthString(month) + ' ' + year;
    };
    ProductionContentComponent.prototype.onStartDateDown = function () {
        // console.log('Start Date Down: ' + this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear));
        if (this.prodSearch.startDateMonth === 1) {
            this.prodSearch.startDateMonth = 12;
            this.prodSearch.startDateYear = this.prodSearch.startDateYear - 1;
        }
        else {
            this.prodSearch.startDateMonth = this.prodSearch.startDateMonth - 1;
        }
        this.prodSearch.startStr = this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear);
        // console.log('Start Date Down: ' + this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear));
        this.filter();
    };
    ProductionContentComponent.prototype.onStartDateUp = function () {
        // console.log('Start Date Up: ' + this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear));
        if (this.prodSearch.startDateMonth === 12) {
            this.prodSearch.startDateMonth = 1;
            this.prodSearch.startDateYear = this.prodSearch.startDateYear + 1;
        }
        else {
            this.prodSearch.startDateMonth = this.prodSearch.startDateMonth + 1;
        }
        this.prodSearch.startStr = this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear);
        // console.log('Start Date Up: ' + this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear));
        this.filter();
    };
    ProductionContentComponent.prototype.onEndDateDown = function () {
        // console.log('End Date Down: ' + this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear));
        if (this.prodSearch.endDateMonth === 1) {
            this.prodSearch.endDateMonth = 12;
            this.prodSearch.endDateYear = this.prodSearch.endDateYear - 1;
        }
        else {
            this.prodSearch.endDateMonth = this.prodSearch.endDateMonth - 1;
        }
        this.prodSearch.endStr = this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear);
        // console.log('End Date Down: ' + this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear));
        this.filter();
    };
    ProductionContentComponent.prototype.onEndDateUp = function () {
        // console.log('End Date Up: ' + this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear));
        if (this.prodSearch.endDateMonth === 12) {
            this.prodSearch.endDateMonth = 1;
            this.prodSearch.endDateYear = this.prodSearch.endDateYear + 1;
        }
        else {
            this.prodSearch.endDateMonth = this.prodSearch.endDateMonth + 1;
        }
        this.prodSearch.endStr = this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear);
        // console.log('End Date Up: ' + this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear));
        this.filter();
    };
    ProductionContentComponent.prototype.onBack = function () {
        console.log('Back clicked');
        this.location.back();
    };
    ProductionContentComponent.prototype.exportReport = function (reportType) {
        this.productionService.getReport(this.production, reportType).subscribe(function (data) {
            var blob = new Blob([data], { type: 'application/octet-stream' });
            var extension = '';
            if (reportType === 1) {
                extension = '.pdf';
            }
            else if (reportType === 2) {
                extension = '.xlsx';
            }
            else if (reportType === 3) {
                extension = '.csv';
            }
            FileSaver.saveAs(blob, "production" + extension);
        });
    };
    ProductionContentComponent.prototype.workbookInit = function (args) {
        console.log("Workbook Init", { args: args });
        this.spread = args.spread;
        this.sheet = this.spread.getActiveSheet();
        this.sheetWidth = this.sheet.getViewportWidth(1);
        console.log('SheetWidth: ', this.sheetWidth);
    };
    return ProductionContentComponent;
}());
export { ProductionContentComponent };
export { ɵ0 };
