import { DivisionOrder } from './division_order';
import { Sale } from './sale';
import { PaymentStatus } from './payment_status';
import { BankTrustManager } from './bank-trust-manager';
import { PaymentDeduction } from './payment_deduction';

export class Payment {
  id: number;
  bcTransId: string;
  bcBlockId: string;
  divisionOrder: DivisionOrder;
  sale: Sale;
  paymentDate: Date;
  ownerGrossValue: number;
  ownerNetValue: number;
  ownerVolume: number;
  totalDeductions: number;
  paymentStatus: PaymentStatus;

  propertyId?: number;
  propertyNo?: string;
  propertyName?: string;
  divOrderNumber?: number;
  ownerName?: string;
  bankTrustManager: BankTrustManager;
  bankName?: string;
  saleDate?: string;
  saleDateSort?: string;
  productId?: number;
  productName?: string;
  price?: string;
  btuStr?: string;
  btuValue?: number;
  volume?: string;
  volumeValue?: number;
  gross?: string;
  grossValue?: number;
  ownerNetValueStr?: string;
  deductions?: string;
  deductionsValue?: number;
  interestTypeId?: number;
  interestTypeAbbr?: string;
  decimalInterest?: string;
  decimalInterestValue?: number;
  royaltyInterest?: string;
  royaltyInterestValue?: number;
  ownerVolumeStr?: string;
  paymentDateStr?: string;
  paymentDateSort?: string;
  statusName?: string;

  // This is used when we expand deductions to see deduction types
  deductionType?: string;

  paymentDeductions: PaymentDeduction[];

}
