import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare var $: any; // jquery
declare let datepicker;
declare let modals;
declare let removeHash;

@Component({
  selector: 'app-sales-add',
  templateUrl: './sales-add.component.html',
  styleUrls: ['./sales-add.component.css']
})
export class SalesAddComponent implements OnInit {

  @Input() displayAddSales: boolean;
  @Output() displayAddSalesChange = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {

      datepicker();
      modals();

      // $('#dialog_add_sales').dialog({
      //   autoOpen: false,
      //   height: 'auto',
      //   width: 960,
      //   modal: true,
      //   show: 'fade',
      //   hide: 'fade',
      //   open: () => { }
      // });

      // Add Property
      // $('#btn_add_sales').click(() => {
      //   $('#dialog_add_sales').dialog('open');
      //   $('body').addClass('no_scroll');
      // });

      // Switch to Upload Property
      // $('#btn_upload_sales, #btn_upload_sales_alt').click(() => {
      //   $('#dialog_add_sales').dialog('close');
      //   $('#dialog_upload_sales').dialog('open');
      //   $('body').addClass('no_scroll');
      // });


      // Confirm Adding a Note
      // $('#btn_confirm_add_sales').click(() => {
      //   $('#dialog_add_sales').dialog('close');
      //   $('#message_processing_sales').fadeIn();

      //   // Wait duration until hiding the loader
      //   setTimeout(
      //     () => {
      //       $('#message_processing_sales').hide();
      //     }, 3000);

      //   // Wait duration of Loader before showing Message
      //   setTimeout(
      //     () => {
      //       $('#message_add_sales').show().delay(5000).fadeOut();
      //     }, 3000);

      //   $('html, body').removeClass('no_scroll');
      // });


      // Show the Dialog if the has on the page is add_sales
      // $(() => {
      //   if (location.hash === '#add_sales') {
      //     $('#dialog_add_sales').dialog('open');
      //     $('body').addClass('no_scroll');
      //     removeHash();
      //   }
      // });
    });
  }

  closeDialog() {
    this.displayAddSales = false;
    this.displayAddSalesChange.emit(false);
  }

}
