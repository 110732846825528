import { User } from 'src/app/model/user';
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { DatePipe, Location, formatDate } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import * as GC from '@grapecity/spread-sheets';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';

import { Production } from 'src/app/model/production';
import { Product } from 'src/app/model/product';
import { Operator } from 'src/app/model/operator';
import { Well } from 'src/app/model/well';
import { USState } from 'src/app/model/us_state';
import { ProductionChangeReason } from 'src/app/model/production_change_reason';
import { ProductionSearch } from 'src/app/model/production_search';

import { ProductionService } from 'src/app/production.service';
import { LookupService } from 'src/app/lookup.service';
import { MessagesService } from 'src/app/messages.service';
import { ValidatorService } from 'src/app/validator.service';

import { DatePickerCellType } from 'src/app/shared/spread/DatePickerCellType';
import { map } from 'rxjs/operators';

declare var $: any; // jquery
const clone = obj => JSON.parse(JSON.stringify(obj));

@Component({
  selector: 'app-production-content',
  templateUrl: './production-content.component.html',
  styleUrls: ['./production-content.component.css'],
  providers: [DatePipe]
})
export class ProductionContentComponent implements OnInit {
  @ViewChild('spreadContainer', { static: false }) spreadContainer: ElementRef;

  // Display/Hide Add Production
  @Input() displayAddProduction: boolean;
  @Output() displayAddProductionChange = new EventEmitter<boolean>();

  // Display/Hide Upload Production
  @Input() displayUploadProduction: boolean;
  @Output() displayUploadProductionChange = new EventEmitter<boolean>();

  prodSearchForm: FormGroup = this.fb.group({
    searchStr: [''],
    state: ['']
  });

  private readonly blockchainExplorerUrl = `${environment.blockchainExplorerUrl}`;

  private sheet: GC.Spread.Sheets.Worksheet = null;
  private spread: GC.Spread.Sheets.Workbook;

  private sheetWidth: number;

  private msg = this.messagesService.messages;

  tabStripVisible = false;
  spreadBackColor = 'white';
  sheetName = 'Production List';
  hostStyle = {
    height: '100%',
    width: '100%'
  };
  data: any;
  autoGenerateColumns = false;

  // Define column indexes
  propertyNoColumn = 0;
  propertyNameColumn = 1;
  wellApiColumn = 2;
  wellNameColumn = 3;
  blockChainColumn = 4;
  blockChainTransIdColumn = 5;
  productColumn = 6;
  prodDateColumn = 7;
  prodVolumeColumn = 8;
  btuColumn = 9;
  gravityColumn = 10;
  tempratureColumn = 11;
  percentChangeColumn = 12;
  prodChangeReasonColumn = 13;
  saveColumn = 14;
  gridColumnCount = 15;

  // This is the percentage change we wish to highlight if NO reason for the change is selected
  percentVolumeChangeHighlight = -20.0;

  allProduction: Production[];
  production: Production[];
  products: Product[];
  operators: Operator[];
  states: USState[];
  productionChangeReasons: ProductionChangeReason[];

  prodSearch: ProductionSearch;
  maxStartDate = false;
  maxEndDate = true;

  lastUpdated: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private productionService: ProductionService,
    private lookupService: LookupService,
    private messagesService: MessagesService,
    private validatorService: ValidatorService,
    private datePipe: DatePipe,
    private location: Location,
    private router: Router
  ) { }

  closeAddProduction() {
    this.displayAddProduction = false;
    this.displayAddProductionChange.emit(false);
  }

  openAddProduction() {
    this.displayAddProduction = true;
    this.displayAddProductionChange.emit(true);
  }

  closeUploadProduction() {
    this.displayUploadProduction = false;
    this.displayUploadProductionChange.emit(false);
  }

  openUploadProduction() {
    this.displayUploadProduction = true;
    this.displayUploadProductionChange.emit(true);
  }

  ngOnInit() {
    // initialise Search criteria
    const today = new Date();

    this.prodSearch = new ProductionSearch();
    this.prodSearch.prodType = 0;
    this.prodSearch.startDateMonth = 1;
    this.prodSearch.startDateYear = today.getFullYear();
    this.prodSearch.startStr = this.dateStr(
      this.prodSearch.startDateMonth,
      this.prodSearch.startDateYear
    );
    this.prodSearch.endDateMonth = today.getMonth() + 1;
    this.prodSearch.endDateYear = today.getFullYear();
    this.prodSearch.endStr = this.dateStr(
      this.prodSearch.endDateMonth,
      this.prodSearch.endDateYear
    );
    this.prodSearch.searchStr = [];
    this.prodSearch.state = '';

    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;

    console.log('Today FullYear: ' + today.getFullYear());

    Promise.all([
      this.getProducts(),
      this.getProductionChangeReasons(),
      this.getOperators(),
      this.getUSStates(),
      // For download transfer speed we get this years production only - we will load the rest of production async
      this.getProductionByYear(operatorId, today.getFullYear())
    ]).then(values => {
      this.getProduction(operatorId);
    });

    this.lastUpdated.setDate(today.getDate() - 2);
  }

  async getProducts() {
    this.products = (await this.lookupService.getProducts()) as Product[];
  }

  async getProductionChangeReasons() {
    this.productionChangeReasons = (await this.productionService.getProdChangeReasons()) as ProductionChangeReason[];
  }

  async getOperators() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;
    this.operators = (await this.lookupService.getOperators(
      operatorId
    )) as Operator[];
  }

  async getUSStates() {
    this.states = (await this.lookupService.getStates()) as USState[];
  }

  getProduction(operatorId: number) {
    console.log('getProduction - start: ' + new Date().toISOString());
    return this.productionService.getProduction(operatorId).pipe(
      map(
        production => {
          console.log('getProduction - Back from API: ' + new Date().toISOString());
          console.log({ production });

          let tempProduction: Production[] = JSON.parse(
            JSON.stringify(production)
          );
          tempProduction = this.setProductionValues(tempProduction);

          // this.production = tempProduction;
          this.allProduction = tempProduction;

          // this.initialiseGrid();
          console.log('getProduction - complete: ' + new Date().toISOString() + ' No. of record: ' + this.allProduction.length);

          return production;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  async getProductionByYear(operatorId: number, year: number) {
    console.log('getProductionByYear - start: ' + new Date().toISOString());
    return this.productionService.getProductionByYear(operatorId, year).pipe(
      map(
        production => {
          console.log('getProductionByYear - Back from API: ' + new Date().toISOString());
          console.log({ production });

          let tempProduction: Production[] = JSON.parse(
            JSON.stringify(production)
          );
          tempProduction = this.setProductionValues(tempProduction);

          this.production = tempProduction;
          this.allProduction = tempProduction;

          this.initialiseGrid();
          console.log('getProductionByYear - complete: ' + new Date().toISOString() + ' No. of record: ' + this.allProduction.length);

          this.filter();
          return production;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  getOperatorById(operatorId: number) {
    return this.operators.find(o => o.id === operatorId);
  }

  getProduct(productName: string) {
    return this.products.find(p => p.name.trim() === productName.trim());
  }

  getProdChangeReason(reason: string) {
    // console.log('Reason: ' + reason);
    // console.log({ ProductionChangeReasons: this.productionChangeReasons });

    let productionChangeReason = this.productionChangeReasons.find(
      p => p.name.trim() === reason.trim()
    );
    // console.log('ProductionChangeReason: ', { productionChangeReason });

    if (productionChangeReason == null) {
      productionChangeReason = new ProductionChangeReason();
      if (reason != null && reason !== '') {
        productionChangeReason.id = 7;
        productionChangeReason.freeText = true;
        productionChangeReason.name = reason;
      } else {
        productionChangeReason.id = 0;
        productionChangeReason.freeText = false;
        productionChangeReason.name = '';
      }
    }

    return productionChangeReason;
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  setProductionValue(productionRec: Production): Production {
    console.log('Production: ', { productionRec });

    if (productionRec.productionDate != null) {
      productionRec.prodDate = formatDate(
        productionRec.productionDate,
        'MM/dd/yyyy',
        'en'
      );
      productionRec.propertyNo = productionRec.well.property.propertyNo;
      productionRec.propertyName = productionRec.well.property.name;
      productionRec.wellApiNo = productionRec.well.apiNo;
      productionRec.wellName = productionRec.well.name;
      productionRec.productName = productionRec.product.name;
      productionRec.percentChange = parseFloat(
        productionRec.percentChange.toFixed(2)
      );
      if (productionRec.btu < 0.001) {
        productionRec.btuStr = '';
      } else {
        productionRec.btuStr = productionRec.btu.toFixed(2);
      }
      if (productionRec.gravity < 0.001) {
        productionRec.gravityStr = '';
      } else {
        productionRec.gravityStr = productionRec.gravity.toFixed(2);
      }
    }

    return productionRec;
  }

  setProductionValues(filteredProduction: Production[]): Production[] {
    filteredProduction.forEach(productionRec => {
      if (productionRec.productionDate != null) {
        productionRec.prodDate = formatDate(
          productionRec.productionDate,
          'MM/dd/yyyy',
          'en'
        );
      }
      productionRec.propertyNo = productionRec.well.property.propertyNo;
      productionRec.propertyName = productionRec.well.property.name;
      productionRec.wellApiNo = productionRec.well.apiNo;
      productionRec.wellName = productionRec.well.name;
      productionRec.productName = productionRec.product.name;
      productionRec.percentChange = parseFloat(
        productionRec.percentChange.toFixed(2)
      );
      if (productionRec.btu < 0.001) {
        productionRec.btuStr = '';
      } else {
        productionRec.btuStr = productionRec.btu.toFixed(2);
      }
      if (productionRec.gravity < 0.001) {
        productionRec.gravityStr = '';
      } else {
        productionRec.gravityStr = productionRec.gravity.toFixed(2);
      }
    });

    // console.log({ filteredProduction });
    return filteredProduction;
  }

  private addValidators(row: number) {
    // Must select Well API
    let validator = this.validatorService.cellNotBlank(
      this.wellApiColumn,
      row,
      this.msg.production.wellApiNo
    );
    this.sheet.setDataValidator(row, this.wellApiColumn, 1, 1, validator);

    // Must Select Product
    validator = this.validatorService.cellNotBlank(
      this.productColumn,
      row,
      this.msg.production.product
    );
    this.sheet.setDataValidator(row, this.productColumn, 1, 1, validator);

    // Must select production date
    validator = this.validatorService.cellNotBlank(
      this.prodDateColumn,
      row,
      this.msg.production.prodDate
    );
    this.sheet.setDataValidator(row, this.prodDateColumn, 1, 1, validator);

    // Must input production Amount
    validator = this.validatorService.cellNotBlank(
      this.prodVolumeColumn,
      row,
      this.msg.production.prodVol
    );
    this.sheet.setDataValidator(row, this.prodVolumeColumn, 1, 1, validator);
  }

  validateProduction(row: number) {
    // console.log('Validate Production - row: ' + row);

    if (
      !this.sheet.isValid(
        row,
        this.wellApiColumn,
        this.sheet.getCell(row, this.wellApiColumn).value()
      )
    ) {
      alert(this.msg.production.wellApiNo);
      return false;
    }
    if (
      !this.sheet.isValid(
        row,
        this.productColumn,
        this.sheet.getCell(row, this.productColumn).value()
      )
    ) {
      alert(this.msg.production.product);
      return false;
    }
    if (
      !this.sheet.isValid(
        row,
        this.prodDateColumn,
        this.sheet.getCell(row, this.prodDateColumn).value()
      )
    ) {
      alert(this.msg.production.prodDate);
      return false;
    }
    if (
      !this.sheet.isValid(
        row,
        this.prodVolumeColumn,
        this.sheet.getCell(row, this.prodVolumeColumn).value()
      )
    ) {
      alert(this.msg.production.prodVol);
      return false;
    }

    return true;
  }

  async saveProduction(origProduction: Production): Promise<Production> {
    // Clone Production, so that the original version will not be formatted and thus affect the displayed value
    const production = clone(origProduction);
    console.log('Cloned Production: ', { production });

    // Default operator for the moment - Operator will probably be stored globally for the session
    production.operator = this.getOperatorById(1);
    production.operatorId = 1;

    if (production.productName != null) {
      production.product = this.getProduct(production.productName);
    }

    if (production.reason != null) {
      production.productionChangeReason = this.getProdChangeReason(
        production.reason
      );
      production.prodChangeReasonId = production.productionChangeReason.id;
      production.reason = production.productionChangeReason.name;
    }

    // Well
    const well: Well = new Well();
    well.id = 0;
    well.apiNo = production.wellApiNo;
    well.name = production.wellName;
    well.operator = production.operator;
    well.products = new Array();
    well.products.push(production.product);

    production.well = well;

    console.log('Prod Date from Grid: ' + production.prodDate);
    if (production.prodDate.length < 10) {
      console.log('Production date is null or empty');
    }
    const prodDate =
      production.prodDate.substr(6, 4) +
      '-' +
      production.prodDate.substr(0, 2) +
      '-' +
      production.prodDate.substr(3, 2);
    console.log('Date: ' + prodDate);

    production.productionDate = prodDate;

    if (production.btuStr === '') {
      production.btu = 0;
    } else {
      production.btu = production.btuStr;
    }

    if (production.gravityStr === '') {
      production.gravity = 0;
    } else {
      production.gravity = production.gravityStr;
    }

    // prodDate, prodVolume, percentChange, prodChangeReason are all in the production object
    console.log('Save Cloned Production: ', { production });

    // Null the insertDate/modifiedDate because they get lost in translation and caused Type-mismatch
    production.insertDate = null;
    production.modifiedDate = null;

    let result = (await this.productionService.save(production)) as Production;

    // Assign the display values back to the save object
    result = this.setProductionValue(result);

    return result;
  }

  changeSaveButtonColor(color) {
    const saveBtnCellType = new GC.Spread.Sheets.CellTypes.Button();
    // saveBtnCellType.buttonBackColor('#CFCFCF');
    saveBtnCellType.buttonBackColor(color);
    saveBtnCellType.text('Save');
    saveBtnCellType.marginBottom(1);
    saveBtnCellType.marginTop(1);
    saveBtnCellType.marginLeft(10);
    saveBtnCellType.marginRight(10);

    return saveBtnCellType;
  }

  getProductionLastMonth(currProd: Production) {
    // Get production last month
    const prodDateArr = currProd.productionDate.toString().split('-');
    console.log('Date: ' + prodDateArr);

    const currYear = Number(prodDateArr[0]);
    const currMonth = Number(prodDateArr[1]);
    console.log('Current Year:' + currYear + '    Month: ' + currMonth);

    let prevYear;
    let prevMonth;
    if (currMonth === 1) {
      prevMonth = 12;
      prevYear = currYear - 1;
    } else {
      prevMonth = currMonth - 1;
      prevYear = currYear;
    }
    console.log('Previous Year:' + prevYear + '    Month: ' + prevMonth);

    // Search global array for production of previous month
    let prodVolumeLastMonth = 0;
    let prodDaysLastMonth = 0;
    this.allProduction.forEach((prodObj: Production, rowIndex: number) => {
      if (
        prodObj.wellApiNo === currProd.wellApiNo &&
        prodObj.productId === currProd.productId
      ) {
        const dateArr = prodObj.productionDate.toString().split('-');
        const dateYear = Number(dateArr[0]);
        const dateMonth = Number(dateArr[1]);
        if (dateYear === prevYear && dateMonth === prevMonth) {
          // Previous month production
          prodVolumeLastMonth += prodObj.amount;
          prodDaysLastMonth += 1;
          console.log(
            'Prod Date: ',
            prodObj.productionDate + ' Last Month Days: ' + prodDaysLastMonth + ' Last Month Amount: ' + prodObj.amount
          );
        }
      }
    });
    console.log('Total Production Last month: ' + prodVolumeLastMonth);
    console.log('Total Production Days Last month: ' + prodDaysLastMonth);
    console.log('Total Production This month: ' + currProd.amount);

    let percentChange = 0.0;
    if (prodDaysLastMonth > 0) {
      const avgProdVolumeLastMonth = prodVolumeLastMonth / prodDaysLastMonth;
      percentChange =
        (100 * (currProd.amount - avgProdVolumeLastMonth)) / avgProdVolumeLastMonth;
      percentChange = parseFloat(percentChange.toFixed(2));

      console.log('Percentage Change: ' + percentChange);
    }

    return percentChange;
  }

  updateGlobalProduction(prodObj: Production) {
    for (let i = 0; i < this.allProduction.length; i++) {
      if (this.allProduction[i].id === prodObj.id) {
        this.allProduction[i] = prodObj;
      }
    }
  }

  createGridEvents() {
    // Bind click-event of Blockchain cell
    this.spread.bind(GC.Spread.Sheets.Events.CellClick, (e, args) => {
      const row = args.row;
      const col = args.col;
      // let cell = this.sheet.getCell(row, col);

      // Get selected Production from array
      const selectedProduction = this.production[row];
      console.log(`[Row: ${row}] Property with Id ${selectedProduction.id}`);

      if (selectedProduction) {
        console.log({ selectedProduction });
        console.log(
          `[Row: ${row}] Production with Id ${selectedProduction.id} found BlockId: ${selectedProduction.bcBlockId}`
        );
      } else {
        console.log(`[Row: ${row}] Production not found`);
      }

      if (row === this.production.length) {
        if (col >= this.propertyNoColumn && col <= this.wellNameColumn) {
          // We need to do a modal Well search
        }
      } else if (
        col === this.blockChainColumn ||
        col === this.blockChainTransIdColumn
      ) {
        const bcTransId = this.sheet.getValue(
          row,
          this.blockChainTransIdColumn
        );
        if (bcTransId != null && bcTransId !== '') {
          let url = '';
          if (selectedProduction.bcBlockId != null) {
            url = this.blockchainExplorerUrl + selectedProduction.bcBlockId;
          } else {
            url = this.blockchainExplorerUrl;
          }
          window.open(url);
        }
      }
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.EnterCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColumnCount - 1; col++) {
        const cell = this.sheet.getCell(row, col);
        if (col !== this.blockChainColumn) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridHighlight);
          }
        }
      }
      // console.log('Row Enter Cell: ' + row);
      this.sheet.resumePaint();
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Un-highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColumnCount - 1; col++) {
        const cell = this.sheet.getCell(row, col);

        if (col !== this.blockChainColumn) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridBackground);
          }
        }
      }

      this.sheet
        .getCell(row, -1)
        .borderTop(
          new GC.Spread.Sheets.LineBorder(
            environment.gridCellBorder,
            GC.Spread.Sheets.LineStyle.thin
          )
        );
      this.sheet
        .getCell(row, -1)
        .borderBottom(
          new GC.Spread.Sheets.LineBorder(
            environment.gridCellBorder,
            GC.Spread.Sheets.LineStyle.thin
          )
        );
      // console.log('Row Leave Cell: ' + row);

      this.sheet.resumePaint();
    });

    this.spread.bind(GC.Spread.Sheets.Events.ValueChanged, (e, args) => {
      const row = args.row;
      const col = args.col;
      this.sheet.suspendPaint();

      // Value changed - clear blockchain transId, change the colour of the save button
      this.production[row].bcTransId = null;

      // Clear Blockchain graphic to the cell
      const cell = this.sheet.getCell(row, this.blockChainColumn);
      cell.text('');
      cell.backgroundImage(null);

      // Cell has changed - enable save button and change its colour
      this.sheet.getRange(row, this.saveColumn, 1, 1).locked(false);
      console.log('Save button unlocked');

      const saveSingleBtnCellType = this.changeSaveButtonColor(
        environment.buttonEnabled
      );
      this.sheet.getCell(row, this.saveColumn).cellType(saveSingleBtnCellType);
      this.sheet
        .getCell(row, this.saveColumn)
        .foreColor(environment.buttonEnabledText);

      // Change the back-ground color to reflect a change in grid data
      this.sheet.getCell(row, col).backColor(environment.gridCellChanged);

      // If change in production volume
      if (col === this.prodVolumeColumn) {
        const percentChange = this.getProductionLastMonth(this.production[row]);
        console.log('Percentage Change: ' + percentChange);
        this.production[row].percentChange = percentChange;

        // Update the global array with this new record also
        this.updateGlobalProduction(this.production[row]);

        // Highlight cells with percentage < [this.percentVolumeChangeHighlight](= -20%) and no reason selected
        if (percentChange < this.percentVolumeChangeHighlight) {
          const reasonId: number = this.production[row].prodChangeReasonId;
          if (reasonId === 0) {
            let pcCell = this.sheet.getCell(row, this.percentChangeColumn);
            pcCell.backColor(environment.gridCellChanged);
            pcCell = this.sheet.getCell(row, this.prodChangeReasonColumn);
            pcCell.backColor(environment.gridCellChanged);
          }
        }
      }

      if (col === this.prodChangeReasonColumn) {
        if (this.production[row].reason === 'Other (free text)') {
          this.production[row].reason = '';
        }
      }

      if (col === this.prodDateColumn) {
        this.data[row]['prodDate'] = '' + this.data[row]['prodDate'];
      }
      this.sheet.resumePaint();
    });

    // Define save button action
    this.spread.bind(GC.Spread.Sheets.Events.ButtonClicked, async (e, args) => {
      this.sheet.suspendPaint();

      let newProduction = false;
      const row = args.row;
      const col = args.col;
      const cellType = this.sheet.getCellType(row, col);

      if (cellType instanceof GC.Spread.Sheets.CellTypes.Button) {
        this.spread.options.highlightInvalidData = true;

        // Add validators to last row
        if (row === this.sheet.getRowCount() - 1) {
          console.log('New production');
          this.addValidators(row);
          newProduction = true;
        }

        const production = this.production[row];
        console.log('Production (b4 validation): ', { production });

        if (this.validateProduction(row)) {
          console.log('Valid Production: ', { production });
          const result = (await this.saveProduction(production)) as Production;
          console.log('Save result: ', { result });

          // Get the first cell of the row - assign the production.id to the filteredProduction
          let cell = this.sheet.getCell(row, 0);
          cell.text(result.id.toString());
          console.log('PropertyId: ' + result.id.toString());

          // Assign bcTransId to grid also
          cell = this.sheet.getCell(row, this.blockChainTransIdColumn);
          cell.text(result.bcTransId);
          console.log('bcTransId: ' + result.bcTransId);

          // Assign Blockchain graphic to the cell
          cell = this.sheet.getCell(row, this.blockChainColumn);
          const imgBlockChain = './assets/images/blockchain_cell@2x.png';
          cell.text('');
          cell.backgroundImage(imgBlockChain);

          // Assign the saved production to the Global Grid Array
          this.production[row] = result;

          if (newProduction) {
            console.log('Result - new: ', { result });
            // Assign new property to the end of the global array with all production
            this.allProduction[this.allProduction.length - 1] = result;

            // Add empty row at end of sheet
            const lastRow = this.sheet.getRowCount();
            this.sheet.addRows(lastRow, 1);
          } else {
            console.log('Result - not new: ', { result });
            // Assign the saved property to the global instance of all production
            this.updateGlobalProduction(result);
          }

          console.log('Production: ', this.production);
          console.log('All Production: ', this.allProduction);

          // Change the color of all the cells back to highlighted
          for (let colIndex = 0; colIndex < 15; colIndex++) {
            this.sheet
              .getCell(row, colIndex)
              .backColor(environment.gridHighlight);
          }
          console.log('Change cells back to highlighted');

          // Change the colour of the Save button back to Grey
          const saveBtnCellType2 = this.changeSaveButtonColor(
            environment.buttonDisabled
          );
          this.sheet.getCell(row, this.saveColumn).cellType(saveBtnCellType2);
          this.sheet
            .getCell(row, this.saveColumn)
            .foreColor(environment.buttonDisabledText);
          console.log('Make button grey - disabled');
        }
      }
      this.sheet.resumePaint();
    });
  }

  initialiseGrid() {
    // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
    // And grid events that are NOT data specific
    // console.log('Initialise Grid');

    this.sheet.suspendPaint();

    // Add Product dropdowns
    const productComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
    const productStrArr = [];
    this.products.forEach((product: Product) => {
      productStrArr.push(product.name);
    });
    productComboBoxType.items(productStrArr).editable(true);
    productComboBoxType.maxDropDownItems(10);
    this.sheet.getCell(-1, this.productColumn).cellType(productComboBoxType);

    const datePickerCellType = new DatePickerCellType();
    this.sheet.getCell(-1, this.prodDateColumn).cellType(datePickerCellType);

    // Add Production Change Reason dropdowns
    const prodChangeReasonComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
    const prodChangeReasonStrArr = [];
    this.productionChangeReasons.forEach(
      (productionChangeReason: ProductionChangeReason) => {
        prodChangeReasonStrArr.push(productionChangeReason.name);
      }
    );

    prodChangeReasonComboBoxType.items(prodChangeReasonStrArr).editable(true);
    prodChangeReasonComboBoxType.maxDropDownItems(10);
    this.sheet
      .getCell(-1, this.prodChangeReasonColumn)
      .cellType(prodChangeReasonComboBoxType);

    const saveBtnCellType = this.changeSaveButtonColor(
      environment.buttonDisabled
    );
    this.sheet.getCell(-1, this.saveColumn).cellType(saveBtnCellType);
    this.sheet.getCell(-1, this.saveColumn).cellPadding('1px');
    this.sheet
      .getCell(-1, this.saveColumn)
      .vAlign(GC.Spread.Sheets.VerticalAlign.center);
    this.sheet
      .getCell(-1, this.saveColumn)
      .hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet
      .getCell(-1, this.saveColumn)
      .foreColor(environment.buttonDisabledText);

    // Define columns
    const prodPropertyNoColInfo = {
      name: 'propertyNo',
      displayName: 'Property #',
      size: 120
    };
    const prodPropertyNameColInfo = {
      name: 'propertyName',
      displayName: 'Property Name',
      size: 220
    };
    const prodWellApiColInfo = {
      name: 'wellApiNo',
      displayName: 'Well API #',
      size: 140
    };
    const prodWellNameColInfo = {
      name: 'wellName',
      displayName: 'Well Name',
      size: 180
    };
    const prodBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
    const prodBCTransIdColInfo = {
      name: 'bcTransId',
      displayName: 'BC #',
      size: 150
    };
    const prodProductColInfo = {
      name: 'productName',
      displayName: 'Product #',
      size: 130
    };
    const prodDateColInfo = {
      name: 'prodDate',
      displayName: 'Prod. Date',
      size: 100
    };
    const prodVolumeColInfo = {
      name: 'amount',
      displayName: 'Prod. Volume',
      size: 100
    };
    const prodBtuColInfo = { name: 'btuStr', displayName: 'BTU', size: 90 };
    const prodGravityColInfo = {
      name: 'gravityStr',
      displayName: 'Gravity',
      size: 90
    };
    const prodTempColInfo = {
      name: 'temperature',
      displayName: 'Temperature',
      size: 90
    };
    const prodPercentChangeColInfo = {
      name: 'percentChange',
      displayName: '% Change 30 Days',
      size: 115
    };
    const prodReasonColInfo = {
      name: 'reason',
      displayName: 'Reason',
      size: 200
    };
    const prodSaveColInfo = { name: '', displayName: '', size: 80 };

    this.sheet.autoGenerateColumns = false;

    this.sheet.setDataSource(this.production);

    this.sheet.bindColumn(this.propertyNoColumn, prodPropertyNoColInfo);
    this.sheet.bindColumn(this.propertyNameColumn, prodPropertyNameColInfo);
    this.sheet.bindColumn(this.wellApiColumn, prodWellApiColInfo);
    this.sheet.bindColumn(this.wellNameColumn, prodWellNameColInfo);
    this.sheet.bindColumn(this.blockChainColumn, prodBCTransImgColInfo);
    this.sheet.bindColumn(this.blockChainTransIdColumn, prodBCTransIdColInfo);
    this.sheet.bindColumn(this.productColumn, prodProductColInfo);
    this.sheet.bindColumn(this.prodDateColumn, prodDateColInfo);
    this.sheet.bindColumn(this.prodVolumeColumn, prodVolumeColInfo);
    this.sheet.bindColumn(this.btuColumn, prodBtuColInfo);
    this.sheet.bindColumn(this.gravityColumn, prodGravityColInfo);
    this.sheet.bindColumn(this.tempratureColumn, prodTempColInfo);
    this.sheet.bindColumn(this.percentChangeColumn, prodPercentChangeColInfo);
    this.sheet.bindColumn(this.prodChangeReasonColumn, prodReasonColInfo);
    this.sheet.bindColumn(this.saveColumn, prodSaveColInfo);

    this.sheet.setColumnCount(
      this.gridColumnCount,
      GC.Spread.Sheets.SheetArea.viewport
    );

    // Lock/Readonly all columns
    this.sheet.options.isProtected = true;
    this.sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: false
    };
    // Unlock from columns 6 for 6 columns (columns 6, 7, 8, 9, 10, 11) - [Product - Temprature]
    this.sheet.getRange(-1, 6, -1, 6).locked(false);
    // Unlock from columns 13 for 1 columns (columns 13) - [Reason]
    this.sheet.getRange(-1, 13, -1, 1).locked(false);

    // set cell alignment
    this.sheet
      .getCell(-1, this.prodDateColumn)
      .hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet
      .getCell(-1, this.prodVolumeColumn)
      .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet
      .getCell(-1, this.btuColumn)
      .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet
      .getCell(-1, this.gravityColumn)
      .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet
      .getCell(-1, this.tempratureColumn)
      .hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet
      .getCell(-1, this.percentChangeColumn)
      .hAlign(GC.Spread.Sheets.HorizontalAlign.right);

    // Create all Grid events required
    this.createGridEvents();

    this.sheet.resumePaint();
    console.log('Initialise Grid - complete');
  }

  applyDataBinding(filteredProduction: Production[]) {
    // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);

    // console.log('Start data binding');

    this.sheet.suspendPaint();
    this.sheet.setDataSource(filteredProduction);

    // console.log('Row Binding - start');
    filteredProduction.forEach(
      (rowProductionObject: Production, rowIndex: number) => {
        // Remove vertical grid-lines
        this.sheet
          .getCell(rowIndex, -1)
          .borderLeft(
            new GC.Spread.Sheets.LineBorder(
              '#FFFFFF',
              GC.Spread.Sheets.LineStyle.thin
            )
          );

        const cell = this.sheet.getCell(rowIndex, this.blockChainColumn);
        let imgBlockChain = null;
        if (filteredProduction[rowIndex].bcTransId != null) {
          imgBlockChain = './assets/images/blockchain_cell@2x.png';
        }
        cell.text('');
        cell.backgroundImage(imgBlockChain);

        this.addValidators(rowIndex);

        // get Percent Change 30 Days
        const percentChange: number = rowProductionObject.percentChange;
        const reasonId: number = rowProductionObject.prodChangeReasonId;

        // Highlight cells with percentage < [this.percentVolumeChangeHighlight](= -20%) and no reason selected
        if (percentChange < this.percentVolumeChangeHighlight) {
          if (reasonId === 0) {
            let pcCell = this.sheet.getCell(rowIndex, this.percentChangeColumn);
            pcCell.backColor(environment.gridCellChanged);
            pcCell = this.sheet.getCell(rowIndex, this.prodChangeReasonColumn);
            pcCell.backColor(environment.gridCellChanged);
          }
        }
      }
    );

    // Highlight first row as selected - except blockchain graphic cell
    for (let col = 0; col < this.gridColumnCount - 1; col++) {
      if (col !== this.blockChainColumn) {
        this.sheet.getCell(0, col).backColor(environment.gridHighlight);
      }
    }

    // Add empty row at end of sheet
    const lastRow = this.sheet.getRowCount();
    this.sheet.addRows(lastRow, 1);

    this.sheet.resumePaint();

    const today = new Date();
    this.maxStartDate =
      this.prodSearch.startDateMonth === today.getMonth() + 1 &&
      this.prodSearch.startDateYear === today.getFullYear();
    this.maxEndDate =
      this.prodSearch.endDateMonth === today.getMonth() + 1 &&
      this.prodSearch.endDateYear === today.getFullYear();
    console.log('Binding Complete - Last row: ' + lastRow);
  }

  public uploadProduction() {
    console.log('Production-Content: Upload Production - clicked');
    this.openUploadProduction();
  }

  private getLastDayOfMonth(year, month) {
    // get first day of month
    let date = new Date(year, month, 1);
    // Add 1 month and subtract 1 day
    date = new Date(date.setMonth(date.getMonth() + 1) - 1);
    return date;
  }

  private filter() {
    // Filters the "allProduction" based on selected filters from the screen
    this.production = [];

    const today = this.transformDate(new Date());
    // console.log('Todays Date: ' + today);

    this.allProduction.forEach((prodObj: Production, rowIndex: number) => {
      // console.log(prodObj);

      // If prodObj is null, undefined or empty - ignore
      if (prodObj != null && Object.keys(prodObj).length !== 0) {
        let includeProd = true;

        const startDate = new Date(
          this.prodSearch.startDateYear,
          this.prodSearch.startDateMonth - 1,
          1
        );
        const endDate = this.getLastDayOfMonth(
          this.prodSearch.endDateYear,
          this.prodSearch.endDateMonth - 1
        );

        // console.log('Production date: ' + this.transformDate(prodObj.productionDate) + '   Start: ' + this.transformDate(startDate)
        //   + '   End Date: ' + this.transformDate(endDate));
        if (
          this.transformDate(prodObj.productionDate) <
          this.transformDate(startDate)
        ) {
          includeProd = false;
        }
        if (
          includeProd &&
          this.transformDate(prodObj.productionDate) >
          this.transformDate(endDate)
        ) {
          includeProd = false;
        }

        // console.log('Production Search State: ', this.prodSearch.state);
        if (includeProd && this.prodSearch.state !== '') {
          includeProd = (prodObj.well.property.county.USState.id === Number(this.prodSearch.state));
        }

        if (includeProd && this.prodSearch.searchStr.length > 0) {
          // Check property No, lease No, Property Name and Property Desc against any part of the search string
          includeProd = true; // Assuem property to be included
          this.prodSearch.searchStr.forEach(searchStr => {
            includeProd =
              includeProd &&
              (prodObj.propertyNo.indexOf(searchStr) !== -1 ||
                prodObj.wellApiNo.indexOf(searchStr) !== -1 ||
                prodObj.propertyName
                  .toUpperCase()
                  .indexOf(searchStr.toUpperCase()) !== -1 ||
                prodObj.well.property.description
                  .toUpperCase()
                  .indexOf(searchStr.toUpperCase()) !== -1 ||
                prodObj.wellName
                  .toUpperCase()
                  .indexOf(searchStr.toUpperCase()) !== -1 ||
                prodObj.well.property.county.name
                  .toUpperCase()
                  .indexOf(searchStr.toUpperCase()) !== -1 ||
                prodObj.well.property.county.USState.name
                  .toUpperCase()
                  .indexOf(searchStr.toUpperCase()) !== -1);
          });
        }

        // console.log('Production Search Type: ', this.prodSearch.prodType);
        if (includeProd && this.prodSearch.prodType > 0) {
          if (this.prodSearch.prodType === 2) {
            // Complete - EffectiveTo date has passed
            if (prodObj.well.property.effectiveTo !== null) {
              includeProd = prodObj.well.property.effectiveTo < today;
              // console.log('Effective To: ' + prodObj.well.property.effectiveTo);
            } else {
              // Property effective to date not set  => Still in Progress (NOT complete)
              includeProd = false;
            }
            // console.log('Complete: Include: ' + includeProd);
          } else {
            // In Progress (= In production) => effectiveTo date not reached yet
            if (prodObj.well.property.effectiveTo !== null) {
              includeProd = prodObj.well.property.effectiveTo > today;
            } else {
              includeProd = true;
            }
            // console.log('In Progress: Include: ' + includeProd + '   prod Object: ' + prodObj);
          }
        }

        // console.log('IncludeProduction: ' + includeProd);
        if (includeProd) {
          this.production.push(prodObj);
        }
      }
    });

    // console.log('All Production: ', this.allProduction);
    // console.table('Production: ', this.production);

    this.applyDataBinding(this.production);
  }

  public doSearch() {
    const str = this.prodSearchForm.get('searchStr').value;
    // console.log('Str: ' + str);
    this.prodSearch.searchStr = str.split(' ');
    // console.log('searchStr: ', this.prodSearch.searchStr);
    this.filter();
  }

  public onStateChange() {
    console.log('State changed: ' + this.prodSearchForm.get('state').value);

    this.prodSearch.state = this.prodSearchForm.get('state').value;
    this.filter();
  }

  public btnAllProduction() {
    console.log('All Production');
    this.prodSearch.prodType = 0;
    this.filter();
  }

  public btnInProgressProduction() {
    console.log('In Progress');
    this.prodSearch.prodType = 1;
    this.filter();
  }

  public btnCompleteProduction() {
    console.log('Complete');
    this.prodSearch.prodType = 2;
    this.filter();
  }

  private dateStr(month: number, year: number) {
    return this.messagesService.monthString(month) + ' ' + year;
  }

  public onStartDateDown() {
    // console.log('Start Date Down: ' + this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear));
    if (this.prodSearch.startDateMonth === 1) {
      this.prodSearch.startDateMonth = 12;
      this.prodSearch.startDateYear = this.prodSearch.startDateYear - 1;
    } else {
      this.prodSearch.startDateMonth = this.prodSearch.startDateMonth - 1;
    }

    this.prodSearch.startStr = this.dateStr(
      this.prodSearch.startDateMonth,
      this.prodSearch.startDateYear
    );
    // console.log('Start Date Down: ' + this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear));
    this.filter();
  }

  public onStartDateUp() {
    // console.log('Start Date Up: ' + this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear));
    if (this.prodSearch.startDateMonth === 12) {
      this.prodSearch.startDateMonth = 1;
      this.prodSearch.startDateYear = this.prodSearch.startDateYear + 1;
    } else {
      this.prodSearch.startDateMonth = this.prodSearch.startDateMonth + 1;
    }

    this.prodSearch.startStr = this.dateStr(
      this.prodSearch.startDateMonth,
      this.prodSearch.startDateYear
    );
    // console.log('Start Date Up: ' + this.dateStr(this.prodSearch.startDateMonth, this.prodSearch.startDateYear));
    this.filter();
  }

  public onEndDateDown() {
    // console.log('End Date Down: ' + this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear));
    if (this.prodSearch.endDateMonth === 1) {
      this.prodSearch.endDateMonth = 12;
      this.prodSearch.endDateYear = this.prodSearch.endDateYear - 1;
    } else {
      this.prodSearch.endDateMonth = this.prodSearch.endDateMonth - 1;
    }

    this.prodSearch.endStr = this.dateStr(
      this.prodSearch.endDateMonth,
      this.prodSearch.endDateYear
    );
    // console.log('End Date Down: ' + this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear));
    this.filter();
  }

  public onEndDateUp() {
    // console.log('End Date Up: ' + this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear));

    if (this.prodSearch.endDateMonth === 12) {
      this.prodSearch.endDateMonth = 1;
      this.prodSearch.endDateYear = this.prodSearch.endDateYear + 1;
    } else {
      this.prodSearch.endDateMonth = this.prodSearch.endDateMonth + 1;
    }

    this.prodSearch.endStr = this.dateStr(
      this.prodSearch.endDateMonth,
      this.prodSearch.endDateYear
    );
    // console.log('End Date Up: ' + this.dateStr(this.prodSearch.endDateMonth, this.prodSearch.endDateYear));
    this.filter();
  }

  public onBack() {
    console.log('Back clicked');
    this.location.back();
  }

  public exportReport(reportType) {
    this.productionService.getReport(this.production, reportType).subscribe((data) => {
      const blob = new Blob([data as BlobPart], { type: 'application/octet-stream' });
      let extension = '';
      if (reportType === 1) {
        extension = '.pdf';
      } else if (reportType === 2) {
        extension = '.xlsx';
      } else if (reportType === 3) {
        extension = '.csv';
      }
      FileSaver.saveAs(blob, `production${extension}`);
    });
  }

  public workbookInit(args) {
    console.log(`Workbook Init`, { args });

    this.spread = args.spread;
    this.sheet = this.spread.getActiveSheet();

    this.sheetWidth = this.sheet.getViewportWidth(1);
    console.log('SheetWidth: ', this.sheetWidth);
  }
}
