import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var clone = function (obj) { return JSON.parse(JSON.stringify(obj)); };
var ɵ0 = clone;
var PropertyService = /** @class */ (function () {
    function PropertyService(http) {
        this.http = http;
        this.propertyUrl = environment.apiUrl + "/property";
    }
    // GET
    PropertyService.prototype.getProperties = function (operatorId) {
        var url = this.propertyUrl + "/getProperties?operatorId=" + operatorId;
        console.log('GetProperties Url: ', url);
        return this.http.get(url);
    };
    // POST
    PropertyService.prototype.add = function (property) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.propertyUrl + '/add';
        console.log('URL: ' + url);
        console.log('Property: ', { property: property });
        // console.log(`POST`, { query: clone(property) });
        return this.http.post(url, property, httpOptions).toPromise();
    };
    // POST
    PropertyService.prototype.getReport = function (properties, reportType) {
        var httpOptions = {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.propertyUrl + "/propertiesReport/" + reportType;
        return this.http.post(url, properties, httpOptions);
    };
    // GET
    PropertyService.prototype.getProperty = function (propertyId) {
        var url = this.propertyUrl + "/getProperty?propertyId=" + propertyId;
        console.log('GetProperty Url: ', url);
        return this.http.get(url);
    };
    // GET
    PropertyService.prototype.getPropertyByNo = function (propertyNo) {
        var url = this.propertyUrl + "/getProperty?propertyNo=" + propertyNo;
        console.log('GetProperty Url: ', url);
        return this.http.get(url);
    };
    /** @nocollapse */ PropertyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PropertyService_Factory() { return new PropertyService(i0.ɵɵinject(i1.HttpClient)); }, token: PropertyService, providedIn: "root" });
    return PropertyService;
}());
export { PropertyService };
export { ɵ0 };
