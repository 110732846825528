import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private readonly performanceUrl = `${environment.apiUrl}/performance`;

  constructor(private http: HttpClient) { }

  // GET
  getPerformanceByDate() {
    const url = `${this.performanceUrl}/getPerformanceByDate`;
    // console.log('GetPerformanceByDate Url: ', url);

    return this.http.get(url).toPromise();
  }

  getPerformanceByWell() {
    const url = `${this.performanceUrl}/getPerformanceByWell`;
    // console.log('GetPerformanceByWell Url: ', url);

    return this.http.get(url).toPromise();
  }
}
