import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { ProductionService } from 'src/app/production.service';
import { MessagesService } from 'src/app/messages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';

declare var $: any; // jquery
declare let datepicker;
declare let modals;
declare let removeHash;

@Component({
  selector: 'app-production-upload',
  templateUrl: './production-upload.component.html',
  styleUrls: ['./production-upload.component.css']
})
export class ProductionUploadComponent implements OnInit, OnDestroy {

  selectedFile: File = null;
  public fileSelected = false;
  public message = this.messagesService.noFileSelected;
  private ngUnsubscribe = new Subject();

  @Input() displayUploadProduction: boolean;
  @Output() displayUploadProductionChange = new EventEmitter<boolean>();

  constructor(
    private productionService: ProductionService,
    private messagesService: MessagesService,
    private authService: AuthService) { }

  closeDialog() {
    this.displayUploadProduction = false;
    this.displayUploadProductionChange.emit(false);
  }

  ngOnInit() { }

  onFileSelectedProduction(event) {
    // alert('Prod file selected');
    this.selectedFile = event.target.files[0] as File;
    this.message = this.selectedFile.name;
    this.fileSelected = true;
    console.log('Production File selected: ' + this.message);
  }

  onUpload() {
    const user = this.authService.getUser();

    const fileName = this.selectedFile.name;
    const fd = new FormData();
    fd.append('file', this.selectedFile, fileName);

    fd.append('userId', user.id.toString());
    fd.append('operatorId', user.operator.id.toString());

    const resultObservable = this.productionService.postProductionFile(fd);

    const resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      console.log('Response: ' + res);
      this.message = fileName + ' - ' + res;
    });

    this.selectedFile = null;
    // this.message = fileName + ' - ' + this.messagesService.fileUploaded + resultSubscription;
  }

  ngOnDestroy() {
    console.log('The component is being destroyed');
    this.ngUnsubscribe.next();
  }

}
