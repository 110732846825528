import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  action: string;
  displayChangePassword = false;
  @Output() displayChangePasswordChange = new EventEmitter<boolean>();
  constructor(private route: ActivatedRoute) {  }
  ngOnInit() {
    this.action = this.route.snapshot.paramMap.get('action');

    if (this.action === null) {
      this.action = '';
    } else if (this.action.toUpperCase() === 'CHANGEPASSWORD') {
      this.changePassword();
    }
  }
  public changePassword() {
    this.displayChangePassword = true;
    this.displayChangePasswordChange.emit(true);
  }


}
