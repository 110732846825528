import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SalesService = /** @class */ (function () {
    function SalesService(http) {
        this.http = http;
        this.salesUrl = environment.apiUrl + "/sales";
    }
    // API: Get
    SalesService.prototype.getSales = function (operatorId) {
        var url = this.salesUrl + "/getSales?operatorId=" + operatorId;
        console.log('Sales Service - Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    SalesService.prototype.getSalesProcessPayments = function (operatorId, startDate, endDate) {
        var startDateStr = startDate.toISOString().split('T')[0];
        var endDateStr = endDate.toISOString().split('T')[0];
        console.log('StartDate: ' + startDateStr + '    End Date: ' + endDateStr);
        var url = this.salesUrl + "/getSalesProcessPayments?operatorId=" + operatorId + "&startDate=" + startDateStr + "&endDate=" + endDateStr;
        console.log('Sales Service - Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    SalesService.prototype.getSalesByYear = function (operatorId, year) {
        var url = this.salesUrl + "/getSales?operatorId=" + operatorId + "&year=" + year;
        console.log('Sales Service - Url: ' + url);
        return this.http.get(url);
    };
    SalesService.prototype.getSalesDeductionsIndividual = function (salesId) {
        var url = this.salesUrl + "/getSalesDeductionsIndividual?salesId=" + salesId;
        console.log('Sales Service - Url: ' + url);
        return this.http.get(url);
    };
    SalesService.prototype.getSalesDeductions = function (operatorId) {
        var url = this.salesUrl + "/getSalesDeductions?operatorId=" + operatorId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    SalesService.prototype.getSalesByPropertyId = function (operatorId, propertyId) {
        return this.http.get(this.salesUrl + "/getSales?operatorId=" + operatorId + "&propertyId=" + propertyId);
    };
    SalesService.prototype.postSalesFile = function (fd) {
        var url = this.salesUrl + "/upload";
        return this.http.post(url, fd, { responseType: 'text' });
    };
    // POST
    SalesService.prototype.save = function (sale, userId) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.salesUrl + ("/save?userId=" + userId);
        console.log('URL: ' + url);
        console.log('Sale: ', { sale: sale });
        return this.http.post(url, sale, httpOptions).toPromise();
    };
    // POST
    SalesService.prototype.getReport = function (sales, reportType) {
        var httpOptions = {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.salesUrl + "/salesReport/" + reportType;
        return this.http.post(url, sales, httpOptions);
    };
    /** @nocollapse */ SalesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SalesService_Factory() { return new SalesService(i0.ɵɵinject(i1.HttpClient)); }, token: SalesService, providedIn: "root" });
    return SalesService;
}());
export { SalesService };
