import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';
import * as FileSaver from 'file-saver';
import '@grapecity/spread-sheets-print';
import '@grapecity/spread-sheets-pdf';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from 'src/environments/environment';

import { Property } from 'src/app/model/property';
import { USState } from 'src/app/model/us_state';
import { Operator } from 'src/app/model/operator';
import { Purchaser } from 'src/app/model/purchaser';
import { Product } from 'src/app/model/product';
import { County } from 'src/app/model/county';
import { Well } from 'src/app/model/well';
import { PropertySearch } from 'src/app/model/property_search';
import { PropertyService } from 'src/app/property.service';
import { LookupService } from 'src/app/lookup.service';
import { MessagesService } from 'src/app/messages.service';
import { DatePickerCellType } from 'src/app/shared/spread/DatePickerCellType';
import { User } from 'src/app/model/user';

declare var $: any; // jquery
const clone = obj => JSON.parse(JSON.stringify(obj));

@Component({
  selector: 'app-properties-content',
  templateUrl: './properties-content.component.html',
  styleUrls: ['./properties-content.component.css'],
  providers: [DatePipe]
})

export class PropertiesContentComponent implements OnInit {

  // Display/Hide Add Property
  @Input() displayAddProperty: boolean;
  @Output() displayAddPropertyChange = new EventEmitter<boolean>();

  // Display/Hide Upload Property (Division Orders)
  @Input() displayUploadProperty: boolean;
  @Output() displayUploadPropertyChange = new EventEmitter<boolean>();

  // Display/Hide blockchain error dialog
  @Input() displayBlockchainErrorDialog: boolean;
  @Output() displayBlockchainErrorDialogChange = new EventEmitter<boolean>();

  // Display/Hide waiting dialog with spinning circle
  @Input() displayWaitingDialog: boolean;
  @Output() displayWaitingDialogChange = new EventEmitter<boolean>();

  propertyForm: FormGroup = this.fb.group({
    searchStr: [''],
    state: ['']
  });

  private readonly blockchainExplorerUrl = `${environment.blockchainExplorerUrl}`;

  public sheet: GC.Spread.Sheets.Worksheet = null;
  public spread: GC.Spread.Sheets.Workbook;

  private sheetWidth: number;

  private msg = this.messagesService.messages;

  tabStripVisible = false;
  spreadBackColor = 'white';
  sheetName = 'Property List';
  hostStyle = {
    height: '100%',
    width: '100%'
  };
  data: any;
  autoGenerateColumns = false;

  // Define column indexes
  propertyIdColumn = 0;
  propertyNoColumn = 1;
  propertyNameColumn = 2;
  stateColumn = 3;
  countyColumn = 4;
  propertyDescColumn = 5;
  operatorColumn = 6;
  purchaserColumn = 7;
  productColumn = 8;
  wellApiColumn = 9;
  effectiveFromColumn = 10;
  effectiveToColumn = 11;
  wellNameColumn = 12;
  blockChainColumn = 13;
  blockChainTransIdColumn = 14;
  saveColumn = 15;
  gridColCount = 16;

  allProperties: Property[];  // Stores all properties
  properties: Property[];     // stores all properties filtered by screen filter criteria
  states: USState[];
  operators: Operator[];
  purchasers: Purchaser[];
  products: Product[];

  propertySearch: PropertySearch;

  operator: Operator;

  lastUpdated: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private propertyService: PropertyService,
    private lookupService: LookupService,
    private messagesService: MessagesService,
    private datePipe: DatePipe,
    private location: Location,
    private router: Router
    // ,private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  closeAddProperty() {
    this.displayAddProperty = false;
    this.displayAddPropertyChange.emit(false);
  }

  openAddProperty() {
    this.displayAddProperty = true;
    this.displayAddPropertyChange.emit(true);
  }

  closeUploadProperty() {
    this.displayUploadProperty = false;
    this.displayUploadPropertyChange.emit(false);
  }

  openUploadProperty() {
    this.displayUploadProperty = true;
    this.displayUploadPropertyChange.emit(true);
  }


  closeBCDialog() {
    this.displayBlockchainErrorDialog = false;
    this.displayBlockchainErrorDialogChange.emit(false);
  }

  openBCDialog() {
    this.displayBlockchainErrorDialog = true;
    this.displayBlockchainErrorDialogChange.emit(true);
  }

  closeWaitingDialog() {
    this.displayWaitingDialog = false;
    this.displayWaitingDialogChange.emit(false);
  }

  openWaitingDialog() {
    this.displayWaitingDialog = true;
    this.displayWaitingDialogChange.emit(true);
  }

  ngOnInit() {
    console.log('Properties-Content - Init - start');
    Promise.all([
      this.getUSStates(),
      this.getOperators(),
      this.getPurchasers(),
      this.getProducts()
    ]).then(values => {
      const user: User = JSON.parse(localStorage.getItem('user'));
      this.operator = user.operator;
      this.getProperties(user.operator.id);
    });

    // initialise Search criteria
    this.propertySearch = new PropertySearch();
    this.propertySearch.propertyType = 0;
    this.propertySearch.searchStr = [];
    this.propertySearch.state = '';
    console.log('Properties-Content - Init - complete');

    this.lastUpdated.setDate(new Date().getDate() - 2);
  }

  getProperties(operatorId: number): void {
    console.log('getProperties - start: ' + new Date().toISOString());
    this.propertyService.getProperties(operatorId).subscribe(
      properties => {
        console.log('getProperties - back from API: ' + new Date().toISOString());
        console.log({ properties });

        let tempProperties: Property[] = JSON.parse(JSON.stringify(properties));
        tempProperties = this.setPropertyValues(tempProperties);

        // Apply entire set of properties to allProperties variable;
        this.allProperties = tempProperties;
        this.properties = tempProperties;

        this.initiliseGrid();
        this.applyDataBinding(tempProperties);
        console.log('getProperties - complete: ' + new Date().toISOString() + '  No. reocrds: ' + this.allProperties.length);
      },
      err => {
        console.log('getProperties:Error');
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.router.navigate(['/login']);
          }
        }
      }
    );
  }

  async getUSStates() {
    this.states = (await (this.lookupService.getStates())) as USState[];
  }

  // This is another way of doing exactly the same thing as the function above
  // Note the use of "then" because the getStates is returning a promise
  // If getStates was returning an observable - replace "then" with "subscribe"
  // getUSStates() {
  //   this.lookupService.getStates().then((states: USState[]) => {
  //     this.states = states;
  //   });
  // }

  async getOperators() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;
    this.operators = (await (this.lookupService.getOperators(operatorId))) as Operator[];
  }

  async getPurchasers() {
    this.purchasers = (await (this.lookupService.getPurchasers())) as Purchaser[];
  }

  async getProducts() {
    this.products = (await (this.lookupService.getProducts())) as Product[];
  }

  getState(name: string) {
    return this.states.find(s => s.name === name);
  }

  getCounty(countyName: string, stateCounties: County[]) {
    return stateCounties.find(c => c.name.trim() === countyName.trim());
  }

  getOperator(operatorName: string) {
    return this.operators.find(o => o.name === operatorName);
  }

  getPurchaser(purchaserName: string) {
    return this.purchasers.find(p => p.name === purchaserName);
  }

  getProduct(productName: string) {
    return this.products.find(p => p.name.trim() === productName.trim());
  }

  formatDate(date: string) {
    // Checks if date is null or undefined (=== strict check for null only)
    if (date == null) {
      return null;
    }
    if (date === '') {
      return null;
    }

    // Format the date to MM/DD/YYYY
    const dateStr = date.substr(5, 2) + '/' + date.substr(8, 2) + '/' + date.substr(0, 4);
    // alert('Date Before: ' + date + '   Date String: ' + dateStr);
    return dateStr;
  }

  formatDateDB(date: string) {
    // Checks if date is null or undefined (=== strict check for null only)
    if (date == null) {
      return null;
    }
    if (date === '') {
      return null;
    }

    // Format the date to YYYY-MM-DD
    const dateStr = date.substr(6, 4) + '-' + date.substr(0, 2) + '-' + date.substr(3, 2);
    // alert('Date Before: ' + date + '   Date String: ' + dateStr);
    return dateStr;
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  setPropertyValue(property: Property): Property {
    property.stateName = property.county ? property.county.USState ? property.county.USState.name : '' : '';
    property.countyName = property.county ? property.county.name : '';
    property.operatorName = this.operator ? this.operator.name : '';
    property.purchaserName = property.purchaser ? property.purchaser.name : '';
    property.wellType = property.wells[0] ? property.wells[0].products[0] ? property.wells[0].products[0].name : null : null;
    property.wellApiNo = property.wells[0] ? property.wells[0].apiNo : null;
    property.wellName = property.wells[0] ? property.wells[0].name : null;

    property.effectiveFrom = this.formatDate(property.effectiveFrom);
    property.effectiveTo = this.formatDate(property.effectiveTo);

    console.log(property);
    return property;
  }

  setPropertyValues(filteredProperties: Property[]): Property[] {
    // console.log('setPropertyValues - start');
    filteredProperties.forEach(property => {
      // console.log('Property: ', { property });

      property.stateName = property.county ? property.county.USState ? property.county.USState.name : '' : '';
      property.countyName = property.county ? property.county.name : '';
      property.operatorName = this.operator ? this.operator.name : '';
      property.purchaserName = property.purchaser ? property.purchaser.name : '';
      property.wellType = property.wells[0] ? property.wells[0].products[0] ? property.wells[0].products[0].name : null : null;
      property.wellApiNo = property.wells[0] ? property.wells[0].apiNo : null;
      property.wellName = property.wells[0] ? property.wells[0].name : null;

      property.effectiveFrom = this.formatDate(property.effectiveFrom);
      property.effectiveTo = this.formatDate(property.effectiveTo);
    });

    // console.log('setPropertyValues - end');
    return filteredProperties;
  }

  private addDataValidatorNotBlank(sheet: GC.Spread.Sheets.Worksheet, col: number, row: number, errorMsg: string) {

    // get the corresponding column letter
    const colLetter = String.fromCharCode(65 + col);

    const notBlankValidator = GC.Spread.Sheets.DataValidation.createFormulaValidator(`NOT(ISBLANK(${colLetter}${row + 1}))`);
    notBlankValidator.ignoreBlank(false);
    // notBlankValidator.showInputMessage(true);
    notBlankValidator.showErrorMessage(true);
    // notBlankValidator.inputMessage(errorMsg);
    notBlankValidator.errorMessage(errorMsg);
    notBlankValidator.inputTitle('Tip');
    sheet.setDataValidator(row, col, 1, 1, notBlankValidator);
  }

  private addValidators(row: number) {

    this.addDataValidatorNotBlank(this.sheet, this.propertyNoColumn, row, this.msg.property.propertyNumber);
    this.addDataValidatorNotBlank(this.sheet, this.propertyNameColumn, row, this.msg.property.propertyName);

    this.addDataValidatorNotBlank(this.sheet, this.stateColumn, row, this.msg.property.propertyState);
    this.addDataValidatorNotBlank(this.sheet, this.countyColumn, row, this.msg.property.propertyCounty);
    this.addDataValidatorNotBlank(this.sheet, this.propertyDescColumn, row, this.msg.property.propertyDecription);
    this.addDataValidatorNotBlank(this.sheet, this.operatorColumn, row, this.msg.property.propertyOperator);
   // this.addDataValidatorNotBlank(this.sheet, this.purchaserColumn, row, this.msg.property.propertyPurchaser);
    // this.addDataValidatorNotBlank(this.sheet, this.productColumn, row, this.msg.property.propertyProduct);
    this.addDataValidatorNotBlank(this.sheet, this.wellApiColumn, row, this.msg.property.propertyWellAPI);
    this.addDataValidatorNotBlank(this.sheet, this.effectiveFromColumn, row, this.msg.property.propertyEffectiveFromDate);
    this.addDataValidatorNotBlank(this.sheet, this.wellNameColumn, row, this.msg.property.propertyWellName);
  }

  validateProperty(row: number) {
    // console.log('Validate Property - row: ' + row);

    // const val = this.sheet.getCell(row, 1).value();
    // const isValid = this.sheet.isValid(row, 1, val);

    // const validator = this.sheet.getCell(row, 1).validator();
    // if (!isValid) {
    //   alert(`Testing - invalid msg: ${validator.errorMessage()} Row: ${row}`);
    //   return false;
    // } else {
    //   alert('Testing - valid Row: ' + row);
    //   return false;
    // }

    if (!this.sheet.isValid(row, this.propertyDescColumn, this.sheet.getCell(row, this.propertyDescColumn).value())) {
      alert(this.msg.property.propertyDecription);
      return false;
    }
    if (!this.sheet.isValid(row, this.propertyNoColumn, this.sheet.getCell(row, this.propertyNoColumn).value())) {
      alert(this.msg.property.propertyNumber);
      return false;
    }
    if (!this.sheet.isValid(row, this.propertyNameColumn, this.sheet.getCell(row, this.propertyNameColumn).value())) {
      alert(this.msg.property.propertyName);
      return false;
    }
    if (!this.sheet.isValid(row, this.stateColumn, this.sheet.getCell(row, this.stateColumn).value())) {
      alert(this.msg.property.propertyState);
      return false;
    }
    if (!this.sheet.isValid(row, this.countyColumn, this.sheet.getCell(row, this.countyColumn).value())) {
      alert(this.msg.property.propertyCounty);
      return false;
    }
    if (!this.sheet.isValid(row, this.operatorColumn, this.sheet.getCell(row, this.operatorColumn).value())) {
      alert(this.msg.property.propertyOperator);
      return false;
    }
    if (!this.sheet.isValid(row, this.purchaserColumn, this.sheet.getCell(row, this.purchaserColumn).value())) {
      alert(this.msg.property.propertyPurchaser);
      return false;
    }
    if (!this.sheet.isValid(row, this.productColumn, this.sheet.getCell(row, this.productColumn).value())) {
      alert(this.msg.property.propertyProduct);
      return false;
    }
    if (!this.sheet.isValid(row, this.wellApiColumn, this.sheet.getCell(row, this.wellApiColumn).value())) {
      alert(this.msg.property.propertyWellAPI);
      return false;
    }
    if (!this.sheet.isValid(row, this.effectiveFromColumn, this.sheet.getCell(row, this.effectiveFromColumn).value())) {
      alert(this.msg.property.propertyEffectiveFromDate);
      return false;
    }
    if (!this.sheet.isValid(row, this.wellNameColumn, this.sheet.getCell(row, this.wellNameColumn).value())) {
      alert(this.msg.property.propertyWellName);
      return false;
    }

    return true;
  }

  async saveProperty(origProperty: Property): Promise<Property> {
    // Clone property, so that the original version will not be formatted and thus affect the displayed value
    const property = clone(origProperty);
    console.log('Cloned Property: ', { property });

    // Based on dropdown selected values generate the property objects based on selected values using stored mappings
    let state: USState = null;
    if (property.stateName != null) {
      state = await (this.getState(property.stateName)) as USState;
    }

    if (property.countyName != null) {
      property.county = this.getCounty(property.countyName, state.counties);
    }
    if (property.operatorName != null) {
      property.operator = this.getOperator(property.operatorName);
    }
    if (property.purchaserName != null) {
      property.purchaser = this.getPurchaser(property.purchaserName);
    }

    // Assign Well fields into the well object
    if (property.id == null || property.id === 0) {
      property.id = 0;

      // New property - add well
      const well: Well = new Well();
      well.id = 0;
      well.apiNo = property.wellApiNo;
      well.name = property.wellName;
      well.operator = property.operator;
      if (property.wellType != null) {
        well.products = new Array();
        well.products.push(this.getProduct(property.wellType));
      }
      property.wells = [];
      property.wells.push(well);
    } else {
      if (typeof property.wells === 'undefined' || property.wells.length === 0) {
        property.wells.push(new Well());
      }
      property.wells[0].apiNo = property.wellApiNo;
      property.wells[0].name = property.wellName;
      // Well operator gets the same operator as the property
      property.wells[0].operator = property.operator;
      if (property.wellType != null) {
        property.wells[0].type = this.getProduct(property.wellType);
      }
    }

    // Format dates to save to the database
    property.effectiveFrom = this.formatDateDB(property.effectiveFrom);
    property.effectiveTo = this.formatDateDB(property.effectiveTo);

    console.log('Save Cloned Property: ', { property });

    // Null the insertDate/modifiedDate because they get lost in translation and caused Type-mismatch
    property.insertDate = null;
    property.modifiedDate = null;

    let result = (await (this.propertyService.add(property))) as Property;

    if (!result.bcCurrent) {
      // Display blockchain error dialog
    }
    // Assign the other root display fields
    result = this.setPropertyValue(result);

    return result;
  }

  changeSaveButtonColor(color) {
    const saveBtnCellType = new GC.Spread.Sheets.CellTypes.Button();
    // saveBtnCellType.buttonBackColor('#CFCFCF');
    saveBtnCellType.buttonBackColor(color);
    saveBtnCellType.text('Save');
    saveBtnCellType.marginBottom(1);
    saveBtnCellType.marginTop(1);
    saveBtnCellType.marginLeft(10);
    saveBtnCellType.marginRight(10);

    return saveBtnCellType;
  }

  createGridEvents() {

    // Bind click-event of Property Id and to Blockchain cells
    this.spread.bind(GC.Spread.Sheets.Events.CellClick, (e, args) => {
      const row = args.row;
      const col = args.col;
      // let cell = this.sheet.getCell(row, col);
      // const cellValue = this.sheet.getCell(row, col).value;
      // const cellValue2 = this.sheet.getValue(row, col);

      // Get selected property form array
      const selectedProperty = this.properties[row];
      console.log(`[Row: ${row}] Property with Id ${selectedProperty.id}`);

      if (col === this.blockChainColumn || col === this.blockChainTransIdColumn) {
        const bcTransId = this.sheet.getValue(row, this.blockChainTransIdColumn);
        if (bcTransId != null && bcTransId !== '') {
          let url = this.blockchainExplorerUrl + "PROPERTY_" + ("0000000000" + selectedProperty.id).slice(-10);
          // if (selectedProperty.bcBlockId != null) {
          //   url = this.blockchainExplorerUrl + selectedProperty.bcBlockId;
          // } else {
          //   url = this.blockchainExplorerUrl;
          // }
          window.open(url);
        }
      } else if (col < this.stateColumn) {
        // alert('/propertyDetail/' + foundProperty.id);
        this.router.navigateByUrl('/propertyDetail/' + selectedProperty.id);
      }
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.EnterCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColCount - 1; col++) {
        const cell = this.sheet.getCell(row, col);
        if (col !== this.blockChainColumn) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridHighlight);
          }
        }
      }
      // console.log('Row Enter Cell: ' + row);
      this.sheet.resumePaint();
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Un-highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColCount - 1; col++) {
        const cell = this.sheet.getCell(row, col);

        if (col !== this.blockChainColumn) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridBackground);
          }
        }
      }

      this.sheet.getCell(row, -1)
        .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      this.sheet.getCell(row, -1)
        .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      // console.log('Row Leave Cell: ' + row);

      this.sheet.resumePaint();
    });

    this.spread.bind(GC.Spread.Sheets.Events.ValueChanged, (e, args) => {
      const row = args.row;
      const col = args.col;

      // Value changed - clear blockchain transId, change the colour of the save button
      this.properties[row].bcTransId = null;

      // Clear Blockchain graphic to the cell
      const cell = this.sheet.getCell(row, this.blockChainColumn);
      cell.text('');
      cell.backgroundImage(null);

      // Cell has changed - enable save button and change its colour
      this.sheet.getRange(row, this.saveColumn, 1, 1).locked(false);

      const saveSingleBtnCellType = this.changeSaveButtonColor(environment.buttonEnabled);
      this.sheet.getCell(row, this.saveColumn).cellType(saveSingleBtnCellType);
      this.sheet.getCell(row, this.saveColumn).foreColor(environment.buttonEnabledText);

      // Change the back-ground color to reflect a change in grid data
      this.sheet.getCell(row, col).backColor(environment.gridCellChanged);

      // If State changed - Add county dropdowns
      if (col === this.stateColumn) {
        const countyComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
        const stateName = this.properties[row].stateName;
        const countyStrArr = [];
        const state = this.getState(stateName);
        state.counties.forEach((county: County) => {
          countyStrArr.push(county.name);
        });
        console.log('State Changed: ', { state });

        console.log('Counties: ', countyStrArr);
        countyComboBoxType.items(countyStrArr).editable(true);
        countyComboBoxType.maxDropDownItems(10);
        this.sheet.getCell(row, this.countyColumn).cellType(countyComboBoxType);
        this.properties[row].countyName = '';
      }

      // It is a complete mystery why this works
      // Problem: When date picker selects a date in Spread JS, in the "DataPickerCellType.getEditorValue" we set the return type to
      // America date format - this displays the date correctly in spread JS
      // Unfortunately, this binds a OADate object to the corresponding cell in filteredProperties
      // Doing the following (if statements) - causes the corresponding cell in filteredProperties to get date as a string in America
      // format which we want so that the API works the same here as it does in the propertyAdd screen
      // Mystery: why this "data" object which is not assigned or bound anywhere else fixes the problem eventhough it is never used
      if (col === 11) {
        this.data[row]['effectiveFrom'] = '' + this.data[row]['effectiveFrom'];
        console.log('Effective From: ', this.data[row]['effectiveFrom']);
      }

      if (col === 12) {
        this.data[row]['effectiveTo'] = '' + this.data[row]['effectiveTo'];
        console.log('Effective To: ', this.data[row]['effectiveTo']);
      }
    });

    // Define save button action
    this.spread.bind(GC.Spread.Sheets.Events.ButtonClicked, async (e, args) => {
      let newProperty = false;
      const row = args.row;
      const col = args.col;
      let cell = this.sheet.getCell(row, col);
      const cellType = this.sheet.getCellType(row, col);

      if (cellType instanceof GC.Spread.Sheets.CellTypes.Button) {
        this.spread.options.highlightInvalidData = true;

        // Add validators to last row
        if (row === this.sheet.getRowCount() - 1) {
          console.log('New property');
          this.addValidators(row);
          newProperty = true;
        }

        const property = this.properties[row];
        console.log('Property (b4 validation): ', { property });

        if (this.validateProperty(row)) {
          console.log('Valid property: ', { property });
          // Display the waitingdialog
          console.log('Waiting Dialog: ' + this.displayWaitingDialog);
          // this.openWaitingDialog();
          // this.changeDetectorRef.detectChanges();

          console.log('Waiting Dialog: ' + this.displayWaitingDialog);

          const result = await (this.saveProperty(property)) as Property;
          console.log('Save result: ', { result });

          // Get the first cell of the row - assign the property.id to the cell and also to the this.properties
          cell = this.sheet.getCell(row, 0);
          cell.text(result.id.toString());
          console.log('PropertyId: ' + result.id.toString());

          if (result.bcCurrent) {
            // Assign bcTransId to grid also
            cell = this.sheet.getCell(row, this.blockChainTransIdColumn);
            cell.text(result.bcTransId);
            console.log('bcTransId: ' + result.bcTransId);

            // Assign Blockchain graphic to the cell
            cell = this.sheet.getCell(row, this.blockChainColumn);
            const imgBlockChain = './assets/images/blockchain_cell@2x.png';
            cell.text('');
            cell.backgroundImage(imgBlockChain);
          } else {
            // Hide the waiting dialog before displaying blockchain dialog
            // this.closeWaitingDialog();
            // this.openBCDialog();
          }

          // Assign the saved property to Global Grid Array
          this.properties[row] = result;

          if (newProperty) {
            console.log('Result - new: ', { result });
            // Assign new property to the end of the global array with all properties
            this.allProperties[this.allProperties.length - 1] = result;

            // Add empty row at end of sheet
            const lastRow = this.sheet.getRowCount();
            this.sheet.addRows(lastRow, 1);
          } else {
            console.log('Result - not new: ', { result });
            // Assign the saved property to the global instance of all properties
            for (let i = 0; i < this.allProperties.length; i++) {
              if (this.allProperties[i].id === result.id) {
                this.allProperties[i] = result;
              }
            }
          }

          console.log('Properties: ', this.properties);
          console.log('All Properties: ', this.allProperties);

          // Change the color of all the cells back to highlighted
          for (let colIndex = 0; colIndex < this.gridColCount - 1; colIndex++) {
            this.sheet.getCell(row, colIndex).backColor(environment.gridHighlight);
          }
          console.log('Change cells back to highlighted');

          // Change the colour of the Save button back to Grey
          const saveBtnCellType2 = this.changeSaveButtonColor(environment.buttonDisabled);
          this.sheet.getCell(row, this.saveColumn).cellType(saveBtnCellType2);
          this.sheet.getCell(row, this.saveColumn).foreColor(environment.buttonDisabledText);
          console.log('Make button grey - disabled');

          // Hide the waiting dialog
          // this.closeWaitingDialog();
        }
      }
    });

  }

  initiliseGrid() {
    // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
    // And grid events that are NOT data specific
    console.log('Initialise Grid');

    this.sheet.suspendPaint();

    // Add State dropdowns
    const stateComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
    const stateStrArr = [];
    this.states.forEach((state: USState) => {
      stateStrArr.push(state.name);
    });

    stateComboBoxType.items(stateStrArr).editable(true);
    stateComboBoxType.maxDropDownItems(10);
    this.sheet.getCell(-1, this.stateColumn).cellType(stateComboBoxType);

    // Add Operator dropdowns
    const operatorComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
    const operatorStrArr = [];
    this.operators.forEach((operator: Operator) => {
      operatorStrArr.push(operator.name);
    });
    operatorComboBoxType.items(operatorStrArr).editable(true);
    operatorComboBoxType.maxDropDownItems(10);
    this.sheet.getCell(-1, this.operatorColumn).cellType(operatorComboBoxType);

    // Add Purchaser dropdowns
    const purchaserComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
    const purchaserStrArr = [];
    this.purchasers.forEach((purchaser: Purchaser) => {
      purchaserStrArr.push(purchaser.name);
    });

    purchaserComboBoxType.items(purchaserStrArr).editable(true);
    purchaserComboBoxType.maxDropDownItems(10);
    this.sheet.getCell(-1, this.purchaserColumn).cellType(purchaserComboBoxType);

    // Add Product dropdowns
    const productComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
    const productStrArr = [];
    this.products.forEach((product: Product) => {
      productStrArr.push(product.name);
    });
    productComboBoxType.items(productStrArr).editable(true);
    productComboBoxType.maxDropDownItems(10);
    this.sheet.getCell(-1, this.productColumn).cellType(productComboBoxType);

    const datePickerCellType = new DatePickerCellType();
    this.sheet.getCell(-1, this.effectiveFromColumn).cellType(datePickerCellType);
    this.sheet.getCell(-1, this.effectiveToColumn).cellType(datePickerCellType);

    const saveBtnCellType = this.changeSaveButtonColor(environment.buttonDisabled);
    this.sheet.getCell(-1, this.saveColumn).cellType(saveBtnCellType);
    this.sheet.getCell(-1, this.saveColumn).cellPadding('1px');
    this.sheet.getCell(-1, this.saveColumn).vAlign(GC.Spread.Sheets.VerticalAlign.center);
    this.sheet.getCell(-1, this.saveColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.saveColumn).foreColor(environment.buttonDisabledText);
    this.sheet.options.allowCellOverflow = false;
    // this.sheet.getCell(-1, this.saveColumn).font('10pt Source Sans Pro');

    // Define columns
    const propertyIdColInfo = { name: 'id', displayName: 'Id', size: 40 };
    const propertyNoColInfo = { name: 'propertyNo', displayName: 'Property#', size: 100 };
    const propertyNameColInfo = { name: 'name', displayName: 'Name', size: 180 };
    const propertyStateColInfo = { name: 'stateName', displayName: 'State', size: 120 };
    const propertyCountyColInfo = { name: 'countyName', displayName: 'County', size: 120 };
    const propertyDescColInfo = { name: 'description', displayName: 'Description', size: 150 };
    const propertyOperatorColInfo = { name: 'operatorName', displayName: 'Operator', size: 120 };
    const propertyPurchaserColInfo = { name: 'purchaserName', displayName: 'Purchaser', size: 100 };
    const propertyWellTypeColInfo = { name: 'wellType', displayName: 'Type', size: 80 };
    const propertyWellApiNoColInfo = { name: 'wellApiNo', displayName: 'Well Number', size: 130 };
    const propertyEffectiveFromColInfo = { name: 'effectiveFrom', displayName: 'Effective From', size: 100 };
    const propertyEffectiveToColInfo = { name: 'effectiveTo', displayName: 'Effective To', size: 100 };
    const propertyWellNameColInfo = { name: 'wellName', displayName: 'Well Name', size: 120 };
    const propertyBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
    const propertyBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 150 };
    const propertySaveColInfo = { name: '', displayName: '', size: 80 };

    this.sheet.autoGenerateColumns = false;

    this.sheet.setDataSource(this.properties);

    this.sheet.bindColumn(this.propertyIdColumn, propertyIdColInfo);

    this.sheet.bindColumn(this.propertyNoColumn, propertyNoColInfo);
    this.sheet.bindColumn(this.propertyNameColumn, propertyNameColInfo);
    this.sheet.bindColumn(this.stateColumn, propertyStateColInfo);
    this.sheet.bindColumn(this.countyColumn, propertyCountyColInfo);
    this.sheet.bindColumn(this.propertyDescColumn, propertyDescColInfo);
    this.sheet.bindColumn(this.operatorColumn, propertyOperatorColInfo);
    this.sheet.bindColumn(this.purchaserColumn, propertyPurchaserColInfo);
    this.sheet.bindColumn(this.productColumn, propertyWellTypeColInfo);
    this.sheet.bindColumn(this.wellApiColumn, propertyWellApiNoColInfo);
    this.sheet.bindColumn(this.effectiveFromColumn, propertyEffectiveFromColInfo);
    this.sheet.bindColumn(this.effectiveToColumn, propertyEffectiveToColInfo);
    this.sheet.bindColumn(this.wellNameColumn, propertyWellNameColInfo);
    this.sheet.bindColumn(this.blockChainColumn, propertyBCTransImgColInfo);
    this.sheet.bindColumn(this.blockChainTransIdColumn, propertyBCTransIdColInfo);
    this.sheet.bindColumn(this.saveColumn, propertySaveColInfo);

    this.sheet.setColumnCount(this.gridColCount, GC.Spread.Sheets.SheetArea.viewport);

    // Lock/Readonly all columns
/*    this.sheet.options.isProtected = true;
    this.sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: false
    };*/
    // Unlock from column 1 for 13 columns(columns 1 - 13) - [Description - Well Name]
    this.sheet.getRange(-1, 1, -1, 13).locked(false);

    // set cell alignment
    this.sheet.getCell(-1, this.propertyIdColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.effectiveFromColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.effectiveToColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);

    this.createGridEvents();

    this.sheet.resumePaint();
  }

  applyDataBinding(filteredProperties: Property[]) {
    // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);

    console.log('Start data binding');

    this.sheet.suspendPaint();
    this.sheet.setDataSource(filteredProperties);

    console.log('Row Binding - start');
    filteredProperties.forEach((rowPropertiesObject: Property, rowIndex: number) => {
      // console.log('Row Index: ' + rowIndex, rowPropertiesObject);

      // Remove vertical grid-lines
      this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));

      // Add Blockchain graphic to cell
      const cell = this.sheet.getCell(rowIndex, this.blockChainColumn);
      let imgBlockChain = null;
      if (filteredProperties[rowIndex].bcTransId != null) {
        imgBlockChain = './assets/images/blockchain_cell@2x.png';
      }
      cell.text('');
      cell.backgroundImage(imgBlockChain);

      // Add county dropdowns - this is dependant on the state selected
      const countyComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
      const stateName = filteredProperties[rowIndex].stateName;
      const countyStrArr = [];

      const state = this.getState(stateName);
      // If state defined - get the counties
      if (state != null) {
        state.counties.forEach((county: County) => {
          countyStrArr.push(county.name);
        });
      }

      // console.log('Counties: ', stateStr);
      countyComboBoxType.items(countyStrArr);
      countyComboBoxType.maxDropDownItems(10);
      this.sheet.getCell(rowIndex, this.countyColumn).cellType(countyComboBoxType);

      // Add validation to spreadsheet
      this.addValidators(rowIndex);
    });
    console.log('Row Binding - complete');

    // Highlight first row except blockchain graphic cell & set the font in all cells
    for (let col = 0; col < this.gridColCount - 1; col++) {
      if (col !== this.blockChainColumn) {
        this.sheet.getCell(0, col).backColor(environment.gridHighlight);

        // this.sheet.getCell(-1, col).font('10pt Source Sans Pro');
      }
    }

    // Add empty row at end of sheet
    const lastRow = this.sheet.getRowCount();
    this.sheet.addRows(lastRow, 1);

    // Add county dropdown to the last row
    const ctyComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
    const ctyStrArr = [];
    // console.log('Counties: ', stateStr);
    ctyComboBoxType.items(ctyStrArr);
    ctyComboBoxType.maxDropDownItems(10);
    this.sheet.getCell(lastRow, this.countyColumn).cellType(ctyComboBoxType);

    this.sheet.resumePaint();

    console.log('Binding Complete - Last row: ' + lastRow);
  }

  private filter() {
    this.properties = [];

    const tempProperties = [];

    const today = this.transformDate(new Date());
    // console.log('Todays Date: ' + today);

    this.allProperties.forEach((propertyObject: Property, rowIndex: number) => {
      // console.log(propertyObject);

      // If propertyObject is null, undefined or empty - ignore
      if (propertyObject != null && Object.keys(propertyObject).length !== 0) {

        let includeProperty = true;
        if (this.propertySearch.state !== '') {
          includeProperty = (propertyObject.county.USState.id === Number(this.propertySearch.state));
        }

        if (includeProperty && this.propertySearch.searchStr.length > 0) {
          // Check property No, lease No, well API no, Property Name, Property Desc, State, county and well name
          // against any part of the search string
          this.propertySearch.searchStr.forEach((searchSubStr) => {
            includeProperty = includeProperty
              && ((propertyObject.propertyNo.indexOf(searchSubStr) !== -1)
                || (propertyObject.wellApiNo.indexOf(searchSubStr) !== -1)
                || (propertyObject.name.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                || (propertyObject.description.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                || (propertyObject.stateName.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                || (propertyObject.countyName.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                || (propertyObject.wellName.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1));
            // console.log('Well API:', propertyObject.wellApiNo, '    Search Str: ', searchSubStr,
            //   '   Search result: ', propertyObject.wellApiNo.indexOf(searchSubStr));
          });
        }

        if (this.propertySearch.propertyType > 0 && includeProperty) {
          if (this.propertySearch.propertyType === 1) {
            // In Progress - effective From date not yet arrived
            if (propertyObject.effectiveFrom !== null) {
              includeProperty = (this.formatDateDB(propertyObject.effectiveFrom) >= today);
            } else {
              includeProperty = false;
            }
          } else if (this.propertySearch.propertyType === 3) {
            // Inactive - EffectiveTo date has passed
            if (propertyObject.effectiveTo !== null) {
              includeProperty = (this.formatDateDB(propertyObject.effectiveTo) < today);
              console.log('Effective To: ' + this.formatDateDB(propertyObject.effectiveTo));
            } else {
              includeProperty = false;
              console.log('Exclude: ' + propertyObject.effectiveTo);
            }
          } else {  // must be active
            if (propertyObject.effectiveFrom !== null) {
              if (this.formatDateDB(propertyObject.effectiveFrom) <= today) {
                if (propertyObject.effectiveTo !== null) {
                  includeProperty = (this.formatDateDB(propertyObject.effectiveTo) > today);
                } else {
                  includeProperty = true;
                }
              } else {
                includeProperty = false;
              }
            } else {
              includeProperty = true;
            }
          }
        }

        // console.log('IncludeProperty: ' + includeProperty);
        if (includeProperty) {
          // this.properties.push(propertyObject);
          tempProperties.push(propertyObject);
        }
      }
    });

    this.properties = [...tempProperties];

    // console.table(this.properties);

    this.applyDataBinding(this.properties);
  }

  public doSearch() {
    const str = this.propertyForm.get('searchStr').value;
    this.propertySearch.searchStr = str.split(' ');
    this.filter();
  }

  public onStateChange() {
    console.log('State changed: ' + this.propertyForm.get('state').value);

    this.propertySearch.state = this.propertyForm.get('state').value;
    this.filter();
  }

  public btnAllProperties() {
    this.propertySearch.propertyType = 0;
    this.filter();
  }

  public btnInProgressProperties() {
    this.propertySearch.propertyType = 1;
    this.filter();
  }

  public btnActiveProperties() {
    this.propertySearch.propertyType = 2;
    this.filter();
  }

  public btnInactiveProperties() {
    this.propertySearch.propertyType = 3;
    this.filter();
  }

  public addProperty() {
    this.openAddProperty();
  }

  public uploadProperty() {
    this.openUploadProperty();
  }

  public onBack() {
    console.log('Back clicked');
    this.location.back();
  }

  public exportReport(reportType) {
    this.propertyService.getReport(this.properties, reportType).subscribe((data) => {
      const blob = new Blob([data as BlobPart], { type: 'application/octet-stream' });
      let extension = '';
      if (reportType === 1) {
        extension = '.pdf';
      } else if (reportType === 2) {
        extension = '.xlsx';
      } else if (reportType === 3) {
        extension = '.csv';
      }
      FileSaver.saveAs(blob, `properties${extension}`);
    });
  }

  public workbookInit(args) {
    console.log(`Workbook Init`, { args });

    this.spread = args.spread as GC.Spread.Sheets.Workbook;
    this.sheet = this.spread.getActiveSheet() as GC.Spread.Sheets.Worksheet;

    this.sheetWidth = this.sheet.getViewportWidth(1);
    console.log('SheetWidth: ', this.sheetWidth);
  }

}
