<app-owner-content [(displayTaxReport)]="displayTaxReport"></app-owner-content>
<app-owner-upload></app-owner-upload>
<app-owner-add></app-owner-add>

<kendo-dialog *ngIf="displayTaxReport">
  <div style="width:80%; display:inline-block;align-content: center">
    <img style="width:1000px" src="../../assets/images/TaxReport.png" alt="../../assets/images/TaxReport.png" />
  </div>
  <div class="dialog_actions clearfix">
    <div class="actions_left">
      <button type="button" class="btn_normal btn_blue" id="btn_add_owner_alt">
        <span class="btn_icon"><span class="icon_create"></span></span>
        &nbsp;<span class="btn_text">CSV</span></button
      >&nbsp;
      <button type="button" class="btn_normal btn_blue" id="btn_add_owner_alt">
        <span class="btn_icon"><span class="icon_create"></span></span>
        &nbsp;<span class="btn_text">Excel</span></button
      >&nbsp;
      <button type="button" class="btn_normal btn_blue" id="btn_add_owner_alt">
        <span class="btn_icon"><span class="icon_create"></span></span>
        &nbsp;<span class="btn_text">Print</span></button
      >&nbsp;
      <button type="button" class="btn_normal btn_blue" id="btn_add_owner_alt">
        <span class="btn_icon"><span class="icon_create"></span></span>
        &nbsp;<span class="btn_text">Share</span>
      </button>
    </div>
    <div class="actions_right">
      <button type="button" class="btn_normal btn_blue" id="btn_confirm_upload_owner" (click)="closeTaxReport()">
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</kendo-dialog>
