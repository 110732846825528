import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { map } from 'rxjs/operators';
import { ProductionSearch } from 'src/app/model/production_search';
import { SalesService } from 'src/app/sales.service';
import { PaymentsService } from 'src/app/payments.service';
var SalesProcessPaymentsComponent = /** @class */ (function () {
    function SalesProcessPaymentsComponent(salesService, paymentsService, router, authService) {
        this.salesService = salesService;
        this.paymentsService = paymentsService;
        this.router = router;
        this.authService = authService;
        this.displayProcessPaymentsChange = new EventEmitter();
        this.salesSearchChange = new EventEmitter();
        this.message = '';
        this.displayMessage = false;
    }
    SalesProcessPaymentsComponent.prototype.ngOnInit = function () {
    };
    SalesProcessPaymentsComponent.prototype.ngOnChanges = function (changes) {
        // WARNING: this will be executed on dialog open and on dialog close
        if (this.displayProcessPayments) {
            this.startDate = new Date(this.salesSearch.startDateYear, this.salesSearch.startDateMonth - 1, 1, 0, 0, 0, 0);
            this.endDate = new Date(this.salesSearch.endDateYear, this.salesSearch.endDateMonth);
            this.endDate.setDate(this.endDate.getDate() - 1);
            console.log('ngOnChanges - startDate: ' + this.startDate.toISOString() + ' - endDate: ' + this.endDate.toISOString());
            this.message = "";
            this.displayMessage = false;
            var operator = this.authService.getUser().operator;
            this.getSalesProcessPayments(operator, this.startDate, this.endDate);
        }
    };
    SalesProcessPaymentsComponent.prototype.getSalesProcessPayments = function (operator, startDate, endDate) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                console.log('getSalesProcessPayments - startDate: ' + startDate.toISOString() + ' - endDate: ' + endDate.toISOString());
                return [2 /*return*/, this.salesService.getSalesProcessPayments(operator.id, startDate, endDate).pipe(map(function (sales) {
                        var tempSales = JSON.parse(JSON.stringify(sales));
                        _this.sales = tempSales;
                        _this.salesCount = 0;
                        _this.salesTotal = 0.0;
                        tempSales.forEach(function (salesRec) {
                            // console.log('Sales: ', { salesRec });
                            _this.salesCount++;
                            _this.salesTotal += salesRec.grsValue;
                        });
                        console.log('getSales - complete: ' + new Date().toISOString() + '    No. Records: ' + _this.sales.length);
                        // console.log('Sales: ' + JSON.stringify(sales));
                        return sales;
                    }, function (err) {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                _this.router.navigate(['/login']);
                            }
                        }
                    })).toPromise()];
            });
        });
    };
    SalesProcessPaymentsComponent.prototype.refresh = function () {
        console.log('Start Date: ' + this.startDate.toISOString() + '    End Date: ' + this.endDate.toISOString());
        var operator = this.authService.getUser().operator;
        this.getSalesProcessPayments(operator, this.startDate, this.endDate);
    };
    SalesProcessPaymentsComponent.prototype.closeDialog = function () {
        this.displayProcessPayments = false;
        this.displayProcessPaymentsChange.emit(false);
    };
    SalesProcessPaymentsComponent.prototype.processPayments = function () {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('user'));
        this.sales.forEach(function (salesRec) {
            console.log('Sales: ', { salesRec: salesRec });
            _this.paymentsService.processPayment(salesRec, user.id);
        });
        this.salesCount = 0;
        this.salesTotal = 0;
        this.message = 'Payments been generate - when completed will be visible in the Payments screen';
        this.displayMessage = true;
    };
    return SalesProcessPaymentsComponent;
}());
export { SalesProcessPaymentsComponent };
