<div style="width: 100vw; height: 100vh; position: absolute; top: 0; left: 0;background-color: rgba(237, 241, 244, 1); z-index:-1;"></div>
<div>
  <div class="form_label">
    <label class="upload_label" for="fileToUpload">
      <span class="icon_upload">
        <i class="fas fa-cloud-upload-alt"> </i>
      </span>
      <br />
      <h1>Drag and drop here to import</h1>
      <br />
      Or browse for your file, if you prefer...
      <br />
      <br />
      <span class="btn_normal" type="button">Browse...</span>
      <br />
      <br />
    </label>
  </div>

  <div id="form_file_selected" style="text-align: center; line-height: 40px;">{{ message }}</div>

  <div class="selectFile" style="clear: both; visibility: hidden;">
    <input type="file" name="fileToUpload" id="fileToUpload" accept=".csv" (change)="onFileSelected($event)" />
  </div>
  <br /><br />
  <div>
    <div class="uploadFile" *ngIf="selectedFile; else file_selected">
      <span
        class="btn_normal"
        type="button"
        (click)="onUpload()"
        style="width: 80px; text-align: center; position: absolute; right: 15px; bottom: 15px;"
        >Upload</span
      >
    </div>
    <ng-template #file_selected>
      <span
        class="btn_normal btn_disabled"
        id="btn_fake_submit"
        style="width: 80px; text-align: center; position: absolute; right: 15px; bottom: 15px;"
        >Upload</span
      >
    </ng-template>
  </div>
</div>
