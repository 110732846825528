<div id="spread-container" class="spread-container" #divSpreadContainer>
  <!-- Spreadsheet LOADED -->
  <gc-spread-sheets
    #spreadContainer
    id="divisionorder-table-data"
    [tabStripVisible]="tabStripVisible"
    [backColor]="spreadBackColor"
    [hostStyle]="hostStyle"
    hostClass="spreadContainer"
    (workbookInitialized)="workbookInit($event)"
  >
  </gc-spread-sheets>
</div>
