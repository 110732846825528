import * as tslib_1 from "tslib";
import { dynamicSort } from '../shared/dynamic-sort';
import { OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, formatDate } from '@angular/common';
import * as GC from '@grapecity/spread-sheets';
import { PropertyService } from '../property.service';
import { LookupService } from '../lookup.service';
import { Property } from '../model/property';
import { Well } from '../model/well';
import { DivisionOrder } from '../model/division_order';
import { DivisionorderService } from '../divisionorder.service';
import { environment } from 'src/environments/environment';
import { Owner } from '../model/owner';
import * as FileSaver from 'file-saver';
import { FormBuilder } from '@angular/forms';
var clone = function (obj) { return JSON.parse(JSON.stringify(obj)); };
var ɵ0 = clone;
var PropertyDetailComponent = /** @class */ (function () {
    function PropertyDetailComponent(fb, activatedRoute, propertyService, divisionOrderService, lookupService, router, location) {
        this.fb = fb;
        this.activatedRoute = activatedRoute;
        this.propertyService = propertyService;
        this.divisionOrderService = divisionOrderService;
        this.lookupService = lookupService;
        this.router = router;
        this.location = location;
        this.blockchainExplorerUrl = "" + environment.blockchainExplorerUrl;
        this.divisionOrderForm = this.fb.group({
            deckId: '',
            interestTypeId: ''
        });
        this.displayUploadProduction = false;
        this.displayUploadSales = false;
        this.displayUploadProperty = false;
        this.displayAddProperty = false;
        this.sheet = null;
        this.tabStripVisible = false;
        this.spreadBackColor = 'white';
        this.sheetName = 'Property List';
        this.hostStyle = {
            height: '100%',
            width: '100%'
        };
        this.autoGenerateColumns = false;
        this.totalDecimalInterest = 0.0000000000;
        this.selectedWell = -1;
        this.selectedProduct = -1;
        this.ownerIdCol = 0;
        this.ownerNameCol = 1;
        this.productCol = 2;
        this.termSartCol = 3;
        this.termEndCol = 4;
        this.interestTypeCol = 5;
        this.decimalIntCol = 6;
        this.blockChainImgCol = 7;
        this.blockChainTransIdCol = 8;
        this.bankCol = 9;
        this.statusCol = 10;
        this.actionCol = 11;
        this.gridColCount = 12;
        this.displayDivisionOrderDialog = false;
        this.chartPeriod = 0; // 0 = Monthly, 1 = Quarterly, 2 = Annually
        this.chartType = 0; // 0 = table, 1 = chart
        this.gridSort = 0; // 0 = unsorted, 1 = sorted by Bank Ascending, -1 = sorted by Bank as Descending
        this.lastUpdated = new Date();
        this.lastUpdatedminus1 = new Date();
        this.lastUpdatedminus2 = new Date();
        this.lastUpdatedminus3 = new Date();
        this.lastUpdatedminus4 = new Date();
        this.lastUpdatedminus5 = new Date();
        this.lastUpdatedminus6 = new Date();
        this.performanceStart = new Date();
        this.performanceEnd = new Date();
        this.property = new Property();
        this.property.wells = [new Well()];
        this.lastUpdated.setDate(new Date().getDate() - 2);
        this.lastUpdatedminus1.setMonth(this.lastUpdated.getMonth() - 1);
        this.lastUpdatedminus2.setMonth(this.lastUpdated.getMonth() - 2);
        this.lastUpdatedminus3.setMonth(this.lastUpdated.getMonth() - 3);
        this.lastUpdatedminus4.setMonth(this.lastUpdated.getMonth() - 4);
        this.lastUpdatedminus5.setMonth(this.lastUpdated.getMonth() - 5);
        this.lastUpdatedminus6.setMonth(this.lastUpdated.getMonth() - 6);
        this.performanceStart.setMonth(this.lastUpdated.getMonth() - 6);
    }
    PropertyDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.selectedDivOrder = new DivisionOrder();
        var propertyStr = this.activatedRoute.snapshot.paramMap.get('id');
        Promise.all([
            this.getOperators(),
            this.getPurchasers(),
            this.getProducts(),
            this.getInterestTypes()
        ]).then(function (values) {
            var user = JSON.parse(localStorage.getItem('user'));
            var operatorId = user.operator.id;
            if (propertyStr.substr(0, 1) === '_') {
                var propertyNo = propertyStr.substr(1);
                console.log('Property No.: ' + propertyNo);
                _this.getPropertyByNo(propertyNo);
                // this.getDivOrdersByPropertyNo(operatorId, propertyNo);
                _this.getDecksByPropertyNo(operatorId, propertyNo);
            }
            else {
                var propertyId = +propertyStr;
                console.log('Property Id.: ' + propertyId);
                _this.getProperty(propertyId);
                // this.getDivOrders(operatorId, propertyId);
                _this.getDecks(operatorId, propertyId);
            }
        });
    };
    PropertyDetailComponent.prototype.getProperty = function (propertyId) {
        var _this = this;
        this.propertyService.getProperty(propertyId).subscribe(function (property) {
            console.log({ property: property });
            _this.property = property;
            // Note: I had to do a lowerCase on the 'AM/PM' part
            _this.modifiedDate = formatDate(_this.property.modifiedDate, 'd MMM yyyy h:mm', 'en-US')
                + formatDate(_this.property.modifiedDate, 'a', 'en-US').toLowerCase();
        });
    };
    PropertyDetailComponent.prototype.getPropertyByNo = function (propertyNo) {
        var _this = this;
        this.propertyService.getPropertyByNo(propertyNo).subscribe(function (property) {
            console.log('Property By No: ', { property: property });
            _this.property = property;
            // Note: I had to do a lowerCase on the 'AM/PM' part
            _this.modifiedDate = formatDate(_this.property.modifiedDate, 'd MMM yyyy h:mm', 'en-US')
                + formatDate(_this.property.modifiedDate, 'a', 'en-US').toLowerCase();
        });
    };
    PropertyDetailComponent.prototype.getDivOrders = function (operatorId, propertyId) {
        var _this = this;
        this.divisionOrderService.getDivisionOrdersByPropertyId(operatorId, propertyId).subscribe(function (divOrders) {
            console.log({ divOrders: divOrders });
            var tempDivOrders = JSON.parse(JSON.stringify(divOrders));
            console.log('pre-transformation', { tempDivOrders: tempDivOrders });
            tempDivOrders = _this.setDivisionOrderValues(tempDivOrders);
            console.log('post transformation', { tempDivOrders: tempDivOrders });
            _this.divOrders = tempDivOrders;
            if (tempDivOrders.length > 0) {
                _this.selectedDivOrder = tempDivOrders[0];
            }
            _this.initialiseGrid();
            _this.applyDataBinding();
        });
    };
    PropertyDetailComponent.prototype.getDivOrdersByPropertyNo = function (operatorId, propertyNo) {
        var _this = this;
        this.divisionOrderService.getDivisionOrdersByPropertyNo(operatorId, propertyNo).subscribe(function (divOrders) {
            // console.log({ divOrders });
            var tempDivOrders = JSON.parse(JSON.stringify(divOrders));
            // console.log('pre-transformation', { tempDivOrders });
            tempDivOrders = _this.setDivisionOrderValues(tempDivOrders);
            // console.log('post transformation', { tempDivOrders });
            _this.divOrders = tempDivOrders;
            if (tempDivOrders.length > 0) {
                _this.selectedDivOrder = tempDivOrders[0];
            }
            _this.initialiseGrid();
            _this.applyDataBinding();
        });
    };
    PropertyDetailComponent.prototype.getDecks = function (operatorId, propertyId) {
        var _this = this;
        this.divisionOrderService.getDecksByPropertyId(operatorId, propertyId).subscribe(function (decks) {
            console.log({ decks: decks });
            var tempDecks = JSON.parse(JSON.stringify(decks));
            console.log('pre-transformation', { tempDecks: tempDecks });
            tempDecks.forEach(function (deck) {
                var tempDivOrders = deck.divisionOrders;
                tempDivOrders = _this.setDivisionOrderValues(tempDivOrders);
                deck.divisionOrders = tempDivOrders;
            });
            console.log('post transformation', { tempDecks: tempDecks });
            _this.decks = tempDecks;
            if (tempDecks.length > 0) {
                _this.selectedDeck = tempDecks[0];
                _this.divOrders = tempDecks[0].divisionOrders;
                _this.selectedDivOrder = tempDecks[0].divisionOrders[0];
            }
            _this.initDivisionOrderSearchForm();
            console.log('divOrders', _this.divOrders);
            setTimeout(function () {
                _this.initialiseGrid();
                _this.applyDataBinding();
            }, 2000);
        });
    };
    PropertyDetailComponent.prototype.getDecksByPropertyNo = function (operatorId, propertyNo) {
        var _this = this;
        this.divisionOrderService.getDecksByPropertyNo(operatorId, propertyNo).subscribe(function (decks) {
            console.log({ decks: decks });
            var tempDecks = JSON.parse(JSON.stringify(decks));
            console.log('pre-transformation', { tempDecks: tempDecks });
            tempDecks.forEach(function (deck) {
                var tempDivOrders = deck.divisionOrders;
                tempDivOrders = _this.setDivisionOrderValues(tempDivOrders);
                deck.divisionOrders = tempDivOrders;
            });
            console.log('post transformation', { tempDecks: tempDecks });
            _this.decks = tempDecks;
            if (tempDecks.length > 0) {
                _this.selectedDeck = tempDecks[0];
                _this.divOrders = tempDecks[0].divisionOrders;
                _this.selectedDivOrder = tempDecks[0].divisionOrders[0];
            }
            var tempDivOrders2 = _this.divOrders;
            _this.initDivisionOrderSearchForm();
            console.log('divOrders', { tempDivOrders2: tempDivOrders2 });
            _this.initialiseGrid();
            _this.applyDataBinding();
        });
    };
    PropertyDetailComponent.prototype.initDivisionOrderSearchForm = function () {
        this.divisionOrderForm.patchValue({ deckId: this.selectedDeck.id });
        this.divisionOrderForm.patchValue({ interestTypeId: '' });
    };
    PropertyDetailComponent.prototype.setDivisionOrderValues = function (filteredDivOrders) {
        filteredDivOrders.forEach(function (divOrder) {
            // console.log('Division Order: ', { divOrder });
            divOrder.lease.title.owner = new Owner(divOrder.lease.title.owner);
            divOrder.ownerId = divOrder.lease.title.owner.id;
            divOrder.ownerName = divOrder.lease.title.owner.fname + ' ' + divOrder.lease.title.owner.lname;
            divOrder.ownerTel = divOrder.lease.title.owner.tel;
            divOrder.ownerAddr = divOrder.lease.title.owner.getAddress();
            divOrder.ownerEmail = divOrder.lease.title.owner.email;
            divOrder.ownerPercent = divOrder.lease.title.ownershipPercent;
            divOrder.propertyNo = divOrder.lease.title.property.propertyNo;
            divOrder.propertyName = divOrder.lease.title.property.name;
            divOrder.propertyDesc = divOrder.lease.title.property.description;
            divOrder.productName = divOrder.product.name;
            divOrder.interestTypeAbbr = divOrder.interestType.abbr;
            if (divOrder.divisionOrderDate != null) {
                // console.log(divOrder.divisionOrderDate);
                divOrder.doDateStr = formatDate(divOrder.divisionOrderDate, 'MM/dd/yyyy', 'en');
            }
            if (divOrder.termStart != null) {
                // console.log(divOrder.termStart);
                divOrder.startDateStr = formatDate(divOrder.termStart, 'MM/dd/yyyy', 'en');
            }
            if (divOrder.termEnd != null) {
                // console.log(divOrder.endDateStr);
                divOrder.endDateStr = formatDate(divOrder.termEnd, 'MM/dd/yyyy', 'en');
            }
            divOrder.trustManagerName = divOrder.bankTrustManager.name;
            divOrder.bankName = divOrder.bankTrustManager.bank.name;
            divOrder.deckCode = divOrder.deck.deckCode;
            divOrder.deckChangeCode = divOrder.deck.deckChangeCode;
            divOrder.deckVersion = divOrder.deck.deckVersionDate;
        });
        return filteredDivOrders;
    };
    PropertyDetailComponent.prototype.onDeckChange = function () {
        var deckId = Number(this.divisionOrderForm.get('deckId').value);
        console.log('Deck changed: ' + deckId);
        var filteredDecks = this.decks.filter(function (deck) {
            return deck.id === deckId;
        });
        if (filteredDecks) {
            this.selectedDeck = filteredDecks[0];
            this.divOrders = filteredDecks[0].divisionOrders;
            this.selectedDivOrder = filteredDecks[0].divisionOrders[0];
        }
        var interestTypeId = this.divisionOrderForm.get('interestTypeId').value;
        if (interestTypeId) {
            this.filterByInterestType(Number(interestTypeId));
        }
        this.initialiseGrid();
        this.applyDataBinding();
    };
    PropertyDetailComponent.prototype.onInterestTypeChange = function () {
        var interestTypeId = Number(this.divisionOrderForm.get('interestTypeId').value);
        console.log('Interest Type changed: ' + interestTypeId);
        this.filterByInterestType(interestTypeId);
        this.initialiseGrid();
        this.applyDataBinding();
    };
    PropertyDetailComponent.prototype.filterByInterestType = function (interestTypeId) {
        if (interestTypeId > 0) {
            var filteredDivisionOrders = this.selectedDeck.divisionOrders.filter(function (divisionOrder) {
                return divisionOrder.interestType.id === interestTypeId;
            });
            if (filteredDivisionOrders) {
                this.divOrders = filteredDivisionOrders;
                this.selectedDivOrder = this.divOrders[0];
            }
        }
        else {
            this.divOrders = this.selectedDeck.divisionOrders;
            this.selectedDivOrder = this.selectedDeck.divisionOrders[0];
        }
    };
    PropertyDetailComponent.prototype.createGridEvents = function () {
        var _this = this;
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.EnterCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Highlight all cells of the row
            for (var col = 0; col < _this.gridColCount; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainImgCol) {
                    var cellBackColor = cell.backColor();
                    _this.sheet.getCell(row, col).backColor(environment.gridHighlight);
                }
            }
            // console.log('Row Enter Cell: ' + row);
            _this.sheet.resumePaint();
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Un-highlight all cells of the row
            for (var col = 0; col < _this.gridColCount; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainImgCol) {
                    var cellBackColor = cell.backColor();
                    _this.sheet.getCell(row, col).backColor(environment.gridBackground);
                }
            }
            _this.sheet.getCell(row, -1)
                .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            _this.sheet.getCell(row, -1)
                .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            // console.log('Row Leave Cell: ' + row);
            _this.sheet.resumePaint();
        });
        // Bind click-event of Blockchain cell
        this.spread.bind(GC.Spread.Sheets.Events.CellClick, function (e, args) {
            var row = args.row;
            var col = args.col;
            _this.selectedDivOrder = _this.divOrders[row];
            console.log('Row: ' + row);
            // console.table(this.divOrders);
            console.log('Selected DivOrder: ', _this.selectedDivOrder);
            // If column Header clicked
            if (args.sheetArea === GC.Spread.Sheets.SheetArea.colHeader) {
                if (col === _this.bankCol) {
                    if (_this.gridSort === 0) {
                        _this.gridSort = 1;
                    }
                    else if (_this.gridSort === 1) {
                        _this.gridSort = -1;
                    }
                    else if (_this.gridSort === -1) {
                        _this.gridSort = 1;
                    }
                    _this.divOrders.sort(dynamicSort('bankName', _this.gridSort));
                    _this.applyDataBinding();
                }
            }
            else if (col === _this.blockChainImgCol || col === _this.blockChainTransIdCol) {
                if (_this.divOrders[row].bcTransId != null) {
                    console.log('Cell: (' + row + ', ' + col
                        + ') DivisionOrderId: ' + _this.divOrders[row].id + '     BC TransId: ' + _this.divOrders[row].bcTransId
                        + '     BC BlockId: ' + _this.divOrders[row].bcBlockId);
                    // const url = this.blockchainExplorerUrl + selectedDivOrder.bcBlockId;
                    var url = '';
                    if (_this.selectedDivOrder.bcBlockId != null) {
                        url = _this.blockchainExplorerUrl + _this.selectedDivOrder.bcBlockId;
                    }
                    else {
                        url = _this.blockchainExplorerUrl;
                    }
                    window.open(url);
                }
                // Add click event to edit owner
            }
            else if (col === _this.ownerIdCol || col === _this.ownerNameCol) {
                // alert('/owner/' + this.divOrders[row].ownerId);
                _this.router.navigateByUrl('/owner/' + _this.divOrders[row].ownerId);
                // Add click event to display Royalty Interest
            }
            else if (col === _this.decimalIntCol) {
                console.log('Decimal Interest clicked - Before - this.displayDivisionOrderDialog: ' + _this.displayDivisionOrderDialog);
                _this.displayDivisionOrderDialog = true;
                console.log('Decimal Interest clicked - After - this.displayDivisionOrderDialog: ' + _this.displayDivisionOrderDialog);
            }
        });
    };
    PropertyDetailComponent.prototype.initialiseGrid = function () {
        this.sheet.suspendPaint();
        // Lock/Readonly all columns
        this.sheet.options.isProtected = true;
        this.sheet.options.protectionOptions = {
            allowSelectUnlockedCells: true,
            allowSelectLockedCells: false
        };
        // Unlock from column 0 for 6 columns(columns 0 - 5) - [Owner# - Royalty Interest]
        this.sheet.getRange(-1, 0, -1, 6).locked(false);
        // Unlock from columns 8 for 4 columns (columns 8 - 11) - [Working Interest - Action]
        this.sheet.getRange(-1, 8, -1, 4).locked(false);
        // Define columns
        var ownerIdColInfo = { name: 'ownerId', displayName: 'Owner#', size: 60 };
        var ownerNameColInfo = { name: 'ownerName', displayName: 'Owner Name', size: 200 };
        var productColInfo = { name: 'productName', displayName: 'Product', size: 120 };
        var doTermStartColInfo = { name: 'termStart', displayName: 'Term Start', size: 90 };
        var doTermEndColInfo = { name: 'termEnd', displayName: 'Term End', size: 90 };
        var interestTypeColInfo = { name: 'interestTypeAbbr', displayName: 'Interest Type', size: 120 };
        var doDecimalIntColInfo = { name: 'decimalInterest', displayName: 'Decimal Int.', formatter: '0.0000000000', size: 120 };
        var doBCImageColInfo = { name: '', displayName: '#', size: 30 };
        var doBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 150 };
        var doBankColInfo = { name: 'bankName', displayName: 'Bank', size: 120 };
        var doStatusColInfo = { name: 'status', displayName: 'Status', size: 70 };
        var doActionColInfo = { name: 'action', displayName: 'Action', size: 70 };
        this.sheet.autoGenerateColumns = true;
        this.sheet.setDataSource(this.divOrders);
        this.sheet.bindColumns([
            ownerIdColInfo,
            ownerNameColInfo,
            productColInfo,
            doTermStartColInfo,
            doTermEndColInfo,
            interestTypeColInfo,
            doDecimalIntColInfo,
            doBCImageColInfo,
            doBCTransIdColInfo,
            doBankColInfo,
            doStatusColInfo,
            doActionColInfo
        ]);
        this.sheet.setColumnCount(this.sheet.getColumnCount());
        this.sheet.getCell(-1, this.productCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.termSartCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.termEndCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.interestTypeCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.decimalIntCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.statusCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.actionCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.createGridEvents();
        this.sheet.resumePaint();
    };
    PropertyDetailComponent.prototype.applyDataBinding = function () {
        var _this = this;
        this.sheet.suspendPaint();
        this.totalDecimalInterest = 0.0;
        // Generate the Block-chain column definition
        this.divOrders.forEach(function (rowDivorderObject, rowIndex) {
            // console.log('Row Index: ' + rowIndex, rowDivorderObject);
            // Remove vertical grid-lines
            _this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
            var cell = _this.sheet.getCell(rowIndex, _this.blockChainImgCol);
            var imgBlockChain = null;
            if (_this.divOrders[rowIndex].bcTransId != null) {
                imgBlockChain = './assets/images/blockchain_cell@2x.png';
                // console.log('Blockchain Trans Id not NULL RowIndex: ' + rowIndex + '   TransId: ' + this.divOrders[rowIndex].bcTransId);
            }
            cell.text('');
            cell.backgroundImage(imgBlockChain);
            _this.totalDecimalInterest += rowDivorderObject.decimalInterest;
        });
        // Highlight first row except blockchain graphic cell
        for (var col = 0; col < this.gridColCount; col++) {
            if (col !== this.blockChainImgCol) {
                this.sheet.getCell(0, col).backColor(environment.gridHighlight);
            }
        }
        this.sheet.resumePaint();
    };
    PropertyDetailComponent.prototype.getOperators = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, operatorId, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        user = JSON.parse(localStorage.getItem('user'));
                        operatorId = user.operator.id;
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getOperators(operatorId))];
                    case 1:
                        _a.operators = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyDetailComponent.prototype.getPurchasers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getPurchasers())];
                    case 1:
                        _a.purchasers = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyDetailComponent.prototype.getProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getProducts())];
                    case 1:
                        _a.products = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyDetailComponent.prototype.getInterestTypes = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getInterestTypes())];
                    case 1:
                        _a.interestTypes = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyDetailComponent.prototype.uploadProduction = function () {
        this.displayUploadProduction = true;
    };
    PropertyDetailComponent.prototype.uploadSales = function () {
        this.displayUploadSales = true;
    };
    PropertyDetailComponent.prototype.uploadDO = function () {
        this.displayUploadProperty = true;
    };
    PropertyDetailComponent.prototype.onBack = function () {
        this.location.back();
    };
    PropertyDetailComponent.prototype.setChartPeriod = function (period) {
        this.chartPeriod = period;
    };
    PropertyDetailComponent.prototype.setChartType = function (type) {
        this.chartType = type;
    };
    PropertyDetailComponent.prototype.onClose = function () {
        alert('Close - dialog');
        this.displayDivisionOrderDialog = false;
    };
    // dynamicSort(property, sortOrder) {
    //   return (a, b) => {
    //     /* next line works with strings and numbers,
    //      * and you may want to customize it to your needs
    //      */
    //     const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    //     return result * sortOrder;
    //   };
    // }
    PropertyDetailComponent.prototype.exportReport = function (reportType) {
        this.divisionOrderService.getReport(this.divOrders, reportType).subscribe(function (data) {
            var blob = new Blob([data], { type: 'application/octet-stream' });
            var extension = '';
            if (reportType === 1) {
                extension = '.pdf';
            }
            else if (reportType === 2) {
                extension = '.xlsx';
            }
            else if (reportType === 3) {
                extension = '.csv';
            }
            FileSaver.saveAs(blob, "division-orders" + extension);
        });
    };
    PropertyDetailComponent.prototype.workbookInit = function (args) {
        console.log("Workbook Init", { args: args });
        this.spread = args.spread;
        this.sheet = this.spread.getActiveSheet();
        this.sheetWidth = this.sheet.getViewportWidth(1);
        console.log('SheetWidth: ', this.sheetWidth);
    };
    return PropertyDetailComponent;
}());
export { PropertyDetailComponent };
export { ɵ0 };
