import { Router } from '@angular/router';
import { User } from './../model/user';
import { OnInit } from '@angular/core';
import { AuthService } from './../auth.service';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(_auth, _router) {
        this._auth = _auth;
        this._router = _router;
        this.errorMessage = '';
        // tslint:disable: variable-name
        this.loginUserData = new User();
    }
    LoginComponent.prototype.ngOnInit = function () {
        localStorage.clear();
    };
    LoginComponent.prototype.loginUser = function () {
        var _this = this;
        console.log('Login ...');
        // console.log('Before Login: ', this.loginUserData);
        this._auth.loginUser(this.loginUserData)
            .subscribe(function (res) {
            console.log('Successful login');
            localStorage.setItem('token', res.token);
            localStorage.setItem('user', res.user);
            // Successfully logged in - navigate to the default page
            _this._router.navigate(['/dashboard']);
        }, function (err) {
            console.log('Login Error: ', err);
            _this.errorMessage = 'Invalid login credentials';
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
