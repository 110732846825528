import { MessagesService } from './../messages.service';
import { SalesService } from './../sales.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-load-sales-csv-file',
  templateUrl: './load-sales-csv-file.component.html',
  styleUrls: ['./load-sales-csv-file.component.css']
})
export class LoadSalesCsvFileComponent implements OnInit, OnDestroy {
  public selectedFile: File = null;
  public message = this.messagesService.noFileSelected;
  private ngUnsubscribe = new Subject();

  onFileSelected(event) {
    this.selectedFile = event.target.files[0] as File;
    this.message = this.selectedFile.name;
  }

  onUpload() {
    const fileName = this.selectedFile.name;
    const fd = new FormData();
    fd.append('file', this.selectedFile, fileName);

    const resultObservable = this.salesService.postSalesFile(fd);

    const resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      console.log('Response: ' + res);
    });

    this.selectedFile = null;
    this.message = fileName + ' - ' + this.messagesService.fileUploaded;
  }

  constructor(private http: HttpClient, private salesService: SalesService, private messagesService: MessagesService) { }

  ngOnInit() { }

  ngOnDestroy() {
    console.log('The component is being destroyed');
    this.ngUnsubscribe.next();
  }
}
