import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { DivisionOrder } from './model/division_order';

@Injectable({
  providedIn: 'root'
})
export class DivisionorderService {

  private readonly divOrderUrl = `${environment.apiUrl}/divisionorder`;

  constructor(private http: HttpClient) { }

  // API: Get
  getDivisionOrders(operatorId: number) {
    const url = `${this.divOrderUrl}/getDivisionOrders?operatorId=${operatorId}`;

    console.log('GetDivisionOrders Url: ', url);

    return this.http.get(url);
  }

  getDivisionOrdersByPropertyId(operatorId: number, propertyId: number) {
    const url = `${this.divOrderUrl}/getDivisionOrders?operatorId=${operatorId}&propertyId=${propertyId}`;

    console.log('GetDivisionOrdersByPropertyId Url: ', url);

    return this.http.get(url);
  }

  getDivisionOrdersByPropertyNo(operatorId: number, propertyNo: string) {
    const url = `${this.divOrderUrl}/getDivisionOrders?operatorId=${operatorId}&propertyNo=${propertyNo}`;

    console.log('GetDivisionOrdersByPropertyNo Url: ', url);

    return this.http.get(url);
  }

  getDecksByPropertyId(operatorId: number, propertyId: number) {
    const url = `${this.divOrderUrl}/getDecks?operatorId=${operatorId}&propertyId=${propertyId}`;

    console.log('GetDecksByPropertyId Url: ', url);

    return this.http.get(url);
  }

  getDecksByPropertyNo(operatorId: number, propertyNo: string) {
    const url = `${this.divOrderUrl}/getDecks?operatorId=${operatorId}&propertyNo=${propertyNo}`;

    console.log('GetDecksByPropertyNo Url: ', url);

    return this.http.get(url);
  }

  postDivisionOrderFile(fd: FormData) {
    const url = `${this.divOrderUrl}/upload`;

    console.log('PostDivisionOrderFile Url: ', url);

    return this.http.post(url, fd, { responseType: 'text' as 'text' });
  }

  // POST
  public getReport(divisionOrders: DivisionOrder[], reportType: number) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.divOrderUrl}/divisionOrdersReport/${reportType}`;
    return this.http.post(url, divisionOrders, httpOptions);
  }

}
