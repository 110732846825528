export class ProductionSearch {
  prodType: number; // 0 = all, 1 = InProgress, 2 = Complete
  startDateMonth: number;
  startDateYear: number;
  startStr: string;
  endDateMonth: number;
  endDateYear: number;
  endStr: string;
  searchStr: string[];
  state: string;
}
