import { Component, OnInit, Input, OnDestroy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { DivisionOrder } from 'src/app/model/division_order';
import { environment } from 'src/environments/environment';

declare var $: any; // jquery
declare let datepicker;
declare let modals;

@Component({
  selector: 'app-divisionorder',
  templateUrl: './divisionorder.component.html',
  styleUrls: ['./divisionorder.component.css']
})

export class DivisionorderComponent implements OnInit, OnDestroy {

  readonly blockchainExplorerUrl = `${environment.blockchainExplorerUrl}`;

  @Input() divOrder: DivisionOrder;
  @Input() displayDODialog: boolean;
  @Output() displayDODialogChange = new EventEmitter<boolean>();

  closeDialog() {
    this.displayDODialog = false;
    this.displayDODialogChange.emit(false);
  }

  constructor(private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {

    $(document).ready(() => {
      datepicker();
      modals();
    });

    console.log('NgInit - DO: ', this.divOrder);
  }

  ngOnDestroy(): void {
    console.log('The component is being destroyed');
    this.divOrder = null;
  }

  public sendForApproval() {
    console.log('Send For Approval DO: ', this.divOrder);
    this.closeDialog();
  }

  public onClickMRO() {
    alert('MRO clicked');
    console.log('Current  DO: ', this.divOrder);
  }

  public onClose() {
    // alert('Close - dialog - from inside RoyaltyInterestComponent');
    this.closeDialog();
    console.log('Close : this._displayRoyaltyInterestDialog: ' + this.displayDODialog);
  }

}
