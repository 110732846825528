<div>
  <div class="form_label">
    <label class="upload_label" for="fileToUpload">
      <span class="icon_upload">
        <i class="fas fa-cloud-upload-alt"> </i>
        <img src="./../../assets/images/upload.jpg" height="100px" width="100px" />
      </span>
      <br />
      <h1>Drag and drop here to import</h1>
      <br />
      Or browse for your file, if you prefer...
      <br />
      <br />
      <span class="btn_normal" type="button">Browse...</span>
    </label>
  </div>
  <div class="form_input hidden">
    <input type="file" name="fileToUpload" id="fileToUpload" accept=".csv" class="inputbox" style="text-align: center;" />

    <button
      class="btn_normal"
      type="submit"
      id="btn_upload"
      value="Upload File"
      name="submit"
      disabled=""
      style="width: 80px; text-align: center; position: absolute; opacity: 0;"
    >
      Upload
    </button>
  </div>
  <div id="form_file_selected" style="text-align: center; line-height: 40px;">No Files Selected</div>
  <div class="form_submit" style="text-align: center; position: absolute; right: 15px; bottom: 15px;">
    <span class="btn_normal btn_disabled" id="btn_fake_submit" style="width: 80px; text-align: center;">Upload</span>
  </div>
</div>
