<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<!-- The following is just to demo the Kendo Modal Dialog working
<div class="example-config" (click)="openDialogWindow()">
  <h4>Status: {{ status }}</h4>
</div>

<kendo-dialog *ngIf="openDialog" title="Action required" (close)="onClose()">
  <p style="margin: 30px; text-align: center;">Do you accept?</p>

  <kendo-dialog-actions>
    <button kendoButton (click)="onAccept()" style="width: 50%; padding:10px">Yes</button>
    <button kendoButton (click)="onDecline()" style="width: 50%; padding:10px;">No</button>
  </kendo-dialog-actions>
</kendo-dialog> -->
