import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DashboardService = /** @class */ (function () {
    function DashboardService(http) {
        this.http = http;
        this.performanceUrl = environment.apiUrl + "/performance";
    }
    // GET
    DashboardService.prototype.getPerformanceByDate = function () {
        var url = this.performanceUrl + "/getPerformanceByDate";
        // console.log('GetPerformanceByDate Url: ', url);
        return this.http.get(url).toPromise();
    };
    DashboardService.prototype.getPerformanceByWell = function () {
        var url = this.performanceUrl + "/getPerformanceByWell";
        // console.log('GetPerformanceByWell Url: ', url);
        return this.http.get(url).toPromise();
    };
    /** @nocollapse */ DashboardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.ɵɵinject(i1.HttpClient)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };
