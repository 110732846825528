<div id="notifications">
  <div class="page_container">
    <div id="notifications_inner">
      <div class="notification">
        <div class="notification_icon">
          <div class="notification_icon_main">
            <span class="icon_payment"></span>
          </div>
          <div class="notification_icon_shadow">
            <span class="icon_payment_shadow"></span>
          </div>
        </div>
        <div class="notification_details">
          <div class="notification_text">
            <div class="notification_title">
              Payments up to date for all Properties
            </div>
            <div class="notification_timestamp">
              Last Payment on {{ lastUpdated | date:'d MMM yyyy' }} 4:30pm
            </div>
          </div>
          <div class="notification_action">
            <a routerLink="/payments">Go to Payments</a>
          </div>
        </div>
        <div class="notification_close">
          <button
            type="button"
            class="btn_normal btn_icon_only btn_close_notification"
            title="Close Notification"
          >
            <span class="btn_icon"><span class="icon_close"></span></span>
            <span class="btn_text">Close Notification</span>
          </button>
        </div>
      </div>

      <div class="notification error">
        <div class="notification_icon">
          <div class="notification_icon_main">
            <span class="icon_payment"></span>
          </div>
          <div class="notification_icon_shadow">
            <span class="icon_payment_shadow"></span>
          </div>
        </div>
        <div class="notification_details">
          <div class="notification_text">
            <div class="notification_title">2 Payment Errors</div>
            <div class="notification_timestamp">
              Last Updated on {{ lastUpdated | date:'d MMM yyyy' }} 4:30pm
            </div>
          </div>
          <div class="notification_action">
            <a routerLink="/dashboard">Show Errors</a>
          </div>
        </div>
        <div class="notification_close">
          <button
            type="button"
            class="btn_normal btn_icon_only btn_close_notification"
            title="Close Notification"
          >
            <span class="btn_icon"><span class="icon_close"></span></span>
            <span class="btn_text">Close Notification</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
