import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { SalesService } from 'src/app/sales.service';
import { MessagesService } from 'src/app/messages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
import { User } from 'src/app/model/user';

declare var $: any; // jquery
declare let datepicker;
declare let modals;
declare let removeHash;

@Component({
  selector: 'app-sales-upload',
  templateUrl: './sales-upload.component.html',
  styleUrls: ['./sales-upload.component.css']
})
export class SalesUploadComponent implements OnInit, OnDestroy {

  @Input() displayUploadSales: boolean;
  @Output() displayUploadSalesChange = new EventEmitter<boolean>();

  selectedFile: File = null;
  public fileSelected = false;
  public message = this.messagesService.noFileSelected;
  private ngUnsubscribe = new Subject();

  constructor(
    private salesService: SalesService,
    private authService: AuthService,
    private messagesService: MessagesService) { }

  ngOnInit() { }

  onFileSelectedSales(event) {
    this.selectedFile = event.target.files[0] as File;
    this.message = this.selectedFile.name;
    this.fileSelected = true;
    console.log('Sales File selected: ' + this.message);
  }

  onUpload() {
    const user = this.authService.getUser();

    const fileName = this.selectedFile.name;
    const fd = new FormData();
    fd.append('file', this.selectedFile, fileName);
    fd.append('userId', user.id.toString());
    fd.append('operatorId', user.operator.id.toString());

    console.log('UserId: ' + user.id);
    console.log('OperatorId: ' + user.operator.id);

    const resultObservable = this.salesService.postSalesFile(fd);

    const resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      console.log('Response: ' + res);
      this.message = fileName + ' - ' + res;
    });

    this.selectedFile = null;
    // this.message = fileName + ' - ' + this.messagesService.fileUploaded + resultSubscription;

    this.closeSalesDialog();
  }

  ngOnDestroy() {
    console.log('The component is being destroyed');
    this.ngUnsubscribe.next();
  }

  closeSalesDialog() {
    this.displayUploadSales = false;
    this.displayUploadSalesChange.emit(false);
  }

}
