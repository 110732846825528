<!-- Adding Property Confirmation -->
<div id="message_add_property" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><span class="icon_blockchain"></span></div>
      <div class="message_icon_shadow"><span class="icon_blockchain_shadow"></span></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Property Created</h3>
        <div class="message_description">
          <p>Property has been successfully created.</p>
        </div>
      </div>
      <div class="message_action">
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">Edit Property</span>
        </button>
      </div>
    </div>
    <div class="message_close">
      <button type="button" class="btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</div>

<!-- Uploading Property Confirmation -->
<div id="message_upload_property" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><span class="icon_blockchain"></span></div>
      <div class="message_icon_shadow"><span class="icon_blockchain_shadow"></span></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Property Uploaded</h3>
        <div class="message_description">
          <p>You have successfully uploaded the file</p>
        </div>
      </div>
      <div class="message_action"></div>
    </div>
    <div class="message_close">
      <button type="button" class="btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</div>

<!-- Processing Property -->
<div id="message_processing_property" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><img src="assets/images/loader_dark.gif" alt="Loader" style="height: 30px;" /></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Uploading Property Details</h3>
      </div>
    </div>
  </div>
</div>

<!-- Adding Production Confirmation -->
<div id="message_add_production" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><span class="icon_blockchain"></span></div>
      <div class="message_icon_shadow"><span class="icon_blockchain_shadow"></span></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Production Created</h3>
        <div class="message_description">
          <p>Production records saved to blockchain</p>
        </div>
      </div>
      <div class="message_action">
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">View Production</span>
        </button>
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">View in Blockchain</span>
        </button>
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">Edit Production</span>
        </button>
      </div>
    </div>
    <div class="message_close">
      <button type="button" class="btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</div>

<!-- Uploading Production Confirmation -->
<div id="message_upload_production" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><span class="icon_blockchain"></span></div>
      <div class="message_icon_shadow"><span class="icon_blockchain_shadow"></span></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Production Uploaded</h3>
        <div class="message_description">
          <p>Production records saved to blockchain</p>
        </div>
      </div>
      <div class="message_action">
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">View Production</span>
        </button>
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">View in Blockchain</span>
        </button>
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">Edit Production</span>
        </button>
      </div>
    </div>
    <div class="message_close">
      <button type="button" class="btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</div>

<!-- Processing Production -->
<div id="message_processing_production" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><img src="assets/images/loader_dark.gif" alt="Loader" style="height: 30px;" /></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Uploading Production Details</h3>
      </div>
    </div>
  </div>
</div>

<!-- Adding Sales & Expense Confirmation -->
<div id="message_add_sales" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><span class="icon_blockchain"></span></div>
      <div class="message_icon_shadow"><span class="icon_blockchain_shadow"></span></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Sales &amp; Expense Created</h3>
        <div class="message_description">
          <p>Sales record saved to blockchain and payment applied</p>
        </div>
      </div>
      <div class="message_action">
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">View Sales</span>
        </button>

        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">View in Blockchain</span>
        </button>

        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">Edit Sales</span>
        </button>

        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">Go to Payments</span>
        </button>
      </div>
    </div>
    <div class="message_close">
      <button type="button" class="btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</div>

<!-- Uploading Sales & Expense Confirmation -->
<div id="message_upload_sales" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><span class="icon_blockchain"></span></div>
      <div class="message_icon_shadow"><span class="icon_blockchain_shadow"></span></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Sales Uploaded</h3>
        <div class="message_description">
          <p>Sales records saved to blockchain and payment applied to Owners</p>
        </div>
      </div>
      <div class="message_action">
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">View Sales</span>
        </button>

        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">View in Blockchain</span>
        </button>

        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">Edit Sales</span>
        </button>

        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">Go to Payments</span>
        </button>
      </div>
    </div>
    <div class="message_close">
      <button type="button" class="btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</div>

<!-- Processing Sales & Expense -->
<div id="message_processing_sales" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><img src="assets/images/loader_dark.gif" alt="Loader" style="height: 30px;" /></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Uploading Sales &amp; Expense Details</h3>
      </div>
    </div>
  </div>
</div>

<!-- Adding Owner Confirmation -->
<div id="message_add_owner" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><span class="icon_blockchain"></span></div>
      <div class="message_icon_shadow"><span class="icon_blockchain_shadow"></span></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Owner Created</h3>
        <div class="message_description">
          <p>Owner has been successfully created.</p>
        </div>
      </div>
      <div class="message_action">
        <button type="button" class="btn_normal btn_border btn_message">
          <span class="btn_text">Edit Owner</span>
        </button>
      </div>
    </div>
    <div class="message_close">
      <button type="button" class="btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</div>

<!-- Uploading Owner Confirmation -->
<div id="message_upload_owner" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><span class="icon_blockchain"></span></div>
      <div class="message_icon_shadow"><span class="icon_blockchain_shadow"></span></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Owner Uploaded</h3>
        <div class="message_description">
          <p>You have successfully created new Owners</p>
        </div>
      </div>
      <div class="message_action"></div>
    </div>
    <div class="message_close">
      <button type="button" class="btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text">Close</span>
      </button>
    </div>
  </div>
</div>

<!-- Processing Owner -->
<div id="message_processing_owner" class="message">
  <div class="message_container">
    <div class="message_icon">
      <div class="message_icon_main"><img src="assets/images/loader_dark.gif" alt="Loader" style="height: 30px;" /></div>
    </div>
    <div class="message_details">
      <div class="message_text">
        <h3 class="message_title">Uploading Owner Details</h3>
      </div>
    </div>
  </div>
</div>
