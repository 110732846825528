import { Component, OnInit, ViewChild, ElementRef, SystemJsNgModuleLoader } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';

import { environment } from './../../environments/environment';
import { DivisionOrder } from '../model/division_order';
import { DivisionorderService } from './../divisionorder.service';
import { MessagesService } from '../messages.service';
import { User } from '../model/user';
import { AuthService } from '../auth.service';
import { Operator } from '../model/operator';

@Component({
  selector: 'app-divisionorder-list',
  templateUrl: './divisionorder-list.component.html',
  styleUrls: ['./divisionorder-list.component.css']
})

export class DivisionorderListComponent implements OnInit {
  @ViewChild('spreadContainer', { static: false }) spreadContainer: ElementRef;
  private readonly blockchainExplorerUrl = `${environment.blockchainExplorerUrl}`;

  private sheet: GC.Spread.Sheets.Worksheet = null;
  private spread: GC.Spread.Sheets.Workbook;

  private sheetWidth: number;

  tabStripVisible = false;
  spreadBackColor = 'white';
  sheetName = 'Division Orders';
  hostStyle = {
    height: '100%',
    width: '100%'
  };
  data: any;
  autoGenerateColumns = false;

  gridColCount = 12;
  blockChainColumn = 6;

  divOrders: DivisionOrder[];
  loggedInUser: User;

  constructor(private divisionOrderService: DivisionorderService,
    private messagesService: MessagesService,
    private authService: AuthService) { }

  ngOnInit() {
    this.loggedInUser = this.authService.getUser();
    this.getDivOrders(this.loggedInUser.operator);
  }

  getDivOrders(operator: Operator): void {
    // Todo: use .pipe(ngUnsubscribe) for every observable + put a ngUnsubscribe.next()
    // inside of each component ngOnDestroy(). For that you need to create a property for that
    // component call "ngUnsubscribe" with the type BehaviorSubject.
    // Example: private ngUnsubscribe: BehaviorSubject = new BehaviorSubject(null); if (/*is not null*/) { /* do whatever */ }
    this.divisionOrderService.getDivisionOrders(operator.id).subscribe(divOrders => {
      console.log({ divOrders });

      this.divOrders = divOrders as DivisionOrder[];

      let filteredDivOrders: DivisionOrder[] = JSON.parse(JSON.stringify(divOrders));
      console.log('pre-transformation', { filteredDivOrders });
      filteredDivOrders = this.setDivisionOrderValues(filteredDivOrders);
      console.log('post transformation', { filteredDivOrders });

      this.applyDataBinding(filteredDivOrders);
    });
  }

  setDivisionOrderValues(filteredDivOrders: DivisionOrder[]): DivisionOrder[] {
    filteredDivOrders.forEach(divOrder => {
      // console.log('Division Order: ', { divOrder });

      divOrder.ownerId = divOrder.lease.title.owner.id;
      divOrder.ownerName = divOrder.lease.title.owner.fname + ' ' + divOrder.lease.title.owner.lname;
      divOrder.ownerPercent = divOrder.lease.title.ownershipPercent;
      divOrder.decimalInterest = divOrder.lease.royaltyInterest;
    });

    return filteredDivOrders;
  }

  applyDataBinding(filteredDivOrders: DivisionOrder[]) {
    const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredDivOrders);

    this.sheet.suspendPaint();

    // Lock/Readonly all columns
    this.sheet.options.isProtected = true;
    this.sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: false
    };
    // Unlock from column 0 for 6 columns(columns 0 - 5) - [Owner# - Royalty Interest]
    this.sheet.getRange(-1, 0, -1, 6).locked(false);
    // Unlock from columns 8 for 4 columns (columns 8 - 11) - [Working Interest - Action]
    this.sheet.getRange(-1, 8, -1, 4).locked(false);

    // Define columns
    const ownerIdColInfo = { name: 'ownerId', displayName: 'Owner#', size: 60 };
    const ownerNameColInfo = { name: 'ownerName', displayName: 'OwnerName', size: 300 };
    const ownerPercentColInfo = { name: 'ownerPercent', displayName: 'Ownership %', size: 100 };
    const doTermStartColInfo = { name: 'termStart', displayName: 'Term Start', size: 100 };
    const doTermEndColInfo = { name: 'termEnd', displayName: 'Term End', size: 100 };
    const doRoyaltyIntColInfo = { name: 'royaltyInterest', displayName: 'Royalty Interest', formatter: '0.00', size: 120 };
    const doBCImageColInfo = { name: '', displayName: '#', size: 30 };
    const doBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 150 };
    const doWorkingIntColInfo = { name: 'workingInterest', displayName: 'Working Interest', formatter: '0.00', size: 120 };
    const doDeductionColInfo = { name: 'deductions', displayName: 'Deductions', formatter: '0.00', size: 100 };
    const doStatusColInfo = { name: 'status', displayName: 'Status', size: 100 };
    const doActionColInfo = { name: 'action', displayName: 'Action', size: 100 };

    // Generate the Block-chain column definition
    filteredDivOrders.forEach((rowDivorderObject: DivisionOrder, rowIndex: number) => {
      // console.log('Row Index: ' + rowIndex, rowDivorderObject);

      // Remove vertical grid-lines
      this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));

      const column = 6;
      const cell = this.sheet.getCell(rowIndex, column);
      let imgBlockChain = null;
      if (filteredDivOrders[rowIndex].bcTransId != null) {
        imgBlockChain = './assets/images/blockchain_cell@2x.png';
        // console.log('Blockchain Trans Id not NULL RowIndex: ' + rowIndex + '   TransId: ' + filteredDivOrders[rowIndex].bcTransId);
      }
      cell.text('');
      cell.backgroundImage(imgBlockChain);
    });

    // Bind click-event of Blockchain cell
    this.spread.bind(GC.Spread.Sheets.Events.CellClick, (e, args) => {
      const sheet = args.sheet;
      const row = args.row;
      const col = args.col;
      const cell = this.sheet.getCell(row, col);
      if (col === 6 || col === 7) {
        if (filteredDivOrders[row].bcTransId != null) {
          console.log('Cell: (' + row + ', ' + col
            + ') DivisionOrderId: ' + filteredDivOrders[row].id + '     BC TransId: ' + filteredDivOrders[row].bcTransId);

          // const url = this.blockchainExplorerUrl + '/id=DivisionOrder_' + filteredDivOrders[row].id;
          const url = this.blockchainExplorerUrl;
          window.open(url);
        }
      }
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.EnterCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColCount; col++) {
        const cell = this.sheet.getCell(row, col);
        if (col !== this.blockChainColumn) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridHighlight);
          }
        }
      }
      // console.log('Row Enter Cell: ' + row);
      this.sheet.resumePaint();
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Un-highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColCount; col++) {
        const cell = this.sheet.getCell(row, col);

        if (col !== this.blockChainColumn) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridBackground);
          }
        }
      }

      this.sheet.getCell(row, -1)
        .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      this.sheet.getCell(row, -1)
        .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      // console.log('Row Leave Cell: ' + row);

      this.sheet.resumePaint();
    });

    this.sheet.autoGenerateColumns = true;
    this.sheet.setDataSource(filteredDivOrders);

    this.sheet.bindColumns([
      ownerIdColInfo,
      ownerNameColInfo,
      ownerPercentColInfo,
      doTermStartColInfo,
      doTermEndColInfo,
      doRoyaltyIntColInfo,
      doBCImageColInfo,
      doBCTransIdColInfo,
      doWorkingIntColInfo,
      doDeductionColInfo,
      doStatusColInfo,
      doActionColInfo
    ]);

    this.sheet.setColumnCount(this.sheet.getColumnCount());

    // Highlight first row except blockchain graphic cell
    for (let col = 0; col < this.gridColCount; col++) {
      if (col !== this.blockChainColumn) {
        this.sheet.getCell(0, col).backColor(environment.gridHighlight);
      }
    }

    this.sheet.resumePaint();
  }

  public workbookInit(args) {
    console.log(`Workbook Init`, { args });

    this.spread = args.spread;
    this.sheet = this.spread.getActiveSheet();

    this.sheetWidth = this.sheet.getViewportWidth(1);
    console.log('SheetWidth: ', this.sheetWidth);
  }
}
