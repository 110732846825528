import { OnInit, OnDestroy, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { DivisionOrder } from 'src/app/model/division_order';
import { environment } from 'src/environments/environment';
var DivisionorderComponent = /** @class */ (function () {
    function DivisionorderComponent(changeDetector) {
        this.changeDetector = changeDetector;
        this.blockchainExplorerUrl = "" + environment.blockchainExplorerUrl;
        this.displayDODialogChange = new EventEmitter();
    }
    DivisionorderComponent.prototype.closeDialog = function () {
        this.displayDODialog = false;
        this.displayDODialogChange.emit(false);
    };
    DivisionorderComponent.prototype.ngOnInit = function () {
        $(document).ready(function () {
            datepicker();
            modals();
        });
        console.log('NgInit - DO: ', this.divOrder);
    };
    DivisionorderComponent.prototype.ngOnDestroy = function () {
        console.log('The component is being destroyed');
        this.divOrder = null;
    };
    DivisionorderComponent.prototype.sendForApproval = function () {
        console.log('Send For Approval DO: ', this.divOrder);
        this.closeDialog();
    };
    DivisionorderComponent.prototype.onClickMRO = function () {
        alert('MRO clicked');
        console.log('Current  DO: ', this.divOrder);
    };
    DivisionorderComponent.prototype.onClose = function () {
        // alert('Close - dialog - from inside RoyaltyInterestComponent');
        this.closeDialog();
        console.log('Close : this._displayRoyaltyInterestDialog: ' + this.displayDODialog);
    };
    return DivisionorderComponent;
}());
export { DivisionorderComponent };
