<div class="page_container">
  <div id="content_inner">
    <!-- Content Header -->
    <div class="content_header">
      <div class="content_history">
        <!-- <button type="button" class="btn_normal btn_big btn_icon_only" id="btn_history_back" onclick="window.location='dashboard.html'">
					<span class="btn_icon"><span class="icon_back"></span></span>
					<span class="btn_text">Back</span>
				</button> -->
      </div>
      <div class="content_title">
        <h1>Properties</h1>
      </div>
      <div class="content_timestamp">
        <div class="timestamp">Last updated: {{ lastUpdated | date:'d MMM yyyy' }} 9:30am</div>
      </div>
      <div class="content_actions">
        <button
          type="button"
          class="btn_normal btn_blue"
          id="btn_add_property"
          (click)="addProperty()"
        >
          <span class="btn_icon"><span class="icon_add"></span></span>
          &nbsp;<span class="btn_text">Add Property</span>
        </button>
      </div>
    </div>

    <!-- Action Bar -->
    <div class="content_action_bar">
      <div class="action_bar_left">
        <ul>
          <li>
            <fieldset class="form_row no_padding clearfix">
              <legend class="hidden_legend">Filter Rows</legend>
              <div class="toggle_group">
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="all"
                      id="property_all"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnAllProperties()"
                    />
                  </div>
                  <label
                    for="property_all"
                    [ngClass]="{
                      toggle_active: propertySearch.propertyType === 0
                    }"
                    >All</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="in_progress"
                      id="property_in_progress"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnInProgressProperties()"
                    />
                  </div>
                  <label
                    for="property_in_progress"
                    [ngClass]="{
                      toggle_active: propertySearch.propertyType === 1
                    }"
                    >In Progress</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="active"
                      id="property_active"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnActiveProperties()"
                    />
                  </div>
                  <label
                    for="property_active"
                    [ngClass]="{
                      toggle_active: propertySearch.propertyType === 2
                    }"
                    >Active</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="inactive"
                      id="property_inactive"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnInactiveProperties()"
                    />
                  </div>
                  <label
                    for="property_inactive"
                    [ngClass]="{
                      toggle_active: propertySearch.propertyType === 3
                    }"
                    >Inactive</label
                  >
                </div>
              </div>
            </fieldset>
          </li>
        </ul>
      </div>
      <form [formGroup]="propertyForm">
        <div class="action_bar_right">
          <ul>
            <li>
              <div class="form_row no_padding">
                <div class="form_label hidden_label">
                  <label for="filter_search_table">Search Table</label>
                </div>
                <div class="form_input">
                  <input
                    #filter_search_table
                    class="inputbox searchbox"
                    id="filter_search_table"
                    name="filter_search_table"
                    placeholder="Search Property No. or Name…"
                    (keyup)="doSearch()"
                    formControlName="searchStr"
                  />
                </div>
              </div>
            </li>
            <li>
              <div class="form_row no_padding">
                <div class="form_label hidden_label">
                  <label for="filter_property_states">Property State</label>
                </div>
                <div class="form_input">
                  <select
                    name="state"
                    id="state"
                    class="inputbox"
                    formControlName="state"
                    (change)="onStateChange()"
                  >
                    <option value="" [selected]="">All States</option>
                    <option *ngFor="let state of states" value="{{ state.id }}">
                      {{ state.name }}
                      <p *ngIf="state.abbr">({{ state.abbr }})</p>
                    </option>
                  </select>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>

    <!-- Body Content -->
    <div class="content_body">
      <div class="scroll_container">
        <div id="spread-container" class="spread-container" #spreadContainer>
          <!-- Spreadsheet LOADED -->
          <gc-spread-sheets
            #spreadDiv
            id="property-table-data"
            class="spreadsheet"
            [tabStripVisible]="tabStripVisible"
            [backColor]="spreadBackColor"
            [hostStyle]="hostStyle"
            hostClass="spreadContainer"
            (workbookInitialized)="workbookInit($event)"
          >
          </gc-spread-sheets>
        </div>
      </div>
    </div>

    <!-- Body Footer -->
    <div class="content_footer">
      <div class="content_footer_inner">
        <div class="content_footer_left">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_white"
                id="btn_back"
                (click)="onBack()"
              >
                <span class="btn_icon"><span class="icon_back"></span></span>
                &nbsp;<span class="btn_text">Back</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="content_footer_right">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_pdf_properties"
                (click)="exportReport(1)"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">PDF</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_esv_properties"
                (click)="exportReport(3)"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">CSV</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_excel_properties"
                (click)="exportReport(2)"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">Excel</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
