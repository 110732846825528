import { AuthService } from './../auth.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../model/user';
import { Router } from '@angular/router';
// This just declares "$" so it does not give an error on the page. But once jQuery is defined at index.html then
// jquery is accessable at runtime from here
declare var $: any; // jquery
// Note: Becuase the follwoing script(./assets/js/common.js) is imported at index.html, it is available at run-time
// to the jQuery below "dropdownMenu()" - but it is not available here at compile time. Therefore, to remove the
// error we need to declere dropdownMenu as a variable
declare let dropdownMenu;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  displayName = '';
  corporateLogo = '';
  currUser: User;

  public isUserDropdownOpen = false;
  public isNotificationDropdownOpen = false;
  // Display/Hide change Password
  @Input() displayChangePassword: boolean;
  @Output() displayChangePasswordChange = new EventEmitter<boolean>();

  // tslint:disable variable-name
  constructor(
    private _authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    $(document).ready(() => {
      dropdownMenu();
    });

    // Get current user from AuthService
    this.currUser = this._authService.getUser();

    if (this.currUser !== null) {
      // Return the displayName
      this.displayName = (this.currUser.fname + ' ' + this.currUser.lname).trim();

      this.corporateLogo = this.currUser.operator.logoPath;
    }
  }

  logout() {
    this.displayName = '';
    this.isUserDropdownOpen = false;
    this.isNotificationDropdownOpen = false;
    console.log('header - Logout');
    this._authService.logoutUser();
  }

  public onLogoutDropdownClicked() {
    this.isUserDropdownOpen = !this.isUserDropdownOpen;
    console.log(`isUserDropdownOpen set to ${this.isUserDropdownOpen}`);
  }

  public onNotificationDropdownClicked() {
    this.isNotificationDropdownOpen = !this.isNotificationDropdownOpen;
    console.log(`isNotificationDropdownOpen set to ${this.isNotificationDropdownOpen}`);
  }

  public loggedIn(location: string) {
    return this._authService.loggedIn();
  }

  public getDisplayName() {
    if (this.displayName === '') {
      // Get current user from AuthService
      const currUser = this._authService.getUser();

      // Return the displayName
      this.displayName = (currUser.fname + ' ' + currUser.lname).trim();
      console.log('this.displayName: ' + this.displayName);
    }
    return this.displayName;
  }

  public getCorporateLogo() {
    console.log('this.corporateLogo: ' + this.corporateLogo);
    if (this.corporateLogo === '') {
      // Get current user from AuthService
      const currUser = this._authService.getUser();

      // Return the displayName
      this.corporateLogo = currUser.operator.logoPath;
      console.log('this.corporateLogo: ' + this.corporateLogo);
    }
    return this.corporateLogo;
  }


  openChangePasswordDialog() {
    this.displayChangePassword = true;
    this.displayChangePasswordChange.emit(true);
  }

  public changePassword() {
    this.openChangePasswordDialog();
  }

}
