import * as tslib_1 from "tslib";
import { OnInit, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as GC from '@grapecity/spread-sheets';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { Sale } from 'src/app/model/sale';
import { ProductionSearch } from 'src/app/model/production_search';
import { SalesService } from 'src/app/sales.service';
import { MessagesService } from 'src/app/messages.service';
import { LookupService } from 'src/app/lookup.service';
import { AuthService } from 'src/app/auth.service';
import { map } from 'rxjs/operators';
var SalesContentComponent = /** @class */ (function () {
    function SalesContentComponent(fb, salesService, lookupService, messagesService, datePipe, location, router, authService) {
        this.fb = fb;
        this.salesService = salesService;
        this.lookupService = lookupService;
        this.messagesService = messagesService;
        this.datePipe = datePipe;
        this.location = location;
        this.router = router;
        this.authService = authService;
        this.displayAddSalesChange = new EventEmitter();
        this.displayUploadSalesChange = new EventEmitter();
        this.displayProcessPaymentsChange = new EventEmitter();
        this.salesSearchChange = new EventEmitter();
        this.salesSearchForm = this.fb.group({
            searchStr: [''],
            state: ['']
        });
        this.blockchainExplorerUrl = "" + environment.blockchainExplorerUrl;
        this.sheet = null;
        this.tabStripVisible = false;
        this.spreadBackColor = 'white';
        this.sheetName = 'Sales List';
        this.hostStyle = {
            height: '100%',
            width: '100%'
        };
        this.autoGenerateColumns = false;
        this.propertyCol = 0;
        this.wellCountCol = 1;
        this.blockChainImageCol = 2;
        this.blockChainTransIdCol = 3;
        this.productCol = 4;
        this.prodDateCol = 5;
        this.prodVolCol = 6;
        this.salesVolCol = 7;
        this.grsValueCol = 8;
        this.totalDedCol = 9;
        this.deductionTypesCol = 10;
        this.gridColCount = 11;
        this.maxStartDate = false;
        this.maxEndDate = true;
        this.displayDeductionDetails = false;
        this.expandIndex1 = -1;
        this.expandIndex2 = -1;
        this.lastUpdated = new Date();
    }
    SalesContentComponent.prototype.closeAddSales = function () {
        this.displayAddSales = false;
        this.displayAddSalesChange.emit(false);
    };
    SalesContentComponent.prototype.openAddSales = function () {
        this.displayAddSales = true;
        this.displayAddSalesChange.emit(true);
    };
    SalesContentComponent.prototype.closeUploadSales = function () {
        this.displayUploadSales = false;
        this.displayUploadSalesChange.emit(false);
    };
    SalesContentComponent.prototype.openUploadSales = function () {
        console.log('Sales-Content: Upload Sales');
        this.displayUploadSales = true;
        this.displayUploadSalesChange.emit(true);
    };
    SalesContentComponent.prototype.closeProcessPayments = function () {
        console.log('Sales-Content: Close Process Payments');
        this.displayProcessPayments = false;
        this.displayProcessPaymentsChange.emit(false);
    };
    SalesContentComponent.prototype.openProcessPayments = function () {
        console.log('Sales-Content: Open Process Payments');
        this.displayProcessPayments = true;
        this.displayProcessPaymentsChange.emit(true);
    };
    SalesContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        // initialise Search criteria
        var today = new Date();
        this.salesSearch = new ProductionSearch();
        this.salesSearch.prodType = 0;
        this.salesSearch.startDateMonth = 1;
        this.salesSearch.startDateYear = today.getFullYear();
        this.salesSearch.startStr = this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear);
        this.salesSearch.endDateMonth = today.getMonth() + 1;
        this.salesSearch.endDateYear = today.getFullYear();
        this.salesSearch.endStr = this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear);
        this.salesSearch.searchStr = [];
        this.salesSearch.state = '';
        this.salesSearchChange.emit(this.salesSearch);
        var operator = this.authService.getUser().operator;
        Promise.all([
            this.getUSStates(),
            this.getSalesByYear(operator, today.getFullYear())
        ]).then(function (values) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getSales(operator)];
                    case 1:
                        _a.sent();
                        // put a small delay on getting sales deductions so that all sales are loaded first
                        // setTimeout(() => this.getSalesDeductions(), 1000);
                        this.getSalesDeductions();
                        return [2 /*return*/];
                }
            });
        }); });
        this.lastUpdated.setDate(today.getDate() - 2);
    };
    SalesContentComponent.prototype.ngOnDestroy = function () {
        this.sheet = null;
        if (this.spread) {
            this.spread.destroy();
            this.spread = undefined;
        }
    };
    SalesContentComponent.prototype.getUSStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getStates())];
                    case 1:
                        _a.states = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesContentComponent.prototype.getSalesByYear = function (operator, year) {
        var _this = this;
        console.log('getSalesByYear - start: ' + new Date().toISOString());
        return this.salesService.getSalesByYear(operator.id, year).pipe(map(function (sales) {
            console.log('getSalesByYear - Back from API: ' + new Date().toISOString());
            // console.log({ sales });
            var tempSales = JSON.parse(JSON.stringify(sales));
            tempSales = _this.setSalesValues(tempSales);
            _this.allSales = tempSales;
            _this.sales = tempSales;
            _this.initialiseGrid();
            _this.filter();
            console.log('getSalesByYear - complete: ' + new Date().toISOString() + '   No. Records: ' + _this.allSales.length);
            return sales;
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    _this.router.navigate(['/login']);
                }
            }
        })).toPromise();
    };
    SalesContentComponent.prototype.getSales = function (operator) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                console.log('Start getSales: ' + new Date().toISOString());
                return [2 /*return*/, this.salesService.getSales(operator.id).pipe(map(function (sales) {
                        console.log('getSales - Back from API: ' + new Date().toISOString());
                        var tempSales = JSON.parse(JSON.stringify(sales));
                        tempSales = _this.setSalesValues(tempSales);
                        _this.allSales = tempSales;
                        console.log('getSales - complete: ' + new Date().toISOString() + '   No. Records: ' + _this.allSales.length);
                        return sales;
                    }, function (err) {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                _this.router.navigate(['/login']);
                            }
                        }
                    })).toPromise()];
            });
        });
    };
    SalesContentComponent.prototype.getSalesDeductions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, operatorId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                user = JSON.parse(localStorage.getItem('user'));
                operatorId = user.operator.id;
                console.log('getSalesDeductions - start: ' + new Date().toISOString());
                return [2 /*return*/, this.salesService.getSalesDeductions(operatorId).pipe(map(function (deductions) {
                        var e_1, _a;
                        console.log('getSalesDeductions - back from API: ' + new Date().toISOString());
                        console.log({ deductions: deductions });
                        var tempDeductions = JSON.parse(JSON.stringify(deductions));
                        try {
                            for (var tempDeductions_1 = tslib_1.__values(tempDeductions), tempDeductions_1_1 = tempDeductions_1.next(); !tempDeductions_1_1.done; tempDeductions_1_1 = tempDeductions_1.next()) {
                                var deduction = tempDeductions_1_1.value;
                                _this.addSaleDeduction(deduction);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (tempDeductions_1_1 && !tempDeductions_1_1.done && (_a = tempDeductions_1.return)) _a.call(tempDeductions_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        _this.sales = _this.allSales;
                        _this.filter();
                        // console.log(this.allSales);
                        console.log('getSalesDeductions - complete: ' + new Date().toISOString());
                    }, function (err) {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                _this.router.navigate(['/login']);
                            }
                        }
                    })).toPromise()];
            });
        });
    };
    SalesContentComponent.prototype.getSalesDeductionsIndividual = function (salesId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                // Get the operatorId from the user - only want this operators payments
                return [2 /*return*/, this.salesService.getSalesDeductionsIndividual(salesId).pipe(map(function (deductions) {
                        console.log({ deductions: deductions });
                        var tempDeductions = JSON.parse(JSON.stringify(deductions));
                        return tempDeductions;
                    }, function (err) {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                _this.router.navigate(['/login']);
                            }
                        }
                    })).toPromise()];
            });
        });
    };
    SalesContentComponent.prototype.addSaleDeduction = function (deduction) {
        var e_2, _a;
        try {
            for (var _b = tslib_1.__values(this.allSales), _c = _b.next(); !_c.done; _c = _b.next()) {
                var sale = _c.value;
                if (sale.id === deduction.salesId) {
                    if (sale.deductions == null) {
                        sale.deductions = [];
                    }
                    sale.deductions.push(deduction);
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    SalesContentComponent.prototype.transformDate = function (date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    };
    SalesContentComponent.prototype.setSalesValues = function (filteredSales) {
        var _this = this;
        filteredSales.forEach(function (salesRec) {
            // console.log('Sales: ', { salesRec });
            salesRec.prodDate = _this.messagesService.monthString(salesRec.month) + ' - ' + salesRec.year;
            salesRec.propertyNo = salesRec.property.propertyNo;
            salesRec.productName = salesRec.product.name;
        });
        return filteredSales;
    };
    SalesContentComponent.prototype.createGridEvents = function () {
        var _this = this;
        // Highlight the row
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.EnterCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColCount; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainImageCol) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridHighlight);
                    }
                }
            }
            _this.sheet.resumePaint();
        });
        // Un-highlight row
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Un-highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColCount; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainImageCol) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridBackground);
                    }
                }
            }
            _this.sheet.getCell(row, -1)
                .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            _this.sheet.getCell(row, -1)
                .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            _this.sheet.resumePaint();
        });
        // Bind click-event of Blockchain cell and to the deductions
        this.spread.bind(GC.Spread.Sheets.Events.CellClick, function (e, args) {
            var row = args.row;
            var col = args.col;
            if (args.sheetArea !== GC.Spread.Sheets.SheetArea.colHeader) {
                // Igonre grid header clicks
                if (col === _this.blockChainImageCol || col === _this.blockChainTransIdCol) {
                    if (_this.sales[row].bcTransId != null) {
                        console.log('Cell: (' + row + ', ' + col
                            + ') SalesId: ' + _this.sales[row].id + '     BC TransId: ' + _this.sales[row].bcTransId
                            + '     BC BlockId: ' + _this.sales[row].bcBlockId);
                        var url = '';
                        if (_this.sales[row].bcBlockId != null) {
                            url = _this.blockchainExplorerUrl + _this.sales[row].bcBlockId;
                        }
                        else {
                            url = _this.blockchainExplorerUrl;
                        }
                        window.open(url);
                    }
                }
                else if (col === _this.totalDedCol) {
                    console.log('Row: ' + row + '; Col: ' + col);
                    _this.onClickDeduction(row);
                }
            }
        });
    };
    SalesContentComponent.prototype.initialiseGrid = function () {
        // Define columns and column bindings as well as grid events
        // console.log('Initialise Grid');
        this.sheet.suspendPaint();
        // Define columns
        var salesPropertyNoColInfo = { name: 'propertyNo', displayName: 'Property #', size: 120 };
        var salesWellCountColInfo = { name: 'wellCount', displayName: 'Wells', size: 50 };
        var salesBCTransImgColInfo = { name: '', displayName: '', size: 30 };
        var salesBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 200 };
        var salesProductColInfo = { name: 'productName', displayName: 'Product #', size: 100 };
        var salesDateColInfo = { name: 'prodDate', displayName: 'Production Date', size: 100 };
        var salesProdVolumeColInfo = { name: 'prodVolume', displayName: 'Production Vol.', size: 120 };
        var salesVolumeColInfo = { name: 'salesVolume', displayName: 'Sales Vol.', size: 120 };
        var salesGrossSalesColInfo = { name: 'grsValue', displayName: 'Grs Value', size: 120 };
        var salesTotalDeductionColInfo = { name: 'totalDeductions', displayName: 'T. Deductions', size: 120 };
        var salesDeductionTypesColInfo = { name: 'deductionType', displayName: 'Deduction Types', size: 200 };
        this.sheet.autoGenerateColumns = false;
        this.sheet.setDataSource(this.sales);
        this.sheet.bindColumn(this.propertyCol, salesPropertyNoColInfo);
        this.sheet.bindColumn(this.wellCountCol, salesWellCountColInfo);
        this.sheet.bindColumn(this.blockChainImageCol, salesBCTransImgColInfo);
        this.sheet.bindColumn(this.blockChainTransIdCol, salesBCTransIdColInfo);
        this.sheet.bindColumn(this.productCol, salesProductColInfo);
        this.sheet.bindColumn(this.prodDateCol, salesDateColInfo);
        this.sheet.bindColumn(this.prodVolCol, salesProdVolumeColInfo);
        this.sheet.bindColumn(this.salesVolCol, salesVolumeColInfo);
        this.sheet.bindColumn(this.grsValueCol, salesGrossSalesColInfo);
        this.sheet.bindColumn(this.totalDedCol, salesTotalDeductionColInfo);
        this.sheet.bindColumn(this.deductionTypesCol, salesDeductionTypesColInfo);
        this.sheet.setColumnCount(this.gridColCount, GC.Spread.Sheets.SheetArea.viewport);
        // Lock/Readonly all columns
        this.sheet.options.isProtected = true;
        this.sheet.options.protectionOptions = {
            allowSelectUnlockedCells: true,
            allowSelectLockedCells: true
        };
        // Add events to the Grid
        this.createGridEvents();
        // set cell alignment
        this.sheet.getCell(-1, this.wellCountCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.prodDateCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.prodVolCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.salesVolCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.grsValueCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.totalDedCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        // Hide the Deductions Type column
        this.showDeductionType(false);
        // Format the gross amount and the deductions as currency
        this.sheet.getCell(-1, this.grsValueCol).formatter('$ ##,###,###0.00');
        this.sheet.getCell(-1, this.totalDedCol).formatter('$ ##,###,###0.00');
        this.sheet.getCell(-1, this.deductionTypesCol).formatter('$ ##,###,###0.00');
        this.sheet.resumePaint();
    };
    SalesContentComponent.prototype.showDeductionType = function (hide) {
        this.sheet.getRange(-1, this.deductionTypesCol, -1, 1).visible(hide);
    };
    SalesContentComponent.prototype.applyDataBinding = function (filteredSales) {
        // console.log('Start data binding');
        var _this = this;
        if (this.sheet) {
            this.sheet.suspendPaint();
            // Apply filtering
            this.sheet.setDataSource(filteredSales);
            // console.log('Row Binding - start - filteredSales: ' + filteredSales.length);
            filteredSales.forEach(function (rowSalesObject, rowIndex) {
                // console.log('Row Index: ' + rowIndex, rowSalesObject);
                // Remove vertical grid-lines
                _this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
                var cell = _this.sheet.getCell(rowIndex, _this.blockChainImageCol);
                var imgBlockChain = null;
                if (filteredSales[rowIndex].bcTransId != null) {
                    imgBlockChain = './assets/images/blockchain_cell@2x.png';
                }
                // console.log('Image: ' + imgBlockChain);
                cell.text('');
                cell.backgroundImage(imgBlockChain);
            });
            // Put vertical grid-line on last column
            this.sheet.getCell(-1, this.gridColCount - 1)
                .borderRight(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            // console.log('Row Binding - complete');
            // Highlight first row except blockchain graphic cell
            for (var col = 0; col < this.gridColCount; col++) {
                if (col !== this.blockChainImageCol) {
                    this.sheet.getCell(0, col).backColor(environment.gridHighlight);
                }
            }
            var lastRow = this.sheet.getRowCount();
            this.sheet.resumePaint();
            var today = new Date();
            this.maxStartDate =
                this.salesSearch.startDateMonth === today.getMonth() + 1 &&
                    this.salesSearch.startDateYear === today.getFullYear();
            this.maxEndDate =
                this.salesSearch.endDateMonth === today.getMonth() + 1 &&
                    this.salesSearch.endDateYear === today.getFullYear();
            // console.log('Binding Complete - Last row: ' + lastRow);
        }
    };
    SalesContentComponent.prototype.getLastDayOfMonth = function (year, month) {
        // get first day of month
        var date = new Date(year, month, 1);
        // Add 1 month and subtract 1 day
        date = new Date(date.setMonth(date.getMonth() + 1) - 1);
        return date;
    };
    SalesContentComponent.prototype.filter = function () {
        var _this = this;
        // Filters the "allSales" based on selected filters from the screen
        this.sales = [];
        var today = this.transformDate(new Date());
        this.allSales.forEach(function (salesObj, rowIndex) {
            // console.log(salesObj);
            // If salesObj is null, undefined or empty - ignore
            if (salesObj != null && Object.keys(salesObj).length !== 0) {
                var includeProd_1 = true;
                var startDate = new Date(_this.salesSearch.startDateYear, _this.salesSearch.startDateMonth - 1, 1);
                var endDate = _this.getLastDayOfMonth(_this.salesSearch.endDateYear, _this.salesSearch.endDateMonth - 1);
                var saleDate = new Date(salesObj.year, salesObj.month - 1, 1);
                // console.log('Sales date: ' + this.transformDate(saleDate) + '   Start: ' + this.transformDate(startDate)
                //   + '   End Date: ' + this.transformDate(endDate));
                if (_this.transformDate(saleDate) < _this.transformDate(startDate)) {
                    includeProd_1 = false;
                }
                if (includeProd_1 && _this.transformDate(saleDate) > _this.transformDate(endDate)) {
                    includeProd_1 = false;
                }
                if (includeProd_1 && _this.salesSearch.state !== '') {
                    console.log('Sales Search State: ', _this.salesSearch.state + '   Property: ' + JSON.stringify(salesObj.property));
                    includeProd_1 = (salesObj.property.county.USState.id === Number(_this.salesSearch.state));
                }
                if (includeProd_1 && _this.salesSearch.searchStr.length > 0) {
                    // Check property No, lease No, Property Name and Property Desc against any part of the search string
                    includeProd_1 = true; // Assuem property to be included
                    _this.salesSearch.searchStr.forEach(function (searchStr) {
                        includeProd_1 = includeProd_1
                            && ((salesObj.propertyNo.indexOf(searchStr) !== -1)
                                || (salesObj.property.description.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                || (salesObj.property.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                || (salesObj.productName.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                || (salesObj.property.county.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                || (salesObj.property.county.USState.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1));
                    });
                }
                // console.log('Sales Search Type: ', this.salesSearch.prodType);
                if (includeProd_1 && _this.salesSearch.prodType > 0) {
                    if (_this.salesSearch.prodType === 2) {
                        // Complete - EffectiveTo date has passed
                        if (salesObj.property.effectiveTo !== null) {
                            includeProd_1 = (salesObj.property.effectiveTo < today);
                            console.log('Effective To: ' + salesObj.property.effectiveTo);
                        }
                        else {
                            // Property effective to date not set  => Still in Progress (NOT complete)
                            includeProd_1 = false;
                        }
                    }
                    else {
                        // In Progress (= In production) => effectiveTo date not reached yet
                        if (salesObj.property.effectiveTo !== null) {
                            includeProd_1 = (salesObj.property.effectiveTo > today);
                        }
                        else {
                            includeProd_1 = true;
                        }
                    }
                }
                // console.log('IncludeProduction: ' + includeProd);
                if (includeProd_1) {
                    _this.sales.push(salesObj);
                }
            }
        });
        this.applyDataBinding(this.sales);
    };
    SalesContentComponent.prototype.uploadSales = function () {
        this.openUploadSales();
    };
    SalesContentComponent.prototype.processPayments = function () {
        this.openProcessPayments();
    };
    SalesContentComponent.prototype.doSearch = function () {
        var str = this.salesSearchForm.get('searchStr').value;
        console.log('Str: ' + str);
        this.salesSearch.searchStr = str.split(' ');
        console.log('searchStr: ', this.salesSearch.searchStr);
        this.filter();
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.onStateChange = function () {
        console.log('State changed: ' + this.salesSearchForm.get('state').value);
        this.salesSearch.state = this.salesSearchForm.get('state').value;
        this.filter();
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.btnAllSales = function () {
        console.log('All Sales');
        this.salesSearch.prodType = 0;
        this.filter();
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.btnInProgressSales = function () {
        console.log('In Progress');
        this.salesSearch.prodType = 1;
        this.filter();
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.btnCompleteSales = function () {
        console.log('Complete');
        this.salesSearch.prodType = 2;
        this.filter();
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.dateStr = function (month, year) {
        return this.messagesService.monthString(month) + ' ' + year;
    };
    SalesContentComponent.prototype.onStartDateDown = function () {
        console.log('Start Date Down: ' + this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear));
        if (this.salesSearch.startDateMonth === 1) {
            this.salesSearch.startDateMonth = 12;
            this.salesSearch.startDateYear = this.salesSearch.startDateYear - 1;
        }
        else {
            this.salesSearch.startDateMonth = this.salesSearch.startDateMonth - 1;
        }
        this.salesSearch.startStr = this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear);
        console.log('Start Date Down: ' + this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear));
        this.filter();
        console.log('salesSearch: ' + JSON.stringify(this.salesSearch));
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.onStartDateUp = function () {
        console.log('Start Date Up: ' + this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear));
        if (this.salesSearch.startDateMonth === 12) {
            this.salesSearch.startDateMonth = 1;
            this.salesSearch.startDateYear = this.salesSearch.startDateYear + 1;
        }
        else {
            this.salesSearch.startDateMonth = this.salesSearch.startDateMonth + 1;
        }
        this.salesSearch.startStr = this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear);
        console.log('Start Date Up: ' + this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear));
        this.filter();
        console.log('salesSearch: ' + JSON.stringify(this.salesSearch));
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.onEndDateDown = function () {
        console.log('End Date Down: ' + this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear));
        if (this.salesSearch.endDateMonth === 1) {
            this.salesSearch.endDateMonth = 12;
            this.salesSearch.endDateYear = this.salesSearch.endDateYear - 1;
        }
        else {
            this.salesSearch.endDateMonth = this.salesSearch.endDateMonth - 1;
        }
        this.salesSearch.endStr = this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear);
        console.log('End Date Down: ' + this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear));
        this.filter();
        console.log('salesSearch: ' + JSON.stringify(this.salesSearch));
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.onEndDateUp = function () {
        console.log('End Date Up: ' + this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear));
        if (this.salesSearch.endDateMonth === 12) {
            this.salesSearch.endDateMonth = 1;
            this.salesSearch.endDateYear = this.salesSearch.endDateYear + 1;
        }
        else {
            this.salesSearch.endDateMonth = this.salesSearch.endDateMonth + 1;
        }
        this.salesSearch.endStr = this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear);
        console.log('End Date Up: ' + this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear));
        this.filter();
        console.log('salesSearch: ' + JSON.stringify(this.salesSearch));
        this.salesSearchChange.emit(this.salesSearch);
    };
    SalesContentComponent.prototype.onClickDeduction = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var sale, _a, i, newSale;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // ignore click on expanded deduction
                        if (this.displayDeductionDetails && index >= this.expandIndex1 + 1 && index <= this.expandIndex2) {
                            console.log('Index clicked: ' + index);
                            return [2 /*return*/];
                        }
                        if (this.displayDeductionDetails) {
                            // Remove previous deduction
                            if (this.expandIndex1 < this.expandIndex2) {
                                this.sales.splice(this.expandIndex1 + 1, this.expandIndex2 - this.expandIndex1);
                            }
                        }
                        // Clicked on the same deduction - hide details
                        if (index === this.expandIndex1) {
                            this.expandIndex1 = -1;
                            this.expandIndex2 = -1;
                            this.displayDeductionDetails = false;
                            this.showDeductionType(false);
                            return [2 /*return*/];
                        }
                        // Adjust the index to indicate we are expanding deductions at the new index location
                        if (index > this.expandIndex1) {
                            index = index - (this.expandIndex2 - this.expandIndex1);
                        }
                        sale = this.sales[index];
                        if (!(sale.deductions == null)) return [3 /*break*/, 2];
                        console.log('Get deductions');
                        console.log('payment: ');
                        console.log(sale);
                        _a = sale;
                        return [4 /*yield*/, this.getSalesDeductionsIndividual(sale.id)];
                    case 1:
                        _a.deductions = _b.sent();
                        _b.label = 2;
                    case 2:
                        this.deductions = sale.deductions;
                        console.log('Show deductions:');
                        console.log(sale);
                        console.log(this.deductions);
                        // Show DeductionType column
                        this.displayDeductionDetails = true;
                        this.showDeductionType(true);
                        for (i = 0; i < this.deductions.length; i++) {
                            newSale = new Sale();
                            newSale.deductionType = this.deductions[i].deductionType.name;
                            newSale.totalDeductions = this.deductions[i].amount;
                            this.sales.splice(index + i + 1, 0, newSale);
                        }
                        this.applyDataBinding(this.sales);
                        this.expandIndex1 = index;
                        this.expandIndex2 = index + this.deductions.length;
                        return [2 /*return*/];
                }
            });
        });
    };
    SalesContentComponent.prototype.onBack = function () {
        console.log('Back clicked');
        this.location.back();
    };
    SalesContentComponent.prototype.exportReport = function (reportType) {
        this.salesService.getReport(this.sales, reportType).subscribe(function (data) {
            var blob = new Blob([data], { type: 'application/octet-stream' });
            var extension = '';
            if (reportType === 1) {
                extension = '.pdf';
            }
            else if (reportType === 2) {
                extension = '.xlsx';
            }
            else if (reportType === 3) {
                extension = '.csv';
            }
            FileSaver.saveAs(blob, "sales" + extension);
        });
    };
    SalesContentComponent.prototype.workbookInit = function (args) {
        console.log("Workbook Init", { args: args });
        this.spread = args.spread;
        this.sheet = this.spread.getActiveSheet();
        this.sheetWidth = this.sheet.getViewportWidth(1);
        console.log('SheetWidth: ', this.sheetWidth);
    };
    return SalesContentComponent;
}());
export { SalesContentComponent };
