<div id="footer">
  <div class="page_container">
    <div id="footer_inner">
      <div id="footer_legal">&copy; Copyright 2021 PipelineDL. All Rights Reserved</div>
      <div id="footer_links" *ngIf="this.loggedIn()">
        <ul>
          <li><a routerLink="/dashboard">Home</a></li>
          <li><a routerLink="/properties">Properties</a></li>
          <li><a routerLink="/production">Production</a></li>
          <li><a routerLink="/sales">Sales & Expenses</a></li>
          <li><a routerLink="/payments">Payments</a></li>
        </ul>
      </div>
      <div id="footer_logo"><img src="./assets/images/powered_by_pipeline@2x.png" alt="Powered by PipelineDL" /></div>
    </div>
  </div>
</div>
