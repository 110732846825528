import { OnInit } from '@angular/core';
import { LookupService } from '../lookup.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
var TestComponent = /** @class */ (function () {
    function TestComponent(lookupService, http) {
        this.lookupService = lookupService;
        this.http = http;
        this.title = 'Oil and Gas';
        this.appFooter = 'Interface for testing Oil and Gas angular pages';
        this.lookupUrl = environment.apiUrl + "/lookup";
    }
    TestComponent.prototype.ngOnInit = function () {
        // this.getOperators();
    };
    TestComponent.prototype.getOperators = function () {
        var _this = this;
        // Todo: use .pipe(ngUnsubscribe) for every observable + put a ngUnsubscribe.next()
        // inside of each component ngOnDestroy(). For that you need to create a property for that
        // component call "ngUnsubscribe" with the type BehaviorSubject.
        // Example: private ngUnsubscribe: BehaviorSubject = new BehaviorSubject(null); if (/*is not null*/) { /* do whatever */ }
        this.getOperatorsFromDB().subscribe(function (operators) {
            console.log({ operators: operators });
            _this.operators = operators;
        });
    };
    // GET
    TestComponent.prototype.getOperatorsFromDB = function () {
        var url = this.lookupUrl + "/getOperators";
        console.log('GetOperators Url: ', url);
        return this.http.get(url);
    };
    return TestComponent;
}());
export { TestComponent };
