// This is a date picker for Spread JS
// Restrictions: Dates are assume to be in MM/DD/YYYY format
// If they are NOT - then there are a number of adjustments required so that this code
// can manage dates correctly
import * as GC from '@grapecity/spread-sheets';

declare var $: any; // jquery

export function DatePickerCellType() { }

DatePickerCellType.prototype = new GC.Spread.Sheets.CellTypes.Base();

DatePickerCellType.prototype.createEditorElement = () => {
  // Create input presenter.
  return document.createElement('input');
};

DatePickerCellType.prototype.selectAll = (editorContext, context) => {
  console.log('SelectAll: EditorContext: ', { editorContext });
  // console.log('SelectAll: Context: ', { context });
  editorContext.setSelectionRange(0, editorContext.value.length);
};

DatePickerCellType.prototype.activateEditor = function (editorContext, cellStyle, cellRect, context) {
  // Initialize input editor.
  console.log('ActivateEditor: EditorContext: ', { editorContext });
  // console.log('ActivateEditor: Context: ', { context });
  if (editorContext) {
    const editor = $(editorContext);
    GC.Spread.Sheets.CellTypes.Base.prototype.activateEditor.apply(this, arguments);
    editor.datepicker();
    // editor.datepicker().changeYear = true;
    // editor.datepicker().changeMonth = true;

    // don't show dropdown when enter editing mode by using keyboard
    // if (context.sheet.editorStatus() === GC.Spread.Sheets.EditorStatus.enter) {
    //   editor.datepicker('option', 'showOn', 'button');
    // }
    editor.css('position', 'absolute');
    editor.attr('gcUIElement', 'gcEditingInput');
    $('.ui-datepicker').attr('gcUIElement', 'gcEditingInput');
  }
};

DatePickerCellType.prototype.deactivateEditor = (editorContext, context) => {
  // Remove input editor when end editor status.
  if (editorContext) {
    $(editorContext).datepicker('hide');
  }
};

DatePickerCellType.prototype.setEditorValue = (editor, value, context) => {
  // Sync value from Cell value to editor value.
  // format date to datepicker format
  // if (value !== null) {
  //   const valueBefore = value;
  //   value = value.substring(5, 7) + '/' + value.substring(8, 10) + '/' + value.substring(0, 4);
  //   alert('Before: ' + valueBefore + ' After: ' + value);
  // }
  // console.log('SetEditorValue: editor: ', { editor });
  console.log('SetEditorValue: value: ', { value });
  // console.log('SetEditorValue: Context: ', { context });
  $(editor).datepicker('setDate', value);
};

DatePickerCellType.prototype.getEditorValue = (editor, context) => {
  // Sync value from editor value to cell value.
  // console.log('GetEditorValue: editor: ', { editor });
  // console.log('GetEditorValue: value: ', $(editor).datepicker('getDate'));
  // console.log('GetEditorValue: Context: ', { context });
  const date = $(editor).datepicker('getDate');
  console.log('GetEditorValue: date: ' + date);
  const returnValue = $.datepicker.formatDate('mm/dd/yy', date);

  return returnValue;
};

DatePickerCellType.prototype.updateEditor = (editorContext, cellStyle, cellRect, context) => {
  // console.log('UpdateEditor: EditorContext: ', { editorContext });
  // console.log('UpdateEditor: cellStyle: ', { cellStyle });
  // console.log('UpdateEditor: cellRect: ', { cellRect });
  // console.log('UpdateEditor: Context: ', { context });
  if (editorContext) {
    const editor = $(editorContext);
    editor.css('width', cellRect.width - 3);
    editor.css('height', cellRect.height - 3);
  }
};
