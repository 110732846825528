export const options = {
  chart: {
    backgroundColor: 'transparent',
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie'
  },
  title: {
    text: ''
  },
  credits: {
    enabled: false,
  },
  tooltip: {
    pointFormat: '<b>${point.y:,.2f}</b>' // {point.name}
  },
  labels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      innerSize: '70%',
      allowPointSelect: false,
      cursor: 'pointer',
      dataLabels: {
        enabled: false,
        // format: '<b>{point.name}</b>: {point.percentage:.1f} %'
      }
    }
  },
  series: [{
    // name: 'Payments',
    colorByPoint: true,
    data: [{
      name: 'Confirmed',
      y: 288764,
      color: '#29BBA9',
    }, {
      name: 'Pending',
      y: 71225,
      color: '#616F7C',
    }, {
      name: 'Issued',
      y: 36613,
      color: '#71ADD1',
    }]
  }]
};
