import { Component, OnInit } from '@angular/core';

declare var $: any; // jquery

@Component({
  selector: 'app-ticker-split',
  templateUrl: './ticker-split.component.html',
  styleUrls: ['./ticker-split.component.css']
})
export class TickerSplitComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('#btn_ticker_toggle').click(function () {
        $('#ticker_drawer').slideToggle();
        $(this).toggleClass('selected');
      });
    });
  }

}
