import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductionSearch } from '../model/production_search';

declare var $: any; // jquery

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})

export class SalesComponent implements OnInit {

  action: string;
  displayAddSales = false;
  displayUploadSales = false;
  displayProcessPayments = false;
  salesSearch = {};

  constructor(
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.action = this.route.snapshot.paramMap.get('action');

    if (this.action === null) {
      this.action = '';
    } else if (this.action.toUpperCase() === 'ADD') {
      this.addSales();
    } else if (this.action.toUpperCase() === 'UPLOAD') {
      this.uploadSales();
    } else if (this.action.toUpperCase() === 'PAYMENTS') {
      this.processPayments();
    }
  }

  public addSales() {
    this.displayAddSales = true;
  }

  public uploadSales() {
    this.displayUploadSales = true;
  }

  public processPayments() {
    this.displayProcessPayments = true;
  }

}
