/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./properties.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@progress/kendo-angular-dialog/dist/es2015/index.ngfactory";
import * as i3 from "@progress/kendo-angular-dialog";
import * as i4 from "@progress/kendo-angular-l10n";
import * as i5 from "../messages/messages.component.ngfactory";
import * as i6 from "../messages/messages.component";
import * as i7 from "./properties-content/properties-content.component.ngfactory";
import * as i8 from "@angular/common";
import * as i9 from "./properties-content/properties-content.component";
import * as i10 from "@angular/forms";
import * as i11 from "../property.service";
import * as i12 from "../lookup.service";
import * as i13 from "../messages.service";
import * as i14 from "@angular/router";
import * as i15 from "./properties-add/properties-add.component.ngfactory";
import * as i16 from "./properties-add/properties-add.component";
import * as i17 from "./properties-upload/properties-upload.component.ngfactory";
import * as i18 from "./properties-upload/properties-upload.component";
import * as i19 from "@angular/common/http";
import * as i20 from "../divisionorder.service";
import * as i21 from "../auth.service";
import * as i22 from "./properties.component";
var styles_PropertiesComponent = [i0.styles];
var RenderType_PropertiesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PropertiesComponent, data: {} });
export { RenderType_PropertiesComponent as RenderType_PropertiesComponent };
function View_PropertiesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "kendo-dialog", [], [[1, "dir", 0], [1, "tabIndex", 0], [2, "k-dialog-wrapper", null]], [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onComponentKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closeBCDialog() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_DialogComponent_0, i2.RenderType_DialogComponent)), i1.ɵprd(6144, null, i3.DIALOG_LOCALIZATION_SERVICE, null, [i4.LocalizationService]), i1.ɵprd(256, null, i4.L10N_PREFIX, "kendo.dialog", []), i1.ɵprd(131584, null, i4.LocalizationService, i4.LocalizationService, [i4.L10N_PREFIX, [2, i4.MessageService], [2, i4.RTL]]), i1.ɵdid(4, 5488640, null, 1, i3.DialogComponent, [i1.ElementRef, i1.Renderer2, i4.LocalizationService, i1.ChangeDetectorRef], null, { close: "close" }), i1.ɵqud(335544320, 1, { titlebarContent: 0 }), (_l()(), i1.ɵeld(6, 0, null, 1, 2, "div", [["class", "errorDialogTitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "k-icon k-i-warning"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Save Property"])), (_l()(), i1.ɵeld(9, 0, null, 1, 1, "p", [["class", "errorDialogMsg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Property saved to DB - but failed to write to Blockchain."])), (_l()(), i1.ɵeld(11, 0, null, 1, 2, "div", [["class", "errorDialogActionButtons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "errorDialogButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeBCDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["OK"]))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).dir; var currVal_1 = i1.ɵnov(_v, 4).tabIndex; var currVal_2 = i1.ɵnov(_v, 4).wrapperClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_PropertiesComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "kendo-dialog", [], [[1, "dir", 0], [1, "tabIndex", 0], [2, "k-dialog-wrapper", null]], [[null, "keydown"]], function (_v, en, $event) { var ad = true; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onComponentKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DialogComponent_0, i2.RenderType_DialogComponent)), i1.ɵprd(6144, null, i3.DIALOG_LOCALIZATION_SERVICE, null, [i4.LocalizationService]), i1.ɵprd(256, null, i4.L10N_PREFIX, "kendo.dialog", []), i1.ɵprd(131584, null, i4.LocalizationService, i4.LocalizationService, [i4.L10N_PREFIX, [2, i4.MessageService], [2, i4.RTL]]), i1.ɵdid(4, 5488640, null, 1, i3.DialogComponent, [i1.ElementRef, i1.Renderer2, i4.LocalizationService, i1.ChangeDetectorRef], null, null), i1.ɵqud(335544320, 2, { titlebarContent: 0 }), (_l()(), i1.ɵeld(6, 0, null, 1, 0, "img", [["alt", "../../assets/images/loader.gif"], ["src", "../../assets/images/loader_dark.gif"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 4, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).dir; var currVal_1 = i1.ɵnov(_v, 4).tabIndex; var currVal_2 = i1.ɵnov(_v, 4).wrapperClass; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_PropertiesComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-messages", [], null, null, null, i5.View_MessagesComponent_0, i5.RenderType_MessagesComponent)), i1.ɵdid(1, 114688, null, 0, i6.MessagesComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "app-properties-content", [], null, [[null, "displayAddPropertyChange"], [null, "displayUploadPropertyChange"], [null, "displayBlockchainErrorDialogChange"], [null, "displayWaitingDialogChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("displayAddPropertyChange" === en)) {
        var pd_0 = ((_co.displayAddProperty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("displayUploadPropertyChange" === en)) {
        var pd_1 = ((_co.displayUploadProperty = $event) !== false);
        ad = (pd_1 && ad);
    } if (("displayBlockchainErrorDialogChange" === en)) {
        var pd_2 = ((_co.displayBlockchainErrorDialog = $event) !== false);
        ad = (pd_2 && ad);
    } if (("displayWaitingDialogChange" === en)) {
        var pd_3 = ((_co.displayWaitingDialog = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i7.View_PropertiesContentComponent_0, i7.RenderType_PropertiesContentComponent)), i1.ɵprd(512, null, i8.DatePipe, i8.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(4, 114688, null, 0, i9.PropertiesContentComponent, [i10.FormBuilder, i11.PropertyService, i12.LookupService, i13.MessagesService, i8.DatePipe, i8.Location, i14.Router], { displayAddProperty: [0, "displayAddProperty"], displayUploadProperty: [1, "displayUploadProperty"], displayBlockchainErrorDialog: [2, "displayBlockchainErrorDialog"], displayWaitingDialog: [3, "displayWaitingDialog"] }, { displayAddPropertyChange: "displayAddPropertyChange", displayUploadPropertyChange: "displayUploadPropertyChange", displayBlockchainErrorDialogChange: "displayBlockchainErrorDialogChange", displayWaitingDialogChange: "displayWaitingDialogChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-properties-add", [], null, [[null, "displayAddPropertyChange"], [null, "displayBlockchainErrorDialogChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("displayAddPropertyChange" === en)) {
        var pd_0 = ((_co.displayAddProperty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("displayBlockchainErrorDialogChange" === en)) {
        var pd_1 = ((_co.displayBlockchainErrorDialog = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i15.View_PropertiesAddComponent_0, i15.RenderType_PropertiesAddComponent)), i1.ɵdid(6, 245760, null, 0, i16.PropertiesAddComponent, [i10.FormBuilder, i11.PropertyService, i12.LookupService], { displayAddProperty: [0, "displayAddProperty"], displayBlockchainErrorDialog: [1, "displayBlockchainErrorDialog"] }, { displayAddPropertyChange: "displayAddPropertyChange", displayBlockchainErrorDialogChange: "displayBlockchainErrorDialogChange" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-properties-upload", [], null, [[null, "displayUploadPropertyChange"], [null, "displayAddPropertyChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("displayUploadPropertyChange" === en)) {
        var pd_0 = ((_co.displayUploadProperty = $event) !== false);
        ad = (pd_0 && ad);
    } if (("displayAddPropertyChange" === en)) {
        var pd_1 = ((_co.displayAddProperty = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i17.View_PropertiesUploadComponent_0, i17.RenderType_PropertiesUploadComponent)), i1.ɵdid(8, 245760, null, 0, i18.PropertiesUploadComponent, [i19.HttpClient, i20.DivisionorderService, i13.MessagesService, i21.AuthService], { displayUploadProperty: [0, "displayUploadProperty"], displayAddProperty: [1, "displayAddProperty"] }, { displayUploadPropertyChange: "displayUploadPropertyChange", displayAddPropertyChange: "displayAddPropertyChange" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropertiesComponent_1)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PropertiesComponent_2)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.displayAddProperty; var currVal_1 = _co.displayUploadProperty; var currVal_2 = _co.displayBlockchainErrorDialog; var currVal_3 = _co.displayWaitingDialog; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.displayAddProperty; var currVal_5 = _co.displayBlockchainErrorDialog; _ck(_v, 6, 0, currVal_4, currVal_5); var currVal_6 = _co.displayUploadProperty; var currVal_7 = _co.displayAddProperty; _ck(_v, 8, 0, currVal_6, currVal_7); var currVal_8 = _co.displayBlockchainErrorDialog; _ck(_v, 10, 0, currVal_8); var currVal_9 = _co.displayWaitingDialog; _ck(_v, 12, 0, currVal_9); }, null); }
export function View_PropertiesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-properties", [], null, null, null, View_PropertiesComponent_0, RenderType_PropertiesComponent)), i1.ɵdid(1, 114688, null, 0, i22.PropertiesComponent, [i14.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PropertiesComponentNgFactory = i1.ɵccf("app-properties", i22.PropertiesComponent, View_PropertiesComponent_Host_0, {}, {}, []);
export { PropertiesComponentNgFactory as PropertiesComponentNgFactory };
