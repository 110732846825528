import * as tslib_1 from "tslib";
import { options } from './donutchart';
import { FormBuilder, FormControl } from '@angular/forms';
import { OnInit } from '@angular/core';
import { LookupService } from 'src/app/lookup.service';
import { DashboardService } from 'src/app/dashboard.service';
import * as Highcharts from 'highcharts';
import { environment } from '../../../environments/environment';
var DashboardWidgetsComponent = /** @class */ (function () {
    function DashboardWidgetsComponent(fb, lookupService, dashboardService) {
        this.fb = fb;
        this.lookupService = lookupService;
        this.dashboardService = dashboardService;
        this.performanceWidgetDisplayFlag = environment.showPerformanceWidget;
        this.summaryWidgetDisplayFlag = environment.showSummaryWidget;
        this.topWellsWidgetDisplayFlag = environment.showTopWellsWidget;
        this.paymentsWidgetDisplayFlag = environment.showPaymentsWidget;
        this.banksWidgetDisplayFlag = environment.showBanksWidget;
        this.openTicketsWidgetDisplayFlag = environment.showOpenTicketsWidget;
        this.leasesWidgetDisplayFlag = environment.showLeasesWidget;
        this.marketsWidgetDisplayFlag = environment.showMarketsWidget;
        this.dashboardForm = this.fb.group({
            performance_well: new FormControl(null),
            performance_product: new FormControl(null),
            top_property_product: new FormControl(null),
            payment_bank: new FormControl(null),
            summary_bank: new FormControl(null)
        });
        this.chartPeriod = 0; // 0 = Monthly, 1 = Quarterly, 2 = Annually
        this.chartType = 0; // 0 = table, 1 = chart
        this.chartPeriodPayment = 0; // 0 = Monthly, 1 = Quarterly, 2 = Annually
        this.lastUpdated = new Date();
        this.lastUpdatedminus1 = new Date();
        this.lastUpdatedminus2 = new Date();
        this.lastUpdatedminus3 = new Date();
        this.lastUpdatedminus4 = new Date();
        this.lastUpdatedminus5 = new Date();
        this.lastUpdatedminus6 = new Date();
        this.royaltyDue = new Date();
        this.manualPaymentsDate = new Date();
        this.performanceStart = new Date();
        this.performanceEnd = new Date();
        this.lastUpdated.setDate(new Date().getDate() - 2);
        this.lastUpdatedminus1.setMonth(this.lastUpdated.getMonth() - 1);
        this.lastUpdatedminus2.setMonth(this.lastUpdated.getMonth() - 2);
        this.lastUpdatedminus3.setMonth(this.lastUpdated.getMonth() - 3);
        this.lastUpdatedminus4.setMonth(this.lastUpdated.getMonth() - 4);
        this.lastUpdatedminus5.setMonth(this.lastUpdated.getMonth() - 5);
        this.lastUpdatedminus6.setMonth(this.lastUpdated.getMonth() - 6);
        this.royaltyDue.setDate(new Date().getDate() + 20);
        this.manualPaymentsDate.setDate(new Date().getDate() - 15);
        this.performanceStart.setMonth(this.lastUpdated.getMonth() - 6);
    }
    DashboardWidgetsComponent.prototype.ngOnInit = function () {
        Promise.all([
            this.getWells(),
            this.getProducts(),
            this.getBanks()
        ]).then(function (values) {
            // this.getTicketsSummary();
            // this.getPerformanceByDate(),
            // this.getPerformanceByWell();
        });
        this.setFormDefaults();
        this.initChart();
    };
    DashboardWidgetsComponent.prototype.initChart = function () {
        console.log('initChart - start');
        this.options = options;
        console.log('initChart - initialize');
        if (this.paymentsWidgetDisplayFlag) {
            this.chart = Highcharts.chart('chart_donut', this.options);
        }
        console.log('initChart - end');
    };
    DashboardWidgetsComponent.prototype.onFormChange = function () {
        this.selectedPerformanceProduct = this.dashboardForm.controls.performance_product.value;
        this.selectedPerformanceWell = this.dashboardForm.controls.performance_well.value;
        this.selectedTopPropertyProduct = this.dashboardForm.controls.top_property_product.value;
        this.selectedBank = this.dashboardForm.controls.payment_bank.value;
        this.selectedSummaryBank = this.dashboardForm.controls.summary_bank.value;
        console.dir(this.selectedBank);
    };
    DashboardWidgetsComponent.prototype.setFormDefaults = function () {
        this.dashboardForm.setValue({
            payment_bank: -1,
            summary_bank: -1,
            performance_well: -1,
            performance_product: -1,
            top_property_product: -1
        });
    };
    DashboardWidgetsComponent.prototype.getWells = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getWells())];
                    case 1:
                        _a.wells = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardWidgetsComponent.prototype.getProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getProducts())];
                    case 1:
                        _a.products = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardWidgetsComponent.prototype.getBanks = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getBanks())];
                    case 1:
                        _a.banks = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    // This should be got from the database through an API call
    DashboardWidgetsComponent.prototype.getTicketsSummary = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/];
            });
        });
    };
    DashboardWidgetsComponent.prototype.getPerformanceByDate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.dashboardService.getPerformanceByDate())];
                    case 1:
                        _a.performanceByDate = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardWidgetsComponent.prototype.getPerformanceByWell = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.dashboardService.getPerformanceByWell())];
                    case 1:
                        _a.performanceByWell = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardWidgetsComponent.prototype.setChartPeriod = function (period) {
        this.chartPeriod = period;
    };
    DashboardWidgetsComponent.prototype.setChartType = function (type) {
        this.chartType = type;
    };
    DashboardWidgetsComponent.prototype.setChartPeriodPayment = function (period) {
        this.chartPeriodPayment = period;
    };
    return DashboardWidgetsComponent;
}());
export { DashboardWidgetsComponent };
