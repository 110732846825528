import { Component, OnInit } from '@angular/core';

declare var $: any; // jquery
declare let datepicker;
declare let modals;
declare let removeHash;

@Component({
  selector: 'app-owner-upload',
  templateUrl: './owner-upload.component.html',
  styleUrls: ['./owner-upload.component.css']
})
export class OwnerUploadComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
