<!-- @dialog Modal for Uploading a new Production -->
<kendo-dialog *ngIf="displayUploadProduction">
  <div id="dialog_upload_production" title="Upload Production">
    <div class="dialog_header">
      <h1>Upload Production</h1>
      <div class="dialog_close" (click)="closeDialog()">
        <button type="button" class="close_modal btn_normal btn_icon_only">
          <span class="btn_icon"><span class="icon_close"></span></span>
          <span class="btn_text hidden_text">Close Dialog or press Escape to dismiss</span>
        </button>
      </div>
    </div>
    <div class="dialog_content">
      <div class="dialog_inner_content">
        <div class="form_upload_container">
          <form action="#form_submitted" method="post" enctype="multipart/form-data">
            <fieldset>
              <div class="form_row no_padding">
                <div class="form_label">
                  <label for="fileToUploadProduction" class="upload_label">
                    <span class="upload_icon">
                      <span class="icon_upload_cloud"></span>
                      <h1>Drag and drop here to import</h1>
                      <p>Or browse for your file, if you prefer...</p>
                      <p><span class="btn_normal btn_blue" type="button">Browse...</span></p>
                    </span>
                    <div id="form_file_selected_production" style="text-align: center; line-height: 40px;">
                      <ng-container *ngIf="!fileSelected; else showFileName">
                        {{ message }}
                      </ng-container>
                      <ng-template #showFileName>
                        <strong>{{ message }}</strong>
                      </ng-template>
                    </div>
                  </label>
                </div>
                <div class="form_input hidden">
                  <input
                    type="file"
                    name="fileToUploadProduction"
                    id="fileToUploadProduction"
                    accept=""
                    class="inputbox"
                    style="text-align: center;"
                    (change)="onFileSelectedProduction($event)"
                  />
                  <input type="submit" class="btn_normal" value="Submit Form" id="submit_form_btn" disabled="" />
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="dialog_actions clearfix">
        <div class="actions_left">
          <!-- <button type="button" class="btn_normal btn_white" id="btn_add_production_alt">
          <span class="btn_icon"><span class="icon_create"></span></span>
          <span class="btn_text">Add Manually</span>
        </button> -->
        </div>
        <div class="actions_right">
          <button type="button" class="btn_normal btn_grey btn_cancel_modal" (click)="closeDialog()">
            <span class="btn_text">Cancel</span>
          </button>
          <ng-container *ngIf="!fileSelected; else uploadEnabled">
            <button type="button" class="btn_normal btn_blue" id="btn_confirm_upload_production" disabled="">
              <span class="btn_text">Upload</span>
            </button>
          </ng-container>
          <ng-template #uploadEnabled>
            <button type="button" class="btn_normal btn_blue" id="btn_confirm_upload_production" (click)="onUpload()">
              <span class="btn_text">Upload</span>
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>
