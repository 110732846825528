import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var ProductionComponent = /** @class */ (function () {
    function ProductionComponent(route) {
        this.route = route;
        this.displayAddProduction = false;
        this.displayUploadProduction = false;
    }
    ProductionComponent.prototype.ngOnInit = function () {
        this.action = this.route.snapshot.paramMap.get('action');
        // alert('Action: ' + this.action);
        if (this.action === null) {
            this.action = '';
        }
        else if (this.action.toUpperCase() === 'ADD') {
            this.addProduction();
        }
        else if (this.action.toUpperCase() === 'UPLOAD') {
            this.uploadProduction();
        }
    };
    ProductionComponent.prototype.addProduction = function () {
        this.displayAddProduction = true;
    };
    ProductionComponent.prototype.uploadProduction = function () {
        this.displayUploadProduction = true;
    };
    return ProductionComponent;
}());
export { ProductionComponent };
