import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Payment } from './model/payment';
import { OperatorPaymentsReq } from './model/operator_payments_req';

@Injectable({
  providedIn: 'root'
})
export class OperatorPaymentsService {
  private readonly paymentsUrl = `${environment.apiUrl}/payments`;

  constructor(private http: HttpClient) { }

  // API: Post
  // getOperatorPayments(year: number, propertyId: number) {
  //   const url = `${this.paymentsUrl}/getOperatorPayments`;
  //   const operatorPaymentsReq: OperatorPaymentsReq = new OperatorPaymentsReq();
  //   operatorPaymentsReq.year = year;
  //   operatorPaymentsReq.propertyId = propertyId;
  //   console.log('Url: ' + url);
  //   return this.http.post(url, operatorPaymentsReq);
  // }

  // API: Get
  getOperatorPayments() {
    const url = `${this.paymentsUrl}/getOperatorPayments`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getOperatorPaymentsByYear(year: number) {
    const url = `${this.paymentsUrl}/getOperatorPayments?year=${year}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getOperatorPaymentsByPropertyId(propertyId: number) {
    const url = `${this.paymentsUrl}/getOperatorPayments?propertyId=${propertyId}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getOperatorPaymentsDeductions(operatorId: number) {
    const url = `${this.paymentsUrl}/getOperatorPaymentsDeductions?operatorId=${operatorId}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getOperatorPaymentsDeductionsIndividual(operatorId: number, paymentId: number) {

    // tslint:disable-next-line: max-line-length
    const url = `${this.paymentsUrl}/getOperatorPaymentsDeductionsIndividual?operatorId=${operatorId}&royaltyPaymentId=${paymentId}`;
    console.log('Url: ' + url);

    return this.http.get(url);
  }

  // POST
  public getReport(payments: Payment[], reportType: number) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.paymentsUrl}/paymentsReport/${reportType}`;
    return this.http.post(url, payments, httpOptions);
  }

}


