import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';

import * as GC from '@grapecity/spread-sheets';
import * as FileSaver from 'file-saver';
import { environment } from 'src/environments/environment';

import { Sale } from 'src/app/model/sale';
import { USState } from 'src/app/model/us_state';
import { ProductionSearch } from 'src/app/model/production_search';

import { SalesService } from 'src/app/sales.service';
import { MessagesService } from 'src/app/messages.service';
import { LookupService } from 'src/app/lookup.service';
import { AuthService } from 'src/app/auth.service';
import { map } from 'rxjs/operators';
import { Deduction } from 'src/app/model/deduction';
import { User } from 'src/app/model/user';

declare var $: any; // jquery

@Component({
  selector: 'app-sales-content',
  templateUrl: './sales-content.component.html',
  styleUrls: ['./sales-content.component.css'],
  providers: [DatePipe]
})
export class SalesContentComponent implements OnInit, OnDestroy {

  @ViewChild('spreadContainer', { static: false }) spreadContainer: ElementRef;

  // Display/Hide Add Sales
  @Input() displayAddSales: boolean;
  @Output() displayAddSalesChange = new EventEmitter<boolean>();

  // Display/Hide Upload Sales
  @Input() displayUploadSales: boolean;
  @Output() displayUploadSalesChange = new EventEmitter<boolean>();

  // Display/Hide ProcessPayments
  @Input() displayProcessPayments: boolean;
  @Output() displayProcessPaymentsChange = new EventEmitter<boolean>();

  // pass the search object in/out
  @Input() salesSearch: ProductionSearch;
  @Output() salesSearchChange = new EventEmitter<ProductionSearch>();

  salesSearchForm: FormGroup = this.fb.group({
    searchStr: [''],
    state: ['']
  });

  private readonly blockchainExplorerUrl = `${environment.blockchainExplorerUrl}`;

  private sheet: GC.Spread.Sheets.Worksheet = null;
  private spread: GC.Spread.Sheets.Workbook;

  private sheetWidth: number;

  tabStripVisible = false;
  spreadBackColor = 'white';
  sheetName = 'Sales List';
  hostStyle = {
    height: '100%',
    width: '100%'
  };
  data: any;
  autoGenerateColumns = false;

  propertyCol = 0;
  wellCountCol = 1;
  blockChainImageCol = 2;
  blockChainTransIdCol = 3;
  productCol = 4;
  prodDateCol = 5;
  prodVolCol = 6;
  salesVolCol = 7;
  grsValueCol = 8;
  totalDedCol = 9;
  deductionTypesCol = 10;
  gridColCount = 11;

  states: USState[];

  sales: Sale[];
  allSales: Sale[];

  maxStartDate = false;
  maxEndDate = true;

  displayDeductionDetails = false;
  deductions: Deduction[];

  expandIndex1 = -1;
  expandIndex2 = -1;

  lastUpdated: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private salesService: SalesService,
    private lookupService: LookupService,
    private messagesService: MessagesService,
    private datePipe: DatePipe,
    private location: Location,
    private router: Router,
    private authService: AuthService) { }

  closeAddSales() {
    this.displayAddSales = false;
    this.displayAddSalesChange.emit(false);
  }

  openAddSales() {
    this.displayAddSales = true;
    this.displayAddSalesChange.emit(true);
  }

  closeUploadSales() {
    this.displayUploadSales = false;
    this.displayUploadSalesChange.emit(false);
  }

  openUploadSales() {
    console.log('Sales-Content: Upload Sales');
    this.displayUploadSales = true;
    this.displayUploadSalesChange.emit(true);
  }

  closeProcessPayments() {
    console.log('Sales-Content: Close Process Payments');
    this.displayProcessPayments = false;
    this.displayProcessPaymentsChange.emit(false);
  }

  openProcessPayments() {
    console.log('Sales-Content: Open Process Payments');
    this.displayProcessPayments = true;
    this.displayProcessPaymentsChange.emit(true);
  }

  ngOnInit() {
    // initialise Search criteria
    const today = new Date();

    this.salesSearch = new ProductionSearch();
    this.salesSearch.prodType = 0;
    this.salesSearch.startDateMonth = 1;
    this.salesSearch.startDateYear = today.getFullYear();
    this.salesSearch.startStr = this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear);
    this.salesSearch.endDateMonth = today.getMonth() + 1;
    this.salesSearch.endDateYear = today.getFullYear();
    this.salesSearch.endStr = this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear);
    this.salesSearch.searchStr = [];
    this.salesSearch.state = '';
    this.salesSearchChange.emit(this.salesSearch);

    const operator = this.authService.getUser().operator;

    Promise.all([
      this.getUSStates(),
      this.getSalesByYear(operator, today.getFullYear())
    ]).then(async values => {
      await this.getSales(operator);
      // put a small delay on getting sales deductions so that all sales are loaded first
      // setTimeout(() => this.getSalesDeductions(), 1000);
      this.getSalesDeductions()
    });

    this.lastUpdated.setDate(today.getDate() - 2);
  }

  ngOnDestroy(): void {
    this.sheet = null;
    if (this.spread) {
      this.spread.destroy();
      this.spread = undefined;
    }
  }

  async getUSStates() {
    this.states = (await (this.lookupService.getStates())) as USState[];
  }

  getSalesByYear(operator, year) {
    console.log('getSalesByYear - start: ' + new Date().toISOString());
    return this.salesService.getSalesByYear(operator.id, year).pipe(
      map(
        sales => {
          console.log('getSalesByYear - Back from API: ' + new Date().toISOString());
          // console.log({ sales });

          let tempSales: Sale[] = JSON.parse(JSON.stringify(sales));
          tempSales = this.setSalesValues(tempSales);

          this.allSales = tempSales;
          this.sales = tempSales;

          this.initialiseGrid();
          this.filter();

          console.log('getSalesByYear - complete: ' + new Date().toISOString() + '   No. Records: ' + this.allSales.length);
          return sales;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  async getSales(operator) {
    console.log('Start getSales: ' + new Date().toISOString());
    return this.salesService.getSales(operator.id).pipe(
      map(
        sales => {
          console.log('getSales - Back from API: ' + new Date().toISOString());

          let tempSales: Sale[] = JSON.parse(JSON.stringify(sales));
          tempSales = this.setSalesValues(tempSales);

          this.allSales = tempSales;
          console.log('getSales - complete: ' + new Date().toISOString() + '   No. Records: ' + this.allSales.length);
          return sales;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  async getSalesDeductions() {
    // Get the operatorId from the user - only want this operators payments
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;

    console.log('getSalesDeductions - start: ' + new Date().toISOString());
    return this.salesService.getSalesDeductions(operatorId).pipe(
      map(
        deductions => {
          console.log('getSalesDeductions - back from API: ' + new Date().toISOString());
          console.log({ deductions });

          const tempDeductions: Deduction[] = JSON.parse(JSON.stringify(deductions));

          for (const deduction of tempDeductions) {
            this.addSaleDeduction(deduction);
          }

          this.sales = this.allSales;
          this.filter();
          // console.log(this.allSales);
          console.log('getSalesDeductions - complete: ' + new Date().toISOString());
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  async getSalesDeductionsIndividual(salesId) {
    // Get the operatorId from the user - only want this operators payments
    return this.salesService.getSalesDeductionsIndividual(salesId).pipe(
      map(
        deductions => {
          console.log({ deductions });

          const tempDeductions: Deduction[] = JSON.parse(JSON.stringify(deductions));

          return tempDeductions;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  private addSaleDeduction(deduction) {
    for (const sale of this.allSales) {
      if (sale.id === deduction.salesId) {
        if (sale.deductions == null) {
          sale.deductions = [];
        }
        sale.deductions.push(deduction);
      }
    }
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  setSalesValues(filteredSales: Sale[]): Sale[] {
    filteredSales.forEach(salesRec => {
      // console.log('Sales: ', { salesRec });

      salesRec.prodDate = this.messagesService.monthString(salesRec.month) + ' - ' + salesRec.year;
      salesRec.propertyNo = salesRec.property.propertyNo;
      salesRec.productName = salesRec.product.name;
    });

    return filteredSales;
  }

  createGridEvents() {

    // Highlight the row
    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.EnterCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColCount; col++) {
        const cell = this.sheet.getCell(row, col);
        if (col !== this.blockChainImageCol) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridHighlight);
          }
        }
      }
      this.sheet.resumePaint();
    });

    // Un-highlight row
    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Un-highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColCount; col++) {
        const cell = this.sheet.getCell(row, col);

        if (col !== this.blockChainImageCol) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridBackground);
          }
        }
      }

      this.sheet.getCell(row, -1)
        .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      this.sheet.getCell(row, -1)
        .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));

      this.sheet.resumePaint();
    });

    // Bind click-event of Blockchain cell and to the deductions
    this.spread.bind(GC.Spread.Sheets.Events.CellClick, (e, args) => {
      const row = args.row;
      const col = args.col;
      if (args.sheetArea !== GC.Spread.Sheets.SheetArea.colHeader) {
        // Igonre grid header clicks
        if (col === this.blockChainImageCol || col === this.blockChainTransIdCol) {
          if (this.sales[row].bcTransId != null) {
            console.log('Cell: (' + row + ', ' + col
              + ') SalesId: ' + this.sales[row].id + '     BC TransId: ' + this.sales[row].bcTransId
              + '     BC BlockId: ' + this.sales[row].bcBlockId);

            let url = '';
            if (this.sales[row].bcBlockId != null) {
              url = this.blockchainExplorerUrl + this.sales[row].bcBlockId;
            } else {
              url = this.blockchainExplorerUrl;
            }
            window.open(url);
          }
        } else if (col === this.totalDedCol) {
          console.log('Row: ' + row + '; Col: ' + col);
          this.onClickDeduction(row);
        }
      }
    });

  }

  initialiseGrid() {
    // Define columns and column bindings as well as grid events
    // console.log('Initialise Grid');

    this.sheet.suspendPaint();

    // Define columns
    const salesPropertyNoColInfo = { name: 'propertyNo', displayName: 'Property #', size: 120 };
    const salesWellCountColInfo = { name: 'wellCount', displayName: 'Wells', size: 50 };
    const salesBCTransImgColInfo = { name: '', displayName: '', size: 30 };
    const salesBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 200 };
    const salesProductColInfo = { name: 'productName', displayName: 'Product #', size: 100 };
    const salesDateColInfo = { name: 'prodDate', displayName: 'Production Date', size: 100 };
    const salesProdVolumeColInfo = { name: 'prodVolume', displayName: 'Production Vol.', size: 120 };
    const salesVolumeColInfo = { name: 'salesVolume', displayName: 'Sales Vol.', size: 120 };
    const salesGrossSalesColInfo = { name: 'grsValue', displayName: 'Grs Value', size: 120 };
    const salesTotalDeductionColInfo = { name: 'totalDeductions', displayName: 'T. Deductions', size: 120 };
    const salesDeductionTypesColInfo = { name: 'deductionType', displayName: 'Deduction Types', size: 200 };

    this.sheet.autoGenerateColumns = false;
    this.sheet.setDataSource(this.sales);

    this.sheet.bindColumn(this.propertyCol, salesPropertyNoColInfo);
    this.sheet.bindColumn(this.wellCountCol, salesWellCountColInfo);
    this.sheet.bindColumn(this.blockChainImageCol, salesBCTransImgColInfo);
    this.sheet.bindColumn(this.blockChainTransIdCol, salesBCTransIdColInfo);
    this.sheet.bindColumn(this.productCol, salesProductColInfo);
    this.sheet.bindColumn(this.prodDateCol, salesDateColInfo);
    this.sheet.bindColumn(this.prodVolCol, salesProdVolumeColInfo);
    this.sheet.bindColumn(this.salesVolCol, salesVolumeColInfo);
    this.sheet.bindColumn(this.grsValueCol, salesGrossSalesColInfo);
    this.sheet.bindColumn(this.totalDedCol, salesTotalDeductionColInfo);
    this.sheet.bindColumn(this.deductionTypesCol, salesDeductionTypesColInfo);

    this.sheet.setColumnCount(this.gridColCount, GC.Spread.Sheets.SheetArea.viewport);

    // Lock/Readonly all columns
    this.sheet.options.isProtected = true;
    this.sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: true
    };

    // Add events to the Grid
    this.createGridEvents();

    // set cell alignment
    this.sheet.getCell(-1, this.wellCountCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.prodDateCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.prodVolCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.salesVolCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.grsValueCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.totalDedCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);

    // Hide the Deductions Type column
    this.showDeductionType(false);

    // Format the gross amount and the deductions as currency
    this.sheet.getCell(-1, this.grsValueCol).formatter('$ ##,###,###0.00');
    this.sheet.getCell(-1, this.totalDedCol).formatter('$ ##,###,###0.00');
    this.sheet.getCell(-1, this.deductionTypesCol).formatter('$ ##,###,###0.00');

    this.sheet.resumePaint();
  }

  showDeductionType(hide: boolean) {
    this.sheet.getRange(-1, this.deductionTypesCol, -1, 1).visible(hide);
  }

  applyDataBinding(filteredSales: Sale[]) {
    // console.log('Start data binding');

    if (this.sheet) {
      this.sheet.suspendPaint();

      // Apply filtering
      this.sheet.setDataSource(filteredSales);

      // console.log('Row Binding - start - filteredSales: ' + filteredSales.length);
      filteredSales.forEach((rowSalesObject: Sale, rowIndex: number) => {
        // console.log('Row Index: ' + rowIndex, rowSalesObject);

        // Remove vertical grid-lines
        this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));

        const cell = this.sheet.getCell(rowIndex, this.blockChainImageCol);
        let imgBlockChain = null;
        if (filteredSales[rowIndex].bcTransId != null) {
          imgBlockChain = './assets/images/blockchain_cell@2x.png';
        }

        // console.log('Image: ' + imgBlockChain);
        cell.text('');
        cell.backgroundImage(imgBlockChain);

      });

      // Put vertical grid-line on last column
      this.sheet.getCell(-1, this.gridColCount - 1)
        .borderRight(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));

      // console.log('Row Binding - complete');

      // Highlight first row except blockchain graphic cell
      for (let col = 0; col < this.gridColCount; col++) {
        if (col !== this.blockChainImageCol) {
          this.sheet.getCell(0, col).backColor(environment.gridHighlight);
        }
      }

      const lastRow = this.sheet.getRowCount();

      this.sheet.resumePaint();

      const today = new Date();
      this.maxStartDate =
        this.salesSearch.startDateMonth === today.getMonth() + 1 &&
        this.salesSearch.startDateYear === today.getFullYear();
      this.maxEndDate =
        this.salesSearch.endDateMonth === today.getMonth() + 1 &&
        this.salesSearch.endDateYear === today.getFullYear();

      // console.log('Binding Complete - Last row: ' + lastRow);
    }
  }

  private getLastDayOfMonth(year, month) {
    // get first day of month
    let date = new Date(year, month, 1);
    // Add 1 month and subtract 1 day
    date = new Date(date.setMonth(date.getMonth() + 1) - 1);
    return date;
  }

  private filter() {
    // Filters the "allSales" based on selected filters from the screen
    this.sales = [];

    const today = this.transformDate(new Date());

    this.allSales.forEach((salesObj: Sale, rowIndex: number) => {
      // console.log(salesObj);

      // If salesObj is null, undefined or empty - ignore
      if (salesObj != null && Object.keys(salesObj).length !== 0) {

        let includeProd = true;

        const startDate = new Date(this.salesSearch.startDateYear, this.salesSearch.startDateMonth - 1, 1);
        const endDate = this.getLastDayOfMonth(this.salesSearch.endDateYear, this.salesSearch.endDateMonth - 1);

        const saleDate = new Date(salesObj.year, salesObj.month - 1, 1);

        // console.log('Sales date: ' + this.transformDate(saleDate) + '   Start: ' + this.transformDate(startDate)
        //   + '   End Date: ' + this.transformDate(endDate));
        if (this.transformDate(saleDate) < this.transformDate(startDate)) {
          includeProd = false;
        }
        if (includeProd && this.transformDate(saleDate) > this.transformDate(endDate)) {
          includeProd = false;
        }

        if (includeProd && this.salesSearch.state !== '') {
          console.log('Sales Search State: ', this.salesSearch.state + '   Property: ' + JSON.stringify(salesObj.property));
          includeProd = (salesObj.property.county.USState.id === Number(this.salesSearch.state));
        }

        if (includeProd && this.salesSearch.searchStr.length > 0) {
          // Check property No, lease No, Property Name and Property Desc against any part of the search string
          includeProd = true; // Assuem property to be included
          this.salesSearch.searchStr.forEach((searchStr) => {
            includeProd = includeProd
              && ((salesObj.propertyNo.indexOf(searchStr) !== -1)
                || (salesObj.property.description.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (salesObj.property.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (salesObj.productName.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (salesObj.property.county.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (salesObj.property.county.USState.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1));
          });
        }

        // console.log('Sales Search Type: ', this.salesSearch.prodType);
        if (includeProd && this.salesSearch.prodType > 0) {
          if (this.salesSearch.prodType === 2) {
            // Complete - EffectiveTo date has passed
            if (salesObj.property.effectiveTo !== null) {
              includeProd = (salesObj.property.effectiveTo < today);
              console.log('Effective To: ' + salesObj.property.effectiveTo);
            } else {
              // Property effective to date not set  => Still in Progress (NOT complete)
              includeProd = false;
            }
          } else {
            // In Progress (= In production) => effectiveTo date not reached yet
            if (salesObj.property.effectiveTo !== null) {
              includeProd = (salesObj.property.effectiveTo > today);
            } else {
              includeProd = true;
            }
          }
        }

        // console.log('IncludeProduction: ' + includeProd);
        if (includeProd) {
          this.sales.push(salesObj);
        }
      }
    });

    this.applyDataBinding(this.sales);
  }

  public uploadSales() {
    this.openUploadSales();
  }

  public processPayments() {
    this.openProcessPayments();
  }

  public doSearch() {
    const str = this.salesSearchForm.get('searchStr').value;
    console.log('Str: ' + str);
    this.salesSearch.searchStr = str.split(' ');
    console.log('searchStr: ', this.salesSearch.searchStr);
    this.filter();

    this.salesSearchChange.emit(this.salesSearch);
  }

  public onStateChange() {
    console.log('State changed: ' + this.salesSearchForm.get('state').value);

    this.salesSearch.state = this.salesSearchForm.get('state').value;
    this.filter();

    this.salesSearchChange.emit(this.salesSearch);
  }

  public btnAllSales() {
    console.log('All Sales');
    this.salesSearch.prodType = 0;
    this.filter();

    this.salesSearchChange.emit(this.salesSearch);
  }

  public btnInProgressSales() {
    console.log('In Progress');
    this.salesSearch.prodType = 1;
    this.filter();

    this.salesSearchChange.emit(this.salesSearch);
  }

  public btnCompleteSales() {
    console.log('Complete');
    this.salesSearch.prodType = 2;
    this.filter();

    this.salesSearchChange.emit(this.salesSearch);
  }

  private dateStr(month: number, year: number) {
    return this.messagesService.monthString(month) + ' ' + year;
  }

  public onStartDateDown() {
    console.log('Start Date Down: ' + this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear));
    if (this.salesSearch.startDateMonth === 1) {
      this.salesSearch.startDateMonth = 12;
      this.salesSearch.startDateYear = this.salesSearch.startDateYear - 1;
    } else {
      this.salesSearch.startDateMonth = this.salesSearch.startDateMonth - 1;
    }

    this.salesSearch.startStr = this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear);
    console.log('Start Date Down: ' + this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear));
    this.filter();
    console.log('salesSearch: ' + JSON.stringify(this.salesSearch));

    this.salesSearchChange.emit(this.salesSearch);
  }

  public onStartDateUp() {
    console.log('Start Date Up: ' + this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear));
    if (this.salesSearch.startDateMonth === 12) {
      this.salesSearch.startDateMonth = 1;
      this.salesSearch.startDateYear = this.salesSearch.startDateYear + 1;
    } else {
      this.salesSearch.startDateMonth = this.salesSearch.startDateMonth + 1;
    }

    this.salesSearch.startStr = this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear);
    console.log('Start Date Up: ' + this.dateStr(this.salesSearch.startDateMonth, this.salesSearch.startDateYear));
    this.filter();
    console.log('salesSearch: ' + JSON.stringify(this.salesSearch));

    this.salesSearchChange.emit(this.salesSearch);
  }

  public onEndDateDown() {
    console.log('End Date Down: ' + this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear));
    if (this.salesSearch.endDateMonth === 1) {
      this.salesSearch.endDateMonth = 12;
      this.salesSearch.endDateYear = this.salesSearch.endDateYear - 1;
    } else {
      this.salesSearch.endDateMonth = this.salesSearch.endDateMonth - 1;
    }

    this.salesSearch.endStr = this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear);
    console.log('End Date Down: ' + this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear));
    this.filter();
    console.log('salesSearch: ' + JSON.stringify(this.salesSearch));

    this.salesSearchChange.emit(this.salesSearch);
  }

  public onEndDateUp() {
    console.log('End Date Up: ' + this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear));

    if (this.salesSearch.endDateMonth === 12) {
      this.salesSearch.endDateMonth = 1;
      this.salesSearch.endDateYear = this.salesSearch.endDateYear + 1;
    } else {
      this.salesSearch.endDateMonth = this.salesSearch.endDateMonth + 1;
    }

    this.salesSearch.endStr = this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear);
    console.log('End Date Up: ' + this.dateStr(this.salesSearch.endDateMonth, this.salesSearch.endDateYear));
    this.filter();
    console.log('salesSearch: ' + JSON.stringify(this.salesSearch));

    this.salesSearchChange.emit(this.salesSearch);
  }

  async onClickDeduction(index: number) {

    // ignore click on expanded deduction
    if (this.displayDeductionDetails && index >= this.expandIndex1 + 1 && index <= this.expandIndex2) {
      console.log('Index clicked: ' + index);
      return;
    }

    if (this.displayDeductionDetails) {
      // Remove previous deduction
      if (this.expandIndex1 < this.expandIndex2) {
        this.sales.splice(this.expandIndex1 + 1, this.expandIndex2 - this.expandIndex1);
      }
    }

    // Clicked on the same deduction - hide details
    if (index === this.expandIndex1) {
      this.expandIndex1 = -1;
      this.expandIndex2 = -1;
      this.displayDeductionDetails = false;
      this.showDeductionType(false);

      return;
    }
    // Adjust the index to indicate we are expanding deductions at the new index location
    if (index > this.expandIndex1) {
      index = index - (this.expandIndex2 - this.expandIndex1);
    }

    const sale = this.sales[index];

    if (sale.deductions == null) {
      console.log('Get deductions');
      console.log('payment: ');
      console.log(sale);
      sale.deductions = await this.getSalesDeductionsIndividual(sale.id);
    }
    this.deductions = sale.deductions;

    console.log('Show deductions:');
    console.log(sale);
    console.log(this.deductions);

    // Show DeductionType column
    this.displayDeductionDetails = true;
    this.showDeductionType(true);

    for (let i = 0; i < this.deductions.length; i++) {
      const newSale: Sale = new Sale();
      newSale.deductionType = this.deductions[i].deductionType.name;
      newSale.totalDeductions = this.deductions[i].amount;
      this.sales.splice(index + i + 1, 0, newSale);
    }
    this.applyDataBinding(this.sales);

    this.expandIndex1 = index;
    this.expandIndex2 = index + this.deductions.length;
  }

  public onBack() {
    console.log('Back clicked');
    this.location.back();
  }

  public exportReport(reportType) {
    this.salesService.getReport(this.sales, reportType).subscribe((data) => {
      const blob = new Blob([data as BlobPart], { type: 'application/octet-stream' });
      let extension = '';
      if (reportType === 1) {
        extension = '.pdf';
      } else if (reportType === 2) {
        extension = '.xlsx';
      } else if (reportType === 3) {
        extension = '.csv';
      }
      FileSaver.saveAs(blob, `sales${extension}`);
    });
  }

  public workbookInit(args) {
    console.log(`Workbook Init`, { args });

    this.spread = args.spread;
    this.sheet = this.spread.getActiveSheet();

    this.sheetWidth = this.sheet.getViewportWidth(1);
    console.log('SheetWidth: ', this.sheetWidth);
  }
}
