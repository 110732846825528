import { Purchaser } from './purchaser';
import { County } from './county';
import { KeyValue } from './key_value';
import { Well } from './well';

export class Property {
  id: number;
  bcCurrent: boolean;
  bcTransId: string;
  bcBlockId: string;
  propertyNo: string;
  name: string;
  description: string;
  county: County;
  effectiveFrom: string;
  effectiveTo: string;
  purchaser: Purchaser;
  keyValues: KeyValue[];
  wells: Well[];

  wellCount: number;
  stateName?: string;
  countyName?: string;
  operatorName?: string;
  purchaserName?: string;
  productName?: string;
  wellType?: string;
  wellApiNo?: string;
  wellName?: string;

  insertBy: number;
  insertDate: Date;
  modifiedBy: number;
  modifiedDate: Date;
}
