<div class="row pt-5">
  <div class="col-md-4 mx-auto">
    <div class="card rounded-0">
      <div class="card-header">
        <h3 class="mb-0 float-left">Login</h3>
        <img
          src="./assets/images/b4e_logo_type.png"
          alt=""
          height="50px"
          class="float-right"
        />
      </div>
      <div class="card-body"></div>
      <form class="form p-3">
        <div class="form-group">
          <label for="">Email</label>
          <input
            [(ngModel)]="loginUserData.email"
            name="email"
            type="text"
            class="form-control rounded-1"
            required
          />
        </div>
        <div class="form-group">
          <label for="">Password</label>
          <input
            [(ngModel)]="loginUserData.password"
            name="password"
            type="password"
            class="form-control rounded-1"
            required
          />
        </div>
        <div class="form-group">
          <label
            id="message"
            style="color: red; width:100%; text-align: right"
            >{{ errorMessage }}</label
          >
        </div>
        <button
          (click)="loginUser()"
          type="button"
          class="btn btn-success float-right"
        >
          Login
        </button>
        <a
          class="nav-link"
          routerLink="/register"
          routerLinkActive="active"
          style="visibility: hidden"
          >Register</a
        >
      </form>
    </div>
  </div>
</div>
