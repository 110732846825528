import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var PropertiesComponent = /** @class */ (function () {
    function PropertiesComponent(route) {
        this.route = route;
        this.displayAddProperty = false;
        this.displayUploadProperty = false;
        this.displayBlockchainErrorDialog = false;
        this.displayWaitingDialog = false;
    }
    PropertiesComponent.prototype.ngOnInit = function () {
        this.action = this.route.snapshot.paramMap.get('action');
        if (this.action === null) {
            this.action = '';
        }
        else if (this.action.toUpperCase() === 'ADD') {
            this.addProperty();
        }
        else if (this.action.toUpperCase() === 'UPLOAD') {
            this.uploadProperty();
        }
        // Alternate way of getting action of url
        // - requires injecting "private router: Router" into the constructor
        // console.log('Router Url: ' + this.router.parseUrl(this.router.url));
        // const url = this.router.url;
        // const index = url.lastIndexOf('/');
        // this.action = '';
        // if (index > 0) {
        //   this.action = url.substr(index + 1);
        //   console.log('Action: ' + this.action);
        // }
        // The following will cause the code inside the function to be repeated every 5000 milli-seconds
        setInterval(function (a) {
            // alert('yes...');
        }, 5000, []);
    };
    PropertiesComponent.prototype.addProperty = function () {
        this.displayAddProperty = true;
    };
    PropertiesComponent.prototype.uploadProperty = function () {
        this.displayUploadProperty = true;
    };
    PropertiesComponent.prototype.closeBCDialog = function () {
        this.displayBlockchainErrorDialog = false;
    };
    PropertiesComponent.prototype.closeWaitingDialog = function () {
        this.displayWaitingDialog = false;
    };
    return PropertiesComponent;
}());
export { PropertiesComponent };
