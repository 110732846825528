import { OnInit, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var UserComponent = /** @class */ (function () {
    function UserComponent(route) {
        this.route = route;
        this.displayChangePassword = false;
        this.displayChangePasswordChange = new EventEmitter();
    }
    UserComponent.prototype.ngOnInit = function () {
        this.action = this.route.snapshot.paramMap.get('action');
        if (this.action === null) {
            this.action = '';
        }
        else if (this.action.toUpperCase() === 'CHANGEPASSWORD') {
            this.changePassword();
        }
    };
    UserComponent.prototype.changePassword = function () {
        this.displayChangePassword = true;
        this.displayChangePasswordChange.emit(true);
    };
    return UserComponent;
}());
export { UserComponent };
