import { Operator } from './operator';
import { Property } from './property';
import { Product } from './product';

export class Well {
  id: number;
  apiNo: string;
  name: string;
  operator: Operator;
  property: Property;
  products: Product[];
}
