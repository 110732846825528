import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ChangePassword } from 'src/app/model/changePassword';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly userUrl = `${environment.apiUrl}/user`;

  constructor(private http: HttpClient) { }

  // POST
  public changePassword(changePassword: ChangePassword) {

    const url = this.userUrl + '/changePassword';
    console.log('URL: ' + url);
    // console.log('changePassword: ', { changePassword });
    return this.http.post(url, changePassword);
  }


}


