import { Component, OnInit } from '@angular/core';
import { LookupService } from 'src/app/lookup.service';
import { GenericSearchResult } from 'src/app/model/generic_search_result';
import {User} from "../../model/user";

declare var $: any; // jquery

@Component({
  selector: 'app-dashboard-search',
  templateUrl: './dashboard-search.component.html',
  styleUrls: ['./dashboard-search.component.css']
})
export class DashboardSearchComponent implements OnInit {

  genericSearchResult: GenericSearchResult;

  constructor(
    private lookupService: LookupService
  ) { }

  ngOnInit() {
    $(document).ready(() => {
      // Search Input Keypup
      $('#dashboard_search_input').keyup((e) => {
        this.checkSearchBox();
      });
    });
  }

  checkSearchBox() {
    const user: User = JSON.parse(localStorage.getItem('user'));
   const operatorId = user.operator.id;
    const searchboxQuery = $('#dashboard_search_input').val();

    console.log('Search Query: ' + searchboxQuery);


    if (searchboxQuery !== '') {
      this.lookupService.getGenericSearchResult(operatorId, searchboxQuery)
      .subscribe(results => {
        console.log('Search Results: ', { results });
        this.genericSearchResult = results as GenericSearchResult;
      });

      // Show the Results Container
      $('#search_form_results').show();

    } else {
      // Hide the Results Container
      $('#search_form_results').hide();
    }
  }
}
