var Owner = /** @class */ (function () {
    function Owner(owner) {
        if (owner) {
            this.id = owner.id;
            this.fname = owner.fname;
            this.lname = owner.lname;
            this.taxNo = owner.taxNo;
            this.addr1 = owner.addr1;
            this.addr2 = owner.addr2;
            this.city = owner.city;
            this.stateCode = owner.stateCode;
            this.zipCode = owner.zipCode;
            this.tel = owner.tel;
            this.fax = owner.fax;
            this.cell = owner.cell;
            this.email = owner.email;
        }
    }
    Owner.prototype.getAddress = function () {
        // console.log('Owner: ', this);
        var addr = '';
        if (this.addr1) {
            addr += this.addr1 + ', ';
        }
        if (this.addr2) {
            addr += this.addr2 + ', ';
        }
        if (this.city) {
            addr += this.city + ', ';
        }
        if (this.stateCode) {
            addr += this.stateCode + ' ';
        }
        if (this.zipCode) {
            addr += this.zipCode;
        }
        return addr;
    };
    return Owner;
}());
export { Owner };
