import { OnInit } from '@angular/core';
var TickerComponent = /** @class */ (function () {
    function TickerComponent() {
    }
    TickerComponent.prototype.ngOnInit = function () {
        $(document).ready(function () {
            $('#btn_ticker_toggle').click(function () {
                $('#ticker_drawer').slideToggle();
                $(this).toggleClass('selected');
            });
        });
    };
    return TickerComponent;
}());
export { TickerComponent };
