import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var $: any; // jquery

@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.css']
})

export class ProductionComponent implements OnInit {

  action: string;
  displayAddProduction = false;
  displayUploadProduction = false;

  constructor(
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.action = this.route.snapshot.paramMap.get('action');
    // alert('Action: ' + this.action);

    if (this.action === null) {
      this.action = '';
    } else if (this.action.toUpperCase() === 'ADD') {
      this.addProduction();
    } else if (this.action.toUpperCase() === 'UPLOAD') {
      this.uploadProduction();
    }

  }

  public addProduction() {
    this.displayAddProduction = true;
  }

  public uploadProduction() {
    this.displayUploadProduction = true;
  }

}
