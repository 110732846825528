import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HostListener } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';
import * as Excel from '@grapecity/spread-excelio';

@Component({
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {
  private myUserId: number;
  hostStyle = {
    width: '100%',
    height: '100%'
  };

  showDrag = true;
  validData = false;

  toastDismissed: Date = null;
  public saveEnabled = false;
  public saveComplete = false;
  public isStress = false;
  private sheet: GC.Spread.Sheets.Worksheet = null;
  private spread: GC.Spread.Sheets.Workbook;
  public running = false;
  public spreadBackColor = 'aliceblue';

  constructor(private router: Router) { }

  workbookInit(args) {
    this.spread = args.spread;
  }

  setIsSaveenabled(val) {
    this.saveEnabled = val;
  }

  setSaveComplete(val) {
    this.saveComplete = val;
    if (this.saveComplete) {
      this.setIsSaveenabled(!this.saveComplete);
      this.showToast('Success', 'Scenario Stored', 'The results of your scenario(\'s) have been stored in the system');
    }
  }

  ngOnInit() { }

  showToast(type: string, title: string, text: string) {
    const body = `<p class="text-light">${text.replace(/\n/g, '<br>')}</p>
      <div class="replace-container">

      </div>`;

    const toast = {
      type,
      title,
      text,
      body,
      showCloseButton: true
    };
  }

  columnWidth = 100;
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    this.showDrag = true;
    if (files.length > 0) {
    }
  }
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.showDrag = false;
  }

  @HostListener('drop', ['$event']) public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const files = evt.dataTransfer.files;
    this.showDrag = false;
    const spread = this.spread;

    const excelIO = new Excel.IO();
    console.log(excelIO);
    excelIO.open(
      files[0],
      function (json) {
        const workbookObj = json;
        console.log('Workbook', workbookObj);
        const workbook = new GC.Spread.Sheets.Workbook();
        workbook.fromJSON(workbookObj);
        for (let i = 0; i < workbook.getSheetCount(); i++) {
          const sheet = new GC.Spread.Sheets.Worksheet('test');
          sheet.fromJSON(workbook.getSheet(i).toJSON());
          spread.addSheet(spread.getSheetCount(), sheet);
        }
      },
      function (e) {
        console.log(e);
      }
    );
  }
}
