<div id="ticker">
  <div class="page_container">
    <div id="ticker_inner">
      <div id="ticker_main">
        <div class="ticker_container">
          <div class="ticker_data ticker_switch">
            <div class="form_row auto_width no_padding">
              <div class="form_label hidden_label">
                <label for="blend_switcher">Select Blend</label>
              </div>
              <div class="form_input">
                <select
                  class="inputbox no_border reversed no_padding"
                  id="blend_switcher"
                  name="blend_switcher"
                >
                  <option value="" selected="">U.S. Blends</option>
                  <option value="">OPEC Blends</option>
                  <option value="">Canadian Blends</option>
                </select>
              </div>
            </div>
          </div>
          <div class="ticker_data ticker_futures_index">
            <div class="form_row auto_width no_padding">
              <div class="form_label hidden_label">
                <label for="oil_brand_switcher">Select Oil Brand</label>
              </div>
              <div class="form_input">
                <select
                  class="inputbox no_border reversed no_padding"
                  id="oil_brand_switcher"
                  name="oil_brand_switcher"
                >
                  <option value="">Louisiana Light</option>
                  <option value="">Domestic SWT @ Cushing</option>
                  <option value="">Giddings</option>
                  <option value="">ANS West Coast</option>
                  <option value="">West Texas Sour</option>
                  <option value="">Eagle Ford</option>
                  <option value="">Oklahoma Sweet</option>
                  <option value="">Kansas Common</option>
                  <option value="">buena Vista</option>
                </select>
              </div>
            </div>
          </div>
          <div class="ticker_data ticker_last">41.33</div>
          <div class="ticker_data ticker_change">
            <span class="change_value up">+0.81</span>
          </div>
          <div class="ticker_data ticker_change_pct">
            <span class="change_value up">+2.00%</span>
          </div>
          <div class="ticker_data ticker_last_updated">1 day ago</div>
        </div>
      </div>
      <div id="ticker_drawer" style="display: none">content here</div>
      <div id="ticker_toggle">
        <button
          type="button"
          class="btn_normal btn_icon_only"
          id="btn_ticker_toggle"
        >
          <span class="btn_icon"><span class="icon_chevron_down"></span></span>
          <span class="btn_text">Toggle Drawer</span>
        </button>
      </div>
    </div>
  </div>
</div>
