import { Operator } from './operator';

export class User {
  id: number;
  fname: string;
  lname: string;
  tel: string;
  email: string;
  password: string;
  entityId: number;
  roleId: number;
  operator: Operator;
  status: number;
  verificationCode: string;
  insertDate: Date;
  insertBy: number;
  modifiedDate: Date;
  modifiedBy: number;
  newPassword: string;
}
