import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';
import * as FileSaver from 'file-saver';
import '@grapecity/spread-sheets-print';
import '@grapecity/spread-sheets-pdf';
import { FormBuilder } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Well } from 'src/app/model/well';
import { PropertySearch } from 'src/app/model/property_search';
import { PropertyService } from 'src/app/property.service';
import { LookupService } from 'src/app/lookup.service';
import { MessagesService } from 'src/app/messages.service';
import { DatePickerCellType } from 'src/app/shared/spread/DatePickerCellType';
var clone = function (obj) { return JSON.parse(JSON.stringify(obj)); };
var ɵ0 = clone;
var PropertiesContentComponent = /** @class */ (function () {
    function PropertiesContentComponent(fb, propertyService, lookupService, messagesService, datePipe, location, router
    // ,private changeDetectorRef: ChangeDetectorRef
    ) {
        this.fb = fb;
        this.propertyService = propertyService;
        this.lookupService = lookupService;
        this.messagesService = messagesService;
        this.datePipe = datePipe;
        this.location = location;
        this.router = router;
        this.displayAddPropertyChange = new EventEmitter();
        this.displayUploadPropertyChange = new EventEmitter();
        this.displayBlockchainErrorDialogChange = new EventEmitter();
        this.displayWaitingDialogChange = new EventEmitter();
        this.propertyForm = this.fb.group({
            searchStr: [''],
            state: ['']
        });
        this.blockchainExplorerUrl = "" + environment.blockchainExplorerUrl;
        this.sheet = null;
        this.msg = this.messagesService.messages;
        this.tabStripVisible = false;
        this.spreadBackColor = 'white';
        this.sheetName = 'Property List';
        this.hostStyle = {
            height: '100%',
            width: '100%'
        };
        this.autoGenerateColumns = false;
        // Define column indexes
        this.propertyIdColumn = 0;
        this.propertyNoColumn = 1;
        this.propertyNameColumn = 2;
        this.stateColumn = 3;
        this.countyColumn = 4;
        this.propertyDescColumn = 5;
        this.operatorColumn = 6;
        this.purchaserColumn = 7;
        this.productColumn = 8;
        this.wellApiColumn = 9;
        this.effectiveFromColumn = 10;
        this.effectiveToColumn = 11;
        this.wellNameColumn = 12;
        this.blockChainColumn = 13;
        this.blockChainTransIdColumn = 14;
        this.saveColumn = 15;
        this.gridColCount = 16;
        this.lastUpdated = new Date();
    }
    PropertiesContentComponent.prototype.closeAddProperty = function () {
        this.displayAddProperty = false;
        this.displayAddPropertyChange.emit(false);
    };
    PropertiesContentComponent.prototype.openAddProperty = function () {
        this.displayAddProperty = true;
        this.displayAddPropertyChange.emit(true);
    };
    PropertiesContentComponent.prototype.closeUploadProperty = function () {
        this.displayUploadProperty = false;
        this.displayUploadPropertyChange.emit(false);
    };
    PropertiesContentComponent.prototype.openUploadProperty = function () {
        this.displayUploadProperty = true;
        this.displayUploadPropertyChange.emit(true);
    };
    PropertiesContentComponent.prototype.closeBCDialog = function () {
        this.displayBlockchainErrorDialog = false;
        this.displayBlockchainErrorDialogChange.emit(false);
    };
    PropertiesContentComponent.prototype.openBCDialog = function () {
        this.displayBlockchainErrorDialog = true;
        this.displayBlockchainErrorDialogChange.emit(true);
    };
    PropertiesContentComponent.prototype.closeWaitingDialog = function () {
        this.displayWaitingDialog = false;
        this.displayWaitingDialogChange.emit(false);
    };
    PropertiesContentComponent.prototype.openWaitingDialog = function () {
        this.displayWaitingDialog = true;
        this.displayWaitingDialogChange.emit(true);
    };
    PropertiesContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log('Properties-Content - Init - start');
        Promise.all([
            this.getUSStates(),
            this.getOperators(),
            this.getPurchasers(),
            this.getProducts()
        ]).then(function (values) {
            var user = JSON.parse(localStorage.getItem('user'));
            _this.operator = user.operator;
            _this.getProperties(user.operator.id);
        });
        // initialise Search criteria
        this.propertySearch = new PropertySearch();
        this.propertySearch.propertyType = 0;
        this.propertySearch.searchStr = [];
        this.propertySearch.state = '';
        console.log('Properties-Content - Init - complete');
        this.lastUpdated.setDate(new Date().getDate() - 2);
    };
    PropertiesContentComponent.prototype.getProperties = function (operatorId) {
        var _this = this;
        console.log('getProperties - start: ' + new Date().toISOString());
        this.propertyService.getProperties(operatorId).subscribe(function (properties) {
            console.log('getProperties - back from API: ' + new Date().toISOString());
            console.log({ properties: properties });
            var tempProperties = JSON.parse(JSON.stringify(properties));
            tempProperties = _this.setPropertyValues(tempProperties);
            // Apply entire set of properties to allProperties variable;
            _this.allProperties = tempProperties;
            _this.properties = tempProperties;
            _this.initiliseGrid();
            _this.applyDataBinding(tempProperties);
            console.log('getProperties - complete: ' + new Date().toISOString() + '  No. reocrds: ' + _this.allProperties.length);
        }, function (err) {
            console.log('getProperties:Error');
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    _this.router.navigate(['/login']);
                }
            }
        });
    };
    PropertiesContentComponent.prototype.getUSStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getStates())];
                    case 1:
                        _a.states = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    // This is another way of doing exactly the same thing as the function above
    // Note the use of "then" because the getStates is returning a promise
    // If getStates was returning an observable - replace "then" with "subscribe"
    // getUSStates() {
    //   this.lookupService.getStates().then((states: USState[]) => {
    //     this.states = states;
    //   });
    // }
    PropertiesContentComponent.prototype.getOperators = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, operatorId, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        user = JSON.parse(localStorage.getItem('user'));
                        operatorId = user.operator.id;
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getOperators(operatorId))];
                    case 1:
                        _a.operators = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesContentComponent.prototype.getPurchasers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getPurchasers())];
                    case 1:
                        _a.purchasers = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesContentComponent.prototype.getProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getProducts())];
                    case 1:
                        _a.products = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesContentComponent.prototype.getState = function (name) {
        return this.states.find(function (s) { return s.name === name; });
    };
    PropertiesContentComponent.prototype.getCounty = function (countyName, stateCounties) {
        return stateCounties.find(function (c) { return c.name.trim() === countyName.trim(); });
    };
    PropertiesContentComponent.prototype.getOperator = function (operatorName) {
        return this.operators.find(function (o) { return o.name === operatorName; });
    };
    PropertiesContentComponent.prototype.getPurchaser = function (purchaserName) {
        return this.purchasers.find(function (p) { return p.name === purchaserName; });
    };
    PropertiesContentComponent.prototype.getProduct = function (productName) {
        return this.products.find(function (p) { return p.name.trim() === productName.trim(); });
    };
    PropertiesContentComponent.prototype.formatDate = function (date) {
        // Checks if date is null or undefined (=== strict check for null only)
        if (date == null) {
            return null;
        }
        if (date === '') {
            return null;
        }
        // Format the date to MM/DD/YYYY
        var dateStr = date.substr(5, 2) + '/' + date.substr(8, 2) + '/' + date.substr(0, 4);
        // alert('Date Before: ' + date + '   Date String: ' + dateStr);
        return dateStr;
    };
    PropertiesContentComponent.prototype.formatDateDB = function (date) {
        // Checks if date is null or undefined (=== strict check for null only)
        if (date == null) {
            return null;
        }
        if (date === '') {
            return null;
        }
        // Format the date to YYYY-MM-DD
        var dateStr = date.substr(6, 4) + '-' + date.substr(0, 2) + '-' + date.substr(3, 2);
        // alert('Date Before: ' + date + '   Date String: ' + dateStr);
        return dateStr;
    };
    PropertiesContentComponent.prototype.transformDate = function (date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    };
    PropertiesContentComponent.prototype.setPropertyValue = function (property) {
        property.stateName = property.county ? property.county.USState ? property.county.USState.name : '' : '';
        property.countyName = property.county ? property.county.name : '';
        property.operatorName = this.operator ? this.operator.name : '';
        property.purchaserName = property.purchaser ? property.purchaser.name : '';
        property.wellType = property.wells[0] ? property.wells[0].products[0] ? property.wells[0].products[0].name : null : null;
        property.wellApiNo = property.wells[0] ? property.wells[0].apiNo : null;
        property.wellName = property.wells[0] ? property.wells[0].name : null;
        property.effectiveFrom = this.formatDate(property.effectiveFrom);
        property.effectiveTo = this.formatDate(property.effectiveTo);
        console.log(property);
        return property;
    };
    PropertiesContentComponent.prototype.setPropertyValues = function (filteredProperties) {
        var _this = this;
        // console.log('setPropertyValues - start');
        filteredProperties.forEach(function (property) {
            // console.log('Property: ', { property });
            property.stateName = property.county ? property.county.USState ? property.county.USState.name : '' : '';
            property.countyName = property.county ? property.county.name : '';
            property.operatorName = _this.operator ? _this.operator.name : '';
            property.purchaserName = property.purchaser ? property.purchaser.name : '';
            property.wellType = property.wells[0] ? property.wells[0].products[0] ? property.wells[0].products[0].name : null : null;
            property.wellApiNo = property.wells[0] ? property.wells[0].apiNo : null;
            property.wellName = property.wells[0] ? property.wells[0].name : null;
            property.effectiveFrom = _this.formatDate(property.effectiveFrom);
            property.effectiveTo = _this.formatDate(property.effectiveTo);
        });
        // console.log('setPropertyValues - end');
        return filteredProperties;
    };
    PropertiesContentComponent.prototype.addDataValidatorNotBlank = function (sheet, col, row, errorMsg) {
        // get the corresponding column letter
        var colLetter = String.fromCharCode(65 + col);
        var notBlankValidator = GC.Spread.Sheets.DataValidation.createFormulaValidator("NOT(ISBLANK(" + colLetter + (row + 1) + "))");
        notBlankValidator.ignoreBlank(false);
        // notBlankValidator.showInputMessage(true);
        notBlankValidator.showErrorMessage(true);
        // notBlankValidator.inputMessage(errorMsg);
        notBlankValidator.errorMessage(errorMsg);
        notBlankValidator.inputTitle('Tip');
        sheet.setDataValidator(row, col, 1, 1, notBlankValidator);
    };
    PropertiesContentComponent.prototype.addValidators = function (row) {
        this.addDataValidatorNotBlank(this.sheet, this.propertyNoColumn, row, this.msg.property.propertyNumber);
        this.addDataValidatorNotBlank(this.sheet, this.propertyNameColumn, row, this.msg.property.propertyName);
        this.addDataValidatorNotBlank(this.sheet, this.stateColumn, row, this.msg.property.propertyState);
        this.addDataValidatorNotBlank(this.sheet, this.countyColumn, row, this.msg.property.propertyCounty);
        this.addDataValidatorNotBlank(this.sheet, this.propertyDescColumn, row, this.msg.property.propertyDecription);
        this.addDataValidatorNotBlank(this.sheet, this.operatorColumn, row, this.msg.property.propertyOperator);
        // this.addDataValidatorNotBlank(this.sheet, this.purchaserColumn, row, this.msg.property.propertyPurchaser);
        // this.addDataValidatorNotBlank(this.sheet, this.productColumn, row, this.msg.property.propertyProduct);
        this.addDataValidatorNotBlank(this.sheet, this.wellApiColumn, row, this.msg.property.propertyWellAPI);
        this.addDataValidatorNotBlank(this.sheet, this.effectiveFromColumn, row, this.msg.property.propertyEffectiveFromDate);
        this.addDataValidatorNotBlank(this.sheet, this.wellNameColumn, row, this.msg.property.propertyWellName);
    };
    PropertiesContentComponent.prototype.validateProperty = function (row) {
        // console.log('Validate Property - row: ' + row);
        // const val = this.sheet.getCell(row, 1).value();
        // const isValid = this.sheet.isValid(row, 1, val);
        // const validator = this.sheet.getCell(row, 1).validator();
        // if (!isValid) {
        //   alert(`Testing - invalid msg: ${validator.errorMessage()} Row: ${row}`);
        //   return false;
        // } else {
        //   alert('Testing - valid Row: ' + row);
        //   return false;
        // }
        if (!this.sheet.isValid(row, this.propertyDescColumn, this.sheet.getCell(row, this.propertyDescColumn).value())) {
            alert(this.msg.property.propertyDecription);
            return false;
        }
        if (!this.sheet.isValid(row, this.propertyNoColumn, this.sheet.getCell(row, this.propertyNoColumn).value())) {
            alert(this.msg.property.propertyNumber);
            return false;
        }
        if (!this.sheet.isValid(row, this.propertyNameColumn, this.sheet.getCell(row, this.propertyNameColumn).value())) {
            alert(this.msg.property.propertyName);
            return false;
        }
        if (!this.sheet.isValid(row, this.stateColumn, this.sheet.getCell(row, this.stateColumn).value())) {
            alert(this.msg.property.propertyState);
            return false;
        }
        if (!this.sheet.isValid(row, this.countyColumn, this.sheet.getCell(row, this.countyColumn).value())) {
            alert(this.msg.property.propertyCounty);
            return false;
        }
        if (!this.sheet.isValid(row, this.operatorColumn, this.sheet.getCell(row, this.operatorColumn).value())) {
            alert(this.msg.property.propertyOperator);
            return false;
        }
        if (!this.sheet.isValid(row, this.purchaserColumn, this.sheet.getCell(row, this.purchaserColumn).value())) {
            alert(this.msg.property.propertyPurchaser);
            return false;
        }
        if (!this.sheet.isValid(row, this.productColumn, this.sheet.getCell(row, this.productColumn).value())) {
            alert(this.msg.property.propertyProduct);
            return false;
        }
        if (!this.sheet.isValid(row, this.wellApiColumn, this.sheet.getCell(row, this.wellApiColumn).value())) {
            alert(this.msg.property.propertyWellAPI);
            return false;
        }
        if (!this.sheet.isValid(row, this.effectiveFromColumn, this.sheet.getCell(row, this.effectiveFromColumn).value())) {
            alert(this.msg.property.propertyEffectiveFromDate);
            return false;
        }
        if (!this.sheet.isValid(row, this.wellNameColumn, this.sheet.getCell(row, this.wellNameColumn).value())) {
            alert(this.msg.property.propertyWellName);
            return false;
        }
        return true;
    };
    PropertiesContentComponent.prototype.saveProperty = function (origProperty) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var property, state, well, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        property = clone(origProperty);
                        console.log('Cloned Property: ', { property: property });
                        state = null;
                        if (!(property.stateName != null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, (this.getState(property.stateName))];
                    case 1:
                        state = (_a.sent());
                        _a.label = 2;
                    case 2:
                        if (property.countyName != null) {
                            property.county = this.getCounty(property.countyName, state.counties);
                        }
                        if (property.operatorName != null) {
                            property.operator = this.getOperator(property.operatorName);
                        }
                        if (property.purchaserName != null) {
                            property.purchaser = this.getPurchaser(property.purchaserName);
                        }
                        // Assign Well fields into the well object
                        if (property.id == null || property.id === 0) {
                            property.id = 0;
                            well = new Well();
                            well.id = 0;
                            well.apiNo = property.wellApiNo;
                            well.name = property.wellName;
                            well.operator = property.operator;
                            if (property.wellType != null) {
                                well.products = new Array();
                                well.products.push(this.getProduct(property.wellType));
                            }
                            property.wells = [];
                            property.wells.push(well);
                        }
                        else {
                            if (typeof property.wells === 'undefined' || property.wells.length === 0) {
                                property.wells.push(new Well());
                            }
                            property.wells[0].apiNo = property.wellApiNo;
                            property.wells[0].name = property.wellName;
                            // Well operator gets the same operator as the property
                            property.wells[0].operator = property.operator;
                            if (property.wellType != null) {
                                property.wells[0].type = this.getProduct(property.wellType);
                            }
                        }
                        // Format dates to save to the database
                        property.effectiveFrom = this.formatDateDB(property.effectiveFrom);
                        property.effectiveTo = this.formatDateDB(property.effectiveTo);
                        console.log('Save Cloned Property: ', { property: property });
                        // Null the insertDate/modifiedDate because they get lost in translation and caused Type-mismatch
                        property.insertDate = null;
                        property.modifiedDate = null;
                        return [4 /*yield*/, (this.propertyService.add(property))];
                    case 3:
                        result = (_a.sent());
                        if (!result.bcCurrent) {
                            // Display blockchain error dialog
                        }
                        // Assign the other root display fields
                        result = this.setPropertyValue(result);
                        return [2 /*return*/, result];
                }
            });
        });
    };
    PropertiesContentComponent.prototype.changeSaveButtonColor = function (color) {
        var saveBtnCellType = new GC.Spread.Sheets.CellTypes.Button();
        // saveBtnCellType.buttonBackColor('#CFCFCF');
        saveBtnCellType.buttonBackColor(color);
        saveBtnCellType.text('Save');
        saveBtnCellType.marginBottom(1);
        saveBtnCellType.marginTop(1);
        saveBtnCellType.marginLeft(10);
        saveBtnCellType.marginRight(10);
        return saveBtnCellType;
    };
    PropertiesContentComponent.prototype.createGridEvents = function () {
        var _this = this;
        // Bind click-event of Property Id and to Blockchain cells
        this.spread.bind(GC.Spread.Sheets.Events.CellClick, function (e, args) {
            var row = args.row;
            var col = args.col;
            // let cell = this.sheet.getCell(row, col);
            // const cellValue = this.sheet.getCell(row, col).value;
            // const cellValue2 = this.sheet.getValue(row, col);
            // Get selected property form array
            var selectedProperty = _this.properties[row];
            console.log("[Row: " + row + "] Property with Id " + selectedProperty.id);
            if (col === _this.blockChainColumn || col === _this.blockChainTransIdColumn) {
                var bcTransId = _this.sheet.getValue(row, _this.blockChainTransIdColumn);
                if (bcTransId != null && bcTransId !== '') {
                    var url = _this.blockchainExplorerUrl + "PROPERTY_" + ("0000000000" + selectedProperty.id).slice(-10);
                    // if (selectedProperty.bcBlockId != null) {
                    //   url = this.blockchainExplorerUrl + selectedProperty.bcBlockId;
                    // } else {
                    //   url = this.blockchainExplorerUrl;
                    // }
                    window.open(url);
                }
            }
            else if (col < _this.stateColumn) {
                // alert('/propertyDetail/' + foundProperty.id);
                _this.router.navigateByUrl('/propertyDetail/' + selectedProperty.id);
            }
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.EnterCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColCount - 1; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainColumn) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridHighlight);
                    }
                }
            }
            // console.log('Row Enter Cell: ' + row);
            _this.sheet.resumePaint();
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Un-highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColCount - 1; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainColumn) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridBackground);
                    }
                }
            }
            _this.sheet.getCell(row, -1)
                .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            _this.sheet.getCell(row, -1)
                .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            // console.log('Row Leave Cell: ' + row);
            _this.sheet.resumePaint();
        });
        this.spread.bind(GC.Spread.Sheets.Events.ValueChanged, function (e, args) {
            var row = args.row;
            var col = args.col;
            // Value changed - clear blockchain transId, change the colour of the save button
            _this.properties[row].bcTransId = null;
            // Clear Blockchain graphic to the cell
            var cell = _this.sheet.getCell(row, _this.blockChainColumn);
            cell.text('');
            cell.backgroundImage(null);
            // Cell has changed - enable save button and change its colour
            _this.sheet.getRange(row, _this.saveColumn, 1, 1).locked(false);
            var saveSingleBtnCellType = _this.changeSaveButtonColor(environment.buttonEnabled);
            _this.sheet.getCell(row, _this.saveColumn).cellType(saveSingleBtnCellType);
            _this.sheet.getCell(row, _this.saveColumn).foreColor(environment.buttonEnabledText);
            // Change the back-ground color to reflect a change in grid data
            _this.sheet.getCell(row, col).backColor(environment.gridCellChanged);
            // If State changed - Add county dropdowns
            if (col === _this.stateColumn) {
                var countyComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
                var stateName = _this.properties[row].stateName;
                var countyStrArr_1 = [];
                var state = _this.getState(stateName);
                state.counties.forEach(function (county) {
                    countyStrArr_1.push(county.name);
                });
                console.log('State Changed: ', { state: state });
                console.log('Counties: ', countyStrArr_1);
                countyComboBoxType.items(countyStrArr_1).editable(true);
                countyComboBoxType.maxDropDownItems(10);
                _this.sheet.getCell(row, _this.countyColumn).cellType(countyComboBoxType);
                _this.properties[row].countyName = '';
            }
            // It is a complete mystery why this works
            // Problem: When date picker selects a date in Spread JS, in the "DataPickerCellType.getEditorValue" we set the return type to
            // America date format - this displays the date correctly in spread JS
            // Unfortunately, this binds a OADate object to the corresponding cell in filteredProperties
            // Doing the following (if statements) - causes the corresponding cell in filteredProperties to get date as a string in America
            // format which we want so that the API works the same here as it does in the propertyAdd screen
            // Mystery: why this "data" object which is not assigned or bound anywhere else fixes the problem eventhough it is never used
            if (col === 11) {
                _this.data[row]['effectiveFrom'] = '' + _this.data[row]['effectiveFrom'];
                console.log('Effective From: ', _this.data[row]['effectiveFrom']);
            }
            if (col === 12) {
                _this.data[row]['effectiveTo'] = '' + _this.data[row]['effectiveTo'];
                console.log('Effective To: ', _this.data[row]['effectiveTo']);
            }
        });
        // Define save button action
        this.spread.bind(GC.Spread.Sheets.Events.ButtonClicked, function (e, args) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var newProperty, row, col, cell, cellType, property, result, imgBlockChain, lastRow, i, colIndex, saveBtnCellType2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newProperty = false;
                        row = args.row;
                        col = args.col;
                        cell = this.sheet.getCell(row, col);
                        cellType = this.sheet.getCellType(row, col);
                        if (!(cellType instanceof GC.Spread.Sheets.CellTypes.Button)) return [3 /*break*/, 2];
                        this.spread.options.highlightInvalidData = true;
                        // Add validators to last row
                        if (row === this.sheet.getRowCount() - 1) {
                            console.log('New property');
                            this.addValidators(row);
                            newProperty = true;
                        }
                        property = this.properties[row];
                        console.log('Property (b4 validation): ', { property: property });
                        if (!this.validateProperty(row)) return [3 /*break*/, 2];
                        console.log('Valid property: ', { property: property });
                        // Display the waitingdialog
                        console.log('Waiting Dialog: ' + this.displayWaitingDialog);
                        // this.openWaitingDialog();
                        // this.changeDetectorRef.detectChanges();
                        console.log('Waiting Dialog: ' + this.displayWaitingDialog);
                        return [4 /*yield*/, (this.saveProperty(property))];
                    case 1:
                        result = _a.sent();
                        console.log('Save result: ', { result: result });
                        // Get the first cell of the row - assign the property.id to the cell and also to the this.properties
                        cell = this.sheet.getCell(row, 0);
                        cell.text(result.id.toString());
                        console.log('PropertyId: ' + result.id.toString());
                        if (result.bcCurrent) {
                            // Assign bcTransId to grid also
                            cell = this.sheet.getCell(row, this.blockChainTransIdColumn);
                            cell.text(result.bcTransId);
                            console.log('bcTransId: ' + result.bcTransId);
                            // Assign Blockchain graphic to the cell
                            cell = this.sheet.getCell(row, this.blockChainColumn);
                            imgBlockChain = './assets/images/blockchain_cell@2x.png';
                            cell.text('');
                            cell.backgroundImage(imgBlockChain);
                        }
                        else {
                            // Hide the waiting dialog before displaying blockchain dialog
                            // this.closeWaitingDialog();
                            // this.openBCDialog();
                        }
                        // Assign the saved property to Global Grid Array
                        this.properties[row] = result;
                        if (newProperty) {
                            console.log('Result - new: ', { result: result });
                            // Assign new property to the end of the global array with all properties
                            this.allProperties[this.allProperties.length - 1] = result;
                            lastRow = this.sheet.getRowCount();
                            this.sheet.addRows(lastRow, 1);
                        }
                        else {
                            console.log('Result - not new: ', { result: result });
                            // Assign the saved property to the global instance of all properties
                            for (i = 0; i < this.allProperties.length; i++) {
                                if (this.allProperties[i].id === result.id) {
                                    this.allProperties[i] = result;
                                }
                            }
                        }
                        console.log('Properties: ', this.properties);
                        console.log('All Properties: ', this.allProperties);
                        // Change the color of all the cells back to highlighted
                        for (colIndex = 0; colIndex < this.gridColCount - 1; colIndex++) {
                            this.sheet.getCell(row, colIndex).backColor(environment.gridHighlight);
                        }
                        console.log('Change cells back to highlighted');
                        saveBtnCellType2 = this.changeSaveButtonColor(environment.buttonDisabled);
                        this.sheet.getCell(row, this.saveColumn).cellType(saveBtnCellType2);
                        this.sheet.getCell(row, this.saveColumn).foreColor(environment.buttonDisabledText);
                        console.log('Make button grey - disabled');
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        }); });
    };
    PropertiesContentComponent.prototype.initiliseGrid = function () {
        // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
        // And grid events that are NOT data specific
        console.log('Initialise Grid');
        this.sheet.suspendPaint();
        // Add State dropdowns
        var stateComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
        var stateStrArr = [];
        this.states.forEach(function (state) {
            stateStrArr.push(state.name);
        });
        stateComboBoxType.items(stateStrArr).editable(true);
        stateComboBoxType.maxDropDownItems(10);
        this.sheet.getCell(-1, this.stateColumn).cellType(stateComboBoxType);
        // Add Operator dropdowns
        var operatorComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
        var operatorStrArr = [];
        this.operators.forEach(function (operator) {
            operatorStrArr.push(operator.name);
        });
        operatorComboBoxType.items(operatorStrArr).editable(true);
        operatorComboBoxType.maxDropDownItems(10);
        this.sheet.getCell(-1, this.operatorColumn).cellType(operatorComboBoxType);
        // Add Purchaser dropdowns
        var purchaserComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
        var purchaserStrArr = [];
        this.purchasers.forEach(function (purchaser) {
            purchaserStrArr.push(purchaser.name);
        });
        purchaserComboBoxType.items(purchaserStrArr).editable(true);
        purchaserComboBoxType.maxDropDownItems(10);
        this.sheet.getCell(-1, this.purchaserColumn).cellType(purchaserComboBoxType);
        // Add Product dropdowns
        var productComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
        var productStrArr = [];
        this.products.forEach(function (product) {
            productStrArr.push(product.name);
        });
        productComboBoxType.items(productStrArr).editable(true);
        productComboBoxType.maxDropDownItems(10);
        this.sheet.getCell(-1, this.productColumn).cellType(productComboBoxType);
        var datePickerCellType = new DatePickerCellType();
        this.sheet.getCell(-1, this.effectiveFromColumn).cellType(datePickerCellType);
        this.sheet.getCell(-1, this.effectiveToColumn).cellType(datePickerCellType);
        var saveBtnCellType = this.changeSaveButtonColor(environment.buttonDisabled);
        this.sheet.getCell(-1, this.saveColumn).cellType(saveBtnCellType);
        this.sheet.getCell(-1, this.saveColumn).cellPadding('1px');
        this.sheet.getCell(-1, this.saveColumn).vAlign(GC.Spread.Sheets.VerticalAlign.center);
        this.sheet.getCell(-1, this.saveColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.saveColumn).foreColor(environment.buttonDisabledText);
        this.sheet.options.allowCellOverflow = false;
        // this.sheet.getCell(-1, this.saveColumn).font('10pt Source Sans Pro');
        // Define columns
        var propertyIdColInfo = { name: 'id', displayName: 'Id', size: 40 };
        var propertyNoColInfo = { name: 'propertyNo', displayName: 'Property#', size: 100 };
        var propertyNameColInfo = { name: 'name', displayName: 'Name', size: 180 };
        var propertyStateColInfo = { name: 'stateName', displayName: 'State', size: 120 };
        var propertyCountyColInfo = { name: 'countyName', displayName: 'County', size: 120 };
        var propertyDescColInfo = { name: 'description', displayName: 'Description', size: 150 };
        var propertyOperatorColInfo = { name: 'operatorName', displayName: 'Operator', size: 120 };
        var propertyPurchaserColInfo = { name: 'purchaserName', displayName: 'Purchaser', size: 100 };
        var propertyWellTypeColInfo = { name: 'wellType', displayName: 'Type', size: 80 };
        var propertyWellApiNoColInfo = { name: 'wellApiNo', displayName: 'Well Number', size: 130 };
        var propertyEffectiveFromColInfo = { name: 'effectiveFrom', displayName: 'Effective From', size: 100 };
        var propertyEffectiveToColInfo = { name: 'effectiveTo', displayName: 'Effective To', size: 100 };
        var propertyWellNameColInfo = { name: 'wellName', displayName: 'Well Name', size: 120 };
        var propertyBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
        var propertyBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 150 };
        var propertySaveColInfo = { name: '', displayName: '', size: 80 };
        this.sheet.autoGenerateColumns = false;
        this.sheet.setDataSource(this.properties);
        this.sheet.bindColumn(this.propertyIdColumn, propertyIdColInfo);
        this.sheet.bindColumn(this.propertyNoColumn, propertyNoColInfo);
        this.sheet.bindColumn(this.propertyNameColumn, propertyNameColInfo);
        this.sheet.bindColumn(this.stateColumn, propertyStateColInfo);
        this.sheet.bindColumn(this.countyColumn, propertyCountyColInfo);
        this.sheet.bindColumn(this.propertyDescColumn, propertyDescColInfo);
        this.sheet.bindColumn(this.operatorColumn, propertyOperatorColInfo);
        this.sheet.bindColumn(this.purchaserColumn, propertyPurchaserColInfo);
        this.sheet.bindColumn(this.productColumn, propertyWellTypeColInfo);
        this.sheet.bindColumn(this.wellApiColumn, propertyWellApiNoColInfo);
        this.sheet.bindColumn(this.effectiveFromColumn, propertyEffectiveFromColInfo);
        this.sheet.bindColumn(this.effectiveToColumn, propertyEffectiveToColInfo);
        this.sheet.bindColumn(this.wellNameColumn, propertyWellNameColInfo);
        this.sheet.bindColumn(this.blockChainColumn, propertyBCTransImgColInfo);
        this.sheet.bindColumn(this.blockChainTransIdColumn, propertyBCTransIdColInfo);
        this.sheet.bindColumn(this.saveColumn, propertySaveColInfo);
        this.sheet.setColumnCount(this.gridColCount, GC.Spread.Sheets.SheetArea.viewport);
        // Lock/Readonly all columns
        /*    this.sheet.options.isProtected = true;
            this.sheet.options.protectionOptions = {
              allowSelectUnlockedCells: true,
              allowSelectLockedCells: false
            };*/
        // Unlock from column 1 for 13 columns(columns 1 - 13) - [Description - Well Name]
        this.sheet.getRange(-1, 1, -1, 13).locked(false);
        // set cell alignment
        this.sheet.getCell(-1, this.propertyIdColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.effectiveFromColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.effectiveToColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.createGridEvents();
        this.sheet.resumePaint();
    };
    PropertiesContentComponent.prototype.applyDataBinding = function (filteredProperties) {
        // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);
        var _this = this;
        console.log('Start data binding');
        this.sheet.suspendPaint();
        this.sheet.setDataSource(filteredProperties);
        console.log('Row Binding - start');
        filteredProperties.forEach(function (rowPropertiesObject, rowIndex) {
            // console.log('Row Index: ' + rowIndex, rowPropertiesObject);
            // Remove vertical grid-lines
            _this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
            // Add Blockchain graphic to cell
            var cell = _this.sheet.getCell(rowIndex, _this.blockChainColumn);
            var imgBlockChain = null;
            if (filteredProperties[rowIndex].bcTransId != null) {
                imgBlockChain = './assets/images/blockchain_cell@2x.png';
            }
            cell.text('');
            cell.backgroundImage(imgBlockChain);
            // Add county dropdowns - this is dependant on the state selected
            var countyComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
            var stateName = filteredProperties[rowIndex].stateName;
            var countyStrArr = [];
            var state = _this.getState(stateName);
            // If state defined - get the counties
            if (state != null) {
                state.counties.forEach(function (county) {
                    countyStrArr.push(county.name);
                });
            }
            // console.log('Counties: ', stateStr);
            countyComboBoxType.items(countyStrArr);
            countyComboBoxType.maxDropDownItems(10);
            _this.sheet.getCell(rowIndex, _this.countyColumn).cellType(countyComboBoxType);
            // Add validation to spreadsheet
            _this.addValidators(rowIndex);
        });
        console.log('Row Binding - complete');
        // Highlight first row except blockchain graphic cell & set the font in all cells
        for (var col = 0; col < this.gridColCount - 1; col++) {
            if (col !== this.blockChainColumn) {
                this.sheet.getCell(0, col).backColor(environment.gridHighlight);
                // this.sheet.getCell(-1, col).font('10pt Source Sans Pro');
            }
        }
        // Add empty row at end of sheet
        var lastRow = this.sheet.getRowCount();
        this.sheet.addRows(lastRow, 1);
        // Add county dropdown to the last row
        var ctyComboBoxType = new GC.Spread.Sheets.CellTypes.ComboBox();
        var ctyStrArr = [];
        // console.log('Counties: ', stateStr);
        ctyComboBoxType.items(ctyStrArr);
        ctyComboBoxType.maxDropDownItems(10);
        this.sheet.getCell(lastRow, this.countyColumn).cellType(ctyComboBoxType);
        this.sheet.resumePaint();
        console.log('Binding Complete - Last row: ' + lastRow);
    };
    PropertiesContentComponent.prototype.filter = function () {
        var _this = this;
        this.properties = [];
        var tempProperties = [];
        var today = this.transformDate(new Date());
        // console.log('Todays Date: ' + today);
        this.allProperties.forEach(function (propertyObject, rowIndex) {
            // console.log(propertyObject);
            // If propertyObject is null, undefined or empty - ignore
            if (propertyObject != null && Object.keys(propertyObject).length !== 0) {
                var includeProperty_1 = true;
                if (_this.propertySearch.state !== '') {
                    includeProperty_1 = (propertyObject.county.USState.id === Number(_this.propertySearch.state));
                }
                if (includeProperty_1 && _this.propertySearch.searchStr.length > 0) {
                    // Check property No, lease No, well API no, Property Name, Property Desc, State, county and well name
                    // against any part of the search string
                    _this.propertySearch.searchStr.forEach(function (searchSubStr) {
                        includeProperty_1 = includeProperty_1
                            && ((propertyObject.propertyNo.indexOf(searchSubStr) !== -1)
                                || (propertyObject.wellApiNo.indexOf(searchSubStr) !== -1)
                                || (propertyObject.name.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                                || (propertyObject.description.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                                || (propertyObject.stateName.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                                || (propertyObject.countyName.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1)
                                || (propertyObject.wellName.toUpperCase().indexOf(searchSubStr.toUpperCase()) !== -1));
                        // console.log('Well API:', propertyObject.wellApiNo, '    Search Str: ', searchSubStr,
                        //   '   Search result: ', propertyObject.wellApiNo.indexOf(searchSubStr));
                    });
                }
                if (_this.propertySearch.propertyType > 0 && includeProperty_1) {
                    if (_this.propertySearch.propertyType === 1) {
                        // In Progress - effective From date not yet arrived
                        if (propertyObject.effectiveFrom !== null) {
                            includeProperty_1 = (_this.formatDateDB(propertyObject.effectiveFrom) >= today);
                        }
                        else {
                            includeProperty_1 = false;
                        }
                    }
                    else if (_this.propertySearch.propertyType === 3) {
                        // Inactive - EffectiveTo date has passed
                        if (propertyObject.effectiveTo !== null) {
                            includeProperty_1 = (_this.formatDateDB(propertyObject.effectiveTo) < today);
                            console.log('Effective To: ' + _this.formatDateDB(propertyObject.effectiveTo));
                        }
                        else {
                            includeProperty_1 = false;
                            console.log('Exclude: ' + propertyObject.effectiveTo);
                        }
                    }
                    else { // must be active
                        if (propertyObject.effectiveFrom !== null) {
                            if (_this.formatDateDB(propertyObject.effectiveFrom) <= today) {
                                if (propertyObject.effectiveTo !== null) {
                                    includeProperty_1 = (_this.formatDateDB(propertyObject.effectiveTo) > today);
                                }
                                else {
                                    includeProperty_1 = true;
                                }
                            }
                            else {
                                includeProperty_1 = false;
                            }
                        }
                        else {
                            includeProperty_1 = true;
                        }
                    }
                }
                // console.log('IncludeProperty: ' + includeProperty);
                if (includeProperty_1) {
                    // this.properties.push(propertyObject);
                    tempProperties.push(propertyObject);
                }
            }
        });
        this.properties = tslib_1.__spread(tempProperties);
        // console.table(this.properties);
        this.applyDataBinding(this.properties);
    };
    PropertiesContentComponent.prototype.doSearch = function () {
        var str = this.propertyForm.get('searchStr').value;
        this.propertySearch.searchStr = str.split(' ');
        this.filter();
    };
    PropertiesContentComponent.prototype.onStateChange = function () {
        console.log('State changed: ' + this.propertyForm.get('state').value);
        this.propertySearch.state = this.propertyForm.get('state').value;
        this.filter();
    };
    PropertiesContentComponent.prototype.btnAllProperties = function () {
        this.propertySearch.propertyType = 0;
        this.filter();
    };
    PropertiesContentComponent.prototype.btnInProgressProperties = function () {
        this.propertySearch.propertyType = 1;
        this.filter();
    };
    PropertiesContentComponent.prototype.btnActiveProperties = function () {
        this.propertySearch.propertyType = 2;
        this.filter();
    };
    PropertiesContentComponent.prototype.btnInactiveProperties = function () {
        this.propertySearch.propertyType = 3;
        this.filter();
    };
    PropertiesContentComponent.prototype.addProperty = function () {
        this.openAddProperty();
    };
    PropertiesContentComponent.prototype.uploadProperty = function () {
        this.openUploadProperty();
    };
    PropertiesContentComponent.prototype.onBack = function () {
        console.log('Back clicked');
        this.location.back();
    };
    PropertiesContentComponent.prototype.exportReport = function (reportType) {
        this.propertyService.getReport(this.properties, reportType).subscribe(function (data) {
            var blob = new Blob([data], { type: 'application/octet-stream' });
            var extension = '';
            if (reportType === 1) {
                extension = '.pdf';
            }
            else if (reportType === 2) {
                extension = '.xlsx';
            }
            else if (reportType === 3) {
                extension = '.csv';
            }
            FileSaver.saveAs(blob, "properties" + extension);
        });
    };
    PropertiesContentComponent.prototype.workbookInit = function (args) {
        console.log("Workbook Init", { args: args });
        this.spread = args.spread;
        this.sheet = this.spread.getActiveSheet();
        this.sheetWidth = this.sheet.getViewportWidth(1);
        console.log('SheetWidth: ', this.sheetWidth);
    };
    return PropertiesContentComponent;
}());
export { PropertiesContentComponent };
export { ɵ0 };
