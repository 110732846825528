import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
var AuthGuard = /** @class */ (function () {
    // tslint:disable: variable-name
    function AuthGuard(_authService, _router) {
        this._authService = _authService;
        this._router = _router;
    }
    AuthGuard.prototype.canActivate = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this._authService.loggedIn()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._authService.verifyToken()
                                .pipe(map(function (res) {
                                // console.log('Auth.guard.ts - Valid Token - new token: ', res.token);
                                localStorage.setItem('token', res.token);
                                return true;
                            }, function (err) {
                                console.log('Auth.guard.ts - Token verification Error: ', err);
                                _this._router.navigate(['/login']);
                                return false;
                            })).toPromise()
                                .catch(function (err) {
                                console.log('Auth.guard.ts - Token verification Error: ', err);
                                _this._router.navigate(['/login']);
                                return false;
                            })];
                    case 1: 
                    // console.log('Auth guard - verify token');
                    return [2 /*return*/, _a.sent()];
                    case 2:
                        console.log('Auth.guard.ts - no token');
                        this._router.navigate(['/login']);
                        return [2 /*return*/, false];
                }
            });
        });
    };
    return AuthGuard;
}());
export { AuthGuard };
