/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./import.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@grapecity/spread-sheets-angular/dist/gc.spread.sheets.angular.ngfactory";
import * as i3 from "@grapecity/spread-sheets-angular/dist/gc.spread.sheets.angular";
import * as i4 from "./import.component";
import * as i5 from "@angular/router";
var styles_ImportComponent = [i0.styles];
var RenderType_ImportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImportComponent, data: {} });
export { RenderType_ImportComponent as RenderType_ImportComponent };
export function View_ImportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" import works!\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "fill"], ["id", "spread-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "button", [["mat-raised-button", ""]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "gc-spread-sheets", [["id", "balance-sheet-structure"]], null, [[null, "workbookInitialized"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("workbookInitialized" === en)) {
        var pd_0 = (_co.workbookInit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SpreadSheetsComponent_0, i2.RenderType_SpreadSheetsComponent)), i1.ɵdid(7, 4898816, [["spreadDiv", 4]], 1, i3.SpreadSheetsComponent, [i1.ElementRef], { backColor: [0, "backColor"], hostStyle: [1, "hostStyle"] }, { workbookInitialized: "workbookInitialized" }), i1.ɵqud(603979776, 1, { sheets: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.spreadBackColor; var currVal_2 = _co.hostStyle; _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.validData; _ck(_v, 3, 0, currVal_0); }); }
export function View_ImportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, [[null, "dragover"], [null, "dragleave"], [null, "drop"]], function (_v, en, $event) { var ad = true; if (("dragover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onDragOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onDragLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("drop" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onDrop($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, View_ImportComponent_0, RenderType_ImportComponent)), i1.ɵdid(1, 114688, null, 0, i4.ImportComponent, [i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImportComponentNgFactory = i1.ɵccf("ng-component", i4.ImportComponent, View_ImportComponent_Host_0, {}, {}, []);
export { ImportComponentNgFactory as ImportComponentNgFactory };
