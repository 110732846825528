<app-messages></app-messages>
<app-properties-content
  [(displayAddProperty)]="displayAddProperty"
  [(displayUploadProperty)]="displayUploadProperty"
  [(displayBlockchainErrorDialog)]="displayBlockchainErrorDialog"
  [(displayWaitingDialog)]="displayWaitingDialog"
></app-properties-content>
<app-properties-add
  [(displayAddProperty)]="displayAddProperty"
  [(displayBlockchainErrorDialog)]="displayBlockchainErrorDialog"
></app-properties-add>
<app-properties-upload
  [(displayUploadProperty)]="displayUploadProperty"
  [(displayAddProperty)]="displayAddProperty"
></app-properties-upload>

<kendo-dialog *ngIf="displayBlockchainErrorDialog" (close)="closeBCDialog()">
  <!-- Title -->
  <div class="errorDialogTitle"><span class="k-icon k-i-warning"></span> Save Property</div>
  <!-- </kendo-dialog-titlebar> -->
  <p class="errorDialogMsg">Property saved to DB - but failed to write to Blockchain.</p>

  <div class="errorDialogActionButtons">
    <button class="errorDialogButton" (click)="closeBCDialog()">OK</button>
  </div>
</kendo-dialog>

<kendo-dialog *ngIf="displayWaitingDialog">
  <img src="../../assets/images/loader_dark.gif" alt="../../assets/images/loader.gif" />
</kendo-dialog>
