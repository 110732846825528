import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Payment } from './model/payment';
import { Sale } from './model/sale';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private readonly paymentsUrl = `${environment.apiUrl}/payments`;

  constructor(private http: HttpClient) { }

  // API: Get
  getPayments(operatorId: number) {
    const url = `${this.paymentsUrl}/getPayments?operatorId=${operatorId}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getPaymentsByYear(operatorId: number, year: number) {
    const url = `${this.paymentsUrl}/getPayments?operatorId=${operatorId}&year=${year}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // GET
  getPaymentsByPropertyId(operatorId: number, propertyId: number) {
    const url = `${this.paymentsUrl}/getPayments?operatorId=${operatorId}&propertyId=${propertyId}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getPaymentsDeductions(operatorId: number) {
    const url = `${this.paymentsUrl}/getPaymentsDeductions?operatorId=${operatorId}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getPaymentsDeductionsIndividual(
    operatorId: number,
    propertyId: number,
    divOrderId: number,
    salesYear: number,
    salesMonth: number,
    productId: number) {

    // tslint:disable-next-line: max-line-length
    const url = `${this.paymentsUrl}/getPaymentsDeductionsIndividual?operatorId=${operatorId}&propertyId=${propertyId}&divOrderId=${divOrderId}&salesYear=${salesYear}&salesMonth=${salesMonth}&productId=${productId}`;
    console.log('Url: ' + url);

    return this.http.get(url);
  }

  // POST
  public getReport(payments: Payment[], reportType: number) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.paymentsUrl}/paymentsReport/${reportType}`;
    return this.http.post(url, payments, httpOptions);
  }

  public processPayment(sale: Sale, userId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const url = this.paymentsUrl + `/processPaymnt?userId=${userId}`;

    console.log('URL: ' + url);
    console.log('Sale: ', { sale });

    return this.http.post(url, sale, httpOptions).toPromise();
  }

}


