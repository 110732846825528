import { Router } from '@angular/router';
import { User } from './../model/user';
import { Component, OnInit } from '@angular/core';
import { AuthService } from './../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorMessage = '';
  // tslint:disable: variable-name
  loginUserData: User = new User();

  constructor(
    private _auth: AuthService,
    private _router: Router) {
  }

  ngOnInit(): void {
    localStorage.clear();
  }

  loginUser() {
    console.log('Login ...');
    // console.log('Before Login: ', this.loginUserData);

    this._auth.loginUser(this.loginUserData)
      .subscribe(
        res => {
          console.log('Successful login');
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', res.user);

          // Successfully logged in - navigate to the default page
          this._router.navigate(['/dashboard']);
        },
        err => {
          console.log('Login Error: ', err);
          this.errorMessage = 'Invalid login credentials';
        }
      );
  }

}
