
<app-change-password
  [(displayChangePassword)]="displayChangePassword"
></app-change-password>
<div id="header">
  <div class="page_container">
    <div id="header_inner">
      <!-- Header Logo -->
      <div id="header_logo" *ngIf="this.loggedIn('Logo 1')">
        <a routerLink="/dashboard" title="Go to Homepage">
          <span class="header_logo_image"
            ><img src="{{ getCorporateLogo() }}" alt="Corporate Oil Logo"
          /></span>
          <span class="hidden_text">Corporate Oil</span>
        </a>
      </div>
      <div id="header_logo" *ngIf="!this.loggedIn('Logo 2')">
        <a routerLink="/" title="Go to Homepage">
          <span class="header_logo_image"
            ><img src="./assets/images/PipelineDL.png" alt="Corporate Oil Logo"
          /></span>
          <span class="hidden_text">Corporate Oil</span>
        </a>
      </div>

      <!-- Header Menu -->
      <div id="header_nav" *ngIf="this.loggedIn('Header Menu')">
        <ul>
          <li><a routerLink="/properties">Properties</a></li>
          <li><a routerLink="/production">Production</a></li>
          <li><a routerLink="/sales">Sales & Expenses</a></li>
          <li><a routerLink="/payments">Payments</a></li>
        </ul>
      </div>

      <!-- Header Notifications -->
      <div
        id="header_notifications"
        *ngIf="this.loggedIn('Notification')"
        (click)="onNotificationDropdownClicked()"
      >
        <div class="dropdown_container">
          <button
            type="button"
            class="btn_normal btn_icon_only btn_dropdown btn_big"
            id="btn_notifications"
          >
            <span class="btn_icon has_notifications"
              ><span class="icon_notification"></span
            ></span>
            <span class="btn_text">Notifications</span>
          </button>
          <div
            id="btn_notifications_container"
            class="dropdown_content min_width position_right"
            [class.show]="isNotificationDropdownOpen"
          >
            <div class="dropdown_content_body">
              <h3 class="dropdown_title">Notifications</h3>
              <p>Content Placeholder</p>
            </div>
            <div class="dropdown_footer">
              content
            </div>
          </div>
        </div>
      </div>

      <!-- Header Search -->
      <div id="header_search" *ngIf="this.loggedIn('Search')">
        <button
          type="button"
          class="btn_normal btn_icon_only btn_big"
          id="btn_header_search"
        >
          <span class="btn_icon"><span class="icon_search"></span></span>
          <span class="btn_text">Search</span>
        </button>
      </div>

      <!-- Header User -->
      <div
        id="header_user"
        *ngIf="this.loggedIn('User')"
        (click)="onLogoutDropdownClicked()"
      >
        <div class="dropdown_container">
          <button
            type="button"
            class="btn_normal btn_dropdown btn_big"
            id="btn_user"
          >
            <span class="btn_text">{{ getDisplayName() }}</span>
            <span class="btn_helper">
              <span class="icon_chevron_down"></span
            ></span>
          </button>
          <div
            id="btn_user_container"
            class="dropdown_content min_width position_right"
            [class.show]="isUserDropdownOpen"
          >
            <div class="dropdown_content_body">
               <button type="button" class="btn_normal"(click)="changePassword()">
                Change Password
              </button>

              <br>
              <button type="button" class="btn_normal" (click)="logout()">
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<script src="./assets/js/common.js"></script>
