<!-- @dialog Modal for Uploading a new Owners -->
<div id="dialog_upload_owner" title="Upload Owners" style="display:none;" role="dialog">
  <div class="dialog_header">
    <h1>Upload Owners</h1>
    <div class="dialog_close">
      <button type="button" class="close_modal btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text hidden_text">Close Dialog or press Escape to dismiss</span>
      </button>
    </div>
  </div>
  <div class="dialog_content">
    <div class="dialog_inner_content">
      <div class="form_upload_container">
        <form action="#form_submitted" method="post" enctype="multipart/form-data">
          <fieldset>
            <div class="form_row no_padding">
              <div class="form_label">
                <label for="fileToUpload" class="upload_label">
                  <span class="upload_icon">
                    <span class="icon_upload_cloud"></span>
                    <h1>Drag and drop here to import</h1>
                    <p>Or browse for your file, if you prefer...</p>
                    <p><span class="btn_normal btn_blue" type="button">Browse...</span></p>
                  </span>
                  <div id="form_file_selected" style="text-align: center; line-height: 40px;">No Files Selected</div>
                </label>
              </div>
              <div class="form_input hidden">
                <input type="file" name="fileToUpload" id="fileToUpload" accept="" class="inputbox" style="text-align: center;" />
                <input type="submit" class="btn_normal" value="Submit Form" id="submit_form_btn" disabled="" />
              </div>
            </div>
          </fieldset>

          <script>
            $('#fileToUpload').change(function() {
              var i = $(this)
                .prev('label')
                .clone();
              var file = $('#fileToUpload')[0].files[0].name;
              $('#form_file_selected').html('<strong>' + file + '</strong>');
              $('#submit_form_btn').prop('disabled', false);
              $('#btn_confirm_upload_owner').prop('disabled', false);
            });
          </script>
        </form>
      </div>
    </div>
    <div class="dialog_actions clearfix">
      <div class="actions_left">
        <button type="button" class="btn_normal btn_white" id="btn_add_owner_alt">
          <span class="btn_icon"><span class="icon_create"></span></span>
          <span class="btn_text">Add Manually</span>
        </button>
      </div>
      <div class="actions_right">
        <button type="button" class="btn_normal btn_grey btn_cancel_modal">
          <span class="btn_text">Cancel</span>
        </button>
        <button type="button" class="btn_normal btn_blue" id="btn_confirm_upload_owner" disabled="">
          <span class="btn_text">Upload</span>
        </button>
      </div>
    </div>
  </div>

  <script type="text/javascript">
    jQuery(document).ready(function() {
      datepicker();
      modals();

      jQuery('#dialog_upload_owner').dialog({
        autoOpen: false,
        height: 'auto',
        width: 960,
        modal: true,
        show: 'fade',
        hide: 'fade',
        open: function() {}
      });

      // Add Property
      jQuery('#btn_upload_owner').click(function() {
        jQuery('#dialog_upload_owner').dialog('open');
        jQuery('body').addClass('no_scroll');
      });

      // Switch to Add Property
      jQuery('#btn_add_owner_alt').click(function() {
        jQuery('#dialog_upload_owner').dialog('close');
        jQuery('#dialog_add_owner').dialog('open');
        jQuery('body').addClass('no_scroll');
      });

      // Confirm Adding a Note
      jQuery('#btn_confirm_upload_owner').click(function() {
        // Trigger a click on the form to submit it
        jQuery('#submit_form_btn').trigger('click');

        jQuery('#dialog_upload_owner').dialog('close');
        jQuery('#message_processing_owner').fadeIn();

        // Wait duration until hiding the loader
        setTimeout(function() {
          jQuery('#message_processing_owner').hide();
        }, 3000);

        // Wait duration of Loader before showing Message
        setTimeout(function() {
          jQuery('#message_upload_owner')
            .show()
            .delay(5000)
            .fadeOut();
        }, 3000);

        jQuery('html, body').removeClass('no_scroll');
      });

      // Show the Dialog if the has on the page is add_owner
      jQuery(function() {
        if (location.hash == '#upload_owner') {
          jQuery('#dialog_upload_owner').dialog('open');
          jQuery('body').addClass('no_scroll');
          removeHash();
        }
      });
    });
  </script>
</div>
