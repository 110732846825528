import { environment } from './../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var API_URL = environment.apiUrl;
var OwnerService = /** @class */ (function () {
    function OwnerService(http) {
        this.http = http;
        this.ownerUrl = environment.apiUrl + "/owner";
    }
    // API: GET / owners
    // GET
    OwnerService.prototype.getOwner = function (ownerId) {
        var url = this.ownerUrl + "/getOwner?ownerId=" + ownerId;
        console.log('GetOwner Url: ', url);
        return this.http.get(url);
    };
    OwnerService.prototype.getProperties = function (operatorId, ownerId) {
        var url = this.ownerUrl + "/getProperties?operatorId=" + operatorId + "&ownerId=" + ownerId;
        console.log('GetOwnerProperties Url: ', url);
        return this.http.get(url);
    };
    OwnerService.prototype.getDivisionOrders = function (operatorId, ownerId) {
        var url = this.ownerUrl + "/getDivisionOrders?operatorId=" + operatorId + "&ownerId=" + ownerId;
        console.log('GetOwnerDivisionOrders Url: ', url);
        return this.http.get(url);
    };
    OwnerService.prototype.getPayments = function (operatorId, ownerId) {
        var url = this.ownerUrl + "/getPayments?operatorId=" + operatorId + "&ownerId=" + ownerId;
        console.log('GetOwnerPayments Url: ', url);
        return this.http.get(url);
    };
    /** @nocollapse */ OwnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OwnerService_Factory() { return new OwnerService(i0.ɵɵinject(i1.HttpClient)); }, token: OwnerService, providedIn: "root" });
    return OwnerService;
}());
export { OwnerService };
