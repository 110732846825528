import { MessagesService } from './../messages.service';
import { DivisionorderService } from './../divisionorder.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaderResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-load-owner-csv-file',
  templateUrl: './load-owner-csv-file.component.html',
  styleUrls: ['./load-owner-csv-file.component.css']
})
export class LoadOwnerCsvFileComponent implements OnInit {
  selectedFile: File = null;
  public message = this.messagesService.noFileSelected;
  private ngUnsubscribe = new Subject();

  onFileSelected(event) {
    this.selectedFile = event.target.files[0] as File;
    this.message = this.selectedFile.name;
  }

  onUpload() {
    const fileName = this.selectedFile.name;

    const fd = new FormData();
    fd.append('file', this.selectedFile, fileName);

    const resultObservable = this.divisionOrderService.postDivisionOrderFile(fd);

    const resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      console.log('Response: ' + res);
    });

    this.selectedFile = null;
    this.message = fileName + ' - ' + this.messagesService.fileUploaded;
  }

  constructor(
    private http: HttpClient,
    private divisionOrderService: DivisionorderService,
    private messagesService: MessagesService) { }

  ngOnInit() { }

  ngOnDestroy() {
    console.log('The component is being destroyed');
    this.ngUnsubscribe.next();
  }
}
