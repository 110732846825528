<!-- @dialog Modal for adding a new Owner -->
<div id="dialog_add_owner" title="Add Owner" style="display:none;" role="dialog">
  <div class="dialog_header">
    <h1>Add Owner</h1>
    <div class="dialog_close">
      <button type="button" class="close_modal btn_normal btn_icon_only">
        <span class="btn_icon"><span class="icon_close"></span></span>
        <span class="btn_text hidden_text">Close Dialog or press Escape to dismiss</span>
      </button>
    </div>
  </div>
  <div class="dialog_content">
    <div class="dialog_inner_content">
      <fieldset class="grouped split_50_50" id="info_owner_survey">
        <legend class="legend_title">Title</legend>
        <p>Content placeholder</p>
        <div class="fieldset_remove">
          <button type="button" class="btn_normal ">
            <span class="btn_text">Remove</span>
          </button>
        </div>
      </fieldset>
    </div>
  </div>
  <div class="dialog_actions clearfix">
    <div class="actions_left">
      <button type="button" class="btn_normal btn_white" id="btn_upload_owner_alt">
        <span class="btn_icon"><span class="icon_upload_cloud"></span></span>
        <span class="btn_text">Upload</span>
      </button>
    </div>
    <div class="actions_right">
      <button type="button" class="btn_normal btn_grey btn_cancel_modal">
        <span class="btn_text">Cancel</span>
      </button>
      <button type="button" class="btn_normal btn_blue" id="btn_confirm_add_owner">
        <span class="btn_text">Add Owner</span>
      </button>
    </div>
  </div>
</div>
