import * as GC from '@grapecity/spread-sheets';
import { environment } from 'src/environments/environment.prod';
GC.Spread.Sheets.LicenseKey = environment.spreadLicense;
// Excel.LicenseKey = environment.spreadLicense;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
