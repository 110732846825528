<div class="app-container">
  <div class="app-header">
    <span class="header-text">Welcome to {{ title }} !</span>
  </div>
  <div class="body-container">
    <div class="navigationBar">
      <h2>Navigation</h2>
      <nav>
        <ul>
          <li>
            <a routerLink="../property" routerLinkActive="active">Property</a>
          </li>
          <li>
            <a routerLink="../propertyAdd" routerLinkActive="active">PropertyAdd</a>
          </li>
          <li>
            <a routerLink="../propertyList" routerLinkActive="active">Property List</a>
          </li>
          <li>
            <a routerLink="../loadOwnerCSVFile" routerLinkActive="active">Load Owner/DivOrder File</a>
          </li>
          <li>
            <a routerLink="../divisionOrderList" routerLinkActive="active">Division Order List</a>
          </li>
          <li>
            <a routerLink="../loadSalesCSVFile" routerLinkActive="active">Load Sales File</a>
          </li>
          <li>
            <a routerLink="../salesList" routerLinkActive="active">Sales List</a>
          </li>
          <li>
            <a routerLink="../loadProductionCSVFile" routerLinkActive="active">Load Production File</a>
          </li>
          <li>
            <a routerLink="../productionList" routerLinkActive="active">Production List</a>
          </li>
          <li>
            <a routerLink="../genericLoadFile" routerLinkActive="active">Generic Load File</a>
          </li>
          <li>
            <a routerLink="../sales" routerLinkActive="active">Sales</a>
          </li>
          <li>
            <a routerLink="../import" routerLinkActive="active">Import</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="componentContainer">
      <router-outlet></router-outlet>
    </div>
  </div>
  <div class="app-footer content">
    <span class="footer-text">{{ appFooter }}</span>
  </div>
</div>
