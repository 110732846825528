import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as GC from '@grapecity/spread-sheets';
import * as Excel from '@grapecity/spread-excelio';
var ImportComponent = /** @class */ (function () {
    function ImportComponent(router) {
        this.router = router;
        this.hostStyle = {
            width: '100%',
            height: '100%'
        };
        this.showDrag = true;
        this.validData = false;
        this.toastDismissed = null;
        this.saveEnabled = false;
        this.saveComplete = false;
        this.isStress = false;
        this.sheet = null;
        this.running = false;
        this.spreadBackColor = 'aliceblue';
        this.columnWidth = 100;
    }
    ImportComponent.prototype.workbookInit = function (args) {
        this.spread = args.spread;
    };
    ImportComponent.prototype.setIsSaveenabled = function (val) {
        this.saveEnabled = val;
    };
    ImportComponent.prototype.setSaveComplete = function (val) {
        this.saveComplete = val;
        if (this.saveComplete) {
            this.setIsSaveenabled(!this.saveComplete);
            this.showToast('Success', 'Scenario Stored', 'The results of your scenario(\'s) have been stored in the system');
        }
    };
    ImportComponent.prototype.ngOnInit = function () { };
    ImportComponent.prototype.showToast = function (type, title, text) {
        var body = "<p class=\"text-light\">" + text.replace(/\n/g, '<br>') + "</p>\n      <div class=\"replace-container\">\n\n      </div>";
        var toast = {
            type: type,
            title: title,
            text: text,
            body: body,
            showCloseButton: true
        };
    };
    ImportComponent.prototype.onDragOver = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        var files = evt.dataTransfer.files;
        this.showDrag = true;
        if (files.length > 0) {
        }
    };
    ImportComponent.prototype.onDragLeave = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        this.showDrag = false;
    };
    ImportComponent.prototype.onDrop = function (evt) {
        evt.preventDefault();
        evt.stopPropagation();
        var files = evt.dataTransfer.files;
        this.showDrag = false;
        var spread = this.spread;
        var excelIO = new Excel.IO();
        console.log(excelIO);
        excelIO.open(files[0], function (json) {
            var workbookObj = json;
            console.log('Workbook', workbookObj);
            var workbook = new GC.Spread.Sheets.Workbook();
            workbook.fromJSON(workbookObj);
            for (var i = 0; i < workbook.getSheetCount(); i++) {
                var sheet = new GC.Spread.Sheets.Worksheet('test');
                sheet.fromJSON(workbook.getSheet(i).toJSON());
                spread.addSheet(spread.getSheetCount(), sheet);
            }
        }, function (e) {
            console.log(e);
        });
    };
    return ImportComponent;
}());
export { ImportComponent };
