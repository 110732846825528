import { Lease } from './lease';
import { HttpBackend } from '@angular/common/http';
import { Bank } from './bank';
import { BankTrustManager } from './bank-trust-manager';
import { InterestType } from './interest-type';
import { Product } from './product';
import { Deck } from './deck';

export class DivisionOrder {
  id: number;
  bcTransId: string;
  bcBlockId: string;
  lease: Lease;
  divisionOrderDate: Date;
  termStart: Date;
  termEnd: Date;
  interestType: InterestType;
  decimalInterest: number;
  product: Product;
  deck: Deck;
  bankTrustManager: BankTrustManager;
  status: string;
  action: string;

  interestTypeAbbr?: string;
  ownerId?: number;
  ownerName?: string;
  ownerTel?: string;
  ownerAddr?: string;
  ownerEmail?: string;
  propertyNo?: string;
  propertyName?: string;
  propertyDesc?: string;
  productName?: string;
  startDateStr?: string;
  endDateStr?: string;
  doDateStr?: string;

  ownerPercent?: number;
  bankName?: string;
  trustManagerName?: string;

  deckCode?: string;
  deckChangeCode?: string;
  deckVersion?: string;
}
