import { AuthService } from './../auth.service';
import { OnInit, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
var HeaderComponent = /** @class */ (function () {
    // tslint:disable variable-name
    function HeaderComponent(_authService, router) {
        this._authService = _authService;
        this.router = router;
        this.displayName = '';
        this.corporateLogo = '';
        this.isUserDropdownOpen = false;
        this.isNotificationDropdownOpen = false;
        this.displayChangePasswordChange = new EventEmitter();
    }
    HeaderComponent.prototype.ngOnInit = function () {
        $(document).ready(function () {
            dropdownMenu();
        });
        // Get current user from AuthService
        this.currUser = this._authService.getUser();
        if (this.currUser !== null) {
            // Return the displayName
            this.displayName = (this.currUser.fname + ' ' + this.currUser.lname).trim();
            this.corporateLogo = this.currUser.operator.logoPath;
        }
    };
    HeaderComponent.prototype.logout = function () {
        this.displayName = '';
        this.isUserDropdownOpen = false;
        this.isNotificationDropdownOpen = false;
        console.log('header - Logout');
        this._authService.logoutUser();
    };
    HeaderComponent.prototype.onLogoutDropdownClicked = function () {
        this.isUserDropdownOpen = !this.isUserDropdownOpen;
        console.log("isUserDropdownOpen set to " + this.isUserDropdownOpen);
    };
    HeaderComponent.prototype.onNotificationDropdownClicked = function () {
        this.isNotificationDropdownOpen = !this.isNotificationDropdownOpen;
        console.log("isNotificationDropdownOpen set to " + this.isNotificationDropdownOpen);
    };
    HeaderComponent.prototype.loggedIn = function (location) {
        return this._authService.loggedIn();
    };
    HeaderComponent.prototype.getDisplayName = function () {
        if (this.displayName === '') {
            // Get current user from AuthService
            var currUser = this._authService.getUser();
            // Return the displayName
            this.displayName = (currUser.fname + ' ' + currUser.lname).trim();
            console.log('this.displayName: ' + this.displayName);
        }
        return this.displayName;
    };
    HeaderComponent.prototype.getCorporateLogo = function () {
        console.log('this.corporateLogo: ' + this.corporateLogo);
        if (this.corporateLogo === '') {
            // Get current user from AuthService
            var currUser = this._authService.getUser();
            // Return the displayName
            this.corporateLogo = currUser.operator.logoPath;
            console.log('this.corporateLogo: ' + this.corporateLogo);
        }
        return this.corporateLogo;
    };
    HeaderComponent.prototype.openChangePasswordDialog = function () {
        this.displayChangePassword = true;
        this.displayChangePasswordChange.emit(true);
    };
    HeaderComponent.prototype.changePassword = function () {
        this.openChangePasswordDialog();
    };
    return HeaderComponent;
}());
export { HeaderComponent };
