var donutId = 'donutChart';
var ɵ0 = [{
        name: 'Confirmed',
        y: 288764,
        color: '#29BBA9',
    }, {
        name: 'Pending',
        y: 71225,
        color: '#616F7C',
    }, {
        name: 'Issued',
        y: 36613,
        color: '#71ADD1',
    }];
export var options = {
    chart: {
        backgroundColor: 'transparent',
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    credits: {
        enabled: false,
    },
    tooltip: {
        pointFormat: '<b>${point.y:,.2f}</b>' // {point.name}
    },
    labels: {
        enabled: false,
    },
    plotOptions: {
        pie: {
            innerSize: '70%',
            allowPointSelect: false,
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
            }
        }
    },
    series: [{
            id: donutId,
            // name: 'Payments',
            colorByPoint: true,
            data: ɵ0
        }]
};
export var setValues = function (chart, confirmed, pending, issued) {
    var opt = {
        data: [{
                name: 'Confirmed',
                y: confirmed,
                color: '#29BBA9',
            }, {
                name: 'Pending',
                y: pending,
                color: '#616F7C',
            }, {
                name: 'Issued',
                y: issued,
                color: '#71ADD1',
            }]
    };
    // @ts-ignore
    chart.get(donutId).update(opt, true);
};
export { ɵ0 };
