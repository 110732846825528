
import { Operator } from './operator';
import { Property } from './property';
import { Product } from './product';
import { Deduction } from './deduction';

export class Sale {
  id: number;
  bcTransId: string;
  bcBlockId: string;
  operator: Operator;
  property: Property;
  product: Product;
  year: number;
  month: number;
  salesVolume: number;
  grsValue: number;
  unitPrice: number;
  netSales: number;

  propertyNo?: string;
  noWells?: number;
  productName?: string;
  prodDate?: string;
  volume?: string;
  totalDeductions?: number;
  btu?: number;

  deductionType?: string;

  deductions: Deduction[];
}
