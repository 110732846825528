import { OnInit } from '@angular/core';
var DashboardNotificationsComponent = /** @class */ (function () {
    function DashboardNotificationsComponent() {
        this.lastUpdated = new Date();
    }
    DashboardNotificationsComponent.prototype.ngOnInit = function () {
        $(document).ready(function () {
            $('.btn_close_notification').click(function () {
                $(this)
                    .closest('.notification')
                    .remove();
            });
        });
        this.lastUpdated.setDate(new Date().getDate() - 2);
    };
    return DashboardNotificationsComponent;
}());
export { DashboardNotificationsComponent };
