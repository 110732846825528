import { AuthGuard } from './auth.guard';
import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyDetailComponent } from './property-detail/property-detail.component';
import { ProductionComponent } from './production/production.component';
import { SalesComponent } from './sales/sales.component';
import { PaymentsComponent } from './payments/payments.component';
import { OwnerComponent } from './owner/owner.component';
import { PropertyComponent } from './property/property.component';
import { DivisionorderListComponent } from './divisionorder-list/divisionorder-list.component';
import { TestComponent } from './test/test.component';
import { LoadOwnerCsvFileComponent } from './load-owner-csv-file/load-owner-csv-file.component';
import { LoadSalesCsvFileComponent } from './load-sales-csv-file/load-sales-csv-file.component';
import { LoadProductionCsvFileComponent } from './load-production-csv-file/load-production-csv-file.component';
import { GenericLoadFileComponent } from './generic-load-file/generic-load-file.component';
import { ImportComponent } from './import/import.component';
import { PaymentComponent } from './payment/payment.component';
import { UserComponent } from './user/user.component';
var routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    // Disable Register for the moment
    // { path: 'register', component: RegisterComponent },
    {
        path: 'dashboard',
        component: DashboardComponent,
        // runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard]
    },
    {
        path: 'property',
        component: PropertyComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'properties',
        component: PropertiesComponent,
        canActivate: [AuthGuard]
    },
    // note "/????" I will read this off the URL to display the appropriate div over the list
    {
        path: 'properties/:action',
        component: PropertiesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'propertyDetail',
        component: PropertyDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'propertyDetail/:id',
        component: PropertyDetailComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'production',
        component: ProductionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'production/:action',
        component: ProductionComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'sales',
        component: SalesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'sales/:action',
        component: SalesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payments',
        component: PaymentsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payments/:action',
        component: PaymentsComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'owner',
        component: OwnerComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'owner/:id',
        component: OwnerComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user',
        component: UserComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user/:action',
        component: UserComponent,
        canActivate: [AuthGuard]
    },
    { path: 'test', component: TestComponent },
    { path: 'loadOwnerCSVFile', component: LoadOwnerCsvFileComponent },
    { path: 'loadSalesCSVFile', component: LoadSalesCsvFileComponent },
    { path: 'loadProductionCSVFile', component: LoadProductionCsvFileComponent },
    { path: 'import', component: ImportComponent },
    { path: 'genericLoadFile', component: GenericLoadFileComponent },
    { path: 'divisionOrderList', component: DivisionorderListComponent },
    // { path: '**', redirectTo: '/dashboard' }
    { path: '**', redirectTo: '/login' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
