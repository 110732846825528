import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { USState } from 'src/app/model/us_state';
import { County } from 'src/app/model/county';
import { Operator } from 'src/app/model/operator';
import { Purchaser } from 'src/app/model/purchaser';
import { Product } from 'src/app/model/product';
import { PropertyService } from 'src/app/property.service';
import { LookupService } from 'src/app/lookup.service';
import { Property } from 'src/app/model/property';
import { Well } from 'src/app/model/well';
import { Subject } from 'rxjs';
import { User } from 'src/app/model/user';

declare var $: any; // jquery
declare let datepicker;
declare let modals;

@Component({
  selector: 'app-properties-add',
  templateUrl: './properties-add.component.html',
  styleUrls: ['./properties-add.component.css']
})

export class PropertiesAddComponent implements OnInit, OnDestroy {
  // @ViewChild('effectiveFromDate', { static: false }) effectiveFromDate: ElementRef;
  // @ViewChild('effectiveToDate', { static: false }) effectiveToDate: ElementRef;

  @Input() displayAddProperty: boolean;
  @Output() displayAddPropertyChange = new EventEmitter<boolean>();

  // Display/Hide blockchain error dialog
  @Input() displayBlockchainErrorDialog: boolean;
  @Output() displayBlockchainErrorDialogChange = new EventEmitter<boolean>();

  private ngUnsubscribe = new Subject();

  showAdditionInfo = false;

  propertyForm: FormGroup = this.fb.group({
    id: [''],
    no: ['', Validators.required],
    name: ['', Validators.required],
    description: [''],
    state: ['', Validators.required],
    county: ['', Validators.required],
    operator: [''],
    purchaser: [''],
    prodType: [''],
    leaseNo: [''],
    wellId: [''],
    wellApiNo: [''],
    wellName: [''],
    effectiveFrom: [''],
    effectiveTo: ['']
  });

  states: USState[];
  counties: County[];
  operators: Operator[];
  purchasers: Purchaser[];
  products: Product[];

  public effectiveFromDateValue: Date = null;
  public effectiveToDateValue: Date = null;

  constructor(
    private fb: FormBuilder,
    private propertyService: PropertyService,
    private lookupService: LookupService) {
    // Disable fields
    this.disableFormFields();
  }

  closeAddPropertyDialog() {
    this.displayAddProperty = false;
    this.displayAddPropertyChange.emit(false);
  }

  closeBCDialog() {
    this.displayBlockchainErrorDialog = false;
    this.displayBlockchainErrorDialogChange.emit(false);

    this.closeAddPropertyDialog();
  }

  initPropertyForm() {
    this.propertyForm.patchValue({ id: '' });

    this.propertyForm.patchValue({ no: '' });
    this.propertyForm.patchValue({ name: '' });
    this.propertyForm.patchValue({ description: '' });
    this.propertyForm.patchValue({ state: '' });
    this.propertyForm.patchValue({ county: '' });
    this.propertyForm.patchValue({ operator: '' });

    this.propertyForm.patchValue({ purchaser: '' });
    this.propertyForm.patchValue({ prodType: '' });
    this.propertyForm.patchValue({ wellId: '' });
    this.propertyForm.patchValue({ wellApiNo: '' });
    this.propertyForm.patchValue({ wellName: '' });
    this.propertyForm.patchValue({ effectiveFrom: '' });
    this.propertyForm.patchValue({ effectiveTo: '' });
  }

  ngOnInit() {

    console.log('Properties-Add - Init - start');

    $(document).ready(() => {
      datepicker();
      modals();
    });

    // Initialise the form to blank
    this.initPropertyForm();

    // Load lists
    this.getUSStates();
    this.getOperators();
    this.getPurchasers();
    this.getProducts();
    console.log('Properties-Add - Init - complete');
  }

  displayAdditionInfo() {
    this.showAdditionInfo = true;
  }

  // This will disable all form fields in the list 'disabledFields'
  disableFormFields() {
    const disabledFields = ['id'];

    disabledFields.forEach(fieldId => this.propertyForm.get(fieldId).disable());
  }

  async getUSStates() {
    this.states = (await (this.lookupService.getStates())) as USState[];
  }

  async getOperators() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;
    this.operators = (await (this.lookupService.getOperators(operatorId))) as Operator[];
  }

  async getPurchasers() {
    this.purchasers = (await (this.lookupService.getPurchasers())) as Purchaser[];
  }

  async getProducts() {
    this.products = (await (this.lookupService.getProducts())) as Product[];
  }

  async onSubmit() {
    // console.log({ effectiveFrom: this.effectiveFromDate.nativeElement.value });
    // console.log({ effectiveTo: this.effectiveToDate.nativeElement.value });
    console.log('Property Form: ', { propertyForm: this.propertyForm.value });
    // console.log('Property Form: ', { propertyForm: this.propertyForm });

    const property: Property = new Property();
    property.id = this.propertyForm.get('id').value === '' ? 0 : Number(this.propertyForm.get('id').value);
    property.propertyNo = this.propertyForm.get('no').value;
    property.name = this.propertyForm.get('name').value;
    property.description = this.propertyForm.get('description').value;

    const county: County = new County();
    county.id = this.propertyForm.get('county').value === '' ? 0 : Number(this.propertyForm.get('county').value);

    property.county = county;

    const operator: Operator = new Operator();
    operator.id = this.propertyForm.get('operator').value === '' ? 0 : Number(this.propertyForm.get('operator').value);

    const purchaser: Purchaser = new Purchaser();
    purchaser.id = this.propertyForm.get('purchaser').value === '' ? 0 : Number(this.propertyForm.get('purchaser').value);
    property.purchaser = purchaser;

    const well: Well = new Well();
    well.id = this.propertyForm.get('wellId').value === '' ? 0 : Number(this.propertyForm.get('wellId').value);
    well.apiNo = this.propertyForm.get('wellApiNo').value;
    well.name = this.propertyForm.get('wellName').value;
    const product: Product = new Product();
    product.id = this.propertyForm.get('prodType').value === '' ? 0 : Number(this.propertyForm.get('prodType').value);
    well.products = new Array();
    well.products.push(product);
    well.operator = operator;

    property.wells = new Array();
    property.wells.push(well);

    property.effectiveFrom = this.dateToString(this.effectiveFromDateValue);
    property.effectiveTo = this.dateToString(this.effectiveToDateValue);

    // Because the datepicker is not updating the propertyForm, we need to get the effectiveFrom/effectiveTo
    // directly from the elements of the page
    // property.effectiveFrom = this.formatDate(this.effectiveFromDate.nativeElement.value);
    // property.effectiveTo = this.formatDate(this.effectiveToDate.nativeElement.value);

    // if (this.propertyForm.get('effectiveFrom').value === '') {
    //   property.effectiveFrom = '';
    // } else {
    //   property.effectiveFrom = this.formatDate(this.propertyForm.get('effectiveFrom').value);
    // }

    // if (this.propertyForm.get('effectiveTo').value === '') {
    //   property.effectiveTo = '';
    // } else {
    //   property.effectiveTo = this.formatDate(this.propertyForm.get('effectiveTo').value);
    // }
    property.keyValues = new Array();

    // Only save data to DB if data is valid
    console.log('this.propertyForm.Status: ', this.propertyForm.status);
    if (this.propertyForm.status === 'VALID') {
      // this.propertyService.add(property).subscribe(res => console.log(res));
      const result = await (this.propertyService.add(property)) as Property;

      // Assign id back to the form
      this.propertyForm.patchValue({ id: result.id });

      console.log('onSubmit - Form Property: ', this.propertyForm);
      console.log('onSubmit - Property: ', property);
      console.log('onSubmit - result: ', result);

      if (!result.bcCurrent) {
        this.displayBlockchainErrorDialog = true;
        // alert('Property saved to DB - but failed to write to Blockchain');
      } else {
        // Close the form
        this.closeAddPropertyDialog();
      }
    }
  }

  formatDate(dateStr: string) {
    if (dateStr === '') {
      return '';
    }
    const dateParts = dateStr.split('/');
    console.log('Date Split: ', dateParts[0], dateParts[1], dateParts[2]);
    console.log('Date Format: ', dateParts[2], '-', dateParts[0], '-', dateParts[1]);
    return dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
  }

  dateToString(date: Date) {
    if (date == null) {
      return '';
    } else {
      // Return date as string in YYYY-MM-DD format
      return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    }
  }

  onStateChange() {
    console.log('State changed: ' + this.propertyForm.get('state').value);

    const state = this.states.find(s => s.id === Number(this.propertyForm.get('state').value));
    this.counties = state.counties;

    console.log('counties: ', { counties: this.counties });
  }

  // Script to initialize the form
  initForm() {
    this.propertyForm.patchValue({ id: 3 });

    this.propertyForm.patchValue({ no: '5462265786' });
    this.propertyForm.patchValue({ name: 'AM-KIRK' });
    this.propertyForm.patchValue({ description: 'NW/4 8-20N-102W' });
    this.propertyForm.patchValue({ state: 49 });
    this.onStateChange();
    this.propertyForm.patchValue({ county: 49037 });
    this.propertyForm.patchValue({ operator: 1 });

    this.propertyForm.patchValue({ purchaser: 1 });
    this.propertyForm.patchValue({ prodType: 10 });
    this.propertyForm.patchValue({ wellId: '14' });
    this.propertyForm.patchValue({ wellApiNo: '46-037-21571' });
    this.propertyForm.patchValue({ wellName: 'Thomsberg 1' });
    this.propertyForm.patchValue({ effectiveFrom: '01/03/2019' });
    this.propertyForm.patchValue({ effectiveTo: '' });
  }

  // Script to initialize the form
  initForm2() {
    this.propertyForm.patchValue({ id: 19 });

    this.propertyForm.patchValue({ no: '5456723567124' });
    this.propertyForm.patchValue({ name: 'NEW-KIRK-WELL' });
    this.propertyForm.patchValue({ description: 'Test-Property-Well' });
    this.propertyForm.patchValue({ state: 49 });
    this.onStateChange();
    this.propertyForm.patchValue({ county: 49003 });
    this.propertyForm.patchValue({ operator: 1 });

    this.propertyForm.patchValue({ purchaser: 2 });
    this.propertyForm.patchValue({ prodType: 10 });
    this.propertyForm.patchValue({ wellId: '31' });
    this.propertyForm.patchValue({ wellApiNo: '049-15326-4712' });
    this.propertyForm.patchValue({ wellName: 'Deep River' });
    this.effectiveFromDateValue = new Date();
    this.effectiveToDateValue = null;
    // this.propertyForm.patchValue({ effectiveFromDate: '01/03/2019' });
    // this.propertyForm.patchValue({ effectiveToDate: '' });

    console.log('Property: ', this.propertyForm);
  }

  ngOnDestroy() {
    console.log('The component is being destroyed');
    this.ngUnsubscribe.next();
  }

}
