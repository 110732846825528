import * as i0 from "@angular/core";
var MessagesService = /** @class */ (function () {
    function MessagesService() {
        // Global Generic Messages - to access this.messagesServices.noFileSelected
        this.noFileSelected = 'No File Selected';
        this.fileUploaded = 'file uploaded for processing';
        this.blockChainTooltip = 'Click to access blockchain explorer';
        // Messages defined by component name
        // to access - this.messagesServices.messages.loadOwner.noFileSeleced
        this.messages = {
            loadOwner: {
                noFileSelected: 'No File Selected'
            },
            property: {
                propertyDecription: 'Property decription required',
                propertyNumber: 'Property number required',
                propertyName: 'Propery name required',
                propertyState: 'State required',
                propertyCounty: 'County required',
                propertyOperator: 'Operator required',
                propertyPurchaser: 'Purchaser required',
                propertyProduct: 'Product required',
                propertyWellAPI: 'Well API required',
                propertyEffectiveFromDate: 'Effective From Date required',
                propertyWellName: 'Well name required'
            },
            production: {
                propertyNumber: 'Property number required',
                propertyName: 'Propery name required',
                wellApiNo: 'Well api required',
                wellName: 'Well name required',
                product: 'Product required',
                prodDate: 'Production date required',
                prodVol: 'Production volume required'
            }
        };
    }
    MessagesService.prototype.monthString = function (month) {
        if (month === 1) {
            return 'Jan';
        }
        else if (month === 2) {
            return 'Feb';
        }
        else if (month === 3) {
            return 'Mar';
        }
        else if (month === 4) {
            return 'Apr';
        }
        else if (month === 5) {
            return 'May';
        }
        else if (month === 6) {
            return 'Jun';
        }
        else if (month === 7) {
            return 'Jul';
        }
        else if (month === 8) {
            return 'Aug';
        }
        else if (month === 9) {
            return 'Sep';
        }
        else if (month === 10) {
            return 'Oct';
        }
        else if (month === 11) {
            return 'Nov';
        }
        else if (month === 12) {
            return 'Dec';
        }
    };
    /** @nocollapse */ MessagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessagesService_Factory() { return new MessagesService(); }, token: MessagesService, providedIn: "root" });
    return MessagesService;
}());
export { MessagesService };
