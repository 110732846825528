/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./divisionorder-list.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@grapecity/spread-sheets-angular/dist/gc.spread.sheets.angular.ngfactory";
import * as i3 from "@grapecity/spread-sheets-angular/dist/gc.spread.sheets.angular";
import * as i4 from "./divisionorder-list.component";
import * as i5 from "../divisionorder.service";
import * as i6 from "../messages.service";
import * as i7 from "../auth.service";
var styles_DivisionorderListComponent = [i0.styles];
var RenderType_DivisionorderListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DivisionorderListComponent, data: {} });
export { RenderType_DivisionorderListComponent as RenderType_DivisionorderListComponent };
export function View_DivisionorderListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { spreadContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [["divSpreadContainer", 1]], null, 3, "div", [["class", "spread-container"], ["id", "spread-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "gc-spread-sheets", [["hostClass", "spreadContainer"], ["id", "divisionorder-table-data"]], null, [[null, "workbookInitialized"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("workbookInitialized" === en)) {
        var pd_0 = (_co.workbookInit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_SpreadSheetsComponent_0, i2.RenderType_SpreadSheetsComponent)), i1.ɵdid(3, 4898816, [[1, 4], ["spreadContainer", 4]], 1, i3.SpreadSheetsComponent, [i1.ElementRef], { tabStripVisible: [0, "tabStripVisible"], backColor: [1, "backColor"], hostStyle: [2, "hostStyle"], hostClass: [3, "hostClass"] }, { workbookInitialized: "workbookInitialized" }), i1.ɵqud(603979776, 2, { sheets: 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabStripVisible; var currVal_1 = _co.spreadBackColor; var currVal_2 = _co.hostStyle; var currVal_3 = "spreadContainer"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_DivisionorderListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-divisionorder-list", [], null, null, null, View_DivisionorderListComponent_0, RenderType_DivisionorderListComponent)), i1.ɵdid(1, 114688, null, 0, i4.DivisionorderListComponent, [i5.DivisionorderService, i6.MessagesService, i7.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DivisionorderListComponentNgFactory = i1.ɵccf("app-divisionorder-list", i4.DivisionorderListComponent, View_DivisionorderListComponent_Host_0, {}, {}, []);
export { DivisionorderListComponentNgFactory as DivisionorderListComponentNgFactory };
