import { formatDate, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as GC from '@grapecity/spread-sheets';
import { Owner } from 'src/app/model/owner';
import { OwnerService } from 'src/app/owner.service';
import { environment } from 'src/environments/environment';
var OwnerContentComponent = /** @class */ (function () {
    function OwnerContentComponent(ownerService, activatedRoute, location, router) {
        this.ownerService = ownerService;
        this.activatedRoute = activatedRoute;
        this.location = location;
        this.router = router;
        this.displayTaxReportChange = new EventEmitter();
        this.displayDivisionOrderDialog = false;
        this.blockchainExplorerUrl = "" + environment.blockchainExplorerUrl;
        this.sheet = null;
        this.sheetProperties = null;
        this.sheetDivisionOrders = null;
        this.sheetPayments = null;
        // Variable to set attributes of the spread js grid
        this.tabStripVisible = false;
        this.spreadBackColor = 'white';
        this.sheetName = 'Properties';
        this.hostStyle = {
            height: '100%',
            width: '100%'
        };
        this.hideGrid = true;
        // Define Property SpreadJS column indexes
        this.propertyCheckBoxColumn = 0;
        this.propertyBlockChainColumn = 1;
        this.propertyNoColumn = 2;
        this.propertyNameColumn = 3;
        this.propertyDescColumn = 4;
        this.propertyStateColumn = 5;
        this.propertyCountyColumn = 6;
        this.propertyOperatorColumn = 7;
        this.propertyPurchaserColumn = 8;
        this.propertyWellCountColumn = 9;
        this.propertyEffectiveFromColumn = 10;
        this.propertyEffectiveToColumn = 11;
        this.propertyBCTransIdColumn = 12;
        this.propertyGridColCount = 13;
        // Define Division Order SpreadJS column indexes
        this.doCheckBoxColumn = 0;
        this.doBlockChainColumn = 1;
        this.doPropertyNoColumn = 2;
        this.doPropertyNameColumn = 3;
        this.doPropertyDescColumn = 4;
        this.doProductColumn = 5;
        this.doInterestTypeColumn = 6;
        this.doDecimalInterestColumn = 7;
        this.doStartDateColumn = 8;
        this.doDeckCodeColumn = 9;
        this.doDeckChangeCodeColumn = 10;
        this.doDeckVersionColumn = 11;
        this.doTrustManagerColumn = 12;
        this.doBankColumn = 13;
        this.doStatusColumn = 14;
        this.doBCTransIdColumn = 15;
        this.doGridColCount = 16;
        // Define Payments SpreadJS column indexes
        this.paymentCheckBoxColumn = 0;
        this.paymentDateColumn = 1;
        this.paymentBankColumn = 2;
        this.paymentTypeColumn = 3;
        this.paymentCheckNoColumn = 4;
        this.paymentCountColumn = 5;
        this.paymentAmountColumn = 6;
        this.paymentStatusColumn = 7;
        this.paymentGridColCount = 8;
        this.owner = new Owner();
    }
    OwnerContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        // This close the notifications
        $(document).ready(function () {
            $('.btn_close_notification').click(function () {
                $(this)
                    .closest('.notification')
                    .remove();
            });
        });
        $(document).ready(function () {
            // Toggle Filter Option for Table
            // $('.filter_option').change(function () {
            //   alert('Toggle filter options');
            //   const inputId = $(this).attr('id');
            //   $(this).closest('.toggle_group').find('label').removeClass('toggle_active');
            //   $('label[for=\'' + inputId + '\']').addClass('toggle_active');
            // });
            // Javascript to select All Checkbox
            // $('#chbox_select_all').click(function () {
            //   // Get the Group ID
            //   const chboxGroup = $(this).attr('data-chbox-group');
            //   // Toggle all checkboxes
            //   $('input.table_chbox[data-chbox-group=' + chboxGroup + ']:checkbox').not(this).prop('checked', this.checked);
            //   // Add Class of selected to all table rows
            //   $('input.table_chbox[data-chbox-group=' + chboxGroup + ']:checkbox').each(() => {
            //     // Check if item is checked
            //     if ($(this).is(':checked')) {
            //       $(this).closest('tr').addClass('selected_row');
            //     } else {
            //       $(this).closest('tr').removeClass('selected_row');
            //     }
            //   });
            // });
            // Javascript for selecting individual Checkboxes
            // $('.table_chbox').click(() => {
            //   const chboxGroup = $(this).attr('data-chbox-group');
            //   // Add 'Selected' Class to table row
            //   if ($(this).closest('tr').hasClass('selected_row')) {
            //     $(this).closest('tr').removeClass('selected_row');
            //   } else {
            //     $(this).closest('tr').addClass('selected_row');
            //   }
            // });
            // Toggle The Chart View
            $('.switch_view').change(function () {
                var inputId = $(this).attr('id');
                var viewType = $(this).val();
                // alert('inputType: ' + inputId + '   viewType: ' + viewType);
                // $('.owner_tab').hide();
                // $('#' + viewType).fadeIn();
                $(this).closest('.toggle_group').find('label').removeClass('toggle_active');
                $('label[for=\'' + inputId + '\']').addClass('toggle_active');
            });
        });
        var ownerId = +this.activatedRoute.snapshot.paramMap.get('id');
        this.getOwner(ownerId);
        // Get the logged in user so we can add get the operator
        var user = JSON.parse(localStorage.getItem('user'));
        this.operator = user.operator;
        Promise.all([
            this.getProperties(this.operator.id, ownerId),
            this.getDivisionOrders(this.operator.id, ownerId),
            this.getPayments(this.operator.id, ownerId)
        ]).then(function (values) {
            _this.clickPropertiesTab();
            // Create grid events for all tabs
            _this.createGridEvents();
        });
    };
    OwnerContentComponent.prototype.getOwner = function (ownerId) {
        var _this = this;
        this.ownerService.getOwner(ownerId).subscribe(function (owner) {
            console.log('Owner got from database');
            console.log({ owner: owner });
            _this.owner = owner;
        }, function (err) {
            console.log('Error getting owner');
            console.log(err);
        });
    };
    OwnerContentComponent.prototype.getOwnerAddress = function () {
        var addr = '';
        if (this.owner.addr1) {
            addr += this.owner.addr1 + ', ';
        }
        if (this.owner.addr2) {
            addr += this.owner.addr2 + ', ';
        }
        if (this.owner.city) {
            addr += this.owner.city + ', ';
        }
        if (this.owner.stateCode) {
            addr += this.owner.stateCode + ' ';
        }
        if (this.owner.zipCode) {
            addr += this.owner.zipCode;
        }
        return addr;
    };
    OwnerContentComponent.prototype.getProperties = function (operatorId, ownerId) {
        var _this = this;
        console.log('getProperties - start: ' + new Date().toISOString());
        return this.ownerService.getProperties(operatorId, ownerId).toPromise().then(function (properties) {
            console.log({ properties: properties });
            _this.properties = properties;
            var tempProperties = JSON.parse(JSON.stringify(properties));
            tempProperties = _this.setPropertyValues(tempProperties);
            _this.properties = tempProperties;
            _this.initilisePropertyGrid();
            _this.applyPropertyDataBinding(tempProperties);
            console.log('getProperties - complete: ' + new Date().toISOString() + '  No. reocrds: ' + _this.properties.length);
            return tempProperties;
        }).catch(function (err) {
            console.log('getProperties:Error');
            console.log(err);
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    _this.router.navigate(['/login']);
                }
            }
        });
    };
    OwnerContentComponent.prototype.setPropertyValues = function (filteredProperties) {
        var _this = this;
        // console.log('setPropertyValues - start');
        filteredProperties.forEach(function (property) {
            // console.log('Property: ', { property });
            property.stateName = property.county ? property.county.USState ? property.county.USState.name : '' : '';
            property.countyName = property.county ? property.county.name : '';
            property.operatorName = _this.operator ? _this.operator.name : '';
            property.purchaserName = property.purchaser ? property.purchaser.name : '';
            property.wellCount = property.wells.length;
            property.effectiveFrom = _this.formatDate(property.effectiveFrom);
            property.effectiveTo = _this.formatDate(property.effectiveTo);
        });
        // console.log('setPropertyValues - end');
        return filteredProperties;
    };
    OwnerContentComponent.prototype.initilisePropertyGrid = function () {
        // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
        // And grid events that are NOT data specific
        console.log('Initialise Properties Grid tab');
        this.spread.setActiveSheetIndex(0);
        var sheet = this.spread.getActiveSheet();
        sheet.suspendPaint();
        // Define columns
        var propertyCheckBoxInfo = { name: '', displayName: '', size: 50 };
        var propertyBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
        var propertyNoColInfo = { name: 'propertyNo', displayName: 'Property#', size: 100 };
        var propertyNameColInfo = { name: 'name', displayName: 'Name', size: 180 };
        var propertyDescColInfo = { name: 'description', displayName: 'Description', size: 180 };
        var propertyStateColInfo = { name: 'stateName', displayName: 'State', size: 120 };
        var propertyCountyColInfo = { name: 'countyName', displayName: 'County', size: 120 };
        var propertyOperatorColInfo = { name: 'operatorName', displayName: 'Operator', size: 120 };
        var propertyPurchaserColInfo = { name: 'purchaserName', displayName: 'Purchaser', size: 120 };
        var propertyWellCountColInfo = { name: 'wellCount', displayName: 'Well Count', size: 80 };
        var propertyEffectiveFromColInfo = { name: 'effectiveFrom', displayName: 'Effective From', size: 100 };
        var propertyEffectiveToColInfo = { name: 'effectiveTo', displayName: 'Effective To', size: 100 };
        var propertyBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 500 };
        sheet.autoGenerateColumns = false;
        sheet.setDataSource(this.properties);
        sheet.bindColumn(this.propertyCheckBoxColumn, propertyCheckBoxInfo);
        sheet.bindColumn(this.propertyBlockChainColumn, propertyBCTransImgColInfo);
        sheet.bindColumn(this.propertyNoColumn, propertyNoColInfo);
        sheet.bindColumn(this.propertyNameColumn, propertyNameColInfo);
        sheet.bindColumn(this.propertyDescColumn, propertyDescColInfo);
        sheet.bindColumn(this.propertyStateColumn, propertyStateColInfo);
        sheet.bindColumn(this.propertyCountyColumn, propertyCountyColInfo);
        sheet.bindColumn(this.propertyOperatorColumn, propertyOperatorColInfo);
        sheet.bindColumn(this.propertyPurchaserColumn, propertyPurchaserColInfo);
        sheet.bindColumn(this.propertyWellCountColumn, propertyWellCountColInfo);
        sheet.bindColumn(this.propertyEffectiveFromColumn, propertyEffectiveFromColInfo);
        sheet.bindColumn(this.propertyEffectiveToColumn, propertyEffectiveToColInfo);
        sheet.bindColumn(this.propertyBCTransIdColumn, propertyBCTransIdColInfo);
        sheet.setColumnCount(this.propertyGridColCount, GC.Spread.Sheets.SheetArea.viewport);
        // Lock/Readonly all columns
        sheet.options.isProtected = true;
        sheet.options.protectionOptions = {
            allowSelectUnlockedCells: true,
            allowSelectLockedCells: false
        };
        // set cell alignment
        sheet.getCell(-1, this.propertyCheckBoxColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.propertyNoColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.propertyStateColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.propertyCountyColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.propertyOperatorColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.propertyPurchaserColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.propertyWellCountColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.propertyEffectiveFromColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.propertyEffectiveToColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        // Add Checkbox to grid
        var cellTypeCheckbox = new GC.Spread.Sheets.CellTypes.CheckBox();
        sheet.setCellType(-1, 0, cellTypeCheckbox);
        // Unlock the checkbox column
        sheet.getRange(-1, this.propertyCheckBoxColumn, -1, this.propertyCheckBoxColumn).locked(false);
        // Hide column row numbers
        sheet.options.rowHeaderVisible = false;
        // sheet.setColumnWidth(0, 0.0, GC.Spread.Sheets.SheetArea.rowHeader);
        console.log('initilisePropertyGrid - resume paint');
        sheet.resumePaint();
    };
    OwnerContentComponent.prototype.applyPropertyDataBinding = function (filteredProperties) {
        // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);
        var _this = this;
        console.log('applyPropertyDataBinding - Start data binding');
        // Work with Payments sheet
        this.spread.setActiveSheetIndex(0);
        var sheet = this.spread.getActiveSheet();
        console.log('applyPropertyDataBinding - Sheet selected');
        sheet.suspendPaint();
        sheet.setDataSource(filteredProperties);
        console.log('applyPropertyDataBinding - Row Binding - start');
        filteredProperties.forEach(function (rowPropertiesObject, rowIndex) {
            // console.log('Row Index: ' + rowIndex, rowPropertiesObject);
            // Remove vertical grid-lines
            sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
            // Add Blockchain graphic to cell
            var cell = sheet.getCell(rowIndex, _this.propertyBlockChainColumn);
            var imgBlockChain = null;
            if (filteredProperties[rowIndex].bcTransId != null) {
                imgBlockChain = './assets/images/blockchain_cell@2x.png';
            }
            cell.text('');
            cell.backgroundImage(imgBlockChain);
        });
        console.log('applyPropertyDataBinding - Row Binding - complete');
        // Highlight first row except blockchain graphic cell & set the font in all cells
        for (var col = 0; col < this.propertyGridColCount - 1; col++) {
            if (col !== this.propertyBlockChainColumn) {
                sheet.getCell(0, col).backColor(environment.gridHighlight);
                // sheet.getCell(-1, col).font('10pt Source Sans Pro');
            }
        }
        sheet.resumePaint();
        console.log('applyPropertyDataBinding - Properties Binding Complete');
    };
    OwnerContentComponent.prototype.getDivisionOrders = function (operatorId, ownerId) {
        var _this = this;
        console.log('Get DivisionOrders - start');
        return this.ownerService.getDivisionOrders(operatorId, ownerId).toPromise().then(function (divisionOrders) {
            console.log({ divisionOrders: divisionOrders });
            _this.divisionOrders = divisionOrders;
            var tempDOs = JSON.parse(JSON.stringify(divisionOrders));
            tempDOs = _this.setDivisionOrderValues(tempDOs);
            _this.divisionOrders = tempDOs;
            _this.initiliseDivisionOrderGrid();
            _this.applyDivisionOrderDataBinding(tempDOs);
            console.log('getDivisionOrders - complete: ' + new Date().toISOString() + '  No. reocrds: ' + _this.divisionOrders.length);
        }).catch(function (err) {
            console.log('getDivisionOrders:Error');
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    _this.router.navigate(['/login']);
                }
            }
        });
    };
    OwnerContentComponent.prototype.setDivisionOrderValues = function (filteredDivisionOrders) {
        // console.log('setDivisionOrderValues - start');
        filteredDivisionOrders.forEach(function (divisionOrder) {
            // console.log({ divisionOrder });
            divisionOrder.propertyNo = divisionOrder.lease.title.property.propertyNo;
            divisionOrder.propertyName = divisionOrder.lease.title.property.name;
            divisionOrder.propertyDesc = divisionOrder.lease.title.property.description;
            divisionOrder.productName = divisionOrder.product.name;
            divisionOrder.interestTypeAbbr = divisionOrder.interestType.abbr;
            if (divisionOrder.divisionOrderDate != null) {
                // console.log(divisionOrder.divisionOrderDate);
                divisionOrder.doDateStr = formatDate(divisionOrder.divisionOrderDate, 'MM/dd/yyyy', 'en');
            }
            if (divisionOrder.termStart != null) {
                // console.log(divisionOrder.termStart);
                divisionOrder.startDateStr = formatDate(divisionOrder.termStart, 'MM/dd/yyyy', 'en');
            }
            if (divisionOrder.termEnd != null) {
                // console.log(divisionOrder.endDateStr);
                divisionOrder.endDateStr = formatDate(divisionOrder.termEnd, 'MM/dd/yyyy', 'en');
            }
            divisionOrder.trustManagerName = divisionOrder.bankTrustManager.name;
            divisionOrder.bankName = divisionOrder.bankTrustManager.bank.name;
            divisionOrder.deckCode = divisionOrder.deck.deckCode;
            divisionOrder.deckChangeCode = divisionOrder.deck.deckChangeCode;
            divisionOrder.deckVersion = divisionOrder.deck.deckVersionDate;
        });
        console.log('setDivisionOrderValues - end');
        return filteredDivisionOrders;
    };
    OwnerContentComponent.prototype.initiliseDivisionOrderGrid = function () {
        // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
        // And grid events that are NOT data specific
        console.log('Initialise Division Order Grid tab');
        // Work with Division orders sheet
        // sheet = this.spread.getSheet(1);
        this.spread.setActiveSheetIndex(1);
        var sheet = this.spread.getActiveSheet();
        sheet.suspendPaint();
        // Define columns
        var doCheckBoxInfo = { name: '', displayName: '', size: 50 };
        var doPropertyBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
        var doPropertyNoColInfo = { name: 'propertyNo', displayName: 'Property#', size: 100 };
        var doPropertyNameColInfo = { name: 'propertyName', displayName: 'Name', size: 180 };
        var doPropertyDescColInfo = { name: 'propertyDesc', displayName: 'Description', size: 180 };
        var doProductColInfo = { name: 'productName', displayName: 'Product', size: 120 };
        var doInterestTypeColInfo = { name: 'interestTypeAbbr', displayName: 'InterestType', size: 120 };
        var doDecimalInterestColInfo = { name: 'decimalInterest', displayName: 'Decimal Int.', formatter: '0.0000000000', size: 120 };
        var doStartDateColInfo = { name: 'startDateStr', displayName: 'Start Date', size: 120 };
        var doDeckCodeColInfo = { name: 'deckCode', displayName: 'Deck', size: 60 };
        var doDeckChangeCodeColInfo = { name: 'deckChangeCode', displayName: 'Change Code', size: 80 };
        var doDeckVersionColInfo = { name: 'deckVersion', displayName: 'Deck Version', size: 120 };
        var doTrustManagerColInfo = { name: 'trustManagerName', displayName: 'Trust Manager', size: 120 };
        var doBankColInfo = { name: 'bankName', displayName: 'Bank', size: 100 };
        var doStatusColInfo = { name: 'status', displayName: 'Status', size: 100 };
        var doBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 500 };
        sheet.autoGenerateColumns = false;
        sheet.setDataSource(this.divisionOrders);
        sheet.bindColumn(this.doCheckBoxColumn, doCheckBoxInfo);
        sheet.bindColumn(this.doBlockChainColumn, doPropertyBCTransImgColInfo);
        sheet.bindColumn(this.doPropertyNoColumn, doPropertyNoColInfo);
        sheet.bindColumn(this.doPropertyNameColumn, doPropertyNameColInfo);
        sheet.bindColumn(this.doPropertyDescColumn, doPropertyDescColInfo);
        sheet.bindColumn(this.doProductColumn, doProductColInfo);
        sheet.bindColumn(this.doInterestTypeColumn, doInterestTypeColInfo);
        sheet.bindColumn(this.doDecimalInterestColumn, doDecimalInterestColInfo);
        sheet.bindColumn(this.doStartDateColumn, doStartDateColInfo);
        sheet.bindColumn(this.doDeckCodeColumn, doDeckCodeColInfo);
        sheet.bindColumn(this.doDeckChangeCodeColumn, doDeckChangeCodeColInfo);
        sheet.bindColumn(this.doDeckVersionColumn, doDeckVersionColInfo);
        sheet.bindColumn(this.doTrustManagerColumn, doTrustManagerColInfo);
        sheet.bindColumn(this.doBankColumn, doBankColInfo);
        sheet.bindColumn(this.doStatusColumn, doStatusColInfo);
        sheet.bindColumn(this.doBCTransIdColumn, doBCTransIdColInfo);
        sheet.setColumnCount(this.doGridColCount, GC.Spread.Sheets.SheetArea.viewport);
        // Lock/Readonly all columns
        sheet.options.isProtected = true;
        sheet.options.protectionOptions = {
            allowSelectUnlockedCells: true,
            allowSelectLockedCells: false
        };
        // set cell alignment
        sheet.getCell(-1, this.doCheckBoxColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doPropertyNoColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doProductColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doInterestTypeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doDecimalInterestColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doStartDateColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doDeckCodeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doDeckChangeCodeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doDeckVersionColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.doStatusColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        // Add Checkbox to grid
        var cellTypeCheckbox = new GC.Spread.Sheets.CellTypes.CheckBox();
        sheet.setCellType(-1, 0, cellTypeCheckbox);
        // Unlock the checkbox column
        sheet.getRange(-1, this.doCheckBoxColumn, -1, this.doCheckBoxColumn).locked(false);
        // Hide column row numbers
        sheet.options.rowHeaderVisible = false;
        // console.log('Create grid events');
        // this.createDivisionOrderGridEvents();
        console.log('initiliseDivisionOrderGrid - resume paint');
        sheet.resumePaint();
    };
    OwnerContentComponent.prototype.applyDivisionOrderDataBinding = function (filteredDivisionOrders) {
        // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);
        var _this = this;
        console.log('applyDivisionOrderDataBinding - Start data binding');
        this.spread.setActiveSheetIndex(1);
        var sheet = this.spread.getActiveSheet();
        sheet.suspendPaint();
        sheet.setDataSource(filteredDivisionOrders);
        console.log('applyDivisionOrderDataBinding - Row Binding - start');
        filteredDivisionOrders.forEach(function (rowDOsObject, rowIndex) {
            // console.log('Row Index: ' + rowIndex, rowPropertiesObject);
            // Remove vertical grid-lines
            sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
            // Add Blockchain graphic to cell
            var cell = sheet.getCell(rowIndex, _this.doBlockChainColumn);
            var imgBlockChain = null;
            if (filteredDivisionOrders[rowIndex].bcTransId != null) {
                imgBlockChain = './assets/images/blockchain_cell@2x.png';
            }
            cell.text('');
            cell.backgroundImage(imgBlockChain);
        });
        console.log('applyDivisionOrderDataBinding - Row Binding - complete');
        // Highlight first row except blockchain graphic cell & set the font in all cells
        for (var col = 0; col < this.doGridColCount - 1; col++) {
            if (col !== this.doBlockChainColumn) {
                sheet.getCell(0, col).backColor(environment.gridHighlight);
                // sheet.getCell(-1, col).font('10pt Source Sans Pro');
            }
        }
        sheet.resumePaint();
        console.log('applyDivisionOrderDataBinding - Division Orders Binding Complete');
    };
    OwnerContentComponent.prototype.getPayments = function (operatorId, ownerId) {
        var _this = this;
        console.log('Get Payments - start');
        return this.ownerService.getPayments(operatorId, ownerId).toPromise().then(function (payments) {
            console.log({ payments: payments });
            _this.payments = payments;
            var tempPayments = JSON.parse(JSON.stringify(payments));
            tempPayments = _this.setPaymentValues(tempPayments);
            _this.payments = tempPayments;
            _this.initilisePaymentGrid();
            _this.applyPaymentDataBinding(tempPayments);
            console.log('getPayments - complete: ' + new Date().toISOString() + '  No. reocrds: ' + _this.payments.length);
        }).catch(function (err) {
            console.log('getPayments:Error ' + err);
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    _this.router.navigate(['/login']);
                }
            }
        });
    };
    OwnerContentComponent.prototype.setPaymentValues = function (filteredOperatorPayments) {
        // console.log('setPaymentValues - start');
        filteredOperatorPayments.forEach(function (operatorPayment) {
            // console.log({ operatorPayment });
            operatorPayment.bankName = operatorPayment.bank.name;
            operatorPayment.paymentTypeName = operatorPayment.paymentType.name;
            operatorPayment.paymentStatusName = operatorPayment.paymentStatus.name;
        });
        // console.log('setPaymentValues - end');
        return filteredOperatorPayments;
    };
    OwnerContentComponent.prototype.initilisePaymentGrid = function () {
        // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
        // And grid events that are NOT data specific
        console.log('Initialise Payments Grid tab');
        // Work with Payments sheet
        this.spread.setActiveSheetIndex(2);
        var sheet = this.spread.getActiveSheet();
        sheet.suspendPaint();
        // Define columns
        var paymentCheckBoxColInfo = { name: '', displayName: '', size: 50 };
        var paymentDateColInfo = { name: 'paymentDate', displayName: 'Date', size: 120 };
        var paymentBankColInfo = { name: 'bankName', displayName: 'Bank', size: 120 };
        var paymentTypeColInfo = { name: 'paymentTypeName', displayName: 'Type', size: 100 };
        var paymentCheckNoColInfo = { name: 'checkNo', displayName: 'Check #', size: 120 };
        var paymentCountColInfo = { name: 'paymentCount', displayName: '#Royalties', size: 80 };
        var paymentAmountColInfo = { name: 'amount', displayName: 'Check Amount', formatter: '0.00', size: 120 };
        var paymentStatusColInfo = { name: 'paymentStatusName', displayName: 'Status', size: 120 };
        sheet.autoGenerateColumns = false;
        sheet.setDataSource(this.payments);
        sheet.bindColumn(this.paymentCheckBoxColumn, paymentCheckBoxColInfo);
        sheet.bindColumn(this.paymentDateColumn, paymentDateColInfo);
        sheet.bindColumn(this.paymentBankColumn, paymentBankColInfo);
        sheet.bindColumn(this.paymentTypeColumn, paymentTypeColInfo);
        sheet.bindColumn(this.paymentCheckNoColumn, paymentCheckNoColInfo);
        sheet.bindColumn(this.paymentCountColumn, paymentCountColInfo);
        sheet.bindColumn(this.paymentAmountColumn, paymentAmountColInfo);
        sheet.bindColumn(this.paymentStatusColumn, paymentStatusColInfo);
        sheet.setColumnCount(this.paymentGridColCount, GC.Spread.Sheets.SheetArea.viewport);
        // Lock/Readonly all columns
        sheet.options.isProtected = true;
        sheet.options.protectionOptions = {
            allowSelectUnlockedCells: true,
            allowSelectLockedCells: false
        };
        // set cell alignment
        sheet.getCell(-1, this.paymentCheckBoxColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.paymentDateColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.paymentBankColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.paymentTypeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.paymentCheckNoColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.paymentCountColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        sheet.getCell(-1, this.paymentAmountColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        sheet.getCell(-1, this.paymentStatusColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        // Add Checkbox to grid
        var cellTypeCheckbox = new GC.Spread.Sheets.CellTypes.CheckBox();
        sheet.setCellType(-1, 0, cellTypeCheckbox);
        // Unlock the checkbox column
        sheet.getRange(-1, this.doCheckBoxColumn, -1, this.doCheckBoxColumn).locked(false);
        // Hide column row numbers
        sheet.options.rowHeaderVisible = false;
        console.log('initilisePaymentGrid - resume paint');
        sheet.resumePaint();
    };
    OwnerContentComponent.prototype.applyPaymentDataBinding = function (filteredPayments) {
        // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredPayments);
        console.log('applyPaymentDataBinding - Start data binding');
        // Work with Payments sheet
        this.spread.setActiveSheetIndex(2);
        var sheet = this.spread.getActiveSheet();
        console.log('initilisePaymentGrid - Sheet selected');
        sheet.suspendPaint();
        sheet.setDataSource(filteredPayments);
        console.log('applyPaymentDataBinding - Row Binding - start');
        filteredPayments.forEach(function (rowPaymentObject, rowIndex) {
            // console.log('Row Index: ' + rowIndex, rowPropertiesObject);
            // Remove vertical grid-lines
            sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
        });
        console.log('applyPaymentDataBinding - Row Binding - complete');
        sheet.resumePaint();
        console.log('applyPaymentDataBinding - Division Orders Binding Complete');
    };
    OwnerContentComponent.prototype.createGridEvents = function () {
        var _this = this;
        // Bind click-event of Property Id and to Blockchain cells
        this.spread.bind(GC.Spread.Sheets.Events.CellClick, function (e, args) {
            var row = args.row;
            var col = args.col;
            var activeSheet = _this.spread.getActiveSheetIndex();
            console.log('createGridEvents - Cell Click Event - Active Sheet [' + activeSheet + '] ,  Row [' + row + '],   Col [' +
                col + ']   Sheet Name: ' + _this.spread.getActiveSheet);
            if (activeSheet === 0) { // Property
                var sheet = _this.spread.getSheet(0);
                // Get selected property form array
                var selectedProperty = _this.properties[row];
                console.log("createGridEvents - [Row: " + row + "] Property with Id " + selectedProperty.id + "   [Col: " + col + "]");
                if (col === _this.propertyBlockChainColumn || col === _this.propertyBCTransIdColumn) {
                    var bcTransId = sheet.getValue(row, _this.propertyBCTransIdColumn);
                    if (bcTransId != null && bcTransId !== '') {
                        var url = '';
                        if (selectedProperty.bcBlockId != null) {
                            url = _this.blockchainExplorerUrl + selectedProperty.bcBlockId;
                        }
                        else {
                            url = _this.blockchainExplorerUrl;
                        }
                        window.open(url);
                    }
                }
                else if (col > _this.propertyBlockChainColumn && col < _this.propertyBCTransIdColumn) {
                    _this.router.navigateByUrl('/propertyDetail/' + selectedProperty.id);
                }
            }
            else if (activeSheet === 1) { // Division Order
                // Get selected divisionOrder form array
                var selectedDivisionOrder = _this.divisionOrders[row];
                console.log("createGridEvents - [Row: " + row + "] Division Order with Id " + selectedDivisionOrder.id + "   [Col: " + col + "]");
                var sheet = _this.spread.getSheet(1);
                if (col === _this.doBlockChainColumn || col === _this.doBCTransIdColumn) {
                    var bcTransId = sheet.getValue(row, _this.doBCTransIdColumn);
                    if (bcTransId != null && bcTransId !== '') {
                        var url = '';
                        if (selectedDivisionOrder.bcBlockId != null) {
                            url = _this.blockchainExplorerUrl + selectedDivisionOrder.bcBlockId;
                        }
                        else {
                            url = _this.blockchainExplorerUrl;
                        }
                        window.open(url);
                    }
                }
                else if (col > _this.doBlockChainColumn && col < _this.doBCTransIdColumn) {
                    // Get selected divisionorder form array
                    _this.selectedDivisionOrder = _this.divisionOrders[row];
                    _this.displayDivisionOrderDialog = true;
                }
            }
            else { // Payments
                // Get selected payment form array
                var selectedPayment = _this.payments[row];
                console.log("createGridEvents - [Row: " + row + "] Payment with Id " + selectedPayment.id + "   [Col: " + col + "]");
                // const sheet = this.spread.getSheet(2);
                // this.router.navigateByUrl('/paymentDetail/' + selectedPayment.id);
            }
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.EnterCell, function (e, args) {
            var row = args.row;
            var activeSheet = _this.spread.getActiveSheetIndex();
            console.log('createGridEvents - Enter Cell Event - Active Sheet [' + activeSheet + ']   Row [' + row + ']   Sheet Name: '
                + _this.spread.getActiveSheet);
            if (activeSheet === 0) { // Property
                var sheet = _this.spread.getSheet(0);
                sheet.suspendPaint();
                // Highlight all cells of the row except changed cells
                for (var col = 0; col < _this.propertyGridColCount - 1; col++) {
                    var cell = sheet.getCell(row, col);
                    if (col !== _this.propertyBlockChainColumn) {
                        var cellBackColor = cell.backColor();
                        if (cellBackColor !== environment.gridCellChanged) {
                            sheet.getCell(row, col).backColor(environment.gridHighlight);
                        }
                    }
                }
                sheet.resumePaint();
            }
            else if (activeSheet === 1) { // DivisionOrder
                var sheet = _this.spread.getSheet(1);
                sheet.suspendPaint();
                // Highlight all cells of the row except changed cells
                for (var col = 0; col < _this.doGridColCount - 1; col++) {
                    var cell = sheet.getCell(row, col);
                    if (col !== _this.doBlockChainColumn) {
                        var cellBackColor = cell.backColor();
                        if (cellBackColor !== environment.gridCellChanged) {
                            sheet.getCell(row, col).backColor(environment.gridHighlight);
                        }
                    }
                }
                sheet.resumePaint();
            }
            // Note:: On payments sheet I do not implement an enterCell event
            // console.log('Row Enter Cell: ' + row);
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, function (e, args) {
            var row = args.row;
            var activeSheet = _this.spread.getActiveSheetIndex();
            console.log('createGridEvents - Leave Cell Event - Active Sheet [' + activeSheet + ']   Row [' + row + ']   Sheet Name: '
                + _this.spread.getActiveSheet);
            if (activeSheet === 0) { // Property
                var sheet = _this.spread.getSheet(0);
                sheet.suspendPaint();
                // Un-highlight all cells of the row except changed cells
                for (var col = 0; col < _this.propertyGridColCount - 1; col++) {
                    var cell = sheet.getCell(row, col);
                    if (col !== _this.propertyBlockChainColumn) {
                        var cellBackColor = cell.backColor();
                        if (cellBackColor !== environment.gridCellChanged) {
                            sheet.getCell(row, col).backColor(environment.gridBackground);
                        }
                    }
                }
                sheet.getCell(row, -1)
                    .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
                sheet.getCell(row, -1)
                    .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
                sheet.resumePaint();
            }
            else if (activeSheet === 1) {
                var sheet = _this.spread.getSheet(1);
                sheet.suspendPaint();
                // Un-highlight all cells of the row except changed cells
                for (var col = 0; col < _this.doGridColCount - 1; col++) {
                    var cell = sheet.getCell(row, col);
                    if (col !== _this.doBlockChainColumn) {
                        var cellBackColor = cell.backColor();
                        if (cellBackColor !== environment.gridCellChanged) {
                            sheet.getCell(row, col).backColor(environment.gridBackground);
                        }
                    }
                }
                sheet.getCell(row, -1)
                    .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
                sheet.getCell(row, -1)
                    .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
                sheet.resumePaint();
            }
            else if (activeSheet === 2) { // Payment
                var sheet = _this.spread.getSheet(2);
                sheet.suspendPaint();
                sheet.getCell(row, -1)
                    .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
                sheet.getCell(row, -1)
                    .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
                sheet.resumePaint();
            }
            // console.log('Row Leave Cell: ' + row);
        });
    };
    OwnerContentComponent.prototype.propertyNoClick = function (propertyNo) {
        var url = 'propertyDetail/' + propertyNo;
        // alert('Url: ' + url);
        this.router.navigateByUrl(url);
    };
    OwnerContentComponent.prototype.onBack = function () {
        this.location.back();
    };
    OwnerContentComponent.prototype.showTaxReport = function () {
        console.log('Show Tax report clicked');
        this.displayTaxReport = true;
        this.displayTaxReportChange.emit(true);
    };
    OwnerContentComponent.prototype.formatDate = function (date) {
        // Checks if date is null or undefined (=== strict check for null only)
        if (date == null) {
            return null;
        }
        if (date === '') {
            return null;
        }
        // Format the date to MM/DD/YYYY
        var dateStr = date.substr(5, 2) + '/' + date.substr(8, 2) + '/' + date.substr(0, 4);
        // alert('Date Before: ' + date + '   Date String: ' + dateStr);
        return dateStr;
    };
    OwnerContentComponent.prototype.clickPropertiesTab = function () {
        this.spread.setActiveSheetIndex(0);
    };
    OwnerContentComponent.prototype.clickDivisionOrdersTab = function () {
        this.spread.setActiveSheetIndex(1);
    };
    OwnerContentComponent.prototype.clickPaymentsTab = function () {
        this.spread.setActiveSheetIndex(2);
    };
    OwnerContentComponent.prototype.workbookInit = function (args) {
        console.log("Workbook Init", { args: args });
        this.spread = args.spread;
        this.sheet = this.spread.getActiveSheet();
        this.sheetWidth = this.sheet.getViewportWidth(1);
        console.log('SheetWidth: ', this.sheetWidth);
        this.sheet.name('Properties');
        this.spread.addSheet(1, new GC.Spread.Sheets.Worksheet('DivisionOrders'));
        this.spread.addSheet(2, new GC.Spread.Sheets.Worksheet('Payments'));
    };
    return OwnerContentComponent;
}());
export { OwnerContentComponent };
