import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.css']
})
export class OwnerComponent implements OnInit {

  displayTaxReport = false;

  constructor() { }

  ngOnInit() {
  }

  closeTaxReport() {
    this.displayTaxReport = false;
  }

  onClick() {
    console.log('DisplayTaxReport: ' + this.displayTaxReport);
  }
}
