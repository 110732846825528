import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Product } from './model/product';
import { USState } from './model/us_state';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  private readonly lookupUrl = `${environment.apiUrl}/lookup`;

  constructor(private http: HttpClient) { }

  // GET
  getStates() {
    const url = `${this.lookupUrl}/getStatesCounties`;

    console.log('GetStates Url: ', url);

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type': 'application/json',
    //     'Access-Control-Request-Headers': 'Content-Type',
    //     // 'Access-Control-Request-Method': 'GET'
    //   })
    // };

    // return this.http.get(url, httpOptions);
    return this.http.get(url).toPromise();
  }

  // GET
  getOperators(operatorId: number) {
    const url = `${this.lookupUrl}/getOperators/${operatorId}`;

    console.log('GetOperators Url: ', url);

    return this.http.get(url).toPromise();
  }

  // GET
  getPurchasers() {
    const url = `${this.lookupUrl}/getPurchasers`;

    console.log('GetPurchasers Url: ', url);

    return this.http.get(url).toPromise();
  }

  // GET
  getProducts() {
    const url = `${this.lookupUrl}/getProducts`;

    console.log('GetProducts Url: ', url);

    return this.http.get(url).toPromise();
  }

  // GET
  getWells() {
    const url = `${this.lookupUrl}/getWells`;
    console.log('GetWells Url: ', url);

    return this.http.get(url).toPromise();
  }

  // GET
  getBanks() {
    const url = `${this.lookupUrl}/getBanks`;
    console.log('GetBanks Url: ', url);

    return this.http.get(url).toPromise();
  }

  // GET
  getGenericSearchResult(operatorId: number, searchKey: string) {
    const url = `${this.lookupUrl}/search/${operatorId}/${searchKey}`;
    console.log('GetGenericSearchResult Url: ', url);

    return this.http.get(url);
  }

  // GET
  getInterestTypes() {
    const url = `${this.lookupUrl}/getInterestTypes`;
    console.log('GetInterestTypes Url: ', url);

    return this.http.get(url).toPromise();
  }
}
