<kendo-dialog *ngIf="displayChangePassword">
  <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
    <!-- @dialog Modal for adding a new Property -->
    <div id="dialog_change_password" title="change Password" role="dialog" class="dialog">
      <div class="dialog_header">
        <h1>Change Password</h1>
        <div class="dialog_close" (click)="closeChangePasswordDialog()">
          <button type="button" class="close_modal btn_normal btn_icon_only">
            <span class="btn_icon"><span class="icon_close"></span></span>
            <span class="btn_text hidden_text">Close Dialog or press Escape to dismiss</span>
          </button>
        </div>
      </div>
      <div class="dialog_content">
        <div class="dialog_inner_content">
          <fieldset>

            <div *ngIf="submitted && changed" class="alert alert-success">
                Password Changes Successfully.
            </div>

            <div *ngIf="submitted && !changed && errorMessage && !(changePasswordForm.touched || changePasswordForm.dirty)" class="alert alert-danger">
              {{ errorMessage }}
            </div>

            <div class="form_row inline">
              <div class="form_label">
                <label for="old_password">Old Password</label>
              </div>
              <div class="form_input">
                <input type="password" name="old_password" id="old_password" class="inputbox" formControlName="oldPassword" autocomplete="off"/>
              </div>
            </div>
            <div *ngIf="changePasswordForm.controls['oldPassword'].invalid && (changePasswordForm.controls['oldPassword'].dirty || changePasswordForm.controls['oldPassword'].touched)"
                 class="alert alert-danger">
              <div *ngIf="changePasswordForm.controls['oldPassword'].errors.required">
                Old Password is required.
              </div>
              <!--<div *ngIf="changePasswordForm.controls['oldPassword'].errors.misMatch">
                Old Passwords do not match.
              </div>-->
            </div>

            <div class="form_row inline">
              <div class="form_label">
                <label for="new_password">New Password</label>

              </div>
              <div class="form_input">
                <input type="password" name="new_password" id="new_password" class="inputbox" formControlName="newPassword" autocomplete="off"/>
              </div>
            </div>

            <div *ngIf="changePasswordForm.controls['newPassword'].invalid && (changePasswordForm.controls['newPassword'].dirty || changePasswordForm.controls['newPassword'].touched)"
                 class="alert alert-danger">
              <div *ngIf="changePasswordForm.controls['newPassword'].errors.required">
                Password is required.
              </div>
              <div *ngIf="changePasswordForm.controls['newPassword'].errors.minlength">
                Password must contain at least 8 characters.
              </div>
              <div *ngIf="changePasswordForm.controls['newPassword'].errors.maxlength">
                 Password cannot exceed 20 characters.
              </div>
              <div *ngIf="changePasswordForm.controls['newPassword'].errors.pattern">
                Password must contain 1 lower case, 1 upper case, 1 number and 1 special characters [@$!%*?&_]
              </div>
            </div>


            <div class="form_row inline">
              <div class="form_label">
                <label for="confirm_new_password">Confirm New Password</label>
              </div>
              <div class="form_input">
                <input type="password" name="confirm_new_password" id="confirm_new_password" class="inputbox" formControlName="confirmPassword" autocomplete="off"/>
              </div>
            </div>

            <div *ngIf="changePasswordForm.controls['confirmPassword'].invalid && (changePasswordForm.controls['confirmPassword'].dirty || changePasswordForm.controls['confirmPassword'].touched)"
                 class="alert alert-danger">
              <div *ngIf="changePasswordForm.controls['confirmPassword'].errors.required">Confirm Password is required</div>
              <div *ngIf="changePasswordForm.controls['confirmPassword'].errors.confirmedValidator">New Password and Confirm Password must match</div>
            </div>

          </fieldset>


        </div>
      </div>
      <div class="dialog_actions clearfix" style="background: none">
        <div class="actions_left">
          <!-- <button type="button" class="btn_normal btn_white" id="btn_upload_property_alt">
            <span class="btn_icon"><span class="icon_upload_cloud"></span></span>
            <span class="btn_text">Upload</span>
          </button> -->
        </div>
        <div class="actions_right">
          <button type="button" class="btn_normal btn_grey btn_cancel_modal" (click)="closeChangePasswordDialog()">
            <span class="btn_text">Cancel</span>
          </button>
          <button
            type="submit"
            name="submit"
            value="Append"
            class="btn_normal btn_blue"
            id="btn_confirm_add_property"
            [disabled]="changePasswordForm.invalid"
            >
            <span class="btn_text">Change Password</span>
          </button>
          <!-- <div class="form_submit">
            <button type="submit" name="submit" class="btn_normal btn_blue" id="btn_confirm_save_property">Save Property</button>
          </div> -->
        </div>
      </div>
    </div>
  </form>
</kendo-dialog>

