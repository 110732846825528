import { Component, OnInit } from '@angular/core';

declare var $: any; // jquery
declare let datepicker;
declare let modals;
declare let removeHash;

@Component({
  selector: 'app-owner-add',
  templateUrl: './owner-add.component.html',
  styleUrls: ['./owner-add.component.css']
})
export class OwnerAddComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $(document).ready(() => {

      datepicker();
      modals();

      $('#dialog_add_owner').dialog({
        autoOpen: false,
        height: 'auto',
        width: 960,
        modal: true,
        show: 'fade',
        hide: 'fade',
        open: () => {

        }
      });

      // Add Owner
      $('#btn_add_owner').click(() => {
        $('#dialog_add_owner').dialog('open');
        $('body').addClass('no_scroll');
      });

      // Switch to Upload Owner
      $('#btn_upload_owner, #btn_upload_owner_alt').click(() => {
        $('#dialog_add_owner').dialog('close');
        $('#dialog_upload_owner').dialog('open');
        $('body').addClass('no_scroll');
      });


      // Confirm Adding a Note
      $('#btn_confirm_add_owner').click(() => {
        $('#dialog_add_owner').dialog('close');
        $('#message_processing_owner').fadeIn();

        // Wait duration until hiding the loader
        setTimeout(
          () => {
            $('#message_processing_owner').hide();
          }, 3000);

        // Wait duration of Loader before showing Message
        setTimeout(
          () => {
            $('#message_add_owner').show().delay(5000).fadeOut();
          }, 3000);

        $('html, body').removeClass('no_scroll');
      });


      // Show the Dialog if the has on the page is add_owner
      $(() => {
        if (location.hash === '#add_owner') {
          $('#dialog_add_owner').dialog('open');
          $('body').addClass('no_scroll');
          removeHash();
        }
      });
    });
  }

}
