<app-divisionorder
  [divOrder]="selectedDivisionOrder"
  [(displayDODialog)]="displayDivisionOrderDialog"
></app-divisionorder>

<div class="page_container">
  <div id="content_inner">
    <!-- Content Header -->
    <div class="content_header">
      <div class="content_history">
        <!-- <button type="button" class="btn_normal btn_big btn_icon_only" id="btn_history_back" (click)="onBack()">
					<span class="btn_icon"><span class="icon_back"></span></span>
					<span class="btn_text">Back</span>
				</button> -->
      </div>
      <div class="content_title">
        <h1>Owner - {{ owner.fname }} {{ owner.lname }} #{{ owner.id }}</h1>
        <h3>Mineral Rights Owner</h3>
      </div>
      <div class="content_timestamp"></div>
      <div class="content_actions"></div>
    </div>

    <div class="notification error">
      <div class="notification_icon">
        <div class="notification_icon_main">
          <span class="icon_payment"></span>
        </div>
        <div class="notification_icon_shadow">
          <span class="icon_payment_shadow"></span>
        </div>
      </div>
      <div class="notification_details">
        <div class="notification_text">
          <div class="notification_title">Well Shut</div>
          <br />
          <div class="notification_timestamp">
            API#82-004-36457 reported shut on 15 April 2019.
          </div>
          <div class="notification_info">Reason: Depletion or closure</div>
          <div class="notification_info">This owner is affected</div>
        </div>
        <div class="notification_action">
          <a href="payments.html"></a>
        </div>
      </div>
      <div class="notification_close">
        <button
          type="button"
          class="btn_normal btn_icon_only btn_close_notification"
          title="Close Notification"
        >
          <span class="btn_icon"><span class="icon_close"></span></span>
          <span class="btn_text">Close Notification</span>
        </button>
      </div>
    </div>

    <!-- Owner Details -->
    <div class="content_extra" id="owner_details">
      <div class="inner_nav">
        <ul>
          <li>
            <a href="properties">Properties ({{ owner.noProperties }})</a>
          </li>
          <li>
            <a href="properties">Deeds ({{ owner.noDeeds }})</a>
          </li>
          <li>
            <a href="properties">Leases ({{ owner.noLeases }})</a>
          </li>
          <li>
            <a href="properties"
              >Division Orders ({{ owner.noDivisionOrders }})</a
            >
          </li>
          <li><p class="href" (click)="showTaxReport()">Tax Report</p></li>
        </ul>
      </div>

      <div class="space_divider"></div>
      <div class="col_group col_4">
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Tax ID</div>
            <div class="data_value">{{ owner.taxNo }}</div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Telephone</div>
            <div class="data_value">{{ owner.tel }}</div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Email</div>
            <div class="data_value">
              <a href="mailto:{{ owner.email }}">{{ owner.email }}</a>
            </div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Address</div>
            <div class="data_value">{{ getOwnerAddress() }}</div>
          </div>
        </div>
      </div>
      <div class="line_divider"></div>
      <div class="col_group col_4">
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Trust Manager</div>
            <div class="data_value">Michael Wilderman</div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Contact Info</div>
            <div class="data_value">+1 (0)87 653-5555</div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Email</div>
            <div class="data_value">
              <a href="mailto:michaelwilderman@transbank.com"
                >michaelwilderman@transbank.com</a
              >
            </div>
          </div>
        </div>
        <div class="col_item">
          <div class="data_row">
            <div class="data_label">Designation of Rights</div>
            <div class="data_value">
              Client #5012, TransBank, 1 Bankplace, Wyoming, 81234
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col_group">
      <div class="col_item">
        <fieldset class="form_row no_padding clearfix">
          <legend class="hidden_legend">Chart View</legend>
          <div class="toggle_group">
            <div class="toggle_group_option" (click)="clickPropertiesTab()">
              <div class="toggle_input">
                <input
                  type="radio"
                  value="owner_properties_tab"
                  id="view_properties"
                  name="switch_view"
                  class="switch_view"
                  checked=""
                />
              </div>
              <label for="view_properties" class="toggle_active">Properties</label>
            </div>
            <div class="toggle_group_option" (click)="clickDivisionOrdersTab()">
              <div class="toggle_input">
                <input
                  type="radio"
                  value="owner_divisionorder_tab"
                  id="view_divisionorders"
                  name="switch_view"
                  class="switch_view"
                />
              </div>
              <label for="view_divisionorders" class="">Division Orders</label>
            </div>
            <div class="toggle_group_option" (click)="clickPaymentsTab()">
              <div class="toggle_input">
                <input
                  type="radio"
                  value="owner_payment_tab"
                  id="view_payments"
                  name="switch_view"
                  class="switch_view"
                />
              </div>
              <label for="view_payments" class="">Payments</label>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col_item"></div>
    </div>

    <div class="space_divider"></div>

    <!-- Body Content -->
    <div class="content_body">
      <div class="owner_tab" id="owner_properties_tab">
        <div class="content_action_bar" style="visibility: hidden;height: 0px;">
          <div class="action_bar_left">
            <ul>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_properties">Properties</label>
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_properties"
                      name="filter_properties"
                    >
                      <option value="all">All Properties (4)</option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_production_types"
                      >Production Types</label
                    >
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_production_types"
                      name="filter_production_types"
                    >
                      <option value="all">All Production Types</option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_period">Period</label>
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_period"
                      name="filter_period"
                    >
                      <option value="6m">Last 6 months</option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="action_bar_right">
            <div class="fine_print">
              Showing results from 5 Sep 2018 to 4 Feb 2020
            </div>
          </div>
        </div>
        <div class="scroll_container">
        <div id="spread-container" class="spread-container" #spreadContainer style="height: 200px;">
            <!-- Spreadsheet LOADED -->
            <gc-spread-sheets
              #spreadDiv
              id="property-table-data"
              class="spreadsheet"
              [tabStripVisible]="tabStripVisible"
              [backColor]="spreadBackColor"
              [hostStyle]="hostStyle"
              hostClass="spreadContainer"
              (workbookInitialized)="workbookInit($event)"
            >
            </gc-spread-sheets>
          </div>
        </div>
      </div>
      <div class="owner_tab" id="owner_divisionorder_tab" style="display: none">
        <!-- Action Bar -->
        <div class="content_action_bar">
          <div class="action_bar_left">
            <ul>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_properties">Properties</label>
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_properties"
                      name="filter_properties"
                    >
                      <option value="all">All Properties (4)</option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_production_types"
                      >Production Types</label
                    >
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_production_types"
                      name="filter_production_types"
                    >
                      <option value="all">All Production Types</option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_period">Period</label>
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_period"
                      name="filter_period"
                    >
                      <option value="6m">Last 6 months</option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="action_bar_right">
            <div class="fine_print">
              Showing results from 5 Sep 2018 to 4 Feb 2020
            </div>
          </div>
        </div>
        Division orders
        <div class="scroll_container">
          <div class="table_placeholder">
            <div class="table_loader">
              <div class="table_loader_icon">
                <span class="icon_table"></span>
              </div>
              <div class="table_loader_text">
                <h4>No Payments Added</h4>
                <p>Upload or Manualy add your Payments</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="owner_tab" id="owner_payment_tab" style="display: none">
        <!-- Action Bar -->
        <div class="content_action_bar">
          <div class="action_bar_left">
            <ul>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_properties">Properties</label>
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_properties"
                      name="filter_properties"
                    >
                      <option value="all">All Properties (4)</option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_production_types"
                      >Production Types</label
                    >
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_production_types"
                      name="filter_production_types"
                    >
                      <option value="all">All Production Types</option>
                    </select>
                  </div>
                </div>
              </li>
              <li>
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="filter_period">Period</label>
                  </div>
                  <div class="form_input">
                    <select
                      class="inputbox"
                      id="filter_period"
                      name="filter_period"
                    >
                      <option value="6m">Last 6 months</option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="action_bar_right">
            <div class="fine_print">
              Showing results from 5 Sep 2018 to 4 Feb 2020
            </div>
          </div>
        </div>
        Payments
        <div class="scroll_container">
          <div class="table_placeholder">
            <div class="table_loader">
              <div class="table_loader_icon">
                <span class="icon_table"></span>
              </div>
              <div class="table_loader_text">
                <h4>No Payments Added</h4>
                <p>Upload or Manualy add your Payments</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Body Footer -->
    <div class="content_footer">
      <div class="content_footer_inner">
        <div class="content_footer_left">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_white"
                id="btn_back"
                (click)="onBack()"
              >
                <span class="btn_icon"><span class="icon_back"></span></span>
                <span class="btn_text">Back</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="content_footer_right">
          <ul>
            <li>
              <button type="button" class="btn_normal btn_white" id="btn_share">
                <span class="btn_icon"><span class="icon_share"></span></span>
                <span class="btn_text">Share</span>
              </button>
            </li>
            <li>
              <button type="button" class="btn_normal btn_blue" id="btn_export">
                <span class="btn_icon"><span class="icon_export"></span></span>
                <span class="btn_text">Export</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
