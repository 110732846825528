import { OnInit } from '@angular/core';
var OwnerAddComponent = /** @class */ (function () {
    function OwnerAddComponent() {
    }
    OwnerAddComponent.prototype.ngOnInit = function () {
        $(document).ready(function () {
            datepicker();
            modals();
            $('#dialog_add_owner').dialog({
                autoOpen: false,
                height: 'auto',
                width: 960,
                modal: true,
                show: 'fade',
                hide: 'fade',
                open: function () {
                }
            });
            // Add Owner
            $('#btn_add_owner').click(function () {
                $('#dialog_add_owner').dialog('open');
                $('body').addClass('no_scroll');
            });
            // Switch to Upload Owner
            $('#btn_upload_owner, #btn_upload_owner_alt').click(function () {
                $('#dialog_add_owner').dialog('close');
                $('#dialog_upload_owner').dialog('open');
                $('body').addClass('no_scroll');
            });
            // Confirm Adding a Note
            $('#btn_confirm_add_owner').click(function () {
                $('#dialog_add_owner').dialog('close');
                $('#message_processing_owner').fadeIn();
                // Wait duration until hiding the loader
                setTimeout(function () {
                    $('#message_processing_owner').hide();
                }, 3000);
                // Wait duration of Loader before showing Message
                setTimeout(function () {
                    $('#message_add_owner').show().delay(5000).fadeOut();
                }, 3000);
                $('html, body').removeClass('no_scroll');
            });
            // Show the Dialog if the has on the page is add_owner
            $(function () {
                if (location.hash === '#add_owner') {
                    $('#dialog_add_owner').dialog('open');
                    $('body').addClass('no_scroll');
                    removeHash();
                }
            });
        });
    };
    return OwnerAddComponent;
}());
export { OwnerAddComponent };
