<!-- @dialog Modal for Division Order -->
<kendo-dialog *ngIf="displayDODialog">
  <div id="dialog_royalty_interest" title="Division Order">
    <div class="royalty_container">
      <div class="royalty_owner">
        <div class="royalty_owner_details">
          <div class="royalty_owner_icon">
            <span class="icon_profile"></span>
          </div>
          <div class="royalty_owner_name">{{ divOrder?.ownerName }}</div>
          <!-- <div class="royalty_owner_title" (click)="onClickMRO()">Mineral Rights Owner</div> -->
          <div class="royalty_owner_title">Mineral Rights Owner</div>
        </div>
        <div class="data_row">
          <div class="data_label">Telephone:</div>
          <div class="data_value">{{ divOrder?.ownerTel }}</div>
        </div>
        <div class="data_row">
          <div class="data_label">Address:</div>
          <div class="data_value">{{ divOrder?.ownerAddr }}</div>
        </div>
        <div class="data_row">
          <div class="data_label">Email:</div>
          <div class="data_value">
            <a href="{{ divOrder?.ownerEmail }}">{{ divOrder?.ownerEmail }}</a>
          </div>
        </div>
      </div>
      <div class="royalty_details">
        <div class="dialog_header">
          <h1>Corporate Oil Inc</h1>
          <h3>
            <a
              href="{{ blockchainExplorerUrl + divOrder?.bcBlockId }}"
              target="_blank"
              ><span class="icon_blockchain"></span> DL{{
                divOrder?.bcBlockId
              }}#</a
            >
          </h3>
          <div class="dialog_close" (click)="onClose()">
            <button type="button" class="close_modal btn_normal btn_icon_only">
              <span class="btn_icon"><span class="icon_close"></span></span>
              <span class="btn_text hidden_text"
                >Close Dialog or press Escape to dismiss</span
              >
            </button>
          </div>
        </div>

        <div class="dialog_content">
          <div class="dialog_inner_content">
            <ul class="data_list">
              <li>
                <div class="data_list_label">Property No:</div>
                <div class="data_list_value">#{{ divOrder?.propertyNo }}</div>
              </li>
              <li>
                <div class="data_list_label">Product:</div>
                <div class="data_list_value">{{ divOrder?.productName }}</div>
              </li>
              <li>
                <div class="data_list_label">Division Order Date:</div>
                <div class="data_list_value">{{ divOrder?.doDateStr }}</div>
              </li>
              <li>
                <div class="data_list_label">Term Start:</div>
                <div class="data_list_value">{{ divOrder?.startDateStr }}</div>
              </li>
              <li>
                <div class="data_list_label">Term End:</div>
                <div class="data_list_value">{{ divOrder?.endDateStr }}</div>
              </li>
              <li>
                <div class="data_list_label">Interest Type:</div>
                <div class="data_list_value">
                  {{ divOrder?.interestType.abbr }}
                </div>
              </li>
              <li>
                <div class="data_list_label">Decimal Interest:</div>
                <div class="data_list_value">
                  {{ divOrder?.decimalInterest }}
                </div>
              </li>
              <li>
                <div class="data_list_label">Deck/Deck Change Code:</div>
                <div class="data_list_value">
                  {{ divOrder.deck.deckCode }} / {{ divOrder.deck.deckChangeCode }}
                </div>
              </li>
              <li>
                <div class="data_list_label">Deck Version:</div>
                <div class="data_list_value">
                  {{ divOrder.deck.deckVersionDate }}
                </div>
              </li>
              <li>
                <div class="data_list_label">Non-Production Rate:</div>
                <div class="data_list_value">$125.00/month</div>
              </li>
              <li>
                <div class="data_list_label">Allowable Deductions:</div>
                <div class="data_list_value">4</div>
              </li>
            </ul>
          </div>
          <div class="dialog_actions no_background clearfix">
            <div class="actions_left"></div>
            <div class="actions_right">
              <button
                type="button"
                class="btn_normal btn_grey btn_cancel_modal"
                (click)="onClose()"
              >
                <span class="btn_text">Cancel</span>
              </button>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_request_approval_royalty_interest"
                (click)="sendForApproval()"
              >
                <span class="btn_text">Send for Approval</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>
