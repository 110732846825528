import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LookupService = /** @class */ (function () {
    function LookupService(http) {
        this.http = http;
        this.lookupUrl = environment.apiUrl + "/lookup";
    }
    // GET
    LookupService.prototype.getStates = function () {
        var url = this.lookupUrl + "/getStatesCounties";
        console.log('GetStates Url: ', url);
        // const httpOptions = {
        //   headers: new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Access-Control-Request-Headers': 'Content-Type',
        //     // 'Access-Control-Request-Method': 'GET'
        //   })
        // };
        // return this.http.get(url, httpOptions);
        return this.http.get(url).toPromise();
    };
    // GET
    LookupService.prototype.getOperators = function (operatorId) {
        var url = this.lookupUrl + "/getOperators/" + operatorId;
        console.log('GetOperators Url: ', url);
        return this.http.get(url).toPromise();
    };
    // GET
    LookupService.prototype.getPurchasers = function () {
        var url = this.lookupUrl + "/getPurchasers";
        console.log('GetPurchasers Url: ', url);
        return this.http.get(url).toPromise();
    };
    // GET
    LookupService.prototype.getProducts = function () {
        var url = this.lookupUrl + "/getProducts";
        console.log('GetProducts Url: ', url);
        return this.http.get(url).toPromise();
    };
    // GET
    LookupService.prototype.getWells = function () {
        var url = this.lookupUrl + "/getWells";
        console.log('GetWells Url: ', url);
        return this.http.get(url).toPromise();
    };
    // GET
    LookupService.prototype.getBanks = function () {
        var url = this.lookupUrl + "/getBanks";
        console.log('GetBanks Url: ', url);
        return this.http.get(url).toPromise();
    };
    // GET
    LookupService.prototype.getGenericSearchResult = function (operatorId, searchKey) {
        var url = this.lookupUrl + "/search/" + operatorId + "/" + searchKey;
        console.log('GetGenericSearchResult Url: ', url);
        return this.http.get(url);
    };
    // GET
    LookupService.prototype.getInterestTypes = function () {
        var url = this.lookupUrl + "/getInterestTypes";
        console.log('GetInterestTypes Url: ', url);
        return this.http.get(url).toPromise();
    };
    /** @nocollapse */ LookupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LookupService_Factory() { return new LookupService(i0.ɵɵinject(i1.HttpClient)); }, token: LookupService, providedIn: "root" });
    return LookupService;
}());
export { LookupService };
