import { OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './../auth.service';
import { environment } from '../../environments/environment';
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(router, auth) {
        var _this = this;
        this.router = router;
        this.auth = auth;
        this.tickerDisplayFlag = environment.showTicker;
        this.notificationDisplayFlag = environment.showNotification;
        this.navigationSubscription = this.router.events.subscribe(function (e) {
            if (e instanceof NavigationEnd) {
                _this.initiliseDashboard();
            }
        });
    }
    DashboardComponent.prototype.initiliseDashboard = function () {
        // Set default values and re-fetch any data you need.
    };
    DashboardComponent.prototype.ngOnInit = function () {
    };
    DashboardComponent.prototype.ngOnDestroy = function () {
        if (this.navigationSubscription) {
            this.navigationSubscription.unsubscribe();
        }
    };
    return DashboardComponent;
}());
export { DashboardComponent };
