import { Router } from '@angular/router';
import { MessagesService } from './../messages.service';
import { OnInit, ElementRef } from '@angular/core';
import { Property } from './../model/property';
import { PropertyService } from './../property.service';
var PropertyComponent = /** @class */ (function () {
    function PropertyComponent(propertyService, messagesService, router) {
        this.propertyService = propertyService;
        this.messagesService = messagesService;
        this.router = router;
        this.tabStripVisible = false;
        this.spreadBackColor = 'aliceblue';
        this.sheetName = 'Property List';
        this.hostStyle = {
            height: '100%',
            width: '100%'
        };
        this.autoGenerateColumns = false;
    }
    PropertyComponent.prototype.ngOnInit = function () {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('user'));
        this.propertyService.getProperties(user.operator.id)
            .subscribe(function (properties) {
            console.log('Property Module: ', { properties: properties });
            _this.properties = properties;
        });
    };
    return PropertyComponent;
}());
export { PropertyComponent };
