import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {Subject} from 'rxjs';
import {User} from 'src/app/model/user';
import {ChangePassword} from 'src/app/model/changePassword';
import {AuthService} from 'src/app/auth.service';
import {UserService} from 'src/app/user.service';
import {ConfirmedValidator} from 'src/app/user/change-password/password.validators';
import {Router} from '@angular/router';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  pwdPattern = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&_])([a-zA-Z0-9@$!%*?&_]{8,20})$';
  currUser: User;
  submitted = false;
  changePassword: ChangePassword;
  changed: boolean;
  errorMessage: string;

  @Input() displayChangePassword: boolean;
  @Output() displayChangePasswordChange = new EventEmitter<boolean>();
  private ngUnsubscribe = new Subject();

  changePasswordForm: FormGroup = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.pwdPattern)]],
      confirmPassword: ['', Validators.required]

    }, {
      validators: ConfirmedValidator('newPassword', 'confirmPassword')
    });

  constructor(private authService: AuthService,
              private fb: FormBuilder,
              private router: Router,
              private userService: UserService ) {
  }

  ngOnInit() {
    this.submitted = false;
    this.changed = false;
    console.log('Password-Change - Init - start');
    this.changePassword = new ChangePassword();
    // Initialise the form to blank
    this.initChangePasswordForm();
    console.log('Password-Change - Init - complete');
  }

  ngOnDestroy() {
    console.log('The component is being destroyed');
    this.ngUnsubscribe.next();
  }

  closeChangePasswordDialog() {
    console.log('Password-Change - dialog - close - start');
    this.submitted = false;
    // this.initChangePasswordForm();
    this.changePasswordForm.reset();
    this.displayChangePassword = false;
    this.displayChangePasswordChange.emit(false);
    console.log('Password-Change - dialog - close - end');
  }

  onSubmit() {
    console.log('Password-Change - start');
    this.submitted = true;

    if (this.changePasswordForm.invalid) {
      return false;

    } else {
      this.currUser = this.authService.getUser();
      this.changePassword.email = this.currUser.email;
      this.changePassword.password = this.changePasswordForm.get('oldPassword').value;
      this.changePassword.newPassword = this.changePasswordForm.get('newPassword').value;

      this.userService.changePassword(this.changePassword).subscribe(
        data => {
          this.changed = true;
          setTimeout(() => {
            console.log(this.changed); // Will result in true;
            this.changed = false; // Here, value is updated
            this.closeChangePasswordDialog();
          }, 2000);
        },
        err => {
          console.log('changePassword() :Error');
          if (err instanceof HttpErrorResponse) {
            if (err.status === 400) {
              this.errorMessage = err.error;
              this.changePasswordForm.reset();

            } else if (err.status === 401) {
              this.router.navigate(['/login']);
            }

          }
        }
      );
    }
  }

  initChangePasswordForm() {
    this.changePasswordForm.patchValue({oldPassword: ''});
    this.changePasswordForm.patchValue({newPassword: ''});
    this.changePasswordForm.patchValue({confirmPassword: ''});
  }
}
