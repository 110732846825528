import { MessagesService } from './../messages.service';
import { SalesService } from './../sales.service';
import { OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
var LoadSalesCsvFileComponent = /** @class */ (function () {
    function LoadSalesCsvFileComponent(http, salesService, messagesService) {
        this.http = http;
        this.salesService = salesService;
        this.messagesService = messagesService;
        this.selectedFile = null;
        this.message = this.messagesService.noFileSelected;
        this.ngUnsubscribe = new Subject();
    }
    LoadSalesCsvFileComponent.prototype.onFileSelected = function (event) {
        this.selectedFile = event.target.files[0];
        this.message = this.selectedFile.name;
    };
    LoadSalesCsvFileComponent.prototype.onUpload = function () {
        var fileName = this.selectedFile.name;
        var fd = new FormData();
        fd.append('file', this.selectedFile, fileName);
        var resultObservable = this.salesService.postSalesFile(fd);
        var resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (res) {
            console.log('Response: ' + res);
        });
        this.selectedFile = null;
        this.message = fileName + ' - ' + this.messagesService.fileUploaded;
    };
    LoadSalesCsvFileComponent.prototype.ngOnInit = function () { };
    LoadSalesCsvFileComponent.prototype.ngOnDestroy = function () {
        console.log('The component is being destroyed');
        this.ngUnsubscribe.next();
    };
    return LoadSalesCsvFileComponent;
}());
export { LoadSalesCsvFileComponent };
