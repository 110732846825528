import { Component, OnInit } from '@angular/core';

declare var $: any; // jquery

@Component({
  selector: 'app-dashboard-notifications',
  templateUrl: './dashboard-notifications.component.html',
  styleUrls: ['./dashboard-notifications.component.css']
})
export class DashboardNotificationsComponent implements OnInit {

  lastUpdated: Date = new Date();

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('.btn_close_notification').click(function () {
        $(this)
          .closest('.notification')
          .remove();
      });
    });

    this.lastUpdated.setDate(new Date().getDate() - 2);
  }

}
