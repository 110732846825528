/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payments.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ticker/ticker.component.ngfactory";
import * as i3 from "../ticker/ticker.component";
import * as i4 from "../messages/messages.component.ngfactory";
import * as i5 from "../messages/messages.component";
import * as i6 from "./operator-payments-content/operator-payments-content.component.ngfactory";
import * as i7 from "@angular/common";
import * as i8 from "./operator-payments-content/operator-payments-content.component";
import * as i9 from "@angular/forms";
import * as i10 from "../lookup.service";
import * as i11 from "../operator-payments.service";
import * as i12 from "../messages.service";
import * as i13 from "@angular/router";
import * as i14 from "./payments.component";
var styles_PaymentsComponent = [i0.styles];
var RenderType_PaymentsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentsComponent, data: {} });
export { RenderType_PaymentsComponent as RenderType_PaymentsComponent };
export function View_PaymentsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticker", [], null, null, null, i2.View_TickerComponent_0, i2.RenderType_TickerComponent)), i1.ɵdid(1, 114688, null, 0, i3.TickerComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-messages", [], null, null, null, i4.View_MessagesComponent_0, i4.RenderType_MessagesComponent)), i1.ɵdid(3, 114688, null, 0, i5.MessagesComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-operator-payments-content", [], null, null, null, i6.View_OperatorPaymentsContentComponent_0, i6.RenderType_OperatorPaymentsContentComponent)), i1.ɵprd(512, null, i7.DatePipe, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(6, 245760, null, 0, i8.OperatorPaymentsContentComponent, [i9.FormBuilder, i10.LookupService, i11.OperatorPaymentsService, i12.MessagesService, i7.DatePipe, i7.Location, i13.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 6, 0); }, null); }
export function View_PaymentsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payments", [], null, null, null, View_PaymentsComponent_0, RenderType_PaymentsComponent)), i1.ɵdid(1, 114688, null, 0, i14.PaymentsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentsComponentNgFactory = i1.ɵccf("app-payments", i14.PaymentsComponent, View_PaymentsComponent_Host_0, {}, {}, []);
export { PaymentsComponentNgFactory as PaymentsComponentNgFactory };
