import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var $: any; // jquery

@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.css']
})
export class PropertiesComponent implements OnInit {

  action: string;
  displayAddProperty = false;
  displayUploadProperty = false;
  displayBlockchainErrorDialog = false;
  jsonExport: string;
  displayWaitingDialog = false;

  constructor(
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.action = this.route.snapshot.paramMap.get('action');

    if (this.action === null) {
      this.action = '';
    } else if (this.action.toUpperCase() === 'ADD') {
      this.addProperty();
    } else if (this.action.toUpperCase() === 'UPLOAD') {
      this.uploadProperty();
    }

    // Alternate way of getting action of url
    // - requires injecting "private router: Router" into the constructor
    // console.log('Router Url: ' + this.router.parseUrl(this.router.url));
    // const url = this.router.url;
    // const index = url.lastIndexOf('/');

    // this.action = '';
    // if (index > 0) {
    //   this.action = url.substr(index + 1);
    //   console.log('Action: ' + this.action);
    // }

    // The following will cause the code inside the function to be repeated every 5000 milli-seconds
    setInterval(a => {
      // alert('yes...');
    }, 5000, []);
  }


  public addProperty() {
    this.displayAddProperty = true;
  }

  public uploadProperty() {
    this.displayUploadProperty = true;
  }

  public closeBCDialog() {
    this.displayBlockchainErrorDialog = false;
  }

  public closeWaitingDialog() {
    this.displayWaitingDialog = false;
  }

}
