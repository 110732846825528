import { formatDate, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as GC from '@grapecity/spread-sheets';
import { DivisionOrder } from 'src/app/model/division_order';
import { Operator } from 'src/app/model/operator';
import { OperatorPayment } from 'src/app/model/operator_payment';
import { Owner } from 'src/app/model/owner';
import { Property } from 'src/app/model/property';
import { User } from 'src/app/model/user';
import { OwnerService } from 'src/app/owner.service';
import { environment } from 'src/environments/environment';


declare var $: any; // jquery

@Component({
  selector: 'app-owner-content',
  templateUrl: './owner-content.component.html',
  styleUrls: ['./owner-content.component.css']
})
export class OwnerContentComponent implements OnInit {

  // Show/Hide Tax Report
  @Input() displayTaxReport: boolean;
  @Output() displayTaxReportChange = new EventEmitter<boolean>();

  displayDivisionOrderDialog = false;
  selectedDivisionOrder: DivisionOrder;

  readonly blockchainExplorerUrl = `${environment.blockchainExplorerUrl}`;

  public spread: GC.Spread.Sheets.Workbook;
  public sheet: GC.Spread.Sheets.Worksheet = null;
  public sheetProperties: GC.Spread.Sheets.Worksheet = null;
  public sheetDivisionOrders: GC.Spread.Sheets.Worksheet = null;
  public sheetPayments: GC.Spread.Sheets.Worksheet = null;

  private sheetWidth: number;

  // Variable to set attributes of the spread js grid
  tabStripVisible = false;
  spreadBackColor = 'white';
  sheetName = 'Properties';
  hostStyle = {
    height: '100%',
    width: '100%'
  };
  hideGrid = true;

  // Define Property SpreadJS column indexes
  propertyCheckBoxColumn = 0;
  propertyBlockChainColumn = 1;
  propertyNoColumn = 2;
  propertyNameColumn = 3;
  propertyDescColumn = 4;
  propertyStateColumn = 5;
  propertyCountyColumn = 6;
  propertyOperatorColumn = 7;
  propertyPurchaserColumn = 8;
  propertyWellCountColumn = 9;
  propertyEffectiveFromColumn = 10;
  propertyEffectiveToColumn = 11;
  propertyBCTransIdColumn = 12;
  propertyGridColCount = 13;

  // Define Division Order SpreadJS column indexes
  doCheckBoxColumn = 0;
  doBlockChainColumn = 1;
  doPropertyNoColumn = 2;
  doPropertyNameColumn = 3;
  doPropertyDescColumn = 4;
  doProductColumn = 5;
  doInterestTypeColumn = 6;
  doDecimalInterestColumn = 7;
  doStartDateColumn = 8;
  doDeckCodeColumn = 9;
  doDeckChangeCodeColumn = 10;
  doDeckVersionColumn = 11;
  doTrustManagerColumn = 12;
  doBankColumn = 13;
  doStatusColumn = 14;
  doBCTransIdColumn = 15;
  doGridColCount = 16;

  // Define Payments SpreadJS column indexes
  paymentCheckBoxColumn = 0;
  paymentDateColumn = 1;
  paymentBankColumn = 2;
  paymentTypeColumn = 3;
  paymentCheckNoColumn = 4;
  paymentCountColumn = 5;
  paymentAmountColumn = 6;
  paymentStatusColumn = 7;
  paymentGridColCount = 8;

  owner: Owner;
  ownerAddress: string;
  operator: Operator;
  properties: Property[];
  divisionOrders: DivisionOrder[];
  payments: OperatorPayment[];

  constructor(
    private ownerService: OwnerService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router) {
    this.owner = new Owner();
  }

  ngOnInit() {

    // This close the notifications
    $(document).ready(() => {
      $('.btn_close_notification').click(function () {
        $(this)
          .closest('.notification')
          .remove();
      });
    });

    $(document).ready(() => {
      // Toggle Filter Option for Table
      // $('.filter_option').change(function () {
      //   alert('Toggle filter options');
      //   const inputId = $(this).attr('id');

      //   $(this).closest('.toggle_group').find('label').removeClass('toggle_active');
      //   $('label[for=\'' + inputId + '\']').addClass('toggle_active');
      // });


      // Javascript to select All Checkbox
      // $('#chbox_select_all').click(function () {

      //   // Get the Group ID
      //   const chboxGroup = $(this).attr('data-chbox-group');
      //   // Toggle all checkboxes
      //   $('input.table_chbox[data-chbox-group=' + chboxGroup + ']:checkbox').not(this).prop('checked', this.checked);
      //   // Add Class of selected to all table rows
      //   $('input.table_chbox[data-chbox-group=' + chboxGroup + ']:checkbox').each(() => {
      //     // Check if item is checked
      //     if ($(this).is(':checked')) {
      //       $(this).closest('tr').addClass('selected_row');
      //     } else {
      //       $(this).closest('tr').removeClass('selected_row');
      //     }
      //   });
      // });


      // Javascript for selecting individual Checkboxes
      // $('.table_chbox').click(() => {
      //   const chboxGroup = $(this).attr('data-chbox-group');

      //   // Add 'Selected' Class to table row
      //   if ($(this).closest('tr').hasClass('selected_row')) {
      //     $(this).closest('tr').removeClass('selected_row');
      //   } else {
      //     $(this).closest('tr').addClass('selected_row');
      //   }
      // });

      // Toggle The Chart View
      $('.switch_view').change(function () {
        const inputId = $(this).attr('id');
        const viewType = $(this).val();

        // alert('inputType: ' + inputId + '   viewType: ' + viewType);

        // $('.owner_tab').hide();
        // $('#' + viewType).fadeIn();

        $(this).closest('.toggle_group').find('label').removeClass('toggle_active');
        $('label[for=\'' + inputId + '\']').addClass('toggle_active');
      });
    });

    const ownerId = +this.activatedRoute.snapshot.paramMap.get('id');

    this.getOwner(ownerId);

    // Get the logged in user so we can add get the operator
    const user: User = JSON.parse(localStorage.getItem('user'));
    this.operator = user.operator;

    Promise.all([
      this.getProperties(this.operator.id, ownerId),
      this.getDivisionOrders(this.operator.id, ownerId),
      this.getPayments(this.operator.id, ownerId)
    ]).then(values => {
      this.clickPropertiesTab();
      // Create grid events for all tabs
      this.createGridEvents();

    });

  }

  getOwner(ownerId) {
    this.ownerService.getOwner(ownerId).subscribe(
      owner => {
        console.log('Owner got from database');
        console.log({ owner });
        this.owner = owner as Owner;
      },
      err => {
        console.log('Error getting owner');
        console.log(err);
      });
  }

  getOwnerAddress() {
    let addr = '';
    if (this.owner.addr1) {
      addr += this.owner.addr1 + ', ';
    }
    if (this.owner.addr2) {
      addr += this.owner.addr2 + ', ';
    }
    if (this.owner.city) {
      addr += this.owner.city + ', ';
    }
    if (this.owner.stateCode) {
      addr += this.owner.stateCode + ' ';
    }
    if (this.owner.zipCode) {
      addr += this.owner.zipCode;
    }
    return addr;
  }

  getProperties(operatorId: number, ownerId: number) {
    console.log('getProperties - start: ' + new Date().toISOString());

    return this.ownerService.getProperties(operatorId, ownerId).toPromise().then(
      properties => {
        console.log({ properties });
        this.properties = properties as Property[];

        let tempProperties: Property[] = JSON.parse(JSON.stringify(properties));
        tempProperties = this.setPropertyValues(tempProperties);

        this.properties = tempProperties;

        this.initilisePropertyGrid();
        this.applyPropertyDataBinding(tempProperties);
        console.log('getProperties - complete: ' + new Date().toISOString() + '  No. reocrds: ' + this.properties.length);

        return tempProperties;
      }
    ).catch(err => {
      console.log('getProperties:Error');
      console.log(err);
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
          this.router.navigate(['/login']);
        }
      }
    });
  }

  setPropertyValues(filteredProperties: Property[]): Property[] {
    // console.log('setPropertyValues - start');
    filteredProperties.forEach(property => {
      // console.log('Property: ', { property });

      property.stateName = property.county ? property.county.USState ? property.county.USState.name : '' : '';
      property.countyName = property.county ? property.county.name : '';
      property.operatorName = this.operator ? this.operator.name : '';
      property.purchaserName = property.purchaser ? property.purchaser.name : '';
      property.wellCount = property.wells.length;

      property.effectiveFrom = this.formatDate(property.effectiveFrom);
      property.effectiveTo = this.formatDate(property.effectiveTo);
    });

    // console.log('setPropertyValues - end');
    return filteredProperties;
  }

  initilisePropertyGrid() {
    // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
    // And grid events that are NOT data specific
    console.log('Initialise Properties Grid tab');

    this.spread.setActiveSheetIndex(0);
    const sheet = this.spread.getActiveSheet();

    sheet.suspendPaint();

    // Define columns
    const propertyCheckBoxInfo = { name: '', displayName: '', size: 50 };
    const propertyBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
    const propertyNoColInfo = { name: 'propertyNo', displayName: 'Property#', size: 100 };
    const propertyNameColInfo = { name: 'name', displayName: 'Name', size: 180 };
    const propertyDescColInfo = { name: 'description', displayName: 'Description', size: 180 };
    const propertyStateColInfo = { name: 'stateName', displayName: 'State', size: 120 };
    const propertyCountyColInfo = { name: 'countyName', displayName: 'County', size: 120 };
    const propertyOperatorColInfo = { name: 'operatorName', displayName: 'Operator', size: 120 };
    const propertyPurchaserColInfo = { name: 'purchaserName', displayName: 'Purchaser', size: 120 };
    const propertyWellCountColInfo = { name: 'wellCount', displayName: 'Well Count', size: 80 };
    const propertyEffectiveFromColInfo = { name: 'effectiveFrom', displayName: 'Effective From', size: 100 };
    const propertyEffectiveToColInfo = { name: 'effectiveTo', displayName: 'Effective To', size: 100 };
    const propertyBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 500 };

    sheet.autoGenerateColumns = false;

    sheet.setDataSource(this.properties);

    sheet.bindColumn(this.propertyCheckBoxColumn, propertyCheckBoxInfo);
    sheet.bindColumn(this.propertyBlockChainColumn, propertyBCTransImgColInfo);
    sheet.bindColumn(this.propertyNoColumn, propertyNoColInfo);
    sheet.bindColumn(this.propertyNameColumn, propertyNameColInfo);
    sheet.bindColumn(this.propertyDescColumn, propertyDescColInfo);
    sheet.bindColumn(this.propertyStateColumn, propertyStateColInfo);
    sheet.bindColumn(this.propertyCountyColumn, propertyCountyColInfo);
    sheet.bindColumn(this.propertyOperatorColumn, propertyOperatorColInfo);
    sheet.bindColumn(this.propertyPurchaserColumn, propertyPurchaserColInfo);
    sheet.bindColumn(this.propertyWellCountColumn, propertyWellCountColInfo);
    sheet.bindColumn(this.propertyEffectiveFromColumn, propertyEffectiveFromColInfo);
    sheet.bindColumn(this.propertyEffectiveToColumn, propertyEffectiveToColInfo);
    sheet.bindColumn(this.propertyBCTransIdColumn, propertyBCTransIdColInfo);

    sheet.setColumnCount(this.propertyGridColCount, GC.Spread.Sheets.SheetArea.viewport);

    // Lock/Readonly all columns
    sheet.options.isProtected = true;
    sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: false
    };

    // set cell alignment
    sheet.getCell(-1, this.propertyCheckBoxColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.propertyNoColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.propertyStateColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.propertyCountyColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.propertyOperatorColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.propertyPurchaserColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.propertyWellCountColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.propertyEffectiveFromColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.propertyEffectiveToColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);

    // Add Checkbox to grid
    const cellTypeCheckbox = new GC.Spread.Sheets.CellTypes.CheckBox();
    sheet.setCellType(-1, 0, cellTypeCheckbox);
    // Unlock the checkbox column
    sheet.getRange(-1, this.propertyCheckBoxColumn, -1, this.propertyCheckBoxColumn).locked(false);

    // Hide column row numbers
    sheet.options.rowHeaderVisible = false;
    // sheet.setColumnWidth(0, 0.0, GC.Spread.Sheets.SheetArea.rowHeader);

    console.log('initilisePropertyGrid - resume paint');
    sheet.resumePaint();
  }

  applyPropertyDataBinding(filteredProperties: Property[]) {
    // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);

    console.log('applyPropertyDataBinding - Start data binding');

    // Work with Payments sheet
    this.spread.setActiveSheetIndex(0);
    const sheet = this.spread.getActiveSheet();
    console.log('applyPropertyDataBinding - Sheet selected');

    sheet.suspendPaint();
    sheet.setDataSource(filteredProperties);

    console.log('applyPropertyDataBinding - Row Binding - start');
    filteredProperties.forEach((rowPropertiesObject: Property, rowIndex: number) => {
      // console.log('Row Index: ' + rowIndex, rowPropertiesObject);

      // Remove vertical grid-lines
      sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));

      // Add Blockchain graphic to cell
      const cell = sheet.getCell(rowIndex, this.propertyBlockChainColumn);
      let imgBlockChain = null;
      if (filteredProperties[rowIndex].bcTransId != null) {
        imgBlockChain = './assets/images/blockchain_cell@2x.png';
      }
      cell.text('');
      cell.backgroundImage(imgBlockChain);
    });

    console.log('applyPropertyDataBinding - Row Binding - complete');

    // Highlight first row except blockchain graphic cell & set the font in all cells
    for (let col = 0; col < this.propertyGridColCount - 1; col++) {
      if (col !== this.propertyBlockChainColumn) {
        sheet.getCell(0, col).backColor(environment.gridHighlight);

        // sheet.getCell(-1, col).font('10pt Source Sans Pro');
      }
    }

    sheet.resumePaint();

    console.log('applyPropertyDataBinding - Properties Binding Complete');
  }

  getDivisionOrders(operatorId: number, ownerId: number) {
    console.log('Get DivisionOrders - start');

    return this.ownerService.getDivisionOrders(operatorId, ownerId).toPromise().then(
      divisionOrders => {
        console.log({ divisionOrders });
        this.divisionOrders = divisionOrders as DivisionOrder[];

        let tempDOs: DivisionOrder[] = JSON.parse(JSON.stringify(divisionOrders));
        tempDOs = this.setDivisionOrderValues(tempDOs);

        this.divisionOrders = tempDOs;

        this.initiliseDivisionOrderGrid();
        this.applyDivisionOrderDataBinding(tempDOs);
        console.log('getDivisionOrders - complete: ' + new Date().toISOString() + '  No. reocrds: ' + this.divisionOrders.length);
      }
    ).catch(
      err => {
        console.log('getDivisionOrders:Error');
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.router.navigate(['/login']);
          }
        }
      }
    );
  }

  setDivisionOrderValues(filteredDivisionOrders: DivisionOrder[]): DivisionOrder[] {
    // console.log('setDivisionOrderValues - start');
    filteredDivisionOrders.forEach(divisionOrder => {
      // console.log({ divisionOrder });

      divisionOrder.propertyNo = divisionOrder.lease.title.property.propertyNo;
      divisionOrder.propertyName = divisionOrder.lease.title.property.name;
      divisionOrder.propertyDesc = divisionOrder.lease.title.property.description;
      divisionOrder.productName = divisionOrder.product.name;
      divisionOrder.interestTypeAbbr = divisionOrder.interestType.abbr;
      if (divisionOrder.divisionOrderDate != null) {
        // console.log(divisionOrder.divisionOrderDate);
        divisionOrder.doDateStr = formatDate(divisionOrder.divisionOrderDate, 'MM/dd/yyyy', 'en');
      }
      if (divisionOrder.termStart != null) {
        // console.log(divisionOrder.termStart);
        divisionOrder.startDateStr = formatDate(divisionOrder.termStart, 'MM/dd/yyyy', 'en');
      }
      if (divisionOrder.termEnd != null) {
        // console.log(divisionOrder.endDateStr);
        divisionOrder.endDateStr = formatDate(divisionOrder.termEnd, 'MM/dd/yyyy', 'en');
      }
      divisionOrder.trustManagerName = divisionOrder.bankTrustManager.name;
      divisionOrder.bankName = divisionOrder.bankTrustManager.bank.name;

      divisionOrder.deckCode = divisionOrder.deck.deckCode;
      divisionOrder.deckChangeCode = divisionOrder.deck.deckChangeCode;
      divisionOrder.deckVersion = divisionOrder.deck.deckVersionDate;
    });

    console.log('setDivisionOrderValues - end');
    return filteredDivisionOrders;
  }

  initiliseDivisionOrderGrid() {
    // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
    // And grid events that are NOT data specific
    console.log('Initialise Division Order Grid tab');

    // Work with Division orders sheet
    // sheet = this.spread.getSheet(1);
    this.spread.setActiveSheetIndex(1);
    const sheet = this.spread.getActiveSheet();

    sheet.suspendPaint();

    // Define columns
    const doCheckBoxInfo = { name: '', displayName: '', size: 50 };
    const doPropertyBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
    const doPropertyNoColInfo = { name: 'propertyNo', displayName: 'Property#', size: 100 };
    const doPropertyNameColInfo = { name: 'propertyName', displayName: 'Name', size: 180 };
    const doPropertyDescColInfo = { name: 'propertyDesc', displayName: 'Description', size: 180 };
    const doProductColInfo = { name: 'productName', displayName: 'Product', size: 120 };
    const doInterestTypeColInfo = { name: 'interestTypeAbbr', displayName: 'InterestType', size: 120 };
    const doDecimalInterestColInfo = { name: 'decimalInterest', displayName: 'Decimal Int.', formatter: '0.0000000000', size: 120 };
    const doStartDateColInfo = { name: 'startDateStr', displayName: 'Start Date', size: 120 };
    const doDeckCodeColInfo = { name: 'deckCode', displayName: 'Deck', size: 60 };
    const doDeckChangeCodeColInfo = { name: 'deckChangeCode', displayName: 'Change Code', size: 80 };
    const doDeckVersionColInfo = { name: 'deckVersion', displayName: 'Deck Version', size: 120 };
    const doTrustManagerColInfo = { name: 'trustManagerName', displayName: 'Trust Manager', size: 120 };
    const doBankColInfo = { name: 'bankName', displayName: 'Bank', size: 100 };
    const doStatusColInfo = { name: 'status', displayName: 'Status', size: 100 };
    const doBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 500 };

    sheet.autoGenerateColumns = false;

    sheet.setDataSource(this.divisionOrders);

    sheet.bindColumn(this.doCheckBoxColumn, doCheckBoxInfo);
    sheet.bindColumn(this.doBlockChainColumn, doPropertyBCTransImgColInfo);
    sheet.bindColumn(this.doPropertyNoColumn, doPropertyNoColInfo);
    sheet.bindColumn(this.doPropertyNameColumn, doPropertyNameColInfo);
    sheet.bindColumn(this.doPropertyDescColumn, doPropertyDescColInfo);
    sheet.bindColumn(this.doProductColumn, doProductColInfo);
    sheet.bindColumn(this.doInterestTypeColumn, doInterestTypeColInfo);
    sheet.bindColumn(this.doDecimalInterestColumn, doDecimalInterestColInfo);
    sheet.bindColumn(this.doStartDateColumn, doStartDateColInfo);
    sheet.bindColumn(this.doDeckCodeColumn, doDeckCodeColInfo);
    sheet.bindColumn(this.doDeckChangeCodeColumn, doDeckChangeCodeColInfo);
    sheet.bindColumn(this.doDeckVersionColumn, doDeckVersionColInfo);
    sheet.bindColumn(this.doTrustManagerColumn, doTrustManagerColInfo);
    sheet.bindColumn(this.doBankColumn, doBankColInfo);
    sheet.bindColumn(this.doStatusColumn, doStatusColInfo);
    sheet.bindColumn(this.doBCTransIdColumn, doBCTransIdColInfo);

    sheet.setColumnCount(this.doGridColCount, GC.Spread.Sheets.SheetArea.viewport);

    // Lock/Readonly all columns
    sheet.options.isProtected = true;
    sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: false
    };

    // set cell alignment
    sheet.getCell(-1, this.doCheckBoxColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doPropertyNoColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doProductColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doInterestTypeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doDecimalInterestColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doStartDateColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doDeckCodeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doDeckChangeCodeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doDeckVersionColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.doStatusColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);

    // Add Checkbox to grid
    const cellTypeCheckbox = new GC.Spread.Sheets.CellTypes.CheckBox();
    sheet.setCellType(-1, 0, cellTypeCheckbox);
    // Unlock the checkbox column
    sheet.getRange(-1, this.doCheckBoxColumn, -1, this.doCheckBoxColumn).locked(false);

    // Hide column row numbers
    sheet.options.rowHeaderVisible = false;

    // console.log('Create grid events');
    // this.createDivisionOrderGridEvents();

    console.log('initiliseDivisionOrderGrid - resume paint');
    sheet.resumePaint();
  }

  applyDivisionOrderDataBinding(filteredDivisionOrders: DivisionOrder[]) {
    // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);

    console.log('applyDivisionOrderDataBinding - Start data binding');

    this.spread.setActiveSheetIndex(1);
    const sheet = this.spread.getActiveSheet();

    sheet.suspendPaint();
    sheet.setDataSource(filteredDivisionOrders);

    console.log('applyDivisionOrderDataBinding - Row Binding - start');
    filteredDivisionOrders.forEach((rowDOsObject: DivisionOrder, rowIndex: number) => {
      // console.log('Row Index: ' + rowIndex, rowPropertiesObject);

      // Remove vertical grid-lines
      sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));

      // Add Blockchain graphic to cell
      const cell = sheet.getCell(rowIndex, this.doBlockChainColumn);
      let imgBlockChain = null;
      if (filteredDivisionOrders[rowIndex].bcTransId != null) {
        imgBlockChain = './assets/images/blockchain_cell@2x.png';
      }
      cell.text('');
      cell.backgroundImage(imgBlockChain);
    });

    console.log('applyDivisionOrderDataBinding - Row Binding - complete');

    // Highlight first row except blockchain graphic cell & set the font in all cells
    for (let col = 0; col < this.doGridColCount - 1; col++) {
      if (col !== this.doBlockChainColumn) {
        sheet.getCell(0, col).backColor(environment.gridHighlight);

        // sheet.getCell(-1, col).font('10pt Source Sans Pro');
      }
    }

    sheet.resumePaint();

    console.log('applyDivisionOrderDataBinding - Division Orders Binding Complete');
  }

  getPayments(operatorId: number, ownerId: number) {
    console.log('Get Payments - start');

    return this.ownerService.getPayments(operatorId, ownerId).toPromise().then(
      payments => {
        console.log({ payments });
        this.payments = payments as OperatorPayment[];

        let tempPayments: OperatorPayment[] = JSON.parse(JSON.stringify(payments));
        tempPayments = this.setPaymentValues(tempPayments);

        this.payments = tempPayments;

        this.initilisePaymentGrid();
        this.applyPaymentDataBinding(tempPayments);
        console.log('getPayments - complete: ' + new Date().toISOString() + '  No. reocrds: ' + this.payments.length);
      }
    ).catch(
      err => {
        console.log('getPayments:Error ' + err);
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.router.navigate(['/login']);
          }
        }
      }
    );
  }

  setPaymentValues(filteredOperatorPayments: OperatorPayment[]): OperatorPayment[] {
    // console.log('setPaymentValues - start');
    filteredOperatorPayments.forEach(operatorPayment => {
      // console.log({ operatorPayment });

      operatorPayment.bankName = operatorPayment.bank.name;
      operatorPayment.paymentTypeName = operatorPayment.paymentType.name;
      operatorPayment.paymentStatusName = operatorPayment.paymentStatus.name;
    });

    // console.log('setPaymentValues - end');
    return filteredOperatorPayments;
  }

  initilisePaymentGrid() {
    // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
    // And grid events that are NOT data specific
    console.log('Initialise Payments Grid tab');

    // Work with Payments sheet
    this.spread.setActiveSheetIndex(2);
    const sheet = this.spread.getActiveSheet();

    sheet.suspendPaint();

    // Define columns
    const paymentCheckBoxColInfo = { name: '', displayName: '', size: 50 };
    const paymentDateColInfo = { name: 'paymentDate', displayName: 'Date', size: 120 };
    const paymentBankColInfo = { name: 'bankName', displayName: 'Bank', size: 120 };
    const paymentTypeColInfo = { name: 'paymentTypeName', displayName: 'Type', size: 100 };
    const paymentCheckNoColInfo = { name: 'checkNo', displayName: 'Check #', size: 120 };
    const paymentCountColInfo = { name: 'paymentCount', displayName: '#Royalties', size: 80 };
    const paymentAmountColInfo = { name: 'amount', displayName: 'Check Amount', formatter: '0.00', size: 120 };
    const paymentStatusColInfo = { name: 'paymentStatusName', displayName: 'Status', size: 120 };

    sheet.autoGenerateColumns = false;

    sheet.setDataSource(this.payments);

    sheet.bindColumn(this.paymentCheckBoxColumn, paymentCheckBoxColInfo);
    sheet.bindColumn(this.paymentDateColumn, paymentDateColInfo);
    sheet.bindColumn(this.paymentBankColumn, paymentBankColInfo);
    sheet.bindColumn(this.paymentTypeColumn, paymentTypeColInfo);
    sheet.bindColumn(this.paymentCheckNoColumn, paymentCheckNoColInfo);
    sheet.bindColumn(this.paymentCountColumn, paymentCountColInfo);
    sheet.bindColumn(this.paymentAmountColumn, paymentAmountColInfo);
    sheet.bindColumn(this.paymentStatusColumn, paymentStatusColInfo);

    sheet.setColumnCount(this.paymentGridColCount, GC.Spread.Sheets.SheetArea.viewport);

    // Lock/Readonly all columns
    sheet.options.isProtected = true;
    sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: false
    };

    // set cell alignment
    sheet.getCell(-1, this.paymentCheckBoxColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.paymentDateColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.paymentBankColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.paymentTypeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.paymentCheckNoColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.paymentCountColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    sheet.getCell(-1, this.paymentAmountColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    sheet.getCell(-1, this.paymentStatusColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);

    // Add Checkbox to grid
    const cellTypeCheckbox = new GC.Spread.Sheets.CellTypes.CheckBox();
    sheet.setCellType(-1, 0, cellTypeCheckbox);
    // Unlock the checkbox column
    sheet.getRange(-1, this.doCheckBoxColumn, -1, this.doCheckBoxColumn).locked(false);

    // Hide column row numbers
    sheet.options.rowHeaderVisible = false;

    console.log('initilisePaymentGrid - resume paint');
    sheet.resumePaint();
  }

  applyPaymentDataBinding(filteredPayments: OperatorPayment[]) {
    // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredPayments);

    console.log('applyPaymentDataBinding - Start data binding');

    // Work with Payments sheet
    this.spread.setActiveSheetIndex(2);
    const sheet = this.spread.getActiveSheet();
    console.log('initilisePaymentGrid - Sheet selected');

    sheet.suspendPaint();
    sheet.setDataSource(filteredPayments);

    console.log('applyPaymentDataBinding - Row Binding - start');
    filteredPayments.forEach((rowPaymentObject: OperatorPayment, rowIndex: number) => {
      // console.log('Row Index: ' + rowIndex, rowPropertiesObject);

      // Remove vertical grid-lines
      sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));

    });

    console.log('applyPaymentDataBinding - Row Binding - complete');

    sheet.resumePaint();

    console.log('applyPaymentDataBinding - Division Orders Binding Complete');
  }

  createGridEvents() {

    // Bind click-event of Property Id and to Blockchain cells
    this.spread.bind(GC.Spread.Sheets.Events.CellClick, (e, args) => {
      const row = args.row;
      const col = args.col;
      const activeSheet = this.spread.getActiveSheetIndex();

      console.log('createGridEvents - Cell Click Event - Active Sheet [' + activeSheet + '] ,  Row [' + row + '],   Col [' +
        col + ']   Sheet Name: ' + this.spread.getActiveSheet);

      if (activeSheet === 0) { // Property
        const sheet = this.spread.getSheet(0);

        // Get selected property form array
        const selectedProperty = this.properties[row];
        console.log(`createGridEvents - [Row: ${row}] Property with Id ${selectedProperty.id}   [Col: ${col}]`);

        if (col === this.propertyBlockChainColumn || col === this.propertyBCTransIdColumn) {
          const bcTransId = sheet.getValue(row, this.propertyBCTransIdColumn);
          if (bcTransId != null && bcTransId !== '') {
            let url = '';
            if (selectedProperty.bcBlockId != null) {
              url = this.blockchainExplorerUrl + selectedProperty.bcBlockId;
            } else {
              url = this.blockchainExplorerUrl;
            }
            window.open(url);
          }
        } else if (col > this.propertyBlockChainColumn && col < this.propertyBCTransIdColumn) {
          this.router.navigateByUrl('/propertyDetail/' + selectedProperty.id);
        }
      } else if (activeSheet === 1) {  // Division Order
        // Get selected divisionOrder form array
        const selectedDivisionOrder = this.divisionOrders[row];
        console.log(`createGridEvents - [Row: ${row}] Division Order with Id ${selectedDivisionOrder.id}   [Col: ${col}]`);

        const sheet = this.spread.getSheet(1);

        if (col === this.doBlockChainColumn || col === this.doBCTransIdColumn) {
          const bcTransId = sheet.getValue(row, this.doBCTransIdColumn);
          if (bcTransId != null && bcTransId !== '') {
            let url = '';
            if (selectedDivisionOrder.bcBlockId != null) {
              url = this.blockchainExplorerUrl + selectedDivisionOrder.bcBlockId;
            } else {
              url = this.blockchainExplorerUrl;
            }
            window.open(url);
          }
        } else if (col > this.doBlockChainColumn && col < this.doBCTransIdColumn) {
          // Get selected divisionorder form array
          this.selectedDivisionOrder = this.divisionOrders[row];
          this.displayDivisionOrderDialog = true;
        }
      } else {  // Payments
        // Get selected payment form array
        const selectedPayment = this.payments[row];
        console.log(`createGridEvents - [Row: ${row}] Payment with Id ${selectedPayment.id}   [Col: ${col}]`);

        // const sheet = this.spread.getSheet(2);
        // this.router.navigateByUrl('/paymentDetail/' + selectedPayment.id);
      }
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.EnterCell, (e, args) => {
      const row = args.row;
      const activeSheet = this.spread.getActiveSheetIndex();
      console.log('createGridEvents - Enter Cell Event - Active Sheet [' + activeSheet + ']   Row [' + row + ']   Sheet Name: '
        + this.spread.getActiveSheet);

      if (activeSheet === 0) { // Property
        const sheet = this.spread.getSheet(0);
        sheet.suspendPaint();
        // Highlight all cells of the row except changed cells
        for (let col = 0; col < this.propertyGridColCount - 1; col++) {
          const cell = sheet.getCell(row, col);
          if (col !== this.propertyBlockChainColumn) {
            const cellBackColor = cell.backColor();
            if (cellBackColor !== environment.gridCellChanged) {
              sheet.getCell(row, col).backColor(environment.gridHighlight);
            }
          }
        }
        sheet.resumePaint();
      } else if (activeSheet === 1) { // DivisionOrder
        const sheet = this.spread.getSheet(1);
        sheet.suspendPaint();
        // Highlight all cells of the row except changed cells
        for (let col = 0; col < this.doGridColCount - 1; col++) {
          const cell = sheet.getCell(row, col);
          if (col !== this.doBlockChainColumn) {
            const cellBackColor = cell.backColor();
            if (cellBackColor !== environment.gridCellChanged) {
              sheet.getCell(row, col).backColor(environment.gridHighlight);
            }
          }
        }
        sheet.resumePaint();
      }
      // Note:: On payments sheet I do not implement an enterCell event

      // console.log('Row Enter Cell: ' + row);
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, (e, args) => {
      const row = args.row;
      const activeSheet = this.spread.getActiveSheetIndex();
      console.log('createGridEvents - Leave Cell Event - Active Sheet [' + activeSheet + ']   Row [' + row + ']   Sheet Name: '
        + this.spread.getActiveSheet);

      if (activeSheet === 0) { // Property
        const sheet = this.spread.getSheet(0);
        sheet.suspendPaint();
        // Un-highlight all cells of the row except changed cells
        for (let col = 0; col < this.propertyGridColCount - 1; col++) {
          const cell = sheet.getCell(row, col);

          if (col !== this.propertyBlockChainColumn) {
            const cellBackColor = cell.backColor();
            if (cellBackColor !== environment.gridCellChanged) {
              sheet.getCell(row, col).backColor(environment.gridBackground);
            }
          }
        }

        sheet.getCell(row, -1)
          .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
        sheet.getCell(row, -1)
          .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));

        sheet.resumePaint();

      } else if (activeSheet === 1) {
        const sheet = this.spread.getSheet(1);
        sheet.suspendPaint();
        // Un-highlight all cells of the row except changed cells
        for (let col = 0; col < this.doGridColCount - 1; col++) {
          const cell = sheet.getCell(row, col);

          if (col !== this.doBlockChainColumn) {
            const cellBackColor = cell.backColor();
            if (cellBackColor !== environment.gridCellChanged) {
              sheet.getCell(row, col).backColor(environment.gridBackground);
            }
          }
        }

        sheet.getCell(row, -1)
          .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
        sheet.getCell(row, -1)
          .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));

        sheet.resumePaint();

      } else if (activeSheet === 2) { // Payment
        const sheet = this.spread.getSheet(2);
        sheet.suspendPaint();

        sheet.getCell(row, -1)
          .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
        sheet.getCell(row, -1)
          .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));

        sheet.resumePaint();
      }

      // console.log('Row Leave Cell: ' + row);
    });

  }


  propertyNoClick(propertyNo: string) {
    const url = 'propertyDetail/' + propertyNo;
    // alert('Url: ' + url);

    this.router.navigateByUrl(url);
  }

  onBack(): void {
    this.location.back();
  }

  showTaxReport() {
    console.log('Show Tax report clicked');
    this.displayTaxReport = true;
    this.displayTaxReportChange.emit(true);
  }

  formatDate(date: string) {
    // Checks if date is null or undefined (=== strict check for null only)
    if (date == null) {
      return null;
    }
    if (date === '') {
      return null;
    }

    // Format the date to MM/DD/YYYY
    const dateStr = date.substr(5, 2) + '/' + date.substr(8, 2) + '/' + date.substr(0, 4);
    // alert('Date Before: ' + date + '   Date String: ' + dateStr);
    return dateStr;
  }

  clickPropertiesTab() {
    this.spread.setActiveSheetIndex(0);
  }

  clickDivisionOrdersTab() {
    this.spread.setActiveSheetIndex(1);
  }

  clickPaymentsTab() {
    this.spread.setActiveSheetIndex(2);
  }

  public workbookInit(args) {
    console.log(`Workbook Init`, { args });

    this.spread = args.spread as GC.Spread.Sheets.Workbook;
    this.sheet = this.spread.getActiveSheet();

    this.sheetWidth = this.sheet.getViewportWidth(1);
    console.log('SheetWidth: ', this.sheetWidth);

    this.sheet.name('Properties');
    this.spread.addSheet(1, new GC.Spread.Sheets.Worksheet('DivisionOrders'));
    this.spread.addSheet(2, new GC.Spread.Sheets.Worksheet('Payments'));
  }

}
