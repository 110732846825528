import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  // tslint:disable-next-line: variable-name
  constructor(private _authService: AuthService) { }

  ngOnInit() {
  }

  loggedIn() {
    return this._authService.loggedIn();
  }

}
