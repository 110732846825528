import { Component, OnInit } from '@angular/core';
import { USState } from '../model/us_state';
import { Operator } from '../model/operator';
import { LookupService } from '../lookup.service';
import { DivisionOrder } from '../model/division_order';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  title = 'Oil and Gas';
  appFooter = 'Interface for testing Oil and Gas angular pages';

  private readonly lookupUrl = `${environment.apiUrl}/lookup`;

  operators: Operator[];

  constructor(private lookupService: LookupService, private http: HttpClient) { }

  ngOnInit() {
    // this.getOperators();

  }

  getOperators(): void {
    // Todo: use .pipe(ngUnsubscribe) for every observable + put a ngUnsubscribe.next()
    // inside of each component ngOnDestroy(). For that you need to create a property for that
    // component call "ngUnsubscribe" with the type BehaviorSubject.
    // Example: private ngUnsubscribe: BehaviorSubject = new BehaviorSubject(null); if (/*is not null*/) { /* do whatever */ }
    this.getOperatorsFromDB().subscribe(operators => {
      console.log({ operators });
      this.operators = operators as Operator[];
    });
  }

  // GET
  getOperatorsFromDB() {
    const url = `${this.lookupUrl}/getOperators`;

    console.log('GetOperators Url: ', url);

    return this.http.get(url);
  }
}
