import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
        this.userUrl = environment.apiUrl + "/user";
    }
    // POST
    UserService.prototype.changePassword = function (changePassword) {
        var url = this.userUrl + '/changePassword';
        console.log('URL: ' + url);
        // console.log('changePassword: ', { changePassword });
        return this.http.post(url, changePassword);
    };
    /** @nocollapse */ UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
export { UserService };
