<p>
  import works!
</p>
<div class="fill" id="spread-container">
  <button mat-raised-button [disabled]="validData">
    <!--<mat-icon>save</mat-icon>-->
  </button>
  <!--  <toaster-container [toasterconfig]="toasterconfig"></toaster-container> -->

  <br />
  <br />
  <gc-spread-sheets
    #spreadDiv
    [backColor]="spreadBackColor"
    [hostStyle]="hostStyle"
    id="balance-sheet-structure"
    (workbookInitialized)="workbookInit($event)"
  >
  </gc-spread-sheets>
</div>
