import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth.service';
import { map } from 'rxjs/operators';
import { ProductionSearch } from 'src/app/model/production_search';
import { Sale } from 'src/app/model/sale';
import { SalesService } from 'src/app/sales.service';
import { PaymentsService } from 'src/app/payments.service';
import { User } from 'src/app/model/user';

@Component({
  selector: 'app-sales-process-payments',
  templateUrl: './sales-process-payments.component.html',
  styleUrls: ['./sales-process-payments.component.css']
})
export class SalesProcessPaymentsComponent implements OnInit, OnChanges {

  @Input() displayProcessPayments: boolean;
  @Output() displayProcessPaymentsChange = new EventEmitter<boolean>();

  @Input() salesSearch: ProductionSearch;
  @Output() salesSearchChange = new EventEmitter<boolean>();

  startDate;
  endDate;

  sales: Sale[];
  salesCount: 0;
  salesTotal: 0.0;
  message: string = '';
  displayMessage: boolean = false;

  constructor(
    private salesService: SalesService,
    private paymentsService: PaymentsService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // WARNING: this will be executed on dialog open and on dialog close

    if (this.displayProcessPayments) {
      this.startDate = new Date(this.salesSearch.startDateYear, this.salesSearch.startDateMonth - 1, 1, 0, 0, 0, 0);
      this.endDate = new Date(this.salesSearch.endDateYear, this.salesSearch.endDateMonth);
      this.endDate.setDate(this.endDate.getDate() - 1);
      console.log('ngOnChanges - startDate: ' + this.startDate.toISOString() + ' - endDate: ' + this.endDate.toISOString());

      this.message = "";
      this.displayMessage = false;
      const operator = this.authService.getUser().operator;
      this.getSalesProcessPayments(operator, this.startDate, this.endDate);
    }
  }

  async getSalesProcessPayments(operator, startDate, endDate) {
    console.log('getSalesProcessPayments - startDate: ' + startDate.toISOString() + ' - endDate: ' + endDate.toISOString());
    return this.salesService.getSalesProcessPayments(operator.id, startDate, endDate).pipe(
      map(
        sales => {

          let tempSales: Sale[] = JSON.parse(JSON.stringify(sales));
          this.sales = tempSales;

          this.salesCount = 0;
          this.salesTotal = 0.0;
          tempSales.forEach(salesRec => {
            // console.log('Sales: ', { salesRec });
            this.salesCount++;
            this.salesTotal += salesRec.grsValue;
          });

          console.log('getSales - complete: ' + new Date().toISOString() + '    No. Records: ' + this.sales.length);
          // console.log('Sales: ' + JSON.stringify(sales));
          return sales;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  refresh() {
    console.log('Start Date: ' + this.startDate.toISOString() + '    End Date: ' + this.endDate.toISOString());

    const operator = this.authService.getUser().operator;
    this.getSalesProcessPayments(operator, this.startDate, this.endDate);
  }

  closeDialog() {
    this.displayProcessPayments = false;
    this.displayProcessPaymentsChange.emit(false);
  }

  processPayments() {

    const user: User = JSON.parse(localStorage.getItem('user'));

    this.sales.forEach(salesRec => {
      console.log('Sales: ', { salesRec });

      this.paymentsService.processPayment(salesRec, user.id);
    });

    this.salesCount = 0;
    this.salesTotal = 0;

    this.message = 'Payments been generate - when completed will be visible in the Payments screen';
    this.displayMessage = true;
  }

}
