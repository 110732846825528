import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  // tslint:disable: variable-name

  constructor(
    private _authService: AuthService,
    private _router: Router) { }

  async canActivate(): Promise<boolean> {
    if (this._authService.loggedIn()) {
      // console.log('Auth guard - verify token');
      return await this._authService.verifyToken()
        .pipe(
          map(
            res => {
              // console.log('Auth.guard.ts - Valid Token - new token: ', res.token);
              localStorage.setItem('token', res.token);
              return true;
            },
            err => {
              console.log('Auth.guard.ts - Token verification Error: ', err);
              this._router.navigate(['/login']);
              return false;
            }
          )
        ).toPromise()
        .catch(
          err => {
            console.log('Auth.guard.ts - Token verification Error: ', err);
            this._router.navigate(['/login']);
            return false;
          }
        );

      // return true;
    } else {
      console.log('Auth.guard.ts - no token');
      this._router.navigate(['/login']);
      return false;
    }
  }
}
