import { Component, OnInit } from '@angular/core';

declare var $: any; // jquery

@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.css']
})
export class TickerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(document).ready(() => {
      $('#btn_ticker_toggle').click(function () {
        $('#ticker_drawer').slideToggle();
        $(this).toggleClass('selected');
      });
    });
  }

}
