import { OnInit, EventEmitter } from '@angular/core';
var SalesAddComponent = /** @class */ (function () {
    function SalesAddComponent() {
        this.displayAddSalesChange = new EventEmitter();
    }
    SalesAddComponent.prototype.ngOnInit = function () {
        $(document).ready(function () {
            datepicker();
            modals();
            // $('#dialog_add_sales').dialog({
            //   autoOpen: false,
            //   height: 'auto',
            //   width: 960,
            //   modal: true,
            //   show: 'fade',
            //   hide: 'fade',
            //   open: () => { }
            // });
            // Add Property
            // $('#btn_add_sales').click(() => {
            //   $('#dialog_add_sales').dialog('open');
            //   $('body').addClass('no_scroll');
            // });
            // Switch to Upload Property
            // $('#btn_upload_sales, #btn_upload_sales_alt').click(() => {
            //   $('#dialog_add_sales').dialog('close');
            //   $('#dialog_upload_sales').dialog('open');
            //   $('body').addClass('no_scroll');
            // });
            // Confirm Adding a Note
            // $('#btn_confirm_add_sales').click(() => {
            //   $('#dialog_add_sales').dialog('close');
            //   $('#message_processing_sales').fadeIn();
            //   // Wait duration until hiding the loader
            //   setTimeout(
            //     () => {
            //       $('#message_processing_sales').hide();
            //     }, 3000);
            //   // Wait duration of Loader before showing Message
            //   setTimeout(
            //     () => {
            //       $('#message_add_sales').show().delay(5000).fadeOut();
            //     }, 3000);
            //   $('html, body').removeClass('no_scroll');
            // });
            // Show the Dialog if the has on the page is add_sales
            // $(() => {
            //   if (location.hash === '#add_sales') {
            //     $('#dialog_add_sales').dialog('open');
            //     $('body').addClass('no_scroll');
            //     removeHash();
            //   }
            // });
        });
    };
    SalesAddComponent.prototype.closeDialog = function () {
        this.displayAddSales = false;
        this.displayAddSalesChange.emit(false);
    };
    return SalesAddComponent;
}());
export { SalesAddComponent };
