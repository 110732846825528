<app-messages></app-messages>
<app-production-upload
  [(displayUploadProduction)]="displayUploadProduction"
></app-production-upload>
<app-sales-upload
  [(displayUploadSales)]="displayUploadSales"
></app-sales-upload>
<app-properties-upload
  [(displayUploadProperty)]="displayUploadProperty"
  [(displayAddProperty)]="displayAddProperty"
></app-properties-upload>

<app-divisionorder
  [divOrder]="selectedDivOrder"
  [(displayDODialog)]="displayDivisionOrderDialog"
></app-divisionorder>

<div id="content">
  <div class="page_container">
    <div id="content_inner">
      <!-- Content Header -->
      <div class="content_header">
        <div class="content_history">
          <!-- <button type="button" class="btn_normal btn_big btn_icon_only" id="btn_history_back" onclick="window.location='properties.html'">
					<span class="btn_icon"><span class="icon_back"></span></span>
					<span class="btn_text">Back</span>
				</button> -->
        </div>
        <div class="content_title">
          <h1>Property Summary</h1>
        </div>
        <div class="content_timestamp">
          <div class="timestamp">Last updated: {{ lastUpdated | date:'d MMM yyyy' }}</div>
        </div>
        <div class="content_actions">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_upload_production"
                (click)="uploadProduction()"
              >
                <span class="btn_icon"
                  ><span class="icon_upload_cloud"></span
                ></span>
                <span class="btn_text">Upload Production</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_upload_sales"
                (click)="uploadSales()"
              >
                <span class="btn_icon"
                  ><span class="icon_upload_cloud"></span
                ></span>
                <span class="btn_text">Upload Sales &amp; Expenses</span>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <!-- Body Content -->
      <div class="content_body">
        <!-- Property Header -->
        <div class="item_header">
          <div class="item_icon">
            <div class="item_icon_main">
              <span class="icon_property"></span>
            </div>
            <div class="item_icon_shadow">
              <span class="icon_property_shadow"></span>
            </div>
          </div>
          <div class="item_details">
            <div id="item_name">{{ property.name }}</div>
            <div id="item_number">#{{ property.propertyNo }}</div>
            <div id="item_blockchain">
              <a href="{{ blockchainExplorerUrl }}" target="_blank"
                ><span class="icon_blockchain"></span> #{{
                  property.bcBlockId
                }}
                Blockchain View</a
              >
            </div>
            <div id="item_address">
              {{ property.description }},
              {{ property.county?.name.toUpperCase() }},
              {{ property.county?.USState?.name }}
            </div>
          </div>
        </div>

        <div class="widget_group">
          <!-- Property Details -->
          <div class="widget_container quarter" id="widget_property_details">
            <div class="widget_inner">
              <div class="widget_header">
                <div class="widget_title">
                  <h3>Property Details</h3>
                </div>
                <div class="widget_actions"></div>
              </div>
              <div class="widget_content">
                <ul class="data_list">
                  <li>
                    <div class="data_list_label">ID</div>
                    <div class="data_list_value">{{ property.id }}</div>
                  </li>
                  <li>
                    <div class="data_list_label">Purchaser</div>
                    <div class="data_list_value">
                      {{ property.purchaser?.name }}
                    </div>
                  </li>
                  <li>
                    <div class="data_list_label">Production Type</div>
                    <div class="data_list_value">
                      {{ (property?.wells)[0]?.products[0]?.name }}
                    </div>
                  </li>
                  <li>
                    <div class="data_list_label">Effective From</div>
                    <div class="data_list_value">
                      {{ property.effectiveFrom | date: "MMM d, yyyy" }}
                    </div>
                  </li>
                  <li>
                    <div class="data_list_label">Effective To</div>
                    <div *ngIf="property.effectiveTo; else emptyDate">
                      <div class="data_list_value">
                        {{ property.effectiveTo | date: "MMM d, yyyy" }}
                      </div>
                    </div>
                    <ng-template #emptyDate>-</ng-template>
                  </li>
                  <li>
                    <div class="data_list_label">Wells</div>
                    <div class="data_list_value">
                      {{ property.wells.length }}
                    </div>
                  </li>
                </ul>
              </div>
              <div class="widget_footer">
                <div class="col_group">
                  <div class="col_item">
                    <a href="javascript:void[0]" id="link_view_property_details"
                      >View All Details
                    </a>
                  </div>
                  <div class="col_item text_right"></div>
                </div>
              </div>
            </div>
          </div>

          <!-- Summary -->
          <div class="widget_container quarter" id="widget_owners_summary">
            <div class="widget_inner">
              <div class="widget_header">
                <div class="widget_title">
                  <h3>Owners Summary</h3>
                </div>
                <div class="widget_actions"></div>
              </div>
              <div class="widget_content">
                <ul class="data_list">
                  <li>
                    <div class="data_list_label">Owners</div>
                    <div class="data_list_value">18</div>
                  </li>
                  <li>
                    <div class="data_list_label">Leases</div>
                    <div class="data_list_value">26</div>
                  </li>
                  <li>
                    <div class="data_list_label">Deeds</div>
                    <div class="data_list_value">20</div>
                  </li>
                  <li>
                    <div class="data_list_label">Division Orders</div>
                    <div class="data_list_value">26</div>
                  </li>
                </ul>

                <div class="info_container" id="royalties">
                  <div class="info_container_icon">
                    <div class="info_container_icon_main">
                      <span class="icon_payment"></span>
                    </div>
                    <div class="info_container_icon_shadow">
                      <span class="icon_payment_shadow"></span>
                    </div>
                  </div>
                  <div class="info_container_details">
                    <div class="info_item">
                      <div class="info_label">Royalties Due</div>
                      <div class="info_value">$28,152</div>
                    </div>
                    <div class="fine_print">Due on {{ lastUpdated | date:'d MMM yyyy' }}</div>
                    <div class="space_divider"></div>
                    <div>
                      <a href="payment_details.html"
                        >Go to Payments <span class="icon_arrow_right"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Performance -->
          <div class="widget_container half" id="widget_performance">
            <div class="widget_inner">
              <div class="widget_header">
                <div class="widget_title">
                  <h3>Performance</h3>
                </div>
                <div class="widget_actions">
                  <ul>
                    <li>
                      <div class="form_row no_padding">
                        <div class="form_label hidden_label">
                          <label for="performance_wells">Wells</label>
                        </div>
                        <div class="form_input">
                          <select
                            class="inputbox no_border"
                            id="performance_wells"
                            name="performance_wells"
                            [(ngModel)]="selectedWell"
                          >
                            <option value="-1">All Wells</option>
                            <option
                              *ngFor="let well of property.wells"
                              value="{{ well.id }}"
                            >
                              {{ well.apiNo }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="form_row no_padding">
                        <div class="form_label hidden_label">
                          <label for="performance_type">Select Type</label>
                        </div>
                        <div class="form_input">
                          <select
                            class="inputbox no_border"
                            id="performance_type"
                            name="performance_type"
                            [(ngModel)]="selectedProduct"
                          >
                            <option value="-1">All Products</option>
                            <option
                              *ngFor="let product of products"
                              value="{{ product.id }}"
                              [selected]="product.id === 10"
                            >
                              {{ product.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="widget_content">
                <div class="chart_toolbar">
                  <div class="chart_toolbar_left">
                    <ul>
                      <li>
                        <fieldset class="form_row no_padding clearfix">
                          <legend class="hidden_legend">Period</legend>
                          <div class="toggle_group">
                            <div class="toggle_group_option">
                              <div class="toggle_input">
                                <input
                                  type="radio"
                                  value="monthly"
                                  id="period_monthly"
                                  name="chart_period"
                                  class="chart_period"
                                  [checked]="true"
                                  (click)="setChartPeriod(0)"
                                />
                              </div>
                              <label
                                for="period_monthly"
                                [ngClass]="{ toggle_active: chartPeriod === 0 }"
                                >Monthly</label
                              >
                            </div>
                            <div class="toggle_group_option">
                              <div class="toggle_input">
                                <input
                                  type="radio"
                                  value="quarterly"
                                  id="period_quarterly"
                                  name="chart_period"
                                  class="chart_period"
                                  (click)="setChartPeriod(1)"
                                />
                              </div>
                              <label
                                for="period_quarterly"
                                [ngClass]="{ toggle_active: chartPeriod === 1 }"
                                >Quarterly</label
                              >
                            </div>
                            <div class="toggle_group_option">
                              <div class="toggle_input">
                                <input
                                  type="radio"
                                  value="annually"
                                  id="period_annually"
                                  name="chart_period"
                                  class="chart_period"
                                  (click)="setChartPeriod(2)"
                                />
                              </div>
                              <label
                                for="period_annually"
                                [ngClass]="{ toggle_active: chartPeriod === 2 }"
                                >Annually</label
                              >
                            </div>
                          </div>
                        </fieldset>
                      </li>
                      <li>
                        <div class="period_dates">
                          <div class="period_date_prev">
                            <button
                              type="button"
                              class="btn_normal btn_icon_only btn_no_border"
                            >
                              <span class="btn_icon"
                                ><span class="icon_chevron_left"></span
                              ></span>
                              <span class="btn_text">Previous Period</span>
                            </button>
                          </div>
                          <div class="period_date_current">
                            <span id="period_current_date_start">{{ performanceStart | date:'MMM yyyy' }}</span>
                            - <span id="period_current_date_end">{{ performanceEnd | date:'MMM yyyy' }}</span>
                          </div>
                          <div class="period_date_next">
                            <button
                              type="button"
                              class="btn_normal btn_icon_only btn_no_border"
                              disabled=""
                            >
                              <span class="btn_icon"
                                ><span class="icon_chevron_right"></span
                              ></span>
                              <span class="btn_text">Next Period</span>
                            </button>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="chart_toolbar_right">
                    <ul>
                      <li>
                        <fieldset class="form_row no_padding clearfix">
                          <legend class="hidden_legend">Chart View</legend>
                          <div class="toggle_group">
                            <div class="toggle_group_option">
                              <div class="toggle_input">
                                <input
                                  type="radio"
                                  value="property_performance_table"
                                  id="view_table"
                                  name="chart_view"
                                  class="chart_view"
                                  checked=""
                                  (click)="setChartType(0)"
                                />
                              </div>
                              <label
                                for="view_table"
                                [ngClass]="{ toggle_active: chartType === 0 }"
                                ><span class="icon_table"></span
                              ></label>
                            </div>
                            <div class="toggle_group_option">
                              <div class="toggle_input">
                                <input
                                  type="radio"
                                  value="property_performance_chart"
                                  id="view_chart"
                                  name="chart_view"
                                  class="chart_view"
                                  (click)="setChartType(1)"
                                />
                              </div>
                              <label
                                for="view_chart"
                                [ngClass]="{ toggle_active: chartType === 1 }"
                                ><span class="icon_chart"></span
                              ></label>
                            </div>
                          </div>
                        </fieldset>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="chart_container"
                  id="property_performance_chart"
                  *ngIf="chartType === 1"
                >
                  <!-- Place Chart Here -->
                  <div class="chart_placeholder">Chart Placeholder</div>
                </div>

                <div
                  class="table_container"
                  id="property_performance_table"
                  *ngIf="chartType === 0"
                >
                  <div class="scroll_container">
                    <table class="table_grid">
                      <thead>
                        <tr>
                          <th><div class="table_header">Date</div></th>
                          <th><div class="table_header">Vol (bbl)</div></th>
                          <th class="text_right">
                            <div class="table_header">Sale</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1 {{ lastUpdated | date:'MMM yyyy' }}</td>
                          <td>11230</td>
                          <td class="text_right">$555,313.32</td>
                        </tr>
                        <tr>
                          <td>1 {{ lastUpdatedminus1 | date:'MMM yyyy' }}</td>
                          <td>11230</td>
                          <td class="text_right">$555,313.32</td>
                        </tr>
                        <tr>
                          <td>1 {{ lastUpdatedminus2 | date:'MMM yyyy' }}</td>
                          <td>11230</td>
                          <td class="text_right">$555,313.32</td>
                        </tr>
                        <tr>
                          <td>1 {{ lastUpdatedminus3 | date:'MMM yyyy' }}</td>
                          <td>11230</td>
                          <td class="text_right">$555,313.32</td>
                        </tr>
                        <tr>
                          <td>1 {{ lastUpdatedminus4 | date:'MMM yyyy' }}</td>
                          <td>11230</td>
                          <td class="text_right">$555,313.32</td>
                        </tr>
                        <tr>
                          <td>1 {{ lastUpdatedminus5 | date:'MMM yyyy' }}</td>
                          <td>11230</td>
                          <td class="text_right">$555,313.32</td>
                        </tr>
                        <tr>
                          <td>1 {{ lastUpdatedminus6 | date:'MMM yyyy' }}</td>
                          <td>11230</td>
                          <td class="text_right">$555,313.32</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="space_divider extra_padding"></div>
                <div class="col_group col_4" id="performance_summary">
                  <div class="col_item">
                    <div class="info_item">
                      <div class="info_label">Production Type</div>
                      <div class="info_value">Oil</div>
                    </div>
                  </div>
                  <div class="col_item">
                    <div class="info_item">
                      <div class="info_label">Total Production</div>
                      <div class="info_value">540bbl</div>
                    </div>
                  </div>
                  <div class="col_item">
                    <div class="info_item">
                      <div class="info_label">Total Sales</div>
                      <div class="info_value">$27,916</div>
                    </div>
                  </div>
                  <div class="col_item">
                    <div class="info_item">
                      <div class="info_label">Avg. Price</div>
                      <div class="info_value">$53.89</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="widget_group">
          <div class="widget_container" id="widget_owners">
            <div class="widget_inner">
              <div class="widget_header">
                <div class="widget_title">
                  <h3>Deck</h3>
                </div>

                <form [formGroup]="divisionOrderForm">
                  <div class="widget_actions">
                    <ul>
                      <li>
                        <div class="info_label" *ngIf="decks && decks.length == 1">
                          {{ selectedDeck.deckCode }} -
                          {{ selectedDeck.deckChangeCode }} -
                          [{{ selectedDeck.deckVersionDate }}]
                        </div>

                        <div class="form_row no_padding" *ngIf="decks && decks.length > 1">
                          <div class="form_input">
                            <select
                              name="divisionOrderDeck"
                              id="divisionOrderDeck"
                              class="inputbox"
                              (change)="onDeckChange()"
                              formControlName="deckId"
                            >
                              <option *ngFor="let deck of decks" value="{{ deck.id }}" [selected]="deck.id == selectedDeck.id">
                                {{ deck.deckCode }} -
                                {{ deck.deckChangeCode }} -
                                [{{ deck.deckVersionDate }}]
                              </option>
                            </select>
                          </div>
                        </div>
                      </li>

                      <li>
                        <div class="form_row no_padding">
                          <div class="form_input">
                            <select
                              name="divisionOrderInterestType"
                              id="divisionOrderInterestType"
                              class="inputbox"
                              (change)="onInterestTypeChange()"
                              formControlName="interestTypeId"
                            >
                              <option value="" [selected]="">All Interest Types</option>
                              <option *ngFor="let interestType of interestTypes" value="{{ interestType.id }}">
                                {{ interestType.abbr }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </form>

                <div class="widget_actions">
                  <ul>
                    <li>
                      <button
                        type="button"
                        class="btn_normal btn_blue"
                        id="btn_upload_owner"
                        (click)="uploadDO()"
                      >
                        <span class="btn_icon"
                          ><span class="icon_upload_cloud"></span
                        ></span>
                        &nbsp;<span class="btn_text">Upload CSV</span>
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        class="btn_normal btn_blue"
                        id="btn_add_owner"
                      >
                        <span class="btn_icon"
                          ><span class="icon_add"></span
                        ></span>
                        &nbsp;<span class="btn_text">Add Owner</span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="widget_content">
                <div class="scroll_container">
                  <div
                    id="spread-container"
                    class="spread-container"
                    #divSpreadContainer
                  >
                    <!-- Spreadsheet LOADED -->
                    <gc-spread-sheets
                      #spreadContainer
                      id="divisionorder-table-data"
                      [tabStripVisible]="tabStripVisible"
                      [backColor]="spreadBackColor"
                      [hostStyle]="hostStyle"
                      hostClass="spreadContainer"
                      (workbookInitialized)="workbookInit($event)"
                      *ngIf="selectedDeck"
                    >
                    </gc-spread-sheets>
                  </div>
                </div>
                <div style="margin-left: 55%;">Decimal Interest Total: {{ totalDecimalInterest.toFixed(10) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Body Footer -->
      <div class="content_footer">
        <div class="content_footer_inner">
          <div class="content_footer_left">
            <ul>
              <li>
                <button
                  type="button"
                  class="btn_normal btn_white"
                  id="btn_back"
                  (click)="onBack()"
                >
                  <span class="btn_icon"><span class="icon_back"></span></span>
                  <span class="btn_text">Back</span>
                </button>
              </li>
            </ul>
          </div>
          <div class="content_footer_right">
            <ul>
              <li>
                <button
                  type="button"
                  class="btn_normal btn_white"
                  id="btn_share"
                >
                  <span class="btn_icon"><span class="icon_share"></span></span>
                  <span class="btn_text">Share</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn_normal btn_blue"
                  id="btn_export_pdf_owners"
                  (click)="exportReport(1)"
                >
                  <span class="btn_icon"><span class="icon_share"></span></span>
                  &nbsp;<span class="btn_text">PDF</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn_normal btn_blue"
                  id="btn_export_csv_owners"
                  (click)="exportReport(3)"
                >
                  <span class="btn_icon"><span class="icon_share"></span></span>
                  &nbsp;<span class="btn_text">CSV</span>
                </button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn_normal btn_blue"
                  id="btn_export_excel_owners"
                  (click)="exportReport(2)"
                >
                  <span class="btn_icon"><span class="icon_share"></span></span>
                  &nbsp;<span class="btn_text">Excel</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<script>
  // Toggle The Chart Period
  $(".chart_period").change(function () {
    var toggle_family = $(this).attr("name");
    var input_id = $(this).attr("id");

    $(this).closest(".toggle_group").find("label").removeClass("toggle_active");
    $("label[for='" + input_id + "']").addClass("toggle_active");
  });

  // Toggle The Chart View
  $(".chart_view").change(function () {
    var toggle_family = $(this).attr("name");
    var input_id = $(this).attr("id");
    var view_type = $(this).val();

    $(this).closest(".widget_content").find(".chart_container").hide();
    $(this).closest(".widget_content").find(".table_container").hide();
    $("#" + view_type).fadeIn();

    $(this).closest(".toggle_group").find("label").removeClass("toggle_active");
    $("label[for='" + input_id + "']").addClass("toggle_active");
  });
</script>
