<div id="widgets_inner">
  <form [formGroup]="dashboardForm" (change)="onFormChange()">
    <div class="widget_group">
      <!-- Performance -->
      <div *ngIf="performanceWidgetDisplayFlag" class="widget_container two_third" id="widget_performance">
        <div class="widget_inner">
          <div class="widget_header">
            <div class="widget_title">
              <h3>Performance</h3>
            </div>
            <div class="widget_actions">
              <ul>
                <li>
                  <div class="form_row no_padding">
                    <div class="form_label hidden_label">
                      <label for="performance_well">Wells</label>
                    </div>
                    <div class="form_input">
                      <select
                        name="performance_well"
                        id="performance_well"
                        class="inputbox border"
                        formControlName="performance_well"
                      >
                        <option [selected]="!selectedPerformanceWell" value="-1"
                          >All Wells</option
                        >
                        <option
                          *ngFor="let well of wells"
                          value="{{ well.id }}"
                        >
                          {{ well.apiNo }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="form_row no_padding">
                    <div class="form_label hidden_label">
                      <label for="performance_product">Select Type</label>
                    </div>
                    <div class="form_input">
                      <select
                        name="performance_product"
                        id="performance_product"
                        class="inputbox border"
                        formControlName="performance_product"
                      >
                        <option
                          value="-1"
                          [selected]="!selectedPerformanceProduct"
                          >All Products</option
                        >
                        <option
                          *ngFor="let product of products"
                          value="{{ product.id }}"
                        >
                          {{ product.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="widget_content">
            <div class="chart_toolbar">
              <div class="chart_toolbar_left">
                <ul>
                  <li>
                    <fieldset class="form_row no_padding clearfix">
                      <legend class="hidden_legend">Period</legend>
                      <div class="toggle_group">
                        <div class="toggle_group_option">
                          <div class="toggle_input">
                            <input
                              type="radio"
                              value="monthly"
                              id="period_monthly"
                              name="chart_period"
                              class="chart_period"
                              [checked]="true"
                              (click)="setChartPeriod(0)"
                            />
                          </div>
                          <label
                            for="period_monthly"
                            [ngClass]="{ toggle_active: chartPeriod === 0 }"
                            >Monthly</label
                          >
                        </div>
                        <div class="toggle_group_option">
                          <div class="toggle_input">
                            <input
                              type="radio"
                              value="quarterly"
                              id="period_quarterly"
                              name="chart_period"
                              class="chart_period"
                              (click)="setChartPeriod(1)"
                            />
                          </div>
                          <label
                            for="period_quarterly"
                            [ngClass]="{ toggle_active: chartPeriod === 1 }"
                            >Quarterly</label
                          >
                        </div>
                        <div class="toggle_group_option">
                          <div class="toggle_input">
                            <input
                              type="radio"
                              value="annually"
                              id="period_annually"
                              name="chart_period"
                              class="chart_period"
                              (click)="setChartPeriod(2)"
                            />
                          </div>
                          <label
                            for="period_annually"
                            [ngClass]="{ toggle_active: chartPeriod === 2 }"
                            >Annually</label
                          >
                        </div>
                      </div>
                    </fieldset>
                  </li>
                  <li>
                    <div class="period_dates">
                      <div class="period_date_prev">
                        <button
                          type="button"
                          class="btn_normal btn_icon_only btn_no_border"
                        >
                          <span class="btn_icon"
                            ><span class="icon_chevron_left"></span
                          ></span>
                          <span class="btn_text">Previous Period</span>
                        </button>
                      </div>
                      <div class="period_date_current">
                        <span id="period_current_date_start">{{ performanceStart | date:'MMM yyyy' }}</span> -
                        <span id="period_current_date_end">{{ performanceEnd | date:'MMM yyyy' }}</span>
                      </div>
                      <div class="period_date_next">
                        <button
                          type="button"
                          class="btn_normal btn_icon_only btn_no_border"
                          disabled=""
                        >
                          <span class="btn_icon"
                            ><span class="icon_chevron_right"></span
                          ></span>
                          <span class="btn_text">Next Period</span>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="chart_toolbar_right">
                <ul>
                  <li>
                    <fieldset class="form_row no_padding clearfix">
                      <legend class="hidden_legend">Chart View</legend>
                      <div class="toggle_group">
                        <div class="toggle_group_option">
                          <div class="toggle_input">
                            <input
                              type="radio"
                              value="top_8_table"
                              id="view_table"
                              name="chart_view"
                              class="chart_view"
                              checked=""
                              (click)="setChartType(0)"
                            />
                          </div>
                          <label
                            for="view_table"
                            [ngClass]="{ toggle_active: chartType === 0 }"
                            ><span class="icon_table"></span
                          ></label>
                        </div>
                        <div class="toggle_group_option">
                          <div class="toggle_input">
                            <input
                              type="radio"
                              value="top_8_chart"
                              id="view_chart"
                              name="chart_view"
                              class="chart_view"
                              (click)="setChartType(1)"
                            />
                          </div>
                          <label
                            for="view_chart"
                            [ngClass]="{ toggle_active: chartType === 1 }"
                            ><span class="icon_chart"></span
                          ></label>
                        </div>
                      </div>
                    </fieldset>
                  </li>
                </ul>
              </div>
            </div>

            <div
              class="chart_container"
              id="top_8_chart"
              *ngIf="chartType === 1"
            >
              <!-- Place Chart Here -->
              <div class="chart_placeholder">
                Chart Placeholder
              </div>
            </div>

            <div
              class="table_container"
              id="top_8_table"
              *ngIf="chartType === 0"
            >
              <div class="table_container">
                <table class="table_grid">
                  <thead>
                    <tr>
                      <th><div class="table_header">Date</div></th>
                      <th><div class="table_header">Vol (bbl)</div></th>
                      <th class="text_right">
                        <div class="table_header">Sale</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1 {{ lastUpdated | date:'MMM yyyy' }}</td>
                      <td>11230</td>
                      <td class="text_right">$555,313.32</td>
                    </tr>
                    <tr>
                      <td>1 {{ lastUpdatedminus1 | date:'MMM yyyy' }}</td>
                      <td>12571</td>
                      <td class="text_right">$687,051.40</td>
                    </tr>
                    <tr>
                      <td>1 {{ lastUpdatedminus2 | date:'MMM yyyy' }}</td>
                      <td>10890</td>
                      <td class="text_right">$612,018.00</td>
                    </tr>
                    <tr>
                      <td>1 {{ lastUpdatedminus3 | date:'MMM yyyy' }}</td>
                      <td>11567</td>
                      <td class="text_right">$620,222.50</td>
                    </tr>
                    <tr>
                      <td>1 {{ lastUpdatedminus4 | date:'MMM yyyy' }}</td>
                      <td>12184</td>
                      <td class="text_right">$657,205.00</td>
                    </tr>
                    <tr>
                      <td>1 {{ lastUpdatedminus5 | date:'MMM yyyy' }}</td>
                      <td>11672</td>
                      <td class="text_right">$629,704.40</td>
                    </tr>
                    <tr>
                      <td>1 {{ lastUpdatedminus6 | date:'MMM yyyy' }}</td>
                      <td>11244</td>
                      <td class="text_right">$664,408.00</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Summary -->
      <div *ngIf="summaryWidgetDisplayFlag" class="widget_container one_third" id="widget_summary">
        <div class="widget_inner">
          <div class="widget_header">
            <div class="widget_title">
              <h3>Summary</h3>
            </div>
            <div class="widget_actions"></div>
          </div>
          <div class="widget_content">
            <div class="col_group col_2">
              <div class="col_item">
                <div class="summary_item">
                  <div class="summary_label">Total Production</div>
                  <div class="summary_value">54,000 bbl</div>
                </div>
              </div>
              <div class="col_item">
                <div class="summary_item">
                  <div class="summary_label">Avg. Price</div>
                  <div class="summary_value">$53.89</div>
                </div>
              </div>
              <div class="col_item">
                <div class="summary_item">
                  <div class="summary_label">Total Sales</div>
                  <div class="summary_value">$2,916,000</div>
                </div>
              </div>
              <div class="col_item">
                <div class="summary_item">
                  <div class="summary_label">Avg. Index Price</div>
                  <div class="summary_value">$54.11</div>
                </div>
              </div>
            </div>
            <div class="space_divider"></div>
            <div class="fine_print">Values correct on {{ lastUpdated | date:'d MMM yyyy' }} 4:30pm</div>
          </div>
          <div class="widget_footer">
            <div class="col_group">
              <div class="col_item">
                <a href="#"><span class="icon_share"></span> Export Results</a>
              </div>
              <div class="col_item text_right"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="topWellsWidgetDisplayFlag" class="widget_group">
      <!-- Top Wells -->
      <div class="widget_container" id="widget_top_properties">
        <div class="widget_inner">
          <div class="widget_header">
            <div class="widget_title">
              <h3>Top 8 Wells</h3>
            </div>
            <div class="widget_actions">
              <ul>
                <li>
                  <div class="form_row no_padding">
                    <div class="form_label hidden_label">
                      <label for="top_property_product">Select Type</label>
                    </div>
                    <div class="form_input">
                      <select
                        name="top_property_product"
                        id="top_property_product"
                        class="inputbox border"
                        formControlName="top_property_product"
                      >
                        <option
                          value="-1"
                          [selected]="!selectedTopPropertyProduct"
                          >All Products</option
                        >
                        <option
                          *ngFor="let product of products"
                          value="{{ product.id }}"
                        >
                          {{ product.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="widget_content">
            <div class="table_containr">
              <table class="table_grid">
                <thead>
                  <tr>
                    <th><div class="table_header">Well</div></th>
                    <th><div class="table_header">Vol (bbl)</div></th>
                    <th class="text_right">
                      <div class="table_header">Prod Change (1M)</div>
                    </th>
                    <th class="text_right">
                      <div class="table_header">Sale</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <a routerLink="/propertyDetail/_5462265775"
                        >#5462265775</a
                      >
                    </td>
                    <td>11230</td>
                    <td class="text_right">
                      <span class="change_value up">40.90%</span>
                    </td>
                    <td class="text_right">$555,313.32</td>
                  </tr>
                  <tr>
                    <td>
                      <a routerLink="/propertyDetail/_5462265751"
                        >#5462265751</a
                      >
                    </td>
                    <td>906</td>
                    <td class="text_right">
                      <span class="change_value down">1.07%</span>
                    </td>
                    <td class="text_right">$49,213.92</td>
                  </tr>
                  <tr>
                    <td>
                      <a routerLink="/propertyDetail/_5462265786"
                        >#5462265786</a
                      >
                    </td>
                    <td>205</td>
                    <td class="text_right">
                      <span class="change_value up">2.05%</span>
                    </td>
                    <td class="text_right">$11,135.60</td>
                  </tr>
                  <tr>
                    <td>
                      <a routerLink="/propertyDetail/_5462265785"
                        >#5462265785</a
                      >
                    </td>
                    <td>205</td>
                    <td class="text_right">
                      <span class="change_value up">2.05%</span>
                    </td>
                    <td class="text_right">$11,135.60</td>
                  </tr>
                  <tr>
                    <td>
                      <a routerLink="/propertyDetail/_5462265784"
                        >#5462265784</a
                      >
                    </td>
                    <td>109</td>
                    <td class="text_right">
                      <span class="change_value up">1.05%</span>
                    </td>
                    <td class="text_right">$5,920.88</td>
                  </tr>
                  <tr>
                    <td>
                      <a routerLink="/propertyDetail/_5462265783"
                        >#5462265783</a
                      >
                    </td>
                    <td>11230</td>
                    <td class="text_right">
                      <span class="change_value up">2.05%</span>
                    </td>
                    <td class="text_right">$5,432.00</td>
                  </tr>
                  <tr>
                    <td>
                      <a routerLink="/propertyDetail/_5462265782"
                        >#5462265782</a
                      >
                    </td>
                    <td>97</td>
                    <td class="text_right">
                      <span class="change_value up">0.98%</span>
                    </td>
                    <td class="text_right">$5,269.04</td>
                  </tr>
                  <tr>
                    <td>
                      <a routerLink="/propertyDetail/_5462265781"
                        >#5462265781</a
                      >
                    </td>
                    <td>11230</td>
                    <td class="text_right">
                      <span class="change_value up">1.22%</span>
                    </td>
                    <td class="text_right">$4,834.48</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="widget_footer">
            <div class="col_group">
              <div class="col_item">
                <div class="fine_print">
                  Values correct on {{ lastUpdated | date:'d MMM yyyy' }} 4:30pm
                </div>
              </div>
              <div class="col_item text_right">
                <a href="#" class=""
                  >View All <span class="icon_arrow_right"></span
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="paymentsWidgetDisplayFlag" class="widget_group">
      <!-- Payments -->
      <div class="widget_container" id="widget_payments">
        <div class="widget_inner">
          <div class="widget_header">
            <div class="widget_title">
              <h3>Payments</h3>
            </div>
          </div>
          <div class="widget_content">
            <div class="chart_toolbar">
              <div class="chart_toolbar_left">
                <ul>
                  <li>
                    <fieldset class="form_row no_padding clearfix">
                      <legend class="hidden_legend">Period</legend>
                      <div class="toggle_group">
                        <div class="toggle_group_option">
                          <div class="toggle_input">
                            <input
                              type="radio"
                              value="monthly_payment"
                              id="period_monthly_payment"
                              name="chart_period_payment"
                              class="chart_period_payment"
                              [checked]="true"
                              (click)="setChartPeriodPayment(0)"
                            />
                          </div>
                          <label
                            for="period_monthly_payment"
                            [ngClass]="{
                              toggle_active: chartPeriodPayment === 0
                            }"
                            >Monthly</label
                          >
                        </div>
                        <div class="toggle_group_option">
                          <div class="toggle_input">
                            <input
                              type="radio"
                              value="quarterly_payment"
                              id="period_quarterly_payment"
                              name="chart_period_payment"
                              class="chart_period_payment"
                              (click)="setChartPeriodPayment(1)"
                            />
                          </div>
                          <label
                            for="period_quarterly_payment"
                            [ngClass]="{
                              toggle_active: chartPeriodPayment === 1
                            }"
                            >Quarterly</label
                          >
                        </div>
                        <div class="toggle_group_option">
                          <div class="toggle_input">
                            <input
                              type="radio"
                              value="annually_payment"
                              id="period_annually_payment"
                              name="chart_period_payment"
                              class="chart_period_payment"
                              (click)="setChartPeriodPayment(2)"
                            />
                          </div>
                          <label
                            for="period_annually_payment"
                            [ngClass]="{
                              toggle_active: chartPeriodPayment === 2
                            }"
                            >Annually</label
                          >
                        </div>
                      </div>
                    </fieldset>
                  </li>
                  <li>
                    <div class="period_dates">
                      <div class="period_date_prev">
                        <button
                          type="button"
                          class="btn_normal btn_icon_only btn_no_border"
                        >
                          <span class="btn_icon"
                            ><span class="icon_chevron_left"></span
                          ></span>
                          <span class="btn_text">Previous Period</span>
                        </button>
                      </div>
                      <div class="period_date_current">
                        <span id="period_current_date_start">{{ performanceStart | date:'MMM yyyy' }}</span> -
                        <span id="period_current_date_end">{{ performanceEnd | date:'MMM yyyy' }}</span>
                      </div>
                      <div class="period_date_next">
                        <button
                          type="button"
                          class="btn_normal btn_icon_only btn_no_border"
                          disabled=""
                        >
                          <span class="btn_icon"
                            ><span class="icon_chevron_right"></span
                          ></span>
                          <span class="btn_text">Next Period</span>
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="chart_toolbar_right">
                <div class="form_row no_padding">
                  <div class="form_label hidden_label">
                    <label for="payment_bank">All Bank</label>
                  </div>
                  <div class="form_input">
                    <select
                      name="payment_bank"
                      id="payment_bank"
                      class="inputbox"
                      formControlName="payment_bank"
                    >
                      <option value="-1" [selected]="true">All Banks</option>
                      <option
                        *ngFor="let bank of banks"
                        value="{{ bank.id }}"
                        [selected]="false"
                      >
                        {{ bank.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content_body">
            <div class="col_group col_2">
              <div class="col_item">
                <div class="col_group col_2">
                  <div class="col_item">
                    <!-- Donut Chart -->
                    <div class="chart_donut_container">
                      <div id="chart_donut" style="height: 240px;"></div>
                      <div class="chart_donut_value">$395,702</div>
                    </div>
                  </div>
                  <div class="col_item text_left">
                    <div class="space_divider extra_padding"></div>
                    <div class="summary_item compressed">
                      <div class="summary_label">
                        <div class="color_block color_confirmed"></div>
                        Confirmed
                      </div>
                      <div class="summary_value">$288,764</div>
                    </div>
                    <div class="summary_item compressed">
                      <div class="summary_label">
                        <div class="color_block color_pending"></div>
                        Pending
                      </div>
                      <div class="summary_value">$71,225</div>
                    </div>
                    <div class="summary_item compressed">
                      <div class="summary_label">
                        <div class="color_block color_issued"></div>
                        Issued
                      </div>
                      <div class="summary_value">$36,613</div>
                    </div>
                  </div>
                </div>
                <div class="timestamp">Values correct on {{ lastUpdated | date:'d MMM yyyy' }} 4:30pm</div>
              </div>
              <div class="col_item text_left">
                <div id="payment_summary_panel" class="summary_panel">
                  <div class="summary_icon">
                    <div class="summary_icon_main">
                      <span class="icon_payment"></span>
                    </div>
                    <div class="summary_icon_shadow">
                      <span class="icon_payment_shadow"></span>
                    </div>
                  </div>
                  <h2 class="summary_title">Summary of Payments</h2>
                  <div class="summary_content">
                    <div class="col_group">
                      <div class="col_item">
                        <div class="summary_item compressed">
                          <div class="summary_label">Royalties Due</div>
                          <div class="summary_value">$56,568.40</div>
                          <div class="fine_print">Due on {{ royaltyDue | date:'d MMM yyyy' }}</div>
                        </div>
                        <div class="summary_item compressed">
                          <div class="summary_label">Royalties Balance</div>
                          <div class="summary_value">$1,495,477.50</div>
                          <div class="fine_print">
                            Last Updated on {{ lastUpdated | date:'d MMM yyyy' }}
                          </div>
                        </div>
                      </div>
                      <div class="col_item text_left">
                        <div class="summary_item compressed">
                          <div class="summary_label">Owner Payments</div>
                          <div class="summary_value">911</div>
                          <div class="fine_print">(343 Manual Payments)</div>
                        </div>
                        <div class="summary_item compressed">
                          <div class="summary_label">Manual Payment Costs</div>
                          <div class="summary_value">
                            $3,670.00
                            <div class="payment_change">
                              <span class="change_value_inverted down"
                                >15% Less</span
                              >
                            </div>
                          </div>
                          <div class="fine_print">Last on {{ manualPaymentsDate | date:'d MMM yyyy' }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="space_divider"></div>
                <div class="col_group">
                  <div class="col_item">
                    <a href="#" class=""
                      >Go to Payment <span class="icon_arrow_right"></span
                    ></a>
                  </div>
                  <div class="col_item text_right">
                    <a href="#"
                      ><span class="icon_share"></span> Export Results</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="banksWidgetDisplayFlag" class="widget_group">
      <!-- Banks -->
      <div class="widget_container" id="widget_top_properties">
        <div class="widget_inner">
          <div class="widget_header">
            <div class="widget_title">
              <h3>Banks</h3>
            </div>
            <div class="widget_actions">
              <ul>
                <li>
                  <div class="chart_toolbar_right">
                    <div class="form_row no_padding">
                      <div class="form_label hidden_label">
                        <label for="summary_bank">All Bank</label>
                      </div>
                      <div class="form_input">
                        <select
                          name="summary_bank"
                          id="summary_bank"
                          class="inputbox"
                          formControlName="summary_bank"
                        >
                          <option value="-1" [selected]="true"
                            >All Banks</option
                          >
                          <option
                            *ngFor="let bank of banks"
                            value="{{ bank.id }}"
                            [selected]="false"
                          >
                            {{ bank.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="widget_content">
            <div class="table_containr">
              <table class="table_grid">
                <thead>
                  <tr>
                    <th>
                      <div class="table_header">
                        <span class="icon_user_network"></span
                        ><span class="hidden_text">network</span>
                      </div>
                    </th>
                    <th><div class="table_header">Bank</div></th>
                    <th class="text_center">
                      <div class="table_header">Overall Payment %</div>
                    </th>
                    <th class="text_center">
                      <div class="table_header">Balance</div>
                    </th>
                    <th class="text_center">
                      <div class="table_header">Manual Payment Costs</div>
                    </th>
                    <th class="text_center">
                      <div class="table_header">Owners</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="icon_user_network"></span></td>
                    <td>JP Morgan Chase</td>
                    <td>
                      <div class="payment_bar_container">
                        <div class="payment_bar" style="width: 100%;">
                          <div
                            class="payment_confirmed"
                            style="width: 96%;"
                          ></div>
                          <div class="payment_pending" style="width: 2%;"></div>
                          <div class="payment_issued" style="width: 2%;"></div>
                        </div>
                        <div class="payment_bar_value">37%</div>
                      </div>
                    </td>
                    <td class="text_center">$142,452</td>
                    <td class="text_center">-</td>
                    <td class="text_center">387</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>First American</td>
                    <td>
                      <div class="payment_bar_container">
                        <div class="payment_bar" style="width: 78%;">
                          <div
                            class="payment_confirmed"
                            style="width: 25%;"
                          ></div>
                          <div
                            class="payment_pending"
                            style="width: 35%;"
                          ></div>
                          <div class="payment_issued" style="width: 40%;"></div>
                        </div>
                        <div class="payment_bar_value">28%</div>
                      </div>
                    </td>
                    <td class="text_center">$110,797</td>
                    <td class="text_center">$2,360</td>
                    <td class="text_center">236</td>
                  </tr>
                  <tr>
                    <td><span class="icon_user_network"></span></td>
                    <td>Wells Fargo</td>
                    <td>
                      <div class="payment_bar_container">
                        <div class="payment_bar" style="width: 47%;">
                          <div
                            class="payment_confirmed"
                            style="width: 97%;"
                          ></div>
                          <div class="payment_pending" style="width: 1%;"></div>
                          <div class="payment_issued" style="width: 2%;"></div>
                        </div>
                        <div class="payment_bar_value">17%</div>
                      </div>
                    </td>
                    <td class="text_center">$67,269</td>
                    <td class="text_center">-</td>
                    <td class="text_center">181</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Bank of Oklahoma</td>
                    <td>
                      <div class="payment_bar_container">
                        <div class="payment_bar" style="width: 38%;">
                          <div
                            class="payment_confirmed"
                            style="width: 40%;"
                          ></div>
                          <div
                            class="payment_pending"
                            style="width: 30%;"
                          ></div>
                          <div class="payment_issued" style="width: 30%;"></div>
                        </div>
                        <div class="payment_bar_value">14%</div>
                      </div>
                    </td>
                    <td class="text_center">$55,398</td>
                    <td class="text_center">$1,070</td>
                    <td class="text_center">107</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>Argent</td>
                    <td>
                      <div class="payment_bar_container">
                        <div class="payment_bar" style="width: 11%;">
                          <div
                            class="payment_confirmed"
                            style="width: 40%;"
                          ></div>
                          <div
                            class="payment_pending"
                            style="width: 30%;"
                          ></div>
                          <div class="payment_issued" style="width: 30%;"></div>
                        </div>
                        <div class="payment_bar_value">4%</div>
                      </div>
                    </td>
                    <td class="text_center">$15,828</td>
                    <td class="text_center">$240</td>
                    <td class="text_center">24</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="widget_footer">
            <div class="col_group">
              <div class="col_item">
                <div class="fine_print">
                  Values correct on {{ lastUpdated | date:'d MMM yyyy' }} 4:30pm
                </div>
              </div>
              <div class="col_item text_right">
                <a href="#" class=""
                  >View All <span class="icon_arrow_right"></span
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="widget_group">
      <!-- Open Tickets -->
      <div *ngIf="openTicketsWidgetDisplayFlag" class="widget_container" id="widget_open_tickets">
        <div class="widget_inner">
          <div class="widget_header">
            <div class="widget_title">
              <h3>Open Tickets</h3>
            </div>
            <div class="widget_actions"></div>
          </div>
          <div class="widget_content">
            <ul class="data_list">
              <li>
                <div class="data_list_label">Payment Inquiries</div>
                <div class="data_list_value">
                  <a href="#" class="data_list_link">16</a>
                </div>
              </li>
              <li>
                <div class="data_list_label">Lease Inquiries</div>
                <div class="data_list_value">
                  <a href="#" class="data_list_link">9</a>
                </div>
              </li>
              <li>
                <div class="data_list_label">Land Inquiries</div>
                <div class="data_list_value">
                  <a href="#" class="data_list_link">2</a>
                </div>
              </li>
              <li>
                <div class="data_list_label">Production Inquiries</div>
                <div class="data_list_value">
                  <a href="#" class="data_list_link">7</a>
                </div>
              </li>
              <li>
                <div class="data_list_label">Division Orders</div>
                <div class="data_list_value">
                  <a href="#" class="data_list_link">8</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Leases -->
      <div *ngIf="leasesWidgetDisplayFlag" class="widget_container" id="widget_leases">
        <div class="widget_inner">
          <div class="widget_header">
            <div class="widget_title">
              <h3>Leases</h3>
            </div>
            <div class="widget_actions"></div>
          </div>
          <div class="widget_content">
            <div class="active_leases">401 Active</div>
            <h4 class="minor_title">Expiring</h4>
            <ul class="data_list">
              <li>
                <div class="data_list_label">Within 30 days</div>
                <div class="data_list_value">
                  <a href="#" class="data_list_link">16</a>
                </div>
              </li>
              <li>
                <div class="data_list_label">Within 60 days</div>
                <div class="data_list_value">
                  <a href="#" class="data_list_link">26</a>
                </div>
              </li>
              <li>
                <div class="data_list_label">Within 90 days</div>
                <div class="data_list_value">
                  <a href="#" class="data_list_link">32</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- Markets -->
      <div *ngIf="marketsWidgetDisplayFlag" class="widget_container" id="widget_markets">
        <div class="widget_inner">
          <div class="widget_header">
            <div class="widget_title">
              <h3>Markets</h3>
            </div>
            <div class="widget_actions"></div>
          </div>
          <div class="widget_content">
            <div class="col_group">
              <div class="col_item">
                <div class="market_object" id="market_oil">
                  <div class="market_icon">
                    <div class="market_icon_main">
                      <span class="icon_production"></span>
                    </div>
                    <div class="market_icon_shadow">
                      <span class="icon_production_shadow"></span>
                    </div>
                  </div>
                  <div class="market_price">$52.20</div>
                  <div class="market_change">
                    <span class="change_value up">0.08c</span>
                  </div>
                  <div class="market_title">Crude Oil</div>
                </div>
              </div>
              <div class="col_item">
                <div class="market_object" id="market_gas">
                  <div class="market_icon">
                    <div class="market_icon_main">
                      <span class="icon_gas"></span>
                    </div>
                    <div class="market_icon_shadow">
                      <span class="icon_gas_shadow"></span>
                    </div>
                  </div>
                  <div class="market_price">$2.33</div>
                  <div class="market_change">
                    <span class="change_value down">0.03c</span>
                  </div>
                  <div class="market_title">Gas</div>
                </div>
              </div>
            </div>
          </div>
          <div class="widget_footer">
            <div class="col_group">
              <div class="col_item"></div>
              <div class="col_item text_right">
                <a href="#" class=""
                  >Go to Markets <span class="icon_arrow_right"></span
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
