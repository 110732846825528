import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OperatorPaymentsService = /** @class */ (function () {
    function OperatorPaymentsService(http) {
        this.http = http;
        this.paymentsUrl = environment.apiUrl + "/payments";
    }
    // API: Post
    // getOperatorPayments(year: number, propertyId: number) {
    //   const url = `${this.paymentsUrl}/getOperatorPayments`;
    //   const operatorPaymentsReq: OperatorPaymentsReq = new OperatorPaymentsReq();
    //   operatorPaymentsReq.year = year;
    //   operatorPaymentsReq.propertyId = propertyId;
    //   console.log('Url: ' + url);
    //   return this.http.post(url, operatorPaymentsReq);
    // }
    // API: Get
    OperatorPaymentsService.prototype.getOperatorPayments = function () {
        var url = this.paymentsUrl + "/getOperatorPayments";
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    OperatorPaymentsService.prototype.getOperatorPaymentsByYear = function (year) {
        var url = this.paymentsUrl + "/getOperatorPayments?year=" + year;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    OperatorPaymentsService.prototype.getOperatorPaymentsByPropertyId = function (propertyId) {
        var url = this.paymentsUrl + "/getOperatorPayments?propertyId=" + propertyId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    OperatorPaymentsService.prototype.getOperatorPaymentsDeductions = function (operatorId) {
        var url = this.paymentsUrl + "/getOperatorPaymentsDeductions?operatorId=" + operatorId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    OperatorPaymentsService.prototype.getOperatorPaymentsDeductionsIndividual = function (operatorId, paymentId) {
        // tslint:disable-next-line: max-line-length
        var url = this.paymentsUrl + "/getOperatorPaymentsDeductionsIndividual?operatorId=" + operatorId + "&royaltyPaymentId=" + paymentId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // POST
    OperatorPaymentsService.prototype.getReport = function (payments, reportType) {
        var httpOptions = {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.paymentsUrl + "/paymentsReport/" + reportType;
        return this.http.post(url, payments, httpOptions);
    };
    /** @nocollapse */ OperatorPaymentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OperatorPaymentsService_Factory() { return new OperatorPaymentsService(i0.ɵɵinject(i1.HttpClient)); }, token: OperatorPaymentsService, providedIn: "root" });
    return OperatorPaymentsService;
}());
export { OperatorPaymentsService };
