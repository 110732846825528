import { MessagesService } from './../messages.service';
import { ProductionService } from './../production.service';
import { OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
var LoadProductionCsvFileComponent = /** @class */ (function () {
    function LoadProductionCsvFileComponent(http, productionService, messagesService) {
        this.http = http;
        this.productionService = productionService;
        this.messagesService = messagesService;
        this.selectedFile = null;
        this.message = this.messagesService.noFileSelected;
        this.ngUnsubscribe = new Subject();
    }
    LoadProductionCsvFileComponent.prototype.onFileSelected = function (event) {
        this.selectedFile = event.target.files[0];
        this.message = this.selectedFile.name;
        console.log('File selected: ' + this.message);
    };
    LoadProductionCsvFileComponent.prototype.onUpload = function () {
        var _this = this;
        var fileName = this.selectedFile.name;
        var fd = new FormData();
        fd.append('file', this.selectedFile, fileName);
        var resultObservable = this.productionService.postProductionFile(fd);
        var resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (res) {
            console.log('Response: ' + res);
            _this.message = fileName + ' - ' + res;
        });
        this.selectedFile = null;
        // this.message = fileName + ' - ' + this.messagesService.fileUploaded + resultSubscription;
    };
    LoadProductionCsvFileComponent.prototype.ngOnInit = function () { };
    LoadProductionCsvFileComponent.prototype.ngOnDestroy = function () {
        console.log('');
        this.ngUnsubscribe.next();
    };
    return LoadProductionCsvFileComponent;
}());
export { LoadProductionCsvFileComponent };
