import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

declare var $: any; // jquery
declare let datepicker;
declare let modals;
declare let removeHash;

@Component({
  selector: 'app-production-add',
  templateUrl: './production-add.component.html',
  styleUrls: ['./production-add.component.css']
})
export class ProductionAddComponent implements OnInit {

  @Input() displayAddProduction: boolean;
  @Output() displayAddProductionChange = new EventEmitter<boolean>();

  constructor() { }

  closeDialog() {
    this.displayAddProduction = false;
    this.displayAddProductionChange.emit(false);
  }

  ngOnInit() { }

}
