<p>
  property works!
</p>
<div class="row mt-5">
  <div class="col-md-4 mb-3" *ngFor="let property of properties">
    <div class="card text-center">
      <div class="card-body">
        <h5 class="card-title">{{ property.name }}</h5>
        <p class="card-text">{{ property.description }}</p>
        <a href="#" class="btn btn-primary">Buy Property</a>
      </div>
      <div class="card-footer text-muted">
        {{ property.effectiveFrom | date: 'shortDate' }}
      </div>
    </div>
  </div>
</div>
