<div id="dashboard_search">
  <div class="page_container">
    <div id="dashboard_search_inner">
      <div id="search_form">
        <div id="search_form_input">
          <div class="form_row no_padding">
            <div class="form_label hidden_label">
              <label for="dashboard_search_input">Search</label>
            </div>
            <div class="form_input">
              <input
                type="text"
                id="dashboard_search_input"
                name="dashboard_search_input"
                class="inputbox searchbox padding_large"
                placeholder="Search owner's names, ID, property, lease, wells and more..."
                style="width:100%"
              />
            </div>
          </div>
        </div>
        <div id="search_form_results" style="display: none;">
          <div *ngIf="genericSearchResult" id="search_form_results_inner">
            <div class="search_form_header">
              <h3 class="search_form_title">
                Search Results
                <span class="search_result_count">(
                  <span id="search_result_count_properties">{{genericSearchResult.numberOfProperty}}  properties, </span>
                  <span id="search_result_count_owners">{{genericSearchResult.numberOfOwner}}  owners, </span>
                   <span id="search_result_count_wells">{{genericSearchResult.numberOfWell}} wells</span>
                  )
                </span>
              </h3>
            </div>
            <div class="search_form_content">
              <ul id="results_list">
                <li *ngFor="let result of genericSearchResult.searchItems;">
                  <a *ngIf="result.type == 'property'"
                    routerLink="/propertyDetail/{{result.id}}" class="result_link type_property">

                    <span class="result_icon"><span class="icon_property"></span></span>
                    <span class="result_details">
                      <span class="result_name">{{result.name}}</span>
                      <span class="result_id"># {{result.number}}</span>
                      <span class="result_error"></span>
                      <span class="result_address">{{result.description}} &nbsp;</span>
                    </span>
                  </a>

                  <a *ngIf="result.type == 'owner'"
                     routerLink="/owner/{{result.id}}" class="result_link type_owner">
                    <span class="result_icon"><span class="icon_owner"></span></span>
                    <span class="result_details">
                      <span class="result_name">{{result.name}}</span>
                      <span class="result_id">ID #{{result.id}}</span>
                      <span class="result_error"></span>
                      <span class="result_address">{{result.description}} &nbsp;</span>
                    </span>
                  </a>

                  <a *ngIf="result.type == 'well'"
                     routerLink="/propertyDetail/{{result.id}}" class="result_link type_wells">
                    <span class="result_icon"><span class="icon_wells"></span></span>
                    <span class="result_details">
                      <span class="result_name">{{result.name}}</span>
                      <span class="result_id">#{{result.number}}</span>
                      <span class="result_error"></span>
                      <span class="result_address">{{result.description}} &nbsp;</span>
                    </span>
                  </a>
                </li>

                <!--<li>
                  <a href="property_details.html" class="result_link type_property">
                    <span class="result_icon"><span class="icon_property"></span></span>
                    <span class="result_details">
                      <span class="result_name">AM-KIRK</span>
                      <span class="result_id">#55461134782</span>
                      <span class="result_error"></span>
                      <span class="result_address">Township 46 Range 70 Section 5, Campbell, Wyoming 82009</span>
                    </span>
                  </a>
                </li>
                <li>
                  <a href="property_details.html" class="result_link type_property">
                    <span class="result_icon"><span class="icon_property"></span></span>
                    <span class="result_details">
                      <span class="result_name">AM-XXXX</span>
                      <span class="result_id">#54675123311</span>
                      <span class="result_error"></span>
                      <span class="result_address">Township 41 Range 52 Section 3, Campbell, Wyoming 82009</span>
                    </span>
                  </a>
                </li>
                <li>
                  <a href="property_details.html" class="result_link type_owner">
                    <span class="result_icon"><span class="icon_owner"></span></span>
                    <span class="result_details">
                      <span class="result_name">Tim Carey</span>
                      <span class="result_id">ID #54622009</span>
                      <span class="result_error"></span>
                      <span class="result_address">Prudhoe Bay #5-Alaska (AK)</span>
                    </span>
                  </a>
                </li>
                <li>
                  <a href="property_details.html" class="result_link type_owner">
                    <span class="result_icon"><span class="icon_owner"></span></span>
                    <span class="result_details">
                      <span class="result_name">AM-KIRK</span>
                      <span class="result_id">ID #546226133</span>
                      <span class="result_error"><span class="icon_error"></span> Payment Errors</span>
                      <span class="result_address">Rapides, Louisiana (LA)</span>
                    </span>
                  </a>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
