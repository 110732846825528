<div id="quicklinks_inner">
  <div class="quicklink_container">
    <!-- Add a Property -->
    <div class="quicklink_item" id="quicklink_property">
      <div class="quicklink_icon">
        <div class="quicklink_icon_main"><span class="icon_property"></span></div>
        <div class="quicklink_icon_shadow"><span class="icon_property_shadow"></span></div>
      </div>
      <h2 class="quicklink_title">Add a Property</h2>
      <div class="quicklink_text">
        <p>Add details for a new property. <a routerLink="/properties">Go to Properties</a></p>
      </div>
      <div class="quicklink_buttons">
        <a routerLink="/properties/upload" style="color: white">
          <button type="button" class="btn_normal btn_blue" id="btn_upload_property" style="margin-right: 10px;">
            <span class="btn_icon"><span class="icon_upload_cloud"></span></span>
            &nbsp;<span class="btn_text">Upload CSV</span>
          </button>
        </a>
        <a routerLink="/properties/add" style="color: white">
          <button type="button" class="btn_normal btn_blue" id="btn_add_property">
            <span class="btn_icon"><span class="icon_create"></span></span>
            &nbsp;<span class="btn_text">Add Manually</span>
          </button>
        </a>
      </div>
    </div>

    <!-- Add Production -->
    <div class="quicklink_item" id="quicklink_production">
      <div class="quicklink_icon">
        <div class="quicklink_icon_main"><span class="icon_production"></span></div>
        <div class="quicklink_icon_shadow"><span class="icon_production_shadow"></span></div>
      </div>
      <h2 class="quicklink_title">Add Production</h2>
      <div class="quicklink_text">
        <p>Upload or add production values for your properties</p>
      </div>
      <div class="quicklink_buttons">
        <a routerLink="/production" style="color: white">
          <button type="button" class="btn_normal btn_blue" id="btn_add_production" style="margin-right: 10px;">
            <span class="btn_icon"><span class="icon_table"></span></span>
            &nbsp;<span class="btn_text">Production</span>
          </button>
        </a>
        <a routerLink="/production/upload" style="color: white">
          <button type="button" class="btn_normal btn_blue" id="btn_upload_production" style="margin-right: 10px;">
            <span class="btn_icon"><span class="icon_upload_cloud"></span></span>
            &nbsp;<span class="btn_text">Upload CSV</span>
          </button>
        </a>
        <!-- <a routerLink="/productionList" style="color: white">
          <button type="button" class="btn_normal btn_blue" id="btn_add_production">
            <span class="btn_icon"><span class="icon_create"></span></span>
            <span class="btn_text">Add Manually</span>
          </button>
        </a> -->
      </div>
    </div>

    <!-- Add Sales & Expenses -->
    <div class="quicklink_item" id="quicklink_sales_expenses">
      <div class="quicklink_icon">
        <div class="quicklink_icon_main"><span class="icon_sales"></span></div>
        <div class="quicklink_icon_shadow"><span class="icon_sales_shadow"></span></div>
      </div>
      <h2 class="quicklink_title">Add Sales &amp; Expenses</h2>
      <div class="quicklink_text">
        <p>Upload or add sales &amp; expenses for your properties</p>
      </div>
      <div class="quicklink_buttons">
        <a routerLink="/sales" style="color: white">
          <button type="button" class="btn_normal btn_blue" id="btn_sales_expenses" style="margin-right: 10px;">
            <span class="btn_icon"><span class="icon_table"></span></span>
            &nbsp;<span class="btn_text">Sales & Expenses</span>
          </button>
        </a>
        <a routerLink="/sales/upload" style="color: white">
          <button type="button" class="btn_normal btn_blue" id="btn_upload_sales_expenses" style="margin-right: 10px;">
            <span class="btn_icon"><span class="icon_upload_cloud"></span></span>
            &nbsp;<span class="btn_text">Upload CSV</span>
          </button>
        </a>
        <!-- <a routerLink="/salesList" style="color: white">
          <button type="button" class="btn_normal btn_blue" id="btn_add_sales_expenses">
            <span class="btn_icon"><span class="icon_create"></span></span>
            <span class="btn_text">Add Manually</span>
          </button>
        </a> -->
      </div>
    </div>
  </div>
</div>
