import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { County } from 'src/app/model/county';
import { Operator } from 'src/app/model/operator';
import { Purchaser } from 'src/app/model/purchaser';
import { Product } from 'src/app/model/product';
import { PropertyService } from 'src/app/property.service';
import { LookupService } from 'src/app/lookup.service';
import { Property } from 'src/app/model/property';
import { Well } from 'src/app/model/well';
import { Subject } from 'rxjs';
var PropertiesAddComponent = /** @class */ (function () {
    function PropertiesAddComponent(fb, propertyService, lookupService) {
        this.fb = fb;
        this.propertyService = propertyService;
        this.lookupService = lookupService;
        this.displayAddPropertyChange = new EventEmitter();
        this.displayBlockchainErrorDialogChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
        this.showAdditionInfo = false;
        this.propertyForm = this.fb.group({
            id: [''],
            no: ['', Validators.required],
            name: ['', Validators.required],
            description: [''],
            state: ['', Validators.required],
            county: ['', Validators.required],
            operator: [''],
            purchaser: [''],
            prodType: [''],
            leaseNo: [''],
            wellId: [''],
            wellApiNo: [''],
            wellName: [''],
            effectiveFrom: [''],
            effectiveTo: ['']
        });
        this.effectiveFromDateValue = null;
        this.effectiveToDateValue = null;
        // Disable fields
        this.disableFormFields();
    }
    PropertiesAddComponent.prototype.closeAddPropertyDialog = function () {
        this.displayAddProperty = false;
        this.displayAddPropertyChange.emit(false);
    };
    PropertiesAddComponent.prototype.closeBCDialog = function () {
        this.displayBlockchainErrorDialog = false;
        this.displayBlockchainErrorDialogChange.emit(false);
        this.closeAddPropertyDialog();
    };
    PropertiesAddComponent.prototype.initPropertyForm = function () {
        this.propertyForm.patchValue({ id: '' });
        this.propertyForm.patchValue({ no: '' });
        this.propertyForm.patchValue({ name: '' });
        this.propertyForm.patchValue({ description: '' });
        this.propertyForm.patchValue({ state: '' });
        this.propertyForm.patchValue({ county: '' });
        this.propertyForm.patchValue({ operator: '' });
        this.propertyForm.patchValue({ purchaser: '' });
        this.propertyForm.patchValue({ prodType: '' });
        this.propertyForm.patchValue({ wellId: '' });
        this.propertyForm.patchValue({ wellApiNo: '' });
        this.propertyForm.patchValue({ wellName: '' });
        this.propertyForm.patchValue({ effectiveFrom: '' });
        this.propertyForm.patchValue({ effectiveTo: '' });
    };
    PropertiesAddComponent.prototype.ngOnInit = function () {
        console.log('Properties-Add - Init - start');
        $(document).ready(function () {
            datepicker();
            modals();
        });
        // Initialise the form to blank
        this.initPropertyForm();
        // Load lists
        this.getUSStates();
        this.getOperators();
        this.getPurchasers();
        this.getProducts();
        console.log('Properties-Add - Init - complete');
    };
    PropertiesAddComponent.prototype.displayAdditionInfo = function () {
        this.showAdditionInfo = true;
    };
    // This will disable all form fields in the list 'disabledFields'
    PropertiesAddComponent.prototype.disableFormFields = function () {
        var _this = this;
        var disabledFields = ['id'];
        disabledFields.forEach(function (fieldId) { return _this.propertyForm.get(fieldId).disable(); });
    };
    PropertiesAddComponent.prototype.getUSStates = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getStates())];
                    case 1:
                        _a.states = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesAddComponent.prototype.getOperators = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, operatorId, _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        user = JSON.parse(localStorage.getItem('user'));
                        operatorId = user.operator.id;
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getOperators(operatorId))];
                    case 1:
                        _a.operators = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesAddComponent.prototype.getPurchasers = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getPurchasers())];
                    case 1:
                        _a.purchasers = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesAddComponent.prototype.getProducts = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getProducts())];
                    case 1:
                        _a.products = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesAddComponent.prototype.onSubmit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var property, county, operator, purchaser, well, product, result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // console.log({ effectiveFrom: this.effectiveFromDate.nativeElement.value });
                        // console.log({ effectiveTo: this.effectiveToDate.nativeElement.value });
                        console.log('Property Form: ', { propertyForm: this.propertyForm.value });
                        property = new Property();
                        property.id = this.propertyForm.get('id').value === '' ? 0 : Number(this.propertyForm.get('id').value);
                        property.propertyNo = this.propertyForm.get('no').value;
                        property.name = this.propertyForm.get('name').value;
                        property.description = this.propertyForm.get('description').value;
                        county = new County();
                        county.id = this.propertyForm.get('county').value === '' ? 0 : Number(this.propertyForm.get('county').value);
                        property.county = county;
                        operator = new Operator();
                        operator.id = this.propertyForm.get('operator').value === '' ? 0 : Number(this.propertyForm.get('operator').value);
                        purchaser = new Purchaser();
                        purchaser.id = this.propertyForm.get('purchaser').value === '' ? 0 : Number(this.propertyForm.get('purchaser').value);
                        property.purchaser = purchaser;
                        well = new Well();
                        well.id = this.propertyForm.get('wellId').value === '' ? 0 : Number(this.propertyForm.get('wellId').value);
                        well.apiNo = this.propertyForm.get('wellApiNo').value;
                        well.name = this.propertyForm.get('wellName').value;
                        product = new Product();
                        product.id = this.propertyForm.get('prodType').value === '' ? 0 : Number(this.propertyForm.get('prodType').value);
                        well.products = new Array();
                        well.products.push(product);
                        well.operator = operator;
                        property.wells = new Array();
                        property.wells.push(well);
                        property.effectiveFrom = this.dateToString(this.effectiveFromDateValue);
                        property.effectiveTo = this.dateToString(this.effectiveToDateValue);
                        // Because the datepicker is not updating the propertyForm, we need to get the effectiveFrom/effectiveTo
                        // directly from the elements of the page
                        // property.effectiveFrom = this.formatDate(this.effectiveFromDate.nativeElement.value);
                        // property.effectiveTo = this.formatDate(this.effectiveToDate.nativeElement.value);
                        // if (this.propertyForm.get('effectiveFrom').value === '') {
                        //   property.effectiveFrom = '';
                        // } else {
                        //   property.effectiveFrom = this.formatDate(this.propertyForm.get('effectiveFrom').value);
                        // }
                        // if (this.propertyForm.get('effectiveTo').value === '') {
                        //   property.effectiveTo = '';
                        // } else {
                        //   property.effectiveTo = this.formatDate(this.propertyForm.get('effectiveTo').value);
                        // }
                        property.keyValues = new Array();
                        // Only save data to DB if data is valid
                        console.log('this.propertyForm.Status: ', this.propertyForm.status);
                        if (!(this.propertyForm.status === 'VALID')) return [3 /*break*/, 2];
                        return [4 /*yield*/, (this.propertyService.add(property))];
                    case 1:
                        result = _a.sent();
                        // Assign id back to the form
                        this.propertyForm.patchValue({ id: result.id });
                        console.log('onSubmit - Form Property: ', this.propertyForm);
                        console.log('onSubmit - Property: ', property);
                        console.log('onSubmit - result: ', result);
                        if (!result.bcCurrent) {
                            this.displayBlockchainErrorDialog = true;
                            // alert('Property saved to DB - but failed to write to Blockchain');
                        }
                        else {
                            // Close the form
                            this.closeAddPropertyDialog();
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    PropertiesAddComponent.prototype.formatDate = function (dateStr) {
        if (dateStr === '') {
            return '';
        }
        var dateParts = dateStr.split('/');
        console.log('Date Split: ', dateParts[0], dateParts[1], dateParts[2]);
        console.log('Date Format: ', dateParts[2], '-', dateParts[0], '-', dateParts[1]);
        return dateParts[2] + '-' + dateParts[0] + '-' + dateParts[1];
    };
    PropertiesAddComponent.prototype.dateToString = function (date) {
        if (date == null) {
            return '';
        }
        else {
            // Return date as string in YYYY-MM-DD format
            return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        }
    };
    PropertiesAddComponent.prototype.onStateChange = function () {
        var _this = this;
        console.log('State changed: ' + this.propertyForm.get('state').value);
        var state = this.states.find(function (s) { return s.id === Number(_this.propertyForm.get('state').value); });
        this.counties = state.counties;
        console.log('counties: ', { counties: this.counties });
    };
    // Script to initialize the form
    PropertiesAddComponent.prototype.initForm = function () {
        this.propertyForm.patchValue({ id: 3 });
        this.propertyForm.patchValue({ no: '5462265786' });
        this.propertyForm.patchValue({ name: 'AM-KIRK' });
        this.propertyForm.patchValue({ description: 'NW/4 8-20N-102W' });
        this.propertyForm.patchValue({ state: 49 });
        this.onStateChange();
        this.propertyForm.patchValue({ county: 49037 });
        this.propertyForm.patchValue({ operator: 1 });
        this.propertyForm.patchValue({ purchaser: 1 });
        this.propertyForm.patchValue({ prodType: 10 });
        this.propertyForm.patchValue({ wellId: '14' });
        this.propertyForm.patchValue({ wellApiNo: '46-037-21571' });
        this.propertyForm.patchValue({ wellName: 'Thomsberg 1' });
        this.propertyForm.patchValue({ effectiveFrom: '01/03/2019' });
        this.propertyForm.patchValue({ effectiveTo: '' });
    };
    // Script to initialize the form
    PropertiesAddComponent.prototype.initForm2 = function () {
        this.propertyForm.patchValue({ id: 19 });
        this.propertyForm.patchValue({ no: '5456723567124' });
        this.propertyForm.patchValue({ name: 'NEW-KIRK-WELL' });
        this.propertyForm.patchValue({ description: 'Test-Property-Well' });
        this.propertyForm.patchValue({ state: 49 });
        this.onStateChange();
        this.propertyForm.patchValue({ county: 49003 });
        this.propertyForm.patchValue({ operator: 1 });
        this.propertyForm.patchValue({ purchaser: 2 });
        this.propertyForm.patchValue({ prodType: 10 });
        this.propertyForm.patchValue({ wellId: '31' });
        this.propertyForm.patchValue({ wellApiNo: '049-15326-4712' });
        this.propertyForm.patchValue({ wellName: 'Deep River' });
        this.effectiveFromDateValue = new Date();
        this.effectiveToDateValue = null;
        // this.propertyForm.patchValue({ effectiveFromDate: '01/03/2019' });
        // this.propertyForm.patchValue({ effectiveToDate: '' });
        console.log('Property: ', this.propertyForm);
    };
    PropertiesAddComponent.prototype.ngOnDestroy = function () {
        console.log('The component is being destroyed');
        this.ngUnsubscribe.next();
    };
    return PropertiesAddComponent;
}());
export { PropertiesAddComponent };
