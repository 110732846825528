import { OnInit } from '@angular/core';
var OwnerComponent = /** @class */ (function () {
    function OwnerComponent() {
        this.displayTaxReport = false;
    }
    OwnerComponent.prototype.ngOnInit = function () {
    };
    OwnerComponent.prototype.closeTaxReport = function () {
        this.displayTaxReport = false;
    };
    OwnerComponent.prototype.onClick = function () {
        console.log('DisplayTaxReport: ' + this.displayTaxReport);
    };
    return OwnerComponent;
}());
export { OwnerComponent };
