import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DivisionorderService } from 'src/app/divisionorder.service';
import { MessagesService } from 'src/app/messages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';

@Component({
  selector: 'app-properties-upload',
  templateUrl: './properties-upload.component.html',
  styleUrls: ['./properties-upload.component.css']
})
export class PropertiesUploadComponent implements OnInit, OnDestroy {

  @Input() displayUploadProperty: boolean;
  @Output() displayUploadPropertyChange = new EventEmitter<boolean>();

  // These are required so we can switch over to Manually Add a property
  @Input() displayAddProperty: boolean;
  @Output() displayAddPropertyChange = new EventEmitter<boolean>();

  uploadDisabled = true;

  selectedFile: File = null;
  public fileSelected = false;
  public message = this.messagesService.noFileSelected;
  private ngUnsubscribe = new Subject();

  constructor(
    private http: HttpClient,
    private divisionOrderService: DivisionorderService,
    private messagesService: MessagesService,
    private authService: AuthService) { }

  closeDialog() {
    console.log('Close upload property dialog');
    this.displayUploadProperty = false;
    this.displayUploadPropertyChange.emit(false);
  }

  addManually() {
    console.log('Add manually');

    // Close Upload Dialog
    this.closeDialog();

    // Open Add Property Dialog
    this.displayAddProperty = true;
    this.displayAddPropertyChange.emit(true);
  }

  ngOnInit() {

  }

  onFileSelectedProperty(event) {
    this.selectedFile = event.target.files[0] as File;
    this.message = this.selectedFile.name;
    this.fileSelected = true;
    console.log('Property File selected: ' + this.message);
  }

  onUpload() {
    console.log('OnUpload - start');
    const user = this.authService.getUser();

    const fileName = this.selectedFile.name;
    console.log('OnUpload - fileName:' + fileName);

    const fd = new FormData();
    fd.append('file', this.selectedFile, fileName);

    fd.append('userId', user.id.toString());
    fd.append('operatorId', user.operator.id.toString());

    const resultObservable = this.divisionOrderService.postDivisionOrderFile(fd);

    const resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      console.log('Response: ' + res);
    });

    this.selectedFile = null;
    this.message = fileName + ' - ' + this.messagesService.fileUploaded;

    this.closeDialog();
  }

  ngOnDestroy() {
    console.log('The component is being destroyed');
    this.ngUnsubscribe.next();
  }

}
