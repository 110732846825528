<div id="ticker">
  <div class="page_container">
    <div id="ticker_inner">
      <div id="ticker_main">
        <div class="ticker_container ticker_split">
          <div class="ticker_data ticker_market">
            <div class="form_row auto_width no_padding">
              <div class="form_label">
                <label for="market_changer">Oil Market Price</label>
              </div>
              <div class="form_input">
                <select class="inputbox no_border reversed no_padding" id="market_changer" name="market_changer">
                  <option value="" selected="">U.S. Blends</option>
                  <option value="">OPEC Blends</option>
                  <option value="">Canadian Blends</option>
                </select>
              </div>
            </div>
          </div>
          <div class="ticker_data ticker_futures_index">
            <div class="form_row auto_width no_padding">
              <div class="form_label">
                <label for="oil_brand_switcher">Futures &amp; Indexes</label>
              </div>
              <div class="form_input">
                <select class="inputbox no_border reversed no_padding" id="oil_brand_switcher" name="oil_brand_switcher">
                  <option value="">Louisiana Light</option>
                  <option value="">Domestic SWT @ Cushing</option>
                  <option value="">Giddings</option>
                  <option value="">ANS West Coast</option>
                  <option value="">West Texas Sour</option>
                  <option value="">Eagle Ford</option>
                  <option value="">Oklahoma Sweet</option>
                  <option value="">Kansas Common</option>
                  <option value="">buena Vista</option>
                </select>
              </div>
            </div>
          </div>
          <div class="ticker_data ticker_last">
            <div class="data_row no_padding">
              <div class="data_label">Last</div>
              <div class="data_value">$49.50</div>
            </div>
          </div>
          <div class="ticker_data ticker_change">
            <div class="data_row no_padding">
              <div class="data_label">Change</div>
              <div class="data_value"><span class="change_value up">+6.00</span></div>
            </div>
          </div>
          <div class="ticker_data ticker_change_pct">
            <div class="data_row no_padding">
              <div class="data_label">Chnge %</div>
              <div class="data_value"><span class="change_value up">+13.79%</span></div>
            </div>
          </div>
          <div class="ticker_data ticker_last_updated">
            <div class="data_row no_padding">
              <div class="data_label">Last Used</div>
              <div class="data_value">1 day ago</div>
            </div>
          </div>
        </div>

        <div class="ticker_container ticker_split">
          <div class="ticker_data ticker_company">
            <div class="form_row auto_width no_padding">
              <div class="form_label">
                <label for="company_contracts">Corporate Oil</label>
              </div>
              <div class="form_input">
                <select class="inputbox no_border reversed no_padding" id="company_contracts" name="company_contracts">
                  <option value="" selected="">Contract #90923409</option>
                </select>
              </div>
            </div>
          </div>
          <div class="ticker_data ticker_average">
            <div class="data_row no_padding">
              <div class="data_label">Average</div>
              <div class="data_value"><span class="change_value up">$48.30</span></div>
            </div>
          </div>
          <div class="ticker_data ticker_change">
            <div class="data_row no_padding">
              <div class="data_label">Change</div>
              <div class="data_value"><span class="change_value up">+4.15</span></div>
            </div>
          </div>
          <div class="ticker_data ticker_change_pct">
            <div class="data_row no_padding">
              <div class="data_label">Change %</div>
              <div class="data_value"><span class="change_value up">+12.05%</span></div>
            </div>
          </div>
          <div class="ticker_data ticker_last_updated">
            <div class="data_row no_padding">
              <div class="data_label">Last Update</div>
              <div class="data_value">6 mins ago</div>
            </div>
          </div>
        </div>
      </div>
      <div id="ticker_drawer" style="display: none;">
        content here
      </div>
      <div id="ticker_toggle">
        <button type="button" class="btn_normal btn_icon_only" id="btn_ticker_toggle">
          <span class="btn_icon"><span class="icon_chevron_down"></span></span>
          <span class="btn_text">Toggle Drawer</span>
        </button>
      </div>
    </div>
  </div>
</div>
