import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Router } from '@angular/router';
import { User } from './model/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  // tslint:disable: variable-name
  private _registerUrl = `${environment.apiUrl}/user/register`;
  private _loginUrl = `${environment.apiUrl}/user/login`;
  private _verifyUrl = `${environment.apiUrl}/user/verify`;

  private user: User;

  constructor(
    private http: HttpClient,
    private router: Router) { }

  registerUser(user) {
    return this.http.post<any>(this._registerUrl, user);
    // return this.http.post(this._registerUrl, user, { responseType: 'text' });
  }

  loginUser(user) {
    return this.http.post<any>(this._loginUrl, user);
    // return this.http.post(this._loginUrl, user, { responseType: 'text' });
  }

  loggedIn() {
    return !!localStorage.getItem('token') && !!localStorage.getItem('user');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    this.user = JSON.parse(localStorage.getItem('user'));
    return this.user;
  }

  verifyToken() {
    // console.log('auth.service.ts - VerifyToken');
    return this.http.get<any>(this._verifyUrl);
  }

  logoutUser() {
    // localStorage.removeItem('token');
    localStorage.clear();

    this.router.navigate(['/login']);
  }
}
