import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'Pipeline DL';

  displayName = '';

  // This is used with HTML part to demo Kendo Modal forms
  // public status = 'open';
  // public openDialog = false;

  // public openDialogWindow() {
  //   this.openDialog = true;
  // }
  // public onClose() {
  //   this.openDialog = false;
  //   this.status = 'closed';
  // }
  // public onAccept() {
  //   this.status = 'accepted';
  // }
  // public onDecline() {
  //   this.status = 'declined';
  // }

}
