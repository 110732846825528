import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HighchartsChartModule } from 'highcharts-angular';

import { DialogsModule } from '@progress/kendo-angular-dialog';
import { IntlModule } from '@progress/kendo-angular-intl';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { SpreadSheetsModule } from '@grapecity/spread-sheets-angular';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MessagesComponent } from './messages/messages.component';
import { TickerComponent } from './ticker/ticker.component';
import { TickerSplitComponent } from './ticker-split/ticker-split.component';

import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardSearchComponent } from './dashboard/dashboard-search/dashboard-search.component';
import { DashboardNotificationsComponent } from './dashboard/dashboard-notifications/dashboard-notifications.component';
import { DashboardQuicklinksComponent } from './dashboard/dashboard-quicklinks/dashboard-quicklinks.component';
import { DashboardWidgetsComponent } from './dashboard/dashboard-widgets/dashboard-widgets.component';

import { PropertiesComponent } from './properties/properties.component';
import { PropertiesContentComponent } from './properties/properties-content/properties-content.component';
import { PropertiesAddComponent } from './properties/properties-add/properties-add.component';
import { PropertiesUploadComponent } from './properties/properties-upload/properties-upload.component';

import { ProductionComponent } from './production/production.component';
import { ProductionContentComponent } from './production/production-content/production-content.component';
import { ProductionAddComponent } from './production/production-add/production-add.component';
import { ProductionUploadComponent } from './production/production-upload/production-upload.component';

import { SalesComponent } from './sales/sales.component';
import { SalesContentComponent } from './sales/sales-content/sales-content.component';
import { SalesAddComponent } from './sales/sales-add/sales-add.component';
import { SalesUploadComponent } from './sales/sales-upload/sales-upload.component';

import { OwnerComponent } from './owner/owner.component';
import { OwnerContentComponent } from './owner/owner-content/owner-content.component';
import { OwnerAddComponent } from './owner/owner-add/owner-add.component';
import { OwnerUploadComponent } from './owner/owner-upload/owner-upload.component';

import { PaymentComponent } from './payment/payment.component';
import { PaymentContentComponent } from './payment/payment-content/payment-content.component';
import { PaymentAddComponent } from './payment/payment-add/payment-add.component';
import { PaymentUploadComponent } from './payment/payment-upload/payment-upload.component';

import { PropertyComponent } from './property/property.component';
import { TestComponent } from './test/test.component';
import { LoadOwnerCsvFileComponent } from './load-owner-csv-file/load-owner-csv-file.component';
import { LoadSalesCsvFileComponent } from './load-sales-csv-file/load-sales-csv-file.component';
import { LoadProductionCsvFileComponent } from './load-production-csv-file/load-production-csv-file.component';
import { GenericLoadFileComponent } from './generic-load-file/generic-load-file.component';
import { DivisionorderListComponent } from './divisionorder-list/divisionorder-list.component';
import { ImportComponent } from './import/import.component';

import { AuthService } from './auth.service';
import { PropertyService } from './property.service';

import * as GC from '@grapecity/spread-sheets';
// import * as Excel from '@grapecity/spread-excelio';
import { WjInputModule } from '@grapecity/wijmo.angular2.input';

import { environment } from 'src/environments/environment.prod';
import { AuthGuard } from './auth.guard';
import { TokenInterceptorService } from './token-interceptor.service';
import { PropertyDetailComponent } from './property-detail/property-detail.component';
import { PaymentsComponent } from './payments/payments.component';
import { PaymentsContentComponent } from './payments/payments-content/payments-content.component';
import { OperatorPaymentsContentComponent } from './payments/operator-payments-content/operator-payments-content.component';

import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { UserComponent } from './user/user.component';
import { DivisionorderComponent } from './divisionorder/divisionorder.component';
import { SalesProcessPaymentsComponent } from './sales/sales-process-payments/sales-process-payments.component';

GC.Spread.Sheets.LicenseKey = environment.spreadLicense;
// Excel.LicenseKey = environment.spreadLicense;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,

    HeaderComponent,
    FooterComponent,
    MessagesComponent,
    TickerComponent,
    TickerSplitComponent,

    DashboardComponent,
    DashboardSearchComponent,
    DashboardNotificationsComponent,
    DashboardQuicklinksComponent,
    DashboardWidgetsComponent,

    PropertiesComponent,
    PropertiesContentComponent,
    PropertiesAddComponent,
    PropertiesUploadComponent,

    PropertyDetailComponent,

    ProductionComponent,
    ProductionContentComponent,
    ProductionAddComponent,
    ProductionUploadComponent,

    SalesComponent,
    SalesContentComponent,
    SalesAddComponent,
    SalesUploadComponent,

    OwnerComponent,
    OwnerContentComponent,
    OwnerAddComponent,
    OwnerUploadComponent,

    PaymentComponent,
    PaymentAddComponent,
    PaymentUploadComponent,
    PaymentContentComponent,
    OperatorPaymentsContentComponent,

    PropertyComponent,
    TestComponent,
    LoadOwnerCsvFileComponent,
    LoadSalesCsvFileComponent,
    LoadProductionCsvFileComponent,
    GenericLoadFileComponent,
    ImportComponent,
    DivisionorderListComponent,
    DivisionorderComponent,
    PaymentsComponent,
    PaymentsContentComponent,

    UserComponent,
    ChangePasswordComponent,
    SalesProcessPaymentsComponent


  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SpreadSheetsModule,
    HttpClientModule,
    WjInputModule,
    DialogsModule,
    IntlModule,
    DateInputsModule,
    HighchartsChartModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    }],
  bootstrap: [
    AppComponent
  ]
})

export class AppModule { }
