import { OnInit } from '@angular/core';
import { LookupService } from 'src/app/lookup.service';
var DashboardSearchComponent = /** @class */ (function () {
    function DashboardSearchComponent(lookupService) {
        this.lookupService = lookupService;
    }
    DashboardSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        $(document).ready(function () {
            // Search Input Keypup
            $('#dashboard_search_input').keyup(function (e) {
                _this.checkSearchBox();
            });
        });
    };
    DashboardSearchComponent.prototype.checkSearchBox = function () {
        var _this = this;
        var user = JSON.parse(localStorage.getItem('user'));
        var operatorId = user.operator.id;
        var searchboxQuery = $('#dashboard_search_input').val();
        console.log('Search Query: ' + searchboxQuery);
        if (searchboxQuery !== '') {
            this.lookupService.getGenericSearchResult(operatorId, searchboxQuery)
                .subscribe(function (results) {
                console.log('Search Results: ', { results: results });
                _this.genericSearchResult = results;
            });
            // Show the Results Container
            $('#search_form_results').show();
        }
        else {
            // Hide the Results Container
            $('#search_form_results').hide();
        }
    };
    return DashboardSearchComponent;
}());
export { DashboardSearchComponent };
