import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DatePipe, formatDate, Location } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import * as GC from '@grapecity/spread-sheets';
import { environment } from 'src/environments/environment';

import { LookupService } from 'src/app/lookup.service';
import { Payment } from 'src/app/model/payment';
import { PaymentsSummary } from 'src/app/model/payments_summary';
import { PaymentSearch } from 'src/app/model/payment_search';
import { MessagesService } from 'src/app/messages.service';
import { dynamicSort, toggleSort } from 'src/app/shared/dynamic-sort';
import { Bank } from 'src/app/model/bank';
import { options, setValues } from './donutchart';
import * as Highcharts from 'highcharts';
import { map } from 'rxjs/operators';
import { BankTrustManager } from 'src/app/model/bank-trust-manager';
import { User } from 'src/app/model/user';
import { Deduction } from 'src/app/model/deduction';
import { PaymentDeduction } from 'src/app/model/payment_deduction';
import * as FileSaver from 'file-saver';
import { OperatorPaymentsService } from '../../operator-payments.service';

declare var $: any; // jquery
const clone = obj => JSON.parse(JSON.stringify(obj));

const paymentStatusPending = 1;
const paymentStatusIssued = 2;
const paymentStatusConfirmed = 3;
@Component({
  selector: 'app-operator-payments-content',
  templateUrl: './operator-payments-content.component.html',
  styleUrls: ['./operator-payments-content.component.css'],
  providers: [DatePipe]
})

export class OperatorPaymentsContentComponent implements OnInit, OnDestroy {

  @ViewChild('spreadContainer', { static: false }) spreadContainer: ElementRef;

  paymentSearchForm: FormGroup = this.fb.group({
    searchStr: [''],
    bank: ['']
  });

  private readonly blockchainExplorerUrl = `${environment.blockchainExplorerUrl}`;

  private sheet: GC.Spread.Sheets.Worksheet = null;
  private spread: GC.Spread.Sheets.Workbook;

  private sheetWidth: number;

  private msg = this.messagesService.messages;

  tabStripVisible = false;
  spreadBackColor = 'white';
  sheetName = 'Payments List';
  hostStyle = {
    height: '100%',
    width: '100%'
  };
  data: any;
  autoGenerateColumns = false;

  bankColumn = 0;
  propertyNoColumn = 1;
  propertyNameColumn = 2;
  divOrderNumberColumn = 3;
  ownerNameColumn = 4;
  saleDateColumn = 5;
  productColumn = 6;
  priceColumn = 7;
  btuColumn = 8;
  volumeColumn = 9;
  grossColumn = 10;
  ownerNetValueColumn = 11;
  deductionsColumn = 12;
  deductionTypeColumn = 13;
  interestTypeColumn = 14;
  decimalInterestColumn = 15;
  ownerVolumeColumn = 16;
  paymentDateColumn = 17;
  statusColumn = 18;
  blockChainColumn = 19;
  blockChainTransIdColumn = 20;
  gridColumnCount = 21;

  allPayments: Payment[];
  payments: Payment[];
  paymentsSummary: PaymentsSummary;

  paymentSearch: PaymentSearch;
  maxStartDate = false;
  maxEndDate = true;

  banks: Bank[];

  private options: any;
  private chart: Highcharts.Chart;

  // 0 = unsorted, 1 = sorted Ascending, -1 = sorted Descending
  sortOrderBank = 0;
  sortOrderPropertyNo = 0;
  sortOrderPropertyName = 0;
  sortOrderDivOrderNumber = 0;
  sortOrderOwnerName = 0;
  sortOrderSaleDate = 0;
  sortOrderProduct = 0;
  sortOrderPrice = 0;
  sortOrderBtu = 0;
  sortOrderVolume = 0;
  sortOrderGross = 0;
  sortOrderSeverence = 0;
  sortOrderOwnerNetValue = 0;
  sortOrderDeductions = 0;
  sortOrderInterestType = 0;
  sortOrderDecimalInterest = 0;
  sortOrderRoyaltyInterest = 0;
  sortOrderOwnerVolume = 0;
  sortOrderPaymentDate = 0;
  sortOrderStatus = 0;
  sortOrderBlockChainTransId = 0;

  displayDeductionDetails = false;
  deductions: Deduction[];

  expandIndex1 = -1;
  expandIndex2 = -1;

  enableToExport = false;

  lastUpdated: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    private paymentsService: OperatorPaymentsService,
    private messagesService: MessagesService,
    private datePipe: DatePipe,
    private location: Location,
    private router: Router) { }

  ngOnInit() {
    // initialise Search criteria
    const today = new Date();

    this.paymentSearch = new PaymentSearch();
    this.paymentSearch.paymentsType = 0;
    this.paymentSearch.paymentPeriodStep = 1;
    this.paymentSearch.startDateMonth = 1;
    this.paymentSearch.startDateYear = today.getFullYear();
    this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
    this.paymentSearch.endDateMonth = today.getMonth() + 1;
    this.paymentSearch.endDateYear = today.getFullYear();
    this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);
    this.paymentSearch.searchStr = [];
    this.paymentSearch.bankId = '';

    this.paymentsSummary = new PaymentsSummary();
    this.paymentsSummary.confirmedValue = 0.0;
    this.paymentsSummary.pendingValue = 0.0;
    this.paymentsSummary.issuedValue = 0.0;
    this.initChart();

    Promise.all([
      this.getBanks(),
      this.getPaymentsByYear(today.getFullYear())
    ]).then(async values => {
      await this.getPayments();
      await this.getPaymentsDeductions()
      this.enableToExport = true;
      // put a small delay on getting payments deductions so that all payments are loaded first
      // setTimeout(() => this.getPaymentsDeductions(), 3000);
      console.log('ngInit - complete: ' + new Date().toISOString());
    });

    this.lastUpdated.setDate(today.getDate() - 3);
  }

  ngOnDestroy(): void {
    this.sheet = null;
    if (this.spread) {
      this.spread.destroy();
      this.spread = undefined;
    }
  }

  private initChart(): void {
    this.options = options;
    this.chart = Highcharts.chart('chart_donut', this.options);
  }

  async getBanks() {
    this.banks = (await (this.lookupService.getBanks())) as Bank[];
  }

  getPaymentsByYear(year: number) {
    // Get the operatorId from the user - only want this operators payments
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;

    console.log('Start getPaymentsByYear: ' + new Date().toISOString());
    return this.paymentsService.getOperatorPaymentsByYear(year).pipe(
      map(
        payments => {
          console.log('Back from API getPaymentsByYear: ' + new Date().toISOString());
          // console.log({ payments });

          let tempPayments: Payment[] = JSON.parse(JSON.stringify(payments));
          // console.log('pre-transformation', { tempPayments });
          tempPayments = this.setPaymentValues(tempPayments);
          // console.log('post transformation', { tempPayments });

          this.initialiseGrid();

          this.sheet.resumePaint();

          this.payments = tempPayments;
          this.allPayments = tempPayments;
          console.log(this.allPayments[0]);

          // Get Payment Summary
          this.paymentsSummary = new PaymentsSummary();
          this.paymentsSummary.issuedValue = 0;
          this.paymentsSummary.confirmedValue = 0;
          this.paymentsSummary.pendingValue = 0;

          this.payments.forEach((paymentObj: Payment, rowIndex: number) => {
            switch (paymentObj.paymentStatus.id) {
              case paymentStatusPending:
                this.paymentsSummary.pendingValue = this.paymentsSummary.pendingValue + paymentObj.ownerNetValue;
                break;
              case paymentStatusIssued:
                this.paymentsSummary.issuedValue = this.paymentsSummary.issuedValue + paymentObj.ownerNetValue;
                break;
              default:
                this.paymentsSummary.confirmedValue = this.paymentsSummary.confirmedValue + paymentObj.ownerNetValue;
            }
          });

          // console.dir(this.paymentsSummary);

          setValues(this.chart, this.paymentsSummary.confirmedValue, this.paymentsSummary.pendingValue, this.paymentsSummary.issuedValue);
          console.log('getPaymentsByYear - complete: ' + new Date().toISOString() + '  No. Records: ' + this.allPayments.length);
          this.filter();
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  async getPayments() {
    // Get the operatorId from the user - only want this operators payments
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;

    console.log('Start getPayments: ' + new Date().toISOString());
    return this.paymentsService.getOperatorPayments().pipe(
      map(
        payments => {
          console.log('Back from API getPayments: ' + new Date().toISOString());
          // console.log({ payments });

          let tempPayments: Payment[] = JSON.parse(JSON.stringify(payments));
          // console.log('pre-transformation', { tempPayments });
          tempPayments = this.setPaymentValues(tempPayments);
          // console.log('post transformation', { tempPayments });

          this.allPayments = tempPayments;
          console.log('getPayments - complete: ' + new Date().toISOString() + '  No. Records: ' + this.allPayments.length);
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  getPaymentsTotal() {
    return this.paymentsSummary.pendingValue + this.paymentsSummary.issuedValue + this.paymentsSummary.confirmedValue;
  }

  async getPaymentsDeductions() {
    // Get the operatorId from the user - only want this operators payments
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;

    console.log('Call getOperatorPaymentsDeductions: ' + new Date().toISOString());
    return this.paymentsService.getOperatorPaymentsDeductions(operatorId).pipe(
      map(
        deductions => {
          // console.log({ deductions });

          const tempDeductions: PaymentDeduction[] = JSON.parse(JSON.stringify(deductions));

          for (const deduction of tempDeductions) {
            this.addPaymentDeduction(deduction);
          }

          this.payments = this.allPayments;
          this.filter();

          // console.log(this.allPayments);
          console.log('Back from getPaymentsDeductions: ' + new Date().toISOString());
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  async getPaymentsDeductionsIndividual(id) {
    // Get the operatorId from the user - only want this operators payments
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;

    return this.paymentsService.getOperatorPaymentsDeductionsIndividual(operatorId, id).pipe(
      map(
        deductions => {
          console.log({ deductions });

          const tempDeductions: PaymentDeduction[] = JSON.parse(JSON.stringify(deductions));

          for (const deduction of tempDeductions) {
            this.addPaymentDeduction(deduction);
          }

          return tempDeductions;
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.router.navigate(['/login']);
            }
          }
        }
      )
    ).toPromise();
  }

  private addPaymentDeduction(deduction) {
    for (const payment of this.allPayments) {
      if (payment.id === deduction.id) {
        if (payment.paymentDeductions == null) {
          payment.paymentDeductions = [];
        }
        payment.paymentDeductions.push(deduction);
      }
    }
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  setPaymentValues(filteredPayment: Payment[]): Payment[] {
    filteredPayment.forEach(paymentRec => {
      // console.log('Payment: ', { paymentRec });

      if (paymentRec.paymentDate != null) {
        paymentRec.propertyId = paymentRec.divisionOrder.lease.title.property.id;
        paymentRec.propertyNo = paymentRec.divisionOrder.lease.title.property.propertyNo;
        paymentRec.propertyName = paymentRec.divisionOrder.lease.title.property.name;
        paymentRec.divOrderNumber = paymentRec.divisionOrder.id;
        paymentRec.ownerName = paymentRec.divisionOrder.lease.title.owner.fname + ' ' + paymentRec.divisionOrder.lease.title.owner.lname;
        paymentRec.bankTrustManager = new BankTrustManager();
        paymentRec.bankTrustManager.bank = new Bank();
        paymentRec.bankTrustManager.bank.id = paymentRec.divisionOrder.bankTrustManager.bank.id;
        paymentRec.bankTrustManager.bank.name = paymentRec.divisionOrder.bankTrustManager.bank.name;
        paymentRec.bankTrustManager.bank.participating = paymentRec.divisionOrder.bankTrustManager.bank.participating;
        paymentRec.bankName = paymentRec.divisionOrder.bankTrustManager.bank.name;
        paymentRec.saleDate = this.messagesService.monthString(paymentRec.sale.month) + '-' + paymentRec.sale.year;
        paymentRec.saleDateSort = paymentRec.sale.year + '-' + paymentRec.sale.month;
        paymentRec.productId = paymentRec.sale.product.id;
        paymentRec.productName = paymentRec.sale.product.name;
        paymentRec.price = paymentRec.sale.unitPrice.toFixed(2);
        if (paymentRec.sale.btu < 0.001) {
          paymentRec.btuStr = '';
        } else {
          paymentRec.btuStr = paymentRec.sale.btu.toFixed(2);
        }
        paymentRec.btuValue = paymentRec.sale.btu;

        paymentRec.volume = paymentRec.sale.salesVolume.toFixed(2);
        paymentRec.volumeValue = paymentRec.sale.salesVolume;
        paymentRec.gross = paymentRec.sale.grsValue.toFixed(2);
        paymentRec.grossValue = paymentRec.sale.grsValue;
        paymentRec.ownerNetValueStr = paymentRec.ownerNetValue.toFixed(2);
        paymentRec.deductions = paymentRec.totalDeductions.toFixed(2);
        paymentRec.deductionsValue = paymentRec.totalDeductions;
        paymentRec.interestTypeId = paymentRec.divisionOrder.interestType.id;
        paymentRec.interestTypeAbbr = paymentRec.divisionOrder.interestType.abbr;
        paymentRec.decimalInterest = paymentRec.divisionOrder.decimalInterest.toFixed(6);
        paymentRec.decimalInterestValue = paymentRec.divisionOrder.decimalInterest;
        paymentRec.ownerVolumeStr = paymentRec.ownerVolume.toFixed(4);
        paymentRec.paymentDateStr = formatDate(paymentRec.paymentDate, 'MM/dd/yyyy', 'en');
        paymentRec.paymentDateSort = formatDate(paymentRec.paymentDate, 'yyyy-MM-dd', 'en');
        paymentRec.statusName = paymentRec.paymentStatus.name;
      }
    });

    return filteredPayment;
  }

  createGridEvents() {
    // Bind click-event of Blockchain cell
    this.spread.bind(GC.Spread.Sheets.Events.CellClick, (e, args) => {
      const sheet = args.sheet;
      const row = args.row;
      const col = args.col;
      const cell = this.sheet.getCell(row, col);

      // If Grid header clicked
      if (args.sheetArea === GC.Spread.Sheets.SheetArea.colHeader) {
        // Sort Ascending/Descending by the column clicked
        if (col === this.bankColumn) {
          this.sortOrderBank = toggleSort(this.sortOrderBank);
          this.payments.sort(dynamicSort('bankName', this.sortOrderBank));
          this.applyDataBinding(this.payments);
        } else if (col === this.propertyNoColumn) {
          this.sortOrderPropertyNo = toggleSort(this.sortOrderPropertyNo);
          this.payments.sort(dynamicSort('propertyNo', this.sortOrderPropertyNo));
          this.applyDataBinding(this.payments);
        } else if (col === this.propertyNameColumn) {
          this.sortOrderPropertyName = toggleSort(this.sortOrderPropertyName);
          this.payments.sort(dynamicSort('propertyName', this.sortOrderPropertyName));
          this.applyDataBinding(this.payments);
        } else if (col === this.divOrderNumberColumn) {
          this.sortOrderDivOrderNumber = toggleSort(this.sortOrderDivOrderNumber);
          this.payments.sort(dynamicSort('divOrderNumber', this.sortOrderDivOrderNumber));
          this.applyDataBinding(this.payments);
        } else if (col === this.ownerNameColumn) {
          this.sortOrderOwnerName = toggleSort(this.sortOrderOwnerName);
          this.payments.sort(dynamicSort('ownerName', this.sortOrderOwnerName));
          this.applyDataBinding(this.payments);
        } else if (col === this.saleDateColumn) {
          this.sortOrderSaleDate = toggleSort(this.sortOrderSaleDate);
          this.payments.sort(dynamicSort('saleDateSort', this.sortOrderSaleDate));
          this.applyDataBinding(this.payments);
        } else if (col === this.productColumn) {
          this.sortOrderProduct = toggleSort(this.sortOrderProduct);
          this.payments.sort(dynamicSort('productName', this.sortOrderProduct));
          this.applyDataBinding(this.payments);
        } else if (col === this.priceColumn) {
          this.sortOrderPrice = toggleSort(this.sortOrderPrice);
          this.payments.sort(dynamicSort('price', this.sortOrderPrice));
          this.applyDataBinding(this.payments);
        } else if (col === this.btuColumn) {
          this.sortOrderBtu = toggleSort(this.sortOrderBtu);
          this.payments.sort(dynamicSort('btuValue', this.sortOrderBtu));
          this.applyDataBinding(this.payments);
        } else if (col === this.volumeColumn) {
          this.sortOrderVolume = toggleSort(this.sortOrderVolume);
          this.payments.sort(dynamicSort('volumeValue', this.sortOrderVolume));
          this.applyDataBinding(this.payments);
        } else if (col === this.grossColumn) {
          this.sortOrderGross = toggleSort(this.sortOrderGross);
          this.payments.sort(dynamicSort('grossValue', this.sortOrderGross));
          this.applyDataBinding(this.payments);
        } else if (col === this.ownerNetValueColumn) {
          this.sortOrderOwnerNetValue = toggleSort(this.sortOrderOwnerNetValue);
          this.payments.sort(dynamicSort('ownerNetValue', this.sortOrderOwnerNetValue));
          this.applyDataBinding(this.payments);
        } else if (col === this.deductionsColumn) {
          this.sortOrderDeductions = toggleSort(this.sortOrderDeductions);
          this.payments.sort(dynamicSort('deductionsValue', this.sortOrderDeductions));
          this.applyDataBinding(this.payments);
        } else if (col === this.interestTypeColumn) {
          this.sortOrderInterestType = toggleSort(this.sortOrderInterestType);
          this.payments.sort(dynamicSort('interestType', this.sortOrderInterestType));
          this.applyDataBinding(this.payments);
        } else if (col === this.decimalInterestColumn) {
          this.sortOrderDecimalInterest = toggleSort(this.sortOrderDecimalInterest);
          this.payments.sort(dynamicSort('decimalInterestValue', this.sortOrderDecimalInterest));
          this.applyDataBinding(this.payments);
        } else if (col === this.ownerVolumeColumn) {
          this.sortOrderOwnerVolume = toggleSort(this.sortOrderOwnerVolume);
          this.payments.sort(dynamicSort('ownerVolume', this.sortOrderOwnerVolume));
          this.applyDataBinding(this.payments);
        } else if (col === this.paymentDateColumn) {
          this.sortOrderPaymentDate = toggleSort(this.sortOrderPaymentDate);
          this.payments.sort(dynamicSort('paymentDateSort', this.sortOrderPaymentDate));
          this.applyDataBinding(this.payments);
        } else if (col === this.statusColumn) {
          this.sortOrderStatus = toggleSort(this.sortOrderStatus);
          this.payments.sort(dynamicSort('statusName', this.sortOrderStatus));
          this.applyDataBinding(this.payments);
        } else if (col === this.blockChainTransIdColumn) {
          this.sortOrderBlockChainTransId = toggleSort(this.sortOrderBlockChainTransId);
          this.payments.sort(dynamicSort('bcTransId', this.sortOrderBlockChainTransId));
          this.applyDataBinding(this.payments);
        }
      } else if (col === this.blockChainColumn || col === this.blockChainTransIdColumn) {
        if (this.payments[row].bcTransId != null) {
          console.log('Cell: (' + row + ', ' + col
            + ') PaymentId: ' + this.payments[row].id + '     BC TransId: ' + this.payments[row].bcTransId);

          const url = this.blockchainExplorerUrl;
          window.open(url);
        }
      } else if (col === this.deductionsColumn) {
        // console.log('Row: ' + row + '; Col: ' + col);
        this.onClickDeduction(row);
      }
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.EnterCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColumnCount - 1; col++) {
        const cell = this.sheet.getCell(row, col);
        if (col !== this.blockChainColumn) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridHighlight);
          }
        }
      }
      // console.log('Row Enter Cell: ' + row);
      this.sheet.resumePaint();
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Un-highlight all cells of the row except changed cells
      for (let col = 0; col < this.gridColumnCount - 1; col++) {
        const cell = this.sheet.getCell(row, col);

        if (col !== this.blockChainColumn) {
          const cellBackColor = cell.backColor();
          if (cellBackColor !== environment.gridCellChanged) {
            this.sheet.getCell(row, col).backColor(environment.gridBackground);
          }
        }
      }

      this.sheet.getCell(row, -1)
        .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      this.sheet.getCell(row, -1)
        .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      // console.log('Row Leave Cell: ' + row);

      this.sheet.resumePaint();
    });

  }

  initialiseGrid() {
    // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
    // And grid events that are NOT data specific

    this.sheet.suspendPaint();

    // Define columns
    const bankNameColInfo = { name: 'bankName', displayName: 'Bank', size: 120 };
    const propertyNoColInfo = { name: 'propertyNo', displayName: 'Property #', size: 120 };
    const propertyNameColInfo = { name: 'propertyName', displayName: 'Property Name', size: 220 };
    const divOrderNoColInfo = { name: 'divOrderNumber', displayName: 'DOI', size: 50 };
    const ownerNameColInfo = { name: 'ownerName', displayName: 'Owner Name', size: 120 };
    const saleDateColInfo = { name: 'saleDate', displayName: 'Sale Date', size: 80 };
    const productColInfo = { name: 'productName', displayName: 'Product', size: 80 };
    const priceColInfo = { name: 'price', displayName: 'Price', size: 80 };
    const btuColInfo = { name: 'btuStr', displayName: 'BTU', size: 80 };
    const volumeColInfo = { name: 'volume', displayName: 'Volume', size: 80 };
    const grossColInfo = { name: 'gross', displayName: 'Gross', size: 80 };
    const ownerNetValueColInfo = { name: 'ownerNetValueStr', displayName: 'Owner Net Value', size: 120 };
    const deductionsColInfo = { name: 'deductions', displayName: 'Deductions', size: 80 };
    // This column is initially hidden
    const salesDeductionTypesColInfo = { name: 'deductionType', displayName: 'Deduction Types', size: 200 };
    const interestTypeColInfo = { name: 'interestTypeAbbr', displayName: 'Int. Type', size: 80 };
    const decimalInterestColInfo = { name: 'decimalInterest', displayName: 'Decimal', size: 80 };
    const royaltyInterestColInfo = { name: 'royaltyInterest', displayName: 'Royalty', size: 80 };
    const ownerVolumeColInfo = { name: 'ownerVolume', displayName: 'Owner Vol.', size: 80 };
    const paymentDateColInfo = { name: 'paymentDateStr', displayName: 'Payment Date', size: 100 };
    const statusColInfo = { name: 'statusName', displayName: 'Status', size: 80 };
    const prodBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
    const prodBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 180 };

    this.sheet.autoGenerateColumns = false;
    this.sheet.setDataSource(this.payments);

    this.sheet.bindColumn(this.bankColumn, bankNameColInfo);
    this.sheet.bindColumn(this.propertyNoColumn, propertyNoColInfo);
    this.sheet.bindColumn(this.propertyNameColumn, propertyNameColInfo);
    this.sheet.bindColumn(this.divOrderNumberColumn, divOrderNoColInfo);
    this.sheet.bindColumn(this.ownerNameColumn, ownerNameColInfo);
    this.sheet.bindColumn(this.saleDateColumn, saleDateColInfo);
    this.sheet.bindColumn(this.productColumn, productColInfo);
    this.sheet.bindColumn(this.priceColumn, priceColInfo);
    this.sheet.bindColumn(this.btuColumn, btuColInfo);
    this.sheet.bindColumn(this.volumeColumn, volumeColInfo);
    this.sheet.bindColumn(this.grossColumn, grossColInfo);
    this.sheet.bindColumn(this.ownerNetValueColumn, ownerNetValueColInfo);
    this.sheet.bindColumn(this.deductionsColumn, deductionsColInfo);
    this.sheet.bindColumn(this.deductionTypeColumn, salesDeductionTypesColInfo);
    this.sheet.bindColumn(this.interestTypeColumn, interestTypeColInfo);
    this.sheet.bindColumn(this.decimalInterestColumn, decimalInterestColInfo);
    this.sheet.bindColumn(this.ownerVolumeColumn, ownerVolumeColInfo);
    this.sheet.bindColumn(this.paymentDateColumn, paymentDateColInfo);
    this.sheet.bindColumn(this.statusColumn, statusColInfo);
    this.sheet.bindColumn(this.blockChainColumn, prodBCTransImgColInfo);
    this.sheet.bindColumn(this.blockChainTransIdColumn, prodBCTransIdColInfo);

    this.sheet.setColumnCount(this.gridColumnCount, GC.Spread.Sheets.SheetArea.viewport);

    // set cell alignment
    this.sheet.getCell(-1, this.propertyNoColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.divOrderNumberColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.ownerNameColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
    this.sheet.getCell(-1, this.saleDateColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.productColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.priceColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.btuColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.volumeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.grossColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.ownerNetValueColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.deductionsColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.interestTypeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.decimalInterestColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.ownerVolumeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.statusColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);

    // Hide the Deductions Type column
    this.showDeductionType(false);

    // Format currency columns
    this.sheet.getCell(-1, this.grossColumn).formatter('$ ##,###,###0.00');
    this.sheet.getCell(-1, this.ownerNetValueColumn).formatter('$ ##,###,###0.00');
    this.sheet.getCell(-1, this.deductionsColumn).formatter('$ ##,###,###0.00');
    this.sheet.getCell(-1, this.deductionTypeColumn).formatter('$ ##,###,###0.00');
    this.sheet.getCell(-1, this.ownerVolumeColumn).formatter('$ ##,###,###0.00');

    // Lock/Readonly all columns
    this.sheet.suspendPaint();
    this.sheet.options.isProtected = true;
    this.sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: true
    };
    // Unlock from columns 6 for 6 columns (columns 6, 7, 8, 9, 10, 11) - [Product - Temperature]
    // this.sheet.getRange(-1, 0, -1, this.gridColumnCount - 1).locked(false);
    // Unlock from columns 13 for 1 columns (columns 13) - [Reason]
    // this.sheet.getRange(-1, 13, -1, 1).locked(false);

    this.createGridEvents();

    this.sheet.resumePaint();
  }

  showDeductionType(hide: boolean) {
    this.sheet.getRange(-1, this.deductionTypeColumn, -1, 1).visible(hide);
  }

  applyDataBinding(filteredPayment: Payment[]) {
    // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);

    // console.log('Start data binding');

    if (this.sheet) {
      this.sheet.suspendPaint();
      this.sheet.setDataSource(filteredPayment);

      // console.log('Row Binding - start');
      filteredPayment.forEach((rowPaymentObject: Payment, rowIndex: number) => {
        // Remove vertical grid-lines
        this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));

        // Clear text in rowHeaders
        // this.sheet.setValue(rowIndex, 0, '', GC.Spread.Sheets.SheetArea.rowHeader);

        const cell = this.sheet.getCell(rowIndex, this.blockChainColumn);
        let imgBlockChain = null;
        if (filteredPayment[rowIndex].bcTransId != null) {
          imgBlockChain = './assets/images/blockchain_cell@2x.png';
        }
        cell.text('');
        cell.backgroundImage(imgBlockChain);
      });

      // console.log('Row Binding - complete');

      // Highlight first row as selected - except blockchain graphic cell
      for (let col = 0; col < this.gridColumnCount - 1; col++) {
        if (col !== this.blockChainColumn) {
          this.sheet.getCell(0, col).backColor(environment.gridHighlight);
        }
      }

      const lastRow = this.sheet.getRowCount();

      this.sheet.resumePaint();

      const today = new Date();
      this.maxStartDate =
        this.paymentSearch.startDateMonth === today.getMonth() + 1 &&
        this.paymentSearch.startDateYear === today.getFullYear();
      this.maxEndDate =
        this.paymentSearch.endDateMonth === today.getMonth() + 1 &&
        this.paymentSearch.endDateYear === today.getFullYear();

      // console.log('Binding Complete');
    }
  }

  private getLastDayOfMonth(year, month) {
    // get first day of month
    let date = new Date(year, month, 1);
    // Add 1 month and subtract 1 day
    date = new Date(date.setMonth(date.getMonth() + 1) - 1);
    return date;
  }

  async filter() {
    // Filters the "allPayment" based on selected filters from the screen
    this.payments = [];

    const today = this.transformDate(new Date());
    // console.log('Todays Date: ' + today);

    let confirmed = 0.0;
    let issued = 0.0;
    let pending = 0.0;

    const startDate = new Date(this.paymentSearch.startDateYear, this.paymentSearch.startDateMonth - 1, 1);
    const endDate = this.getLastDayOfMonth(this.paymentSearch.endDateYear, this.paymentSearch.endDateMonth - 1);

    console.log('Start Date: ' + this.transformDate(startDate) + '   End Date: ' + this.transformDate(endDate));

    this.allPayments.forEach((paymentObj: Payment, rowIndex: number) => {
      // console.log(paymentObj);

      // If paymentObj is null, undefined or empty - ignore
      if (paymentObj != null && Object.keys(paymentObj).length !== 0) {
        let includeProd = true;

        // console.log('Payment date: ' + this.transformDate(paymentObj.paymentDate) + '   Start: ' + this.transformDate(startDate)
        //   + '   End Date: ' + this.transformDate(endDate));
        if (this.transformDate(paymentObj.paymentDate) < this.transformDate(startDate)) {
          includeProd = false;
        }
        if (includeProd && this.transformDate(paymentObj.paymentDate) > this.transformDate(endDate)) {
          includeProd = false;
        }

        // console.log('this.paymentSearch.bankId: ' + this.paymentSearch.bankId);
        if (this.paymentSearch.bankId !== '') {
          includeProd = (paymentObj.bankTrustManager.bank.id === Number(this.paymentSearch.bankId));
        }

        if (includeProd && this.paymentSearch.searchStr.length > 0) {
          // Check property No, lease No, Property Name and Property Desc against any part of the search string
          includeProd = true; // Assume property to be included
          this.paymentSearch.searchStr.forEach((searchStr) => {
            includeProd = includeProd
              && ((paymentObj.divisionOrder.lease.title.property.propertyNo.indexOf(searchStr) !== -1)
                || (paymentObj.divisionOrder.lease.title.property.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (paymentObj.divisionOrder.lease.title.property.description.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (paymentObj.divisionOrder.lease.title.property.county.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (paymentObj.divisionOrder.lease.title.property.county.USState.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (paymentObj.bankTrustManager.bank.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (paymentObj.divisionOrder.lease.title.owner.fname.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                || (paymentObj.divisionOrder.lease.title.owner.lname.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
              );
          });
        }

        // console.log('Payment Search Type: ', this.paymentSearch.paymentsType);
        if (includeProd && this.paymentSearch.paymentsType > 0) {
          includeProd = (paymentObj.paymentStatus.id === this.paymentSearch.paymentsType);
        }

        // console.log('IncludePayment: ' + includeProd);
        if (includeProd) {
          this.payments.push(paymentObj);

          switch (paymentObj.paymentStatus.id) {
            case paymentStatusPending:
              pending = pending + paymentObj.ownerNetValue;
              break;
            case paymentStatusIssued:
              issued = issued + paymentObj.ownerNetValue;
              break;
            default:
              confirmed = confirmed + paymentObj.ownerNetValue;
          }
        }
      }
    });

    this.paymentsSummary.confirmedValue = confirmed;
    this.paymentsSummary.issuedValue = issued;
    this.paymentsSummary.pendingValue = pending;
    setValues(this.chart, this.paymentsSummary.confirmedValue, this.paymentsSummary.pendingValue, this.paymentsSummary.issuedValue);

    this.applyDataBinding(this.payments);
    // console.log('Filter: ');
    // console.log(this.payments);
  }

  public doSearch() {
    const str = this.paymentSearchForm.get('searchStr').value;
    // console.log('Str: ' + str);
    this.paymentSearch.searchStr = str.split(' ');
    // console.log('searchStr: ', this.paymentSearch.searchStr);
    this.filter();
  }

  public onBankChange() {
    console.log('Bank changed: ' + this.paymentSearchForm.get('bank').value);

    this.paymentSearch.bankId = this.paymentSearchForm.get('bank').value;
    this.filter();
  }

  public btnAllPayments() {
    console.log('All Payment');
    this.paymentSearch.paymentsType = 0;
    this.filter();
  }

  public btnPendingPayments() {
    console.log('Pending');
    this.paymentSearch.paymentsType = 1;
    this.filter();
  }

  public btnIssuedPayments() {
    console.log('Issued');
    this.paymentSearch.paymentsType = 2;
    this.filter();
  }

  public btnConfirmedPayments() {
    console.log('Confirmed');
    this.paymentSearch.paymentsType = 3;
    this.filter();
  }

  public btnPaymentStepMonthly() {
    console.log('Monthly');
    this.paymentSearch.paymentPeriodStep = 1;
  }

  public btnPaymentStepQuarterly() {
    console.log('Quarterly');
    this.paymentSearch.paymentPeriodStep = 3;
    this.paymentSearch.startDateMonth = Math.floor((this.paymentSearch.startDateMonth - 1) / 3) * 3 + 1;
    this.paymentSearch.endDateMonth = Math.floor((this.paymentSearch.endDateMonth - 1) / 3) * 3 + 3;

    // If end month is after today's month
    const today = new Date();
    if (this.paymentSearch.endDateYear === today.getFullYear()) {
      while (this.paymentSearch.endDateMonth > today.getMonth() + 1) {
        this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth - 3;
      }
    }
    this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
    this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);

    this.maxStartDate = (this.paymentSearch.startDateMonth === today.getMonth() + 1
      && this.paymentSearch.startDateYear === today.getFullYear());
    this.maxEndDate = (this.paymentSearch.endDateMonth === today.getMonth() + 1 && this.paymentSearch.endDateYear === today.getFullYear());
    this.filter();
  }

  public btnPaymentStepAnnually() {
    console.log('Annually');
    this.paymentSearch.paymentPeriodStep = 12;
    this.paymentSearch.startDateMonth = 1;
    const today = new Date();
    if (this.paymentSearch.endDateYear === today.getFullYear()) {
      this.paymentSearch.endDateMonth = today.getMonth() + 1;
    } else {
      this.paymentSearch.endDateMonth = 12;
    }

    this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
    this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);

    this.maxStartDate = (this.paymentSearch.startDateMonth === today.getMonth() + 1
      && this.paymentSearch.startDateYear === today.getFullYear());
    this.maxEndDate = (this.paymentSearch.endDateMonth === today.getMonth() + 1 && this.paymentSearch.endDateYear === today.getFullYear());
    this.filter();
  }

  private dateStr(month: number, year: number) {
    return this.messagesService.monthString(month) + ' ' + year;
  }

  public onStartDateDown() {
    // console.log('Start Date Down: ' + this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear));

    if (this.paymentSearch.paymentPeriodStep === 1) {
      if (this.paymentSearch.startDateMonth === 1) {
        this.paymentSearch.startDateMonth = 12;
        this.paymentSearch.startDateYear = this.paymentSearch.startDateYear - 1;
      } else {
        this.paymentSearch.startDateMonth = this.paymentSearch.startDateMonth - 1;
      }
    } else if (this.paymentSearch.paymentPeriodStep === 3) {
      if (this.paymentSearch.startDateMonth === 1) {
        this.paymentSearch.startDateMonth = 10;
        this.paymentSearch.startDateYear = this.paymentSearch.startDateYear - 1;
      } else {
        this.paymentSearch.startDateMonth = this.paymentSearch.startDateMonth - 3;
      }
    } else if (this.paymentSearch.paymentPeriodStep === 12) {
      this.paymentSearch.startDateYear = this.paymentSearch.startDateYear - 1;
      this.paymentSearch.startDateMonth = 1;
    }

    this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
    // console.log('Start Date Down: ' + this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear));
    this.filter();
  }

  public onStartDateUp() {
    console.log('Start Date Up: ' + this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear));
    if (this.paymentSearch.paymentPeriodStep === 1) {
      if (this.paymentSearch.startDateMonth === 12) {
        this.paymentSearch.startDateMonth = 1;
        this.paymentSearch.startDateYear = this.paymentSearch.startDateYear + 1;
      } else {
        this.paymentSearch.startDateMonth = this.paymentSearch.startDateMonth + 1;
      }
    } else if (this.paymentSearch.paymentPeriodStep === 3) {
      if (this.paymentSearch.startDateMonth === 10) {
        this.paymentSearch.startDateMonth = 1;
        this.paymentSearch.startDateYear = this.paymentSearch.startDateYear + 1;
      } else {
        this.paymentSearch.startDateMonth = this.paymentSearch.startDateMonth + 3;
      }
    } else if (this.paymentSearch.paymentPeriodStep === 12) {
      this.paymentSearch.startDateYear = this.paymentSearch.startDateYear + 1;
      this.paymentSearch.startDateMonth = 1;
    }

    console.log('Start Year: ' + this.paymentSearch.startDateYear);
    console.log('Start Month: ' + this.paymentSearch.startDateMonth);
    const today = new Date();
    if (this.paymentSearch.startDateYear === today.getFullYear()) {
      if (this.paymentSearch.startDateMonth > today.getMonth() + 1) {
        this.paymentSearch.startDateMonth = today.getMonth() + 1;
      }
    } else if (this.paymentSearch.startDateYear > today.getFullYear()) {
      this.paymentSearch.startDateYear = today.getFullYear();
      this.paymentSearch.startDateMonth = today.getMonth() + 1;
    }
    if (this.paymentSearch.paymentPeriodStep === 3) {
      this.btnPaymentStepQuarterly();
    } else if (this.paymentSearch.paymentPeriodStep === 12) {
      this.btnPaymentStepAnnually();
    }

    this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);

    this.filter();
  }

  public onEndDateDown() {
    // console.log('End Date Down: ' + this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear));
    if (this.paymentSearch.paymentPeriodStep === 1) {
      if (this.paymentSearch.endDateMonth === 1) {
        this.paymentSearch.endDateMonth = 12;
        this.paymentSearch.endDateYear = this.paymentSearch.endDateYear - 1;
      } else {
        this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth - 1;
      }
    } else if (this.paymentSearch.paymentPeriodStep === 3) {
      if (this.paymentSearch.endDateMonth === 1) {
        this.paymentSearch.endDateMonth = 10;
        this.paymentSearch.endDateYear = this.paymentSearch.endDateYear - 1;
      } else {
        this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth - 3;
      }
    } else if (this.paymentSearch.paymentPeriodStep === 12) {
      this.paymentSearch.endDateMonth = 12;
      this.paymentSearch.endDateYear = this.paymentSearch.endDateYear - 1;
    }

    this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);
    // console.log('End Date Down: ' + this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear));
    this.filter();
  }

  public onEndDateUp() {
    // console.log('End Date Up: ' + this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear));
    const today = new Date();

    if (this.paymentSearch.paymentPeriodStep === 1) {
      if (this.paymentSearch.endDateMonth === 12) {
        this.paymentSearch.endDateMonth = 1;
        this.paymentSearch.endDateYear = this.paymentSearch.endDateYear + 1;
      } else {
        this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth + 1;
      }
    } else if (this.paymentSearch.paymentPeriodStep === 3) {
      if (this.paymentSearch.endDateMonth === 10) {
        this.paymentSearch.endDateMonth = 1;
        this.paymentSearch.endDateYear = this.paymentSearch.endDateYear + 1;
      } else {
        this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth + 3;
      }
    } else if (this.paymentSearch.paymentPeriodStep === 12) {
      this.paymentSearch.endDateYear = this.paymentSearch.endDateYear + 1;
      this.paymentSearch.endDateMonth = 12;
    }

    console.log('End Year: ' + this.paymentSearch.endDateYear);
    console.log('End Month: ' + this.paymentSearch.endDateMonth);
    if (this.paymentSearch.endDateYear === today.getFullYear()) {
      if (this.paymentSearch.endDateMonth > today.getMonth() + 1) {
        this.paymentSearch.endDateMonth = today.getMonth() + 1;
      }
    } else if (this.paymentSearch.endDateYear > today.getFullYear()) {
      this.paymentSearch.endDateYear = today.getFullYear();
      this.paymentSearch.endDateMonth = today.getMonth() + 1;
    }
    if (this.paymentSearch.paymentPeriodStep === 3) {
      this.btnPaymentStepQuarterly();
    } else if (this.paymentSearch.paymentPeriodStep === 12) {
      this.btnPaymentStepAnnually();
    }

    this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);
    // console.log('End Date Up: ' + this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear));
    this.filter();
  }

  async onClickDeduction(index: number) {
    // console.log('Index clicked: ' + index);

    // ignore click on expanded deduction
    if (this.displayDeductionDetails && index >= this.expandIndex1 + 1 && index <= this.expandIndex2) {
      console.log('Index clicked: ' + index);
      return;
    }

    if (this.displayDeductionDetails) {
      // Remove previous deduction
      if (this.expandIndex1 < this.expandIndex2) {
        this.payments.splice(this.expandIndex1 + 1, this.expandIndex2 - this.expandIndex1);
      }
    }

    // Clicked on the same deduction - hide details
    if (index === this.expandIndex1) {
      this.expandIndex1 = -1;
      this.expandIndex2 = -1;
      this.displayDeductionDetails = false;
      this.showDeductionType(false);

      return;
    }
    // Adjust the index to indicate we are expanding deductions at the new index location
    if (index > this.expandIndex1) {
      index = index - (this.expandIndex2 - this.expandIndex1);
    }

    const payment = this.payments[index];

    if (payment.paymentDeductions == null) {
      console.log('payment: ');
      console.log(payment);
      this.deductions = await this.getPaymentsDeductionsIndividual(payment.id);
    } else {
      this.deductions = payment.paymentDeductions;
    }

    // console.log('Show deductions:');
    // console.log(payment);
    // console.log(this.deductions);

    // Show DeductionType column
    this.displayDeductionDetails = true;
    this.showDeductionType(true);

    for (let i = 0; i < this.deductions.length; i++) {
      const newPayment: Payment = new Payment();
      newPayment.deductionType = this.deductions[i].deductionType.name;
      newPayment.deductions = this.deductions[i].amount.toString();
      newPayment.deductionsValue = this.deductions[i].amount;
      this.payments.splice(index + i + 1, 0, newPayment);
    }
    this.applyDataBinding(this.payments);

    this.expandIndex1 = index;
    this.expandIndex2 = index + this.deductions.length;
  }

  public onBack() {
    this.location.back();
  }

  public exportReport(reportType) {
    this.paymentsService.getReport(this.payments, reportType).subscribe((data) => {
      const blob = new Blob([data as BlobPart], { type: 'application/octet-stream' });
      let extension = '';
      if (reportType === 1) {
        extension = '.pdf';
      } else if (reportType === 2) {
        extension = '.xlsx';
      } else if (reportType === 3) {
        extension = '.csv';
      }
      FileSaver.saveAs(blob, `payments${extension}`);
    });
  }

  public workbookInit(args) {
    // console.log(`Workbook Init`, { args });

    this.spread = args.spread;
    this.sheet = this.spread.getActiveSheet();

    this.sheetWidth = this.sheet.getViewportWidth(1);

    // Hide the row Header column
    // this.sheet.setColumnVisible(0, false, GC.Spread.Sheets.SheetArea.rowHeader);

    // Set the Row Header width
    this.sheet.suspendPaint();
    this.sheet.setColumnWidth(0, 0.0, GC.Spread.Sheets.SheetArea.rowHeader);
    this.sheet.resumePaint();

    // Set Row Header text value
    // this.sheet.setValue(1, 0, 'Row Header', GC.Spread.Sheets.SheetArea.rowHeader);
  }

}

