<kendo-dialog *ngIf="displayProcessPayments">
  <!-- @dialog Modal for Uploading a new Sales & Expenses -->
  <div id="dialog_upload_sales" title="Process Payments">
    <div class="dialog_header">
      <h1>Process Payments</h1>
      <div class="dialog_close">
        <button type="button" class="close_modal btn_normal btn_icon_only" (click)="closeDialog()">
          <span class="btn_icon"><span class="icon_close"></span></span>
          <span class="btn_text hidden_text">Close Dialog or press Escape to dismiss</span>
        </button>
      </div>
    </div>
    <div class="dialog_content">
      <div class="dialog_inner_content">
        <div class="form_upload_container">
          <form action="#form_submitted" method="post" enctype="multipart/form-data" id="frmProcessPayments">
            <div class="example-wrapper" style="min-height: 100px">
              <p>From Date:
                <kendo-datepicker [(value)]="startDate" (valueChange)="refresh()"></kendo-datepicker>
                &nbsp;&nbsp;
                To Date:
                <kendo-datepicker [(value)]="endDate" (valueChange)="refresh()"></kendo-datepicker>
              </p>
            </div>
            <div id="salesDiv" class="center" *ngIf="!displayMessage">
              No. Sales: {{ salesCount }}<br>
              Total Sales: {{ salesTotal | currency }}
            </div>
            <div id="message" class="center" *ngIf="displayMessage">
              {{ message }}
            </div>
          </form>
        </div>
      </div>
      <div class="dialog_actions clearfix">
        <div class="actions_left">
        </div>
        <div class="actions_right">
          <button type="button" class="btn_normal btn_grey btn_cancel_modal" (click)="closeDialog()">
            <span class="btn_text">Cancel</span>
          </button>
          <ng-container *ngIf="salesCount == 0; else processPaymentsEnabled">
            <button type="button" class="btn_normal btn_blue" id="btn_process_payments" disabled="">
              <span class="btn_text">Process Payments</span>
            </button>
          </ng-container>
          <ng-template #processPaymentsEnabled>
            <button type="button" class="btn_normal btn_blue" id="btn_confirm_upload_sales" (click)="processPayments()">
              <span class="btn_text">Process Payments</span>
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</kendo-dialog>
