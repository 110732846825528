import { OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
var FooterComponent = /** @class */ (function () {
    // tslint:disable-next-line: variable-name
    function FooterComponent(_authService) {
        this._authService = _authService;
    }
    FooterComponent.prototype.ngOnInit = function () {
    };
    FooterComponent.prototype.loggedIn = function () {
        return this._authService.loggedIn();
    };
    return FooterComponent;
}());
export { FooterComponent };
