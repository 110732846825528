<div class="page_container">
  <div id="content_inner">
    <!-- Content Header -->
    <div class="content_header">
      <div class="content_history">
        <!-- <button type="button" class="btn_normal btn_big btn_icon_only" id="btn_history_back" onclick="window.location='dashboard.html'">
					<span class="btn_icon"><span class="icon_back"></span></span>
					<span class="btn_text">Back</span>
				</button> -->
      </div>
      <div class="content_title">
        <h1>Sales &amp; Expenses</h1>
      </div>
      <div class="content_timestamp">
        <div class="timestamp">Last updated: {{ lastUpdated | date:'d MMM yyyy' }} 9:30am</div>
      </div>
      <div class="content_actions">
        <button
          type="button"
          class="btn_normal btn_blue"
          id="btn_upload_sales"
          (click)="uploadSales()"
        >
          <span class="btn_icon"><span class="icon_upload_cloud"></span></span>
          <span class="btn_text">Upload Sales &amp; Expenses</span>
        </button>
      </div>
      <div>
        &nbsp;
      </div>
      <div class="content_actions">
        <button
          type="button"
          class="btn_normal btn_blue"
          id="btn_process_payments"
          (click)="processPayments()"
        >
          <span class="btn_icon"><span class="icon_commission"></span></span>
          &nbsp;
          <span class="btn_text">Process Payments</span>
        </button>
      </div>
    </div>

    <!-- Action Bar -->
    <div class="content_action_bar">
      <div class="action_bar_left">
        <ul>
          <li>
            <fieldset class="form_row no_padding clearfix">
              <legend class="hidden_legend">Filter Rows</legend>
              <div class="toggle_group">
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="all"
                      id="sales_all"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnAllSales()"
                    />
                  </div>
                  <label
                    for="sales_all"
                    [ngClass]="{ toggle_active: salesSearch.prodType === 0 }"
                    >All</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="in_progress"
                      id="sales_in_progress"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnInProgressSales()"
                    />
                  </div>
                  <label
                    for="sales_in_progress"
                    [ngClass]="{ toggle_active: salesSearch.prodType === 1 }"
                    >In Progress</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="active"
                      id="sales_complete"
                      name="filter_option"
                      class="filter_option"
                      checked=""
                      (click)="btnCompleteSales()"
                    />
                  </div>
                  <label
                    for="sales_complete"
                    [ngClass]="{ toggle_active: salesSearch.prodType === 2 }"
                    >Complete</label
                  >
                </div>
              </div>
            </fieldset>
          </li>
        </ul>
      </div>
      <form [formGroup]="salesSearchForm">
        <div class="action_bar_right">
          <ul>
            <li>
              <div class="form_row no_padding">
                <div id="period_dates" class="period_dates">
                  <div class="period_date_prev">
                    <button
                      id="btnStartDateDown"
                      type="button"
                      class="btn_normal btn_white"
                      (click)="onStartDateDown()"
                    >
                      <span class="btn_icon"
                        ><span class="icon_chevron_left"></span
                      ></span>
                    </button>
                    <button
                      id="btnStartDateUp"
                      type="button"
                      class="btn_normal btn_white"
                      (click)="onStartDateUp()"
                      [disabled]="maxStartDate"
                    >
                      <span class="btn_icon"
                        ><span class="icon_chevron_right"></span
                      ></span>
                    </button>
                  </div>
                  <div class="period_date_current">
                    &nbsp;<span id="period_current_date_start">{{
                      salesSearch.startStr
                    }}</span
                    >&nbsp;-&nbsp;
                    <span id="period_current_date_end">{{
                      salesSearch.endStr
                    }}</span
                    >&nbsp;
                  </div>
                  <div class="period_date_next">
                    <button
                      id="btnEndDateDown"
                      type="button"
                      class="btn_normal btn_white"
                      (click)="onEndDateDown()"
                    >
                      <span class="btn_icon"
                        ><span class="icon_chevron_left"></span
                      ></span>
                    </button>
                    <button
                      id="btnEndDateUp"
                      type="button"
                      class="btn_normal btn_white"
                      (click)="onEndDateUp()"
                      [disabled]="maxEndDate"
                    >
                      <span class="btn_icon"
                        ><span class="icon_chevron_right"></span
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="form_row no_padding">
                <div class="form_label hidden_label">
                  <label for="filter_search_table">Search Table</label>
                </div>
                <div class="form_input">
                  <input
                    type="text"
                    id="filter_search_table"
                    name="filter_search_table"
                    class="inputbox searchbox"
                    placeholder="Search Sales..."
                    style="width: 100%; height: 30px"
                    (keyup)="doSearch()"
                    formControlName="searchStr"
                  />
                </div>
              </div>
            </li>
            <li>
              <div
                id="divStates"
                class="ax_default"
                tabindex="0"
                style="cursor: pointer"
              >
                <div class="form_input">
                  <select
                    name="state"
                    id="state"
                    class="inputbox"
                    formControlName="state"
                    (change)="onStateChange()"
                  >
                    <option value="" [selected]="">All States</option>
                    <option *ngFor="let state of states" value="{{ state.id }}">
                      {{ state.name }}
                      <p *ngIf="state.abbr">({{ state.abbr }})</p>
                    </option>
                  </select>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>

    <!-- Body Content -->
    <div class="content_body">
      <div class="scroll_container">
        <div id="spread-container" class="spread-container" #divSpreadContainer>
          <!-- Spreadsheet LOADED -->
          <gc-spread-sheets
            #spreadContainer
            id="sales-table-data"
            [tabStripVisible]="tabStripVisible"
            [backColor]="spreadBackColor"
            [hostStyle]="hostStyle"
            hostClass="spreadContainer"
            (workbookInitialized)="workbookInit($event)"
          >
          </gc-spread-sheets>
        </div>
      </div>
    </div>

    <!-- Body Footer -->
    <div class="content_footer">
      <div class="content_footer_inner">
        <div class="content_footer_left">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_white"
                id="btn_back"
                (click)="onBack()"
              >
                <span class="btn_icon"><span class="icon_back"></span></span>
                &nbsp;<span class="btn_text">Back</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="content_footer_right">
          <ul>
            <li>
              <button type="button" class="btn_normal btn_white" id="btn_share">
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">Share</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_pdf_sales"
                (click)="exportReport(1)"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">PDF</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_csv_sales"
                (click)="exportReport(3)"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">CSV</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_excel_sales"
                (click)="exportReport(2)"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">Excel</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
