import { HttpClient } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var AuthService = /** @class */ (function () {
    function AuthService(http, router) {
        this.http = http;
        this.router = router;
        // tslint:disable: variable-name
        this._registerUrl = environment.apiUrl + "/user/register";
        this._loginUrl = environment.apiUrl + "/user/login";
        this._verifyUrl = environment.apiUrl + "/user/verify";
    }
    AuthService.prototype.registerUser = function (user) {
        return this.http.post(this._registerUrl, user);
        // return this.http.post(this._registerUrl, user, { responseType: 'text' });
    };
    AuthService.prototype.loginUser = function (user) {
        return this.http.post(this._loginUrl, user);
        // return this.http.post(this._loginUrl, user, { responseType: 'text' });
    };
    AuthService.prototype.loggedIn = function () {
        return !!localStorage.getItem('token') && !!localStorage.getItem('user');
    };
    AuthService.prototype.getToken = function () {
        return localStorage.getItem('token');
    };
    AuthService.prototype.getUser = function () {
        this.user = JSON.parse(localStorage.getItem('user'));
        return this.user;
    };
    AuthService.prototype.verifyToken = function () {
        // console.log('auth.service.ts - VerifyToken');
        return this.http.get(this._verifyUrl);
    };
    AuthService.prototype.logoutUser = function () {
        // localStorage.removeItem('token');
        localStorage.clear();
        this.router.navigate(['/login']);
    };
    /** @nocollapse */ AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
