import { Payment } from './payment';

export class PaymentSearch {
  paymentsType: number; // 0 = all, 1 = Pending, 2 = Issued, 3 = Confirmed
  paymentPeriodStep: number;
  startDateMonth: number;
  startDateYear: number;
  startStr: string;
  endDateMonth: number;
  endDateYear: number;
  endStr: string;
  searchStr: string[];
  bankId: string;
}
