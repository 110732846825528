import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PaymentsService = /** @class */ (function () {
    function PaymentsService(http) {
        this.http = http;
        this.paymentsUrl = environment.apiUrl + "/payments";
    }
    // API: Get
    PaymentsService.prototype.getPayments = function (operatorId) {
        var url = this.paymentsUrl + "/getPayments?operatorId=" + operatorId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    PaymentsService.prototype.getPaymentsByYear = function (operatorId, year) {
        var url = this.paymentsUrl + "/getPayments?operatorId=" + operatorId + "&year=" + year;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // GET
    PaymentsService.prototype.getPaymentsByPropertyId = function (operatorId, propertyId) {
        var url = this.paymentsUrl + "/getPayments?operatorId=" + operatorId + "&propertyId=" + propertyId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    PaymentsService.prototype.getPaymentsDeductions = function (operatorId) {
        var url = this.paymentsUrl + "/getPaymentsDeductions?operatorId=" + operatorId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // API: Get
    PaymentsService.prototype.getPaymentsDeductionsIndividual = function (operatorId, propertyId, divOrderId, salesYear, salesMonth, productId) {
        // tslint:disable-next-line: max-line-length
        var url = this.paymentsUrl + "/getPaymentsDeductionsIndividual?operatorId=" + operatorId + "&propertyId=" + propertyId + "&divOrderId=" + divOrderId + "&salesYear=" + salesYear + "&salesMonth=" + salesMonth + "&productId=" + productId;
        console.log('Url: ' + url);
        return this.http.get(url);
    };
    // POST
    PaymentsService.prototype.getReport = function (payments, reportType) {
        var httpOptions = {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.paymentsUrl + "/paymentsReport/" + reportType;
        return this.http.post(url, payments, httpOptions);
    };
    PaymentsService.prototype.processPayment = function (sale, userId) {
        var httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.paymentsUrl + ("/processPaymnt?userId=" + userId);
        console.log('URL: ' + url);
        console.log('Sale: ', { sale: sale });
        return this.http.post(url, sale, httpOptions).toPromise();
    };
    /** @nocollapse */ PaymentsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentsService_Factory() { return new PaymentsService(i0.ɵɵinject(i1.HttpClient)); }, token: PaymentsService, providedIn: "root" });
    return PaymentsService;
}());
export { PaymentsService };
