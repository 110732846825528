/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dashboard.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ticker/ticker.component.ngfactory";
import * as i3 from "../ticker/ticker.component";
import * as i4 from "./dashboard-notifications/dashboard-notifications.component.ngfactory";
import * as i5 from "./dashboard-notifications/dashboard-notifications.component";
import * as i6 from "@angular/common";
import * as i7 from "./dashboard-search/dashboard-search.component.ngfactory";
import * as i8 from "./dashboard-search/dashboard-search.component";
import * as i9 from "../lookup.service";
import * as i10 from "./dashboard-quicklinks/dashboard-quicklinks.component.ngfactory";
import * as i11 from "./dashboard-quicklinks/dashboard-quicklinks.component";
import * as i12 from "./dashboard-widgets/dashboard-widgets.component.ngfactory";
import * as i13 from "./dashboard-widgets/dashboard-widgets.component";
import * as i14 from "@angular/forms";
import * as i15 from "../dashboard.service";
import * as i16 from "./dashboard.component";
import * as i17 from "@angular/router";
import * as i18 from "../auth.service";
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
export { RenderType_DashboardComponent as RenderType_DashboardComponent };
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticker", [], null, null, null, i2.View_TickerComponent_0, i2.RenderType_TickerComponent)), i1.ɵdid(1, 114688, null, 0, i3.TickerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard-notifications", [], null, null, null, i4.View_DashboardNotificationsComponent_0, i4.RenderType_DashboardNotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i5.DashboardNotificationsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_1)), i1.ɵdid(1, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-dashboard-search", [], null, null, null, i7.View_DashboardSearchComponent_0, i7.RenderType_DashboardSearchComponent)), i1.ɵdid(3, 114688, null, 0, i8.DashboardSearchComponent, [i9.LookupService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(5, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 6, "div", [["id", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "page_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["id", "content_inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-dashboard-quicklinks", [], null, null, null, i10.View_DashboardQuicklinksComponent_0, i10.RenderType_DashboardQuicklinksComponent)), i1.ɵdid(10, 114688, null, 0, i11.DashboardQuicklinksComponent, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "app-dashboard-widgets", [], null, null, null, i12.View_DashboardWidgetsComponent_0, i12.RenderType_DashboardWidgetsComponent)), i1.ɵdid(12, 114688, null, 0, i13.DashboardWidgetsComponent, [i14.FormBuilder, i9.LookupService, i15.DashboardService], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tickerDisplayFlag; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); var currVal_1 = _co.notificationDisplayFlag; _ck(_v, 5, 0, currVal_1); _ck(_v, 10, 0); _ck(_v, 12, 0); }, null); }
export function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 245760, null, 0, i16.DashboardComponent, [i17.Router, i18.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DashboardComponentNgFactory = i1.ɵccf("app-dashboard", i16.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
export { DashboardComponentNgFactory as DashboardComponentNgFactory };
