import { Bank } from './bank';

export class BankTrustManager {
  id: number;
  bank: Bank;
  name: string;
  tel: string;
  fax: string;
  cell: string;
  email: string;
}
