<kendo-dialog *ngIf="displayAddProperty">
  <form [formGroup]="propertyForm" (ngSubmit)="onSubmit()">
    <!-- @dialog Modal for adding a new Property -->
    <div id="dialog_add_property" title="Add Property" role="dialog" class="dialog">
      <div class="dialog_header">
        <h1>Add Property</h1>
        <div class="dialog_close" (click)="closeAddPropertyDialog()">
          <button type="button" class="close_modal btn_normal btn_icon_only">
            <span class="btn_icon"><span class="icon_close"></span></span>
            <span class="btn_text hidden_text">Close Dialog or press Escape to dismiss</span>
          </button>
        </div>
      </div>
      <div class="dialog_content">
        <div class="dialog_inner_content">
          <fieldset class="grouped split_50_50" id="info_property_details">
            <legend class="legend_title">Property Details</legend>
            <div class="form_row inline">
              <div class="form_label">
                <label for="prop_id" id="link_autofill" (click)="initForm2()">ID</label>
              </div>
              <div class="form_input">
                <input type="text" name="prop_id" id="prop_id" class="inputbox" formControlName="id" />
              </div>
            </div>

            <div class="form_row inline">
              <div class="form_label">
                <label for="prop_desc">Description</label>
              </div>
              <div class="form_input">
                <input type="text" name="prop_desc" id="prop_desc" class="inputbox" formControlName="description" />
              </div>
            </div>

            <div class="form_row inline">
              <div class="form_label">
                <label for="prop_number">Property #</label>
              </div>
              <div class="form_input">
                <input type="text" name="prop_number" id="prop_number" class="inputbox" formControlName="no" required />
              </div>
              <div
                style="clear:both;"
                *ngIf="propertyForm.get('no').invalid && (propertyForm.get('no').dirty || propertyForm.get('no').touched)"
                class="alert alert-danger"
              >
                <div *ngIf="propertyForm.get('no').getError('required')">
                  Property# is required.
                </div>
              </div>
            </div>

            <div class="form_row inline">
              <div class="form_label">
                <label for="prop_state">State</label>
              </div>
              <div class="form_input">
                <select name="prop_state" id="prop_state" class="inputbox" formControlName="state" required (change)="onStateChange()">
                  <option value="" selected="">Select State</option>
                  <option *ngFor="let state of states" value="{{ state.id }}">
                    {{ state.name }}
                    <p *ngIf="state.abbr">({{ state.abbr }})</p>
                  </option>
                </select>
              </div>
              <div
                style="clear:both;"
                *ngIf="propertyForm.get('state').invalid && (propertyForm.get('state').dirty || propertyForm.get('state').touched)"
                class="alert alert-danger"
              >
                <div *ngIf="propertyForm.get('state').getError('required')">
                  State is required.
                </div>
              </div>
            </div>

            <div class="form_row inline">
              <div class="form_label">
                <label for="prop_name">Name</label>
              </div>
              <div class="form_input">
                <input type="text" name="prop_name" id="prop_name" class="inputbox" formControlName="name" required />
              </div>
              <div
                style="clear:both;"
                *ngIf="propertyForm.get('name').invalid && (propertyForm.get('name').dirty || propertyForm.get('name').touched)"
                class="alert alert-danger"
              >
                <div *ngIf="propertyForm.get('name').getError('required')">
                  Property Name is required.
                </div>
              </div>
            </div>

            <div class="form_row inline">
              <div class="form_label">
                <label for="prop_county">County</label>
              </div>
              <div class="form_input">
                <select name="prop_county" id="prop_county" class="inputbox" formControlName="county">
                  <option value="" selected="">Select County</option>
                  <option *ngFor="let county of counties" value="{{ county.id }}"> {{ county.name }} </option>
                </select>
              </div>
              <div
                style="clear:both;"
                *ngIf="propertyForm.get('county').invalid && (propertyForm.get('county').dirty || propertyForm.get('county').touched)"
                class="alert alert-danger"
              >
                <div *ngIf="propertyForm.get('county').getError('required')">
                  County is required.
                </div>
              </div>
            </div>
          </fieldset>

          <div class="form_row" *ngIf="!showAdditionInfo">
            <button
              type="button"
              class="btn_normal btn_no_padding btn_blue_link"
              id="btn_show_additional_info"
              (click)="displayAdditionInfo()"
            >
              <span class="btn_icon"><span class="icon_add"></span></span>
              <span class="btn_text">Add Additional Information</span>
            </button>
          </div>

          <div class="flex_group flex_no_wrap" id="additional_info_container" style="max-width: calc(50% - 20px);" *ngIf="showAdditionInfo">
            <div class="flex_row flex_grow">
              <div class="form_label hidden_label">
                <label for="additional_info">Additional Information</label>
              </div>
              <div class="form_input ">
                <select name="additional_info" id="additional_info" class="inputbox">
                  <option value="" disabled="" selected="">Select Additional Information</option>
                  <option value="Abstract">Abstract</option>
                  <option value="Block">Block</option>
                  <option value="Formation">Formation</option>
                  <option value="Legal Description">Legal Description</option>
                  <option value="Participage Area">Participage Area</option>
                  <option value="Reservoir">Reservoir</option>
                  <option value="Survey">Survey</option>
                  <option value="Tract">Tract</option>
                  <option value="Unit">Unit</option>
                </select>
              </div>
            </div>
            <div class="flex_row_action">
              <button type="button" class="btn_normal btn_blue">
                <span class="btn_text">Add</span>
              </button>
            </div>
          </div>

          <fieldset class="grouped split_50_50" id="info_property_production">
            <legend class="legend_title">Production</legend>
            <div class="form_row inline">
              <div class="form_row_repeater">
                <div class="form_label">
                  <label for="prod_operator">Operator</label>
                </div>
                <div class="form_input">
                  <select name="prod_operator" id="prod_operator" class="inputbox" formControlName="operator">
                    <option value="" selected="">Select Operator</option>
                    <option *ngFor="let operator of operators" value="{{ operator.id }}"> {{ operator.name }} </option>
                  </select>
                </div>
              </div>
              <div class="form_adder">
                <button type="button" class="btn_normal btn_no_padding btn_blue_link">
                  <span class="btn_icon"><span class="icon_add"></span></span>
                  <span class="btn_text">Add Another Operator</span>
                </button>
              </div>
            </div>

            <div class="form_row inline">
              <div class="form_row_repeater">
                <div class="form_label">
                  <label for="prod_purchaser">Purchaser</label>
                </div>
                <div class="form_input">
                  <select name="prod_purchaser" id="prod_purchaser" class="inputbox" formControlName="purchaser">
                    <option value="" selected="">Select Purchaser</option>
                    <option *ngFor="let purchaser of purchasers" value="{{ purchaser.id }}"> {{ purchaser.name }} </option>
                  </select>
                </div>
              </div>
              <div class="form_adder">
                <button type="button" class="btn_normal btn_no_padding btn_blue_link">
                  <span class="btn_icon"><span class="icon_add"></span></span>
                  <span class="btn_text">Add Another Purchaser</span>
                </button>
              </div>
            </div>

            <fieldset class="grouped well_container" id="well_details_1">
              <legend>Well Details</legend>

              <div class="form_row inline">
                <div class="form_label">
                  <label for="prod_type">Production Type</label>
                </div>
                <div class="form_input">
                  <select name="prod_type" id="prod_type" class="inputbox" formControlName="prodType">
                    <option value="" selected="">Select Production Type</option>
                    <option *ngFor="let product of products" value="{{ product.id }}"> {{ product.name }} </option>
                  </select>
                </div>
              </div>

              <div class="form_row inline">
                <div class="form_label">
                  <label for="prod_lease_number">Lease Number</label>
                </div>
                <div class="form_input">
                  <input type="text" name="prod_lease_number" id="prod_lease_number" class="inputbox" formControlName="leaseNo" />
                </div>
              </div>

              <div class="form_row inline">
                <div class="form_label">
                  <label for="prod_well_number">Well #</label>
                </div>
                <div class="form_input">
                  <input type="hidden" name="prod_well_id" id="prod_well_id" formControlName="wellId" />
                  <input type="text" name="prod_well_number" id="prod_well_number" class="inputbox" formControlName="wellApiNo" />
                </div>
              </div>

              <div class="form_row inline">
                <div class="form_label">
                  <label for="prod_effective_from_date">Effective From</label>
                </div>
                <div class="form_input">
                  <kendo-datepicker
                    role="dialog"
                    clase="dialog"
                    #effectiveFromDate
                    placeholder=""
                    style="width:100%;"
                    name="prod_effective_from_date"
                    id="prod_effective_from_date"
                    formControlName="effectiveFrom"
                    [format]="'MM/dd/yyyy'"
                    [(value)]="effectiveFromDateValue"
                  >
                  </kendo-datepicker>
                </div>
              </div>

              <div class="form_row inline">
                <div class="form_label">
                  <label for="prod_well_name">Well Name</label>
                </div>
                <div class="form_input">
                  <input type="text" name="prod_well_name" id="prod_well_name" class="inputbox" formControlName="wellName" />
                </div>
              </div>

              <div class="form_row inline">
                <div class="form_label">
                  <label for="prod_effective_to_date">Effective To</label>
                </div>
                <div class="form_input">
                  <kendo-datepicker
                    #effectiveToDate
                    placeholder=""
                    style="width:100%;"
                    name="prod_effective_to_date"
                    id="prod_effective_to_date"
                    formControlName="effectiveTo"
                    [format]="'MM/dd/yyyy'"
                    [(value)]="effectiveToDateValue"
                  >
                  </kendo-datepicker>
                </div>
              </div>
            </fieldset>

            <div class="">
              <button type="button" class="btn_normal btn_no_padding btn_blue_link" id="btn_add_another_well">
                <span class="btn_icon"><span class="icon_add"></span></span>
                <span class="btn_text">Add Another Well</span>
              </button>
            </div>
          </fieldset>

          <!-- <fieldset class="grouped split_50_50" id="info_property_survey">
            <legend class="legend_title">Survey</legend>
            <p>Content placeholder</p>

            <div class="fieldset_remove">
              <button type="button" class="btn_normal ">
                <span class="btn_text">Remove</span>
              </button>
            </div>
          </fieldset> -->
        </div>
      </div>
      <div class="dialog_actions clearfix">
        <div class="actions_left">
          <!-- <button type="button" class="btn_normal btn_white" id="btn_upload_property_alt">
            <span class="btn_icon"><span class="icon_upload_cloud"></span></span>
            <span class="btn_text">Upload</span>
          </button> -->
        </div>
        <div class="actions_right">
          <button type="button" class="btn_normal btn_grey btn_cancel_modal" (click)="closeAddPropertyDialog()">
            <span class="btn_text">Cancel</span>
          </button>
          <button
            type="submit"
            name="submit"
            value="Append"
            class="btn_normal btn_blue"
            id="btn_confirm_add_property"
            [disabled]="propertyForm.invalid"
          >
            <span class="btn_text">Save Property</span>
          </button>
          <!-- <div class="form_submit">
            <button type="submit" name="submit" class="btn_normal btn_blue" id="btn_confirm_save_property">Save Property</button>
          </div> -->
        </div>
      </div>
    </div>
  </form>
</kendo-dialog>

