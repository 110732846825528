import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Production } from './model/production';

@Injectable({
  providedIn: 'root'
})
export class ProductionService {
  private readonly productionUrl = `${environment.apiUrl}/production`;

  constructor(private http: HttpClient) { }

  // API: Get
  getProduction(operatorId: number) {
    const url = `${this.productionUrl}/getProduction?operatorId=${operatorId}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getProductionByYear(operatorId: number, year: number) {
    const url = `${this.productionUrl}/getProduction?operatorId=${operatorId}&year=${year}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  // GET
  getProductionByPropertyId(operatorId: number, propertyId: number) {
    return this.http.get(`${this.productionUrl}/getProduction?operatorId=${operatorId}&propertyId=${propertyId}`);
  }

  // GET
  getProdChangeReasons() {
    console.log('GetProdChangeReasons Url: ', `${this.productionUrl}/getProductionChangeReasons`);

    return this.http.get(`${this.productionUrl}/getProductionChangeReasons`).toPromise();
  }

  // POST
  postProductionFile(fd: FormData) {
    const url = `${this.productionUrl}/upload`;

    return this.http.post(url, fd, { responseType: 'text' as 'text' });
  }

  // POST
  public save(production: Production) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(`${this.productionUrl}/save`, production, httpOptions).toPromise();
  }

  // POST
  public getReport(productions: Production[], reportType: number) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.productionUrl}/productionReport/${reportType}`;
    return this.http.post(url, productions, httpOptions);
  }

}
