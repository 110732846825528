import { OnInit } from '@angular/core';
var TickerSplitComponent = /** @class */ (function () {
    function TickerSplitComponent() {
    }
    TickerSplitComponent.prototype.ngOnInit = function () {
        $(document).ready(function () {
            $('#btn_ticker_toggle').click(function () {
                $('#ticker_drawer').slideToggle();
                $(this).toggleClass('selected');
            });
        });
    };
    return TickerSplitComponent;
}());
export { TickerSplitComponent };
