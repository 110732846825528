import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DivisionorderService } from 'src/app/divisionorder.service';
import { MessagesService } from 'src/app/messages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
var PropertiesUploadComponent = /** @class */ (function () {
    function PropertiesUploadComponent(http, divisionOrderService, messagesService, authService) {
        this.http = http;
        this.divisionOrderService = divisionOrderService;
        this.messagesService = messagesService;
        this.authService = authService;
        this.displayUploadPropertyChange = new EventEmitter();
        this.displayAddPropertyChange = new EventEmitter();
        this.uploadDisabled = true;
        this.selectedFile = null;
        this.fileSelected = false;
        this.message = this.messagesService.noFileSelected;
        this.ngUnsubscribe = new Subject();
    }
    PropertiesUploadComponent.prototype.closeDialog = function () {
        console.log('Close upload property dialog');
        this.displayUploadProperty = false;
        this.displayUploadPropertyChange.emit(false);
    };
    PropertiesUploadComponent.prototype.addManually = function () {
        console.log('Add manually');
        // Close Upload Dialog
        this.closeDialog();
        // Open Add Property Dialog
        this.displayAddProperty = true;
        this.displayAddPropertyChange.emit(true);
    };
    PropertiesUploadComponent.prototype.ngOnInit = function () {
    };
    PropertiesUploadComponent.prototype.onFileSelectedProperty = function (event) {
        this.selectedFile = event.target.files[0];
        this.message = this.selectedFile.name;
        this.fileSelected = true;
        console.log('Property File selected: ' + this.message);
    };
    PropertiesUploadComponent.prototype.onUpload = function () {
        console.log('OnUpload - start');
        var user = this.authService.getUser();
        var fileName = this.selectedFile.name;
        console.log('OnUpload - fileName:' + fileName);
        var fd = new FormData();
        fd.append('file', this.selectedFile, fileName);
        fd.append('userId', user.id.toString());
        fd.append('operatorId', user.operator.id.toString());
        var resultObservable = this.divisionOrderService.postDivisionOrderFile(fd);
        var resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (res) {
            console.log('Response: ' + res);
        });
        this.selectedFile = null;
        this.message = fileName + ' - ' + this.messagesService.fileUploaded;
        this.closeDialog();
    };
    PropertiesUploadComponent.prototype.ngOnDestroy = function () {
        console.log('The component is being destroyed');
        this.ngUnsubscribe.next();
    };
    return PropertiesUploadComponent;
}());
export { PropertiesUploadComponent };
