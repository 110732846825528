import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Sale } from './model/sale';

@Injectable({
  providedIn: 'root'
})
export class SalesService {
  private readonly salesUrl = `${environment.apiUrl}/sales`;

  constructor(private http: HttpClient) { }

  // API: Get
  getSales(operatorId: number) {
    const url = `${this.salesUrl}/getSales?operatorId=${operatorId}`;
    console.log('Sales Service - Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getSalesProcessPayments(operatorId: number, startDate: Date, endDate: Date) {
    const startDateStr = startDate.toISOString().split('T')[0];
    const endDateStr = endDate.toISOString().split('T')[0];
    console.log('StartDate: ' + startDateStr + '    End Date: ' + endDateStr);

    const url = `${this.salesUrl}/getSalesProcessPayments?operatorId=${operatorId}&startDate=${startDateStr}&endDate=${endDateStr}`;
    console.log('Sales Service - Url: ' + url);
    return this.http.get(url);
  }

  // API: Get
  getSalesByYear(operatorId: number, year: number) {
    const url = `${this.salesUrl}/getSales?operatorId=${operatorId}&year=${year}`;
    console.log('Sales Service - Url: ' + url);
    return this.http.get(url);
  }

  getSalesDeductionsIndividual(salesId: number) {
    const url = `${this.salesUrl}/getSalesDeductionsIndividual?salesId=${salesId}`;
    console.log('Sales Service - Url: ' + url);
    return this.http.get(url);
  }

  getSalesDeductions(operatorId: number) {
    const url = `${this.salesUrl}/getSalesDeductions?operatorId=${operatorId}`;
    console.log('Url: ' + url);
    return this.http.get(url);
  }

  getSalesByPropertyId(operatorId: number, propertyId: number) {
    return this.http.get(`${this.salesUrl}/getSales?operatorId=${operatorId}&propertyId=${propertyId}`);
  }

  postSalesFile(fd: FormData) {
    const url = `${this.salesUrl}/upload`;

    return this.http.post(url, fd, { responseType: 'text' as 'text' });
  }

  // POST
  public save(sale: Sale, userId: number) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const url = this.salesUrl + `/save?userId=${userId}`;

    console.log('URL: ' + url);
    console.log('Sale: ', { sale });

    return this.http.post(url, sale, httpOptions).toPromise();
  }

  // POST
  public getReport(sales: Sale[], reportType: number) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.salesUrl}/salesReport/${reportType}`;
    return this.http.post(url, sales, httpOptions);
  }
}
