<div class="page_container">
  <div id="content_inner">
    <!-- Content Header -->
    <div class="content_header">
      <div class="content_history">
        <!-- <button type="button" class="btn_normal btn_big btn_icon_only" id="btn_history_back" onclick="window.location='dashboard.html'">
					<span class="btn_icon"><span class="icon_back"></span></span>
					<span class="btn_text">Back</span>
				</button> -->
      </div>
      <div class="content_title">
        <h1>Payments</h1>
      </div>
      <div class="content_timestamp">
        <div class="timestamp">Last updated: {{ lastUpdated | date:'d MMM yyyy' }} 4:30pm</div>
      </div>
      <div class="content_actions"></div>
    </div>

    <!-- Action Bar -->
    <div class="content_action_bar">
      <div class="action_bar_left">
        <ul>
          <li>
            <fieldset class="form_row no_padding clearfix">
              <legend class="hidden_legend">Filter Rows</legend>
              <div class="toggle_group">
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="all"
                      id="step_monthly"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnPaymentStepMonthly()"
                    />
                  </div>
                  <label
                    for="step_monthly"
                    [ngClass]="{
                      toggle_active: paymentSearch.paymentPeriodStep === 1
                    }"
                    >Monthly</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="in_progress"
                      id="step_quarterly"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnPaymentStepQuarterly()"
                    />
                  </div>
                  <label
                    for="step_quarterly"
                    [ngClass]="{
                      toggle_active: paymentSearch.paymentPeriodStep === 3
                    }"
                    >Quarterly</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="active"
                      id="step_annually"
                      name="filter_option"
                      class="filter_option"
                      checked=""
                      (click)="btnPaymentStepAnnually()"
                    />
                  </div>
                  <label
                    for="step_annually"
                    [ngClass]="{
                      toggle_active: paymentSearch.paymentPeriodStep === 12
                    }"
                    >Annually</label
                  >
                </div>
              </div>
            </fieldset>
          </li>
        </ul>
      </div>
      <form [formGroup]="paymentSearchForm">
        <div class="action_bar_right">
          <ul>
            <li>
              <div class="form_row no_padding">
                <div id="period_dates" class="period_dates">
                  <div class="period_date_prev">
                    <button
                      id="btnStartDateDown"
                      type="button"
                      class="btn_normal btn_white"
                      (click)="onStartDateDown()"
                    >
                      <span class="btn_icon"
                        ><span class="icon_chevron_left"></span
                      ></span>
                    </button>
                    <button
                      id="btnStartDateUp"
                      type="button"
                      class="btn_normal btn_white"
                      (click)="onStartDateUp()"
                      [disabled]="maxStartDate"
                    >
                      <span class="btn_icon"
                        ><span class="icon_chevron_right"></span
                      ></span>
                    </button>
                  </div>
                  <div class="period_date_current">
                    &nbsp;<span id="period_current_date_start">{{
                      paymentSearch.startStr
                    }}</span
                    >&nbsp;-&nbsp;
                    <span id="period_current_date_end">{{
                      paymentSearch.endStr
                    }}</span
                    >&nbsp;
                  </div>
                  <div class="period_date_next">
                    <button
                      id="btnEndDateDown"
                      type="button"
                      class="btn_normal btn_white"
                      (click)="onEndDateDown()"
                    >
                      <span class="btn_icon"
                        ><span class="icon_chevron_left"></span
                      ></span>
                    </button>
                    <button
                      id="btnEndDateUp"
                      type="button"
                      class="btn_normal btn_white"
                      (click)="onEndDateUp()"
                      [disabled]="maxEndDate"
                    >
                      <span class="btn_icon"
                        ><span class="icon_chevron_right"></span
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="form_row no_padding">
                <div class="form_label hidden_label">
                  <label for="filter_payment_bank">Banks</label>
                </div>
                <div class="form_input">
                  <select
                    name="bank"
                    id="bank"
                    class="inputbox"
                    formControlName="bank"
                    (change)="onBankChange()"
                  >
                    <option value="" [selected]="">All Banks</option>
                    <option *ngFor="let bank of banks" value="{{ bank.id }}">
                      {{ bank.name }}
                    </option>
                  </select>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>

    <!-- Body Content -->
    <div class="content_body">
      <div class="col_group col_2">
        <div class="col_item">
          <div class="col_group col_2">
            <div class="col_item">
              <!-- Donut Chart -->
              <div class="chart_donut_container">
                <div id="chart_donut" style="height: 260px"></div>
                <div id="chart_donut_value" class="chart_donut_value">
                  {{ getPaymentsTotal() | currency }}
                </div>
              </div>
            </div>
            <div class="col_item text_left">
              <div class="space_divider extra_padding"></div>

              <div class="summary_item compressed">
                <div class="summary_label">
                  <div class="color_block color_pending"></div>
                  Pending
                </div>
                <div class="summary_value">
                  {{ paymentsSummary.pendingValue | currency }}
                </div>
              </div>

              <div class="summary_item compressed">
                <div class="summary_label">
                  <div class="color_block color_issued"></div>
                  Issued
                </div>
                <div class="summary_value">
                  {{ paymentsSummary.issuedValue | currency }}
                </div>
              </div>

              <div class="summary_item compressed">
                <div class="summary_label">
                  <div class="color_block color_confirmed"></div>
                  Confirmed
                </div>
                <div class="summary_value">
                  {{ paymentsSummary.confirmedValue | currency }}
                </div>
              </div>
            </div>
          </div>

          <div class="timestamp">Values correct on {{ lastUpdated | date:'d MMM yyyy' }} 4:30pm</div>
        </div>
        <div class="col_item text_left">
          <div id="payment_summary_panel" class="summary_panel">
            <div class="summary_icon">
              <div class="summary_icon_main">
                <span class="icon_payment"></span>
              </div>
              <div class="summary_icon_shadow">
                <span class="icon_payment_shadow"></span>
              </div>
            </div>
            <h2 class="summary_title">Summary of Payments</h2>
            <div class="summary_content">
              <div class="col_group">
                <div class="col_item">
                  <div class="summary_item compressed">
                    <div class="summary_label">Royalties Due</div>
                    <div class="summary_value">$10,067.93</div>
                    <!-- <div class="fine_print">Due on 2 Jan 2020</div> -->
                  </div>

                  <div class="summary_item compressed">
                    <div class="summary_label">Royalties Paid</div>
                    <div class="summary_value">$2,097,351.02</div>
                    <div class="fine_print">Last Updated on {{ lastUpdated | date:'d MMM yyyy' }}</div>
                  </div>
                </div>
                <div class="col_item text_left">
                  <div class="summary_item compressed">
                    <div class="summary_label">Owner Payments</div>
                    <div class="summary_value">1,949</div>
                    <div class="fine_print">(265 Pending Payments)</div>
                  </div>

                  <!-- <div class="summary_item compressed">
                    <div class="summary_label">Manual Payment Costs</div>
                    <div class="summary_value">
                      $3,670.00
                      <div class="payment_change">
                        <span class="change_value_inverted down">15% Less</span>
                      </div>
                    </div>
                    <div class="fine_print">Last on 31 Jan 2019</div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="space_divider"></div>

          <div class="col_group">
            <div class="col_item">
              <a href="#" class=""
                >Go to Payment <span class="icon_arrow_right"></span
              ></a>
            </div>
            <div class="col_item text_right">
              <a href="#"><span class="icon_share"></span>Export Results</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="space_divider extra_padding"></div>

    <!-- Action Bar 2 -->
    <div class="content_action_bar">
      <div class="action_bar_left">
        <ul>
          <li>
            <fieldset class="form_row no_padding clearfix">
              <legend class="hidden_legend">Filter Rows</legend>
              <div class="toggle_group">
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="all"
                      id="payments_all"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnAllPayments()"
                    />
                  </div>
                  <label
                    for="payments_all"
                    [ngClass]="{
                      toggle_active: paymentSearch.paymentsType === 0
                    }"
                    >All</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="in_progress"
                      id="payments_pending"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnPendingPayments()"
                    />
                  </div>
                  <label
                    for="payments_pending"
                    [ngClass]="{
                      toggle_active: paymentSearch.paymentsType === 1
                    }"
                    >Pending</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="active"
                      id="payments_issued"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnIssuedPayments()"
                    />
                  </div>
                  <label
                    for="payments_issued"
                    [ngClass]="{
                      toggle_active: paymentSearch.paymentsType === 2
                    }"
                    >Issued</label
                  >
                </div>
                <div class="toggle_group_option">
                  <div class="toggle_input">
                    <input
                      type="radio"
                      value="active"
                      id="payments_confirmed"
                      name="filter_option"
                      class="filter_option"
                      (click)="btnConfirmedPayments()"
                    />
                  </div>
                  <label
                    for="payments_confirmed"
                    [ngClass]="{
                      toggle_active: paymentSearch.paymentsType === 3
                    }"
                    >Confirmed</label
                  >
                </div>
              </div>
            </fieldset>
          </li>
        </ul>
      </div>
      <form [formGroup]="paymentSearchForm">
        <div class="action_bar_right">
          <ul>
            <li>
              <div class="form_row no_padding">
                <div class="form_label hidden_label">
                  <label for="filter_search_table">Search Table</label>
                </div>
                <div class="form_input">
                  <input
                    type="text"
                    id="filter_search_table"
                    name="filter_search_table"
                    class="inputbox searchbox"
                    placeholder="Search Payments..."
                    style="width: 100%; height: 30px"
                    (keyup)="doSearch()"
                    formControlName="searchStr"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </form>
    </div>

    <!-- Body Content -->
    <div class="content_body">
      <div class="scroll_container">
        <div id="spread-container" class="spread-container" #divSpreadContainer>
          <!-- Spreadsheet LOADED -->
          <gc-spread-sheets
            #spreadContainer
            id="payments-table-data"
            [tabStripVisible]="tabStripVisible"
            [backColor]="spreadBackColor"
            [hostStyle]="hostStyle"
            hostClass="spreadContainer"
            (workbookInitialized)="workbookInit($event)"
          >
          </gc-spread-sheets>
        </div>
      </div>
    </div>

    <!-- Body Footer -->
    <div class="content_footer">
      <div class="content_footer_inner">
        <div class="content_footer_left">
          <ul>
            <li>
              <button
                type="button"
                class="btn_normal btn_white"
                id="btn_back"
                (click)="onBack()"
              >
                <span class="btn_icon"><span class="icon_back"></span></span>
                &nbsp;<span class="btn_text">Back</span>
              </button>
            </li>
          </ul>
        </div>
        <div class="content_footer_right">
          <ul>
            <li>
              <button type="button" class="btn_normal btn_white" id="btn_share">
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">Share</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_pdf_payments"
                (click)="exportReport(1)"
                [disabled]="!enableToExport"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">PDF</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_csv_payments"
                (click)="exportReport(3)"
                [disabled]="!enableToExport"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">CSV</span>
              </button>
            </li>
            <li>
              <button
                type="button"
                class="btn_normal btn_blue"
                id="btn_export_excel_payments"
                (click)="exportReport(2)"
                [disabled]="!enableToExport"
              >
                <span class="btn_icon"><span class="icon_share"></span></span>
                &nbsp;<span class="btn_text">Excel</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
