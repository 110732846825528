import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { ChangePassword } from 'src/app/model/changePassword';
import { AuthService } from 'src/app/auth.service';
import { UserService } from 'src/app/user.service';
import { ConfirmedValidator } from 'src/app/user/change-password/password.validators';
import { Router } from '@angular/router';
var ChangePasswordComponent = /** @class */ (function () {
    function ChangePasswordComponent(authService, fb, router, userService) {
        this.authService = authService;
        this.fb = fb;
        this.router = router;
        this.userService = userService;
        this.pwdPattern = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&_])([a-zA-Z0-9@$!%*?&_]{8,20})$';
        this.submitted = false;
        this.displayChangePasswordChange = new EventEmitter();
        this.ngUnsubscribe = new Subject();
        this.changePasswordForm = this.fb.group({
            oldPassword: ['', Validators.required],
            newPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(20), Validators.pattern(this.pwdPattern)]],
            confirmPassword: ['', Validators.required]
        }, {
            validators: ConfirmedValidator('newPassword', 'confirmPassword')
        });
    }
    ChangePasswordComponent.prototype.ngOnInit = function () {
        this.submitted = false;
        this.changed = false;
        console.log('Password-Change - Init - start');
        this.changePassword = new ChangePassword();
        // Initialise the form to blank
        this.initChangePasswordForm();
        console.log('Password-Change - Init - complete');
    };
    ChangePasswordComponent.prototype.ngOnDestroy = function () {
        console.log('The component is being destroyed');
        this.ngUnsubscribe.next();
    };
    ChangePasswordComponent.prototype.closeChangePasswordDialog = function () {
        console.log('Password-Change - dialog - close - start');
        this.submitted = false;
        // this.initChangePasswordForm();
        this.changePasswordForm.reset();
        this.displayChangePassword = false;
        this.displayChangePasswordChange.emit(false);
        console.log('Password-Change - dialog - close - end');
    };
    ChangePasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        console.log('Password-Change - start');
        this.submitted = true;
        if (this.changePasswordForm.invalid) {
            return false;
        }
        else {
            this.currUser = this.authService.getUser();
            this.changePassword.email = this.currUser.email;
            this.changePassword.password = this.changePasswordForm.get('oldPassword').value;
            this.changePassword.newPassword = this.changePasswordForm.get('newPassword').value;
            this.userService.changePassword(this.changePassword).subscribe(function (data) {
                _this.changed = true;
                setTimeout(function () {
                    console.log(_this.changed); // Will result in true;
                    _this.changed = false; // Here, value is updated
                    _this.closeChangePasswordDialog();
                }, 2000);
            }, function (err) {
                console.log('changePassword() :Error');
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 400) {
                        _this.errorMessage = err.error;
                        _this.changePasswordForm.reset();
                    }
                    else if (err.status === 401) {
                        _this.router.navigate(['/login']);
                    }
                }
            });
        }
    };
    ChangePasswordComponent.prototype.initChangePasswordForm = function () {
        this.changePasswordForm.patchValue({ oldPassword: '' });
        this.changePasswordForm.patchValue({ newPassword: '' });
        this.changePasswordForm.patchValue({ confirmPassword: '' });
    };
    return ChangePasswordComponent;
}());
export { ChangePasswordComponent };
