export const environment = {
  production: true,
  pilot: true,
  apiUrl: 'https://pipelinedl-pilot-api.azurewebsites.net/webapi',
  //apiUrl: 'http://169.61.37.178:8080/pipelinedl-api-server/webapi',
  // apiUrl: '/pipelinedl-app-server/webapi',
  // tslint:disable-next-line: max-line-length
  // blockchainExplorerUrl: 'https://b1cf68b304374c5f96c310febcf3a3a9-optools.uss02.blockchain.cloud.ibm.com/peer/Peer1Pipel_0/channel/pipelinechannel/block/',
  // tslint:disable-next-line: max-line-length
  blockchainExplorerUrl: 'http://oil-n-gas-pilot.centralus.cloudapp.azure.com:5984/_utils/#database/oilngasdev_oilngaschaincode/',
  // blockchainExplorerUrl: 'http://139.59.193.133:8080/#/transactions',

  spreadLicense:
    // tslint:disable-next-line: max-line-length
    // 'Nuarca,444445911338471#B0TuvSd7YDW6Z4VUl6bUhWQsFETh3ENKp7KM3SU4BVYQt6c8E7Rq34N9Q5aZRVWXBlcONUYvgnb5sWc7gVYjF6aFVTYw3yTGZFRhRHSwlzb4R6bXpWRHlHa8dzdR3mR7V6LxYHeWRHdLZVWPtGb4RkSodzKnR6V5MUYl3iVDhWY8h7NmRDSXFmU4VTeGBHM4N6UPBzTzYjZxh5QFdTewRFb8M7T9EXd0NXN5JkaqdVVPBjZBVWSsB5U98kYEVlVWVjM7NUcC3UezR6bllTYpV6YsJXNsJEb4oERwJHOyQHM8okN4kHTiojITJCLiYDM6cTQFRkI0ICSiwiNwkDN5kDOzcTM0IicfJye&Qf35VfikkR9IkI0IyQiwiIyEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICNzATNxADI4AjNwkTMwIjI0ICdyNkIsICNwcDM9EDMyIiOiAHeFJCLiE6YyFWdOJiOiEmTDJCLiEzN4gzMzETM9UDN4QDN4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBlHNTdTTu9Ee636ZkF7Q93UMJtCUTNVaWRmSINEV6wWUt56YyxkNjRWWDtyNpdmVrcEd5d7Q5AlQy4kQr5WYQ9WWz3mawMzdjVkWIBTMW9EVsJUah3Wdit4Tl3iUmFzc7omYnZjdSl4QDZESiZDDVzU',
    // tslint:disable-next-line: max-line-length
    // 'Empyrean Solutions,445182643533491#B0ATeV5Y5oke9lGNH3WM7JnVQF7Vzx6Zq3EZoJkQoN5RiF5VplVW8wEVCNmSvhVM5t6KlJnVjVncOpkS7U6YQZWbEBXUwcjZGpXMXZ4UaZWezITN6kUMwtUOLtSM7s4NxBDMohGeTRkR7xUMI9EOTRmQGhlVKRVRUFkbGZTOPRndzMXdpJ5bEFUahFlcBhzaOBDT9czUOZFV094byZmW7QzbFpmNVF4YmVDTvpWR5YUesN5LTVDbExmRzdEUtRDTXtWaSx6QpVTSRdlW7QGWCtiV9dUe63GbPpndwEGS4R4b5N6ZiojITJCLigTMBJ4QyEENiojIIJCLwkzMyUjNzYDN0IicfJye#4Xfd5nIJZUOCJiOiMkIsIiMx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiUjM6ITNwASMwcDM9EDMyIiOiQncDJCLiMnbvlGd5x6bTBibhVmc9BXbFJiOiEmTDJCLiETO4MzM5MDN6IDOxUDN4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRVXOklzbOdncn9mRmZzRuh4VIFFWJhFaINTbwpmbNNmbR5UQkFWVkNzNJRVYv5GS8UmVTlEaVlVR7J4bTh7bRdzb9dGcDNkNqpVWQVjZHRTVlFnbxYVWrgGWah6d724QI36T4l6K64UTyhFUKJ4KGJrZ3l',
    // tslint:disable-next-line: max-line-length
    // '52.117.5.200|35.196.146.231,E877517848645992#B0vkm7VxYDMhJ4Tmd4V9JGSnpXZ0BDdS96QndlcklTQoh4Sw2ESpJjQVtCRP3SVmdVMqlDUjpEVVZmT8FFN83idQ9mS74WeqFzbrdXMktGOIt6aEpWSGVXVCdUYWZkZMllYMtGe5E4cOFkZmNWcWdXaz9mZ424KkJDR5p6ZvA5ZnZzdXpWYQllav3UbjVkRVZGSrNHN9g5KSdDOaZGVqFWZS94a55ma98mazcDTmZVRwpnYv3SOjFmb6Y7YnpFNuFGV7FVMUBHeipWUlNXcxV4QXNlT84kRHF5cVJnNLNmcGRTRDJ6K0FjTmZ6djdjQONTUY3ENvUne52WQIlkI0IyUiwiIFRjR4ATNBJjI0ICSiwiN8cjN4cjN6YTM0IicfJye&Qf35VfiU5TzYjI0IyQiwiIzEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsIiNzATM6ADIzETMxAjMwIjI0ICdyNkIsICOxITMxIDMyIiOiAHeFJCLiEzMy8iN4EjL6kTMuUzMsADMy8SNucTMx8iM5IiOiMXbEJCLiE6YyFWdOJiOiEmTDJCLlVnc4pjIsZXRiwiIykTO5QjN8QDO7ETN7cDOiojIklkIs4XZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdRNHbtZkNxRWT58Ga4N5ZuZUVJh7LLpGWI5EN6hnavclatpmY956LWZ5L7M4SQRWN7t4ZHlWMoljWoZEOrxUcrV6Z6NnZWx6Qr8kWFJnUFF7btdUWvEXraCX',
    // tslint:disable-next-line: max-line-length
    '169.61.37.178|35.196.146.231,E877517848645992#B0vZBFOFNjW5dTW6IVRIJXMnBDSmZmMzlkahp6U7sUb9tCVsB5RXZkdzgjb5InR9wUasNHOwlHezZEbYRlTxJXTvp4ZSFUQylVN4VnVOlTMWh6SFp4awIEcyBDMGx6UOVnW6c6YKN7VWFXORVHULpWSQh5SOVkZnh5dER7YpFlb9V7QvYVY4o6QONWS9FjMMx4SudUMzJzcXd5UklGSZ56YOVUVzVnbQVjS7wWTT36dDpGRUl7M4MmUqVjckRmQLpnNFB7TFlFca5mdsRUYWN4TrEnSktUVmF6RRRTQ4Ime53GR4hVZrBHVq3SZMNkNzhkVpJ7YjdkR9dmMVp7QiojITJCLiQzNyADNEBTNiojIIJCLxUTM6QjM9UTN0IicfJye&Qf35VfiU5TzYjI0IyQiwiIzEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsIyN4MTNxADI4ITMxAjMwIjI0ICdyNkIsICOxITMxIDMyIiOiAHeFJCLiEzMy8iN4EjL6kTMuUzMsgzNx8yNz8SM68SO6EjI0IyctRkIsISYjJXY59kI0ISYONkIsUWdyRnOiwmdFJCLiITO9UDN6gDN8cTM5czN8IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPnNDS6RVNFZVevkDT6Vmca9ESwU5LvcjZm3ySYtmdChnd4N4dZBHe8REbKZjaaRzdjdGMCN5MpB5SwIENMd7cIlUSVZmRwQnezUkYwJUR8QTcwo4Z7EEHPsT',

  gridHighlight: '#EDF4FC',
  gridBackground: 'white',
  gridCellChanged: '#FFE6A2',
  gridCellBorder: '#E0E0E0',
  buttonEnabled: '#468EE5',
  buttonEnabledText: 'white',
  buttonDisabled: 'rgba(0,0,0,0.05)',
  buttonDisabledText: '#CCCCCC',

  showTicker: false,
  showNotification: false,
  showPerformanceWidget: false,
  showSummaryWidget: false,
  showTopWellsWidget: false,
  showPaymentsWidget: false,
  showBanksWidget: false,
  showOpenTicketsWidget: false,
  showLeasesWidget: false,
  showMarketsWidget: false
};
