<app-ticker-split></app-ticker-split>
<app-messages></app-messages>
<app-sales-content
  [(displayAddSales)]="displayAddSales"
  [(displayUploadSales)]="displayUploadSales"
  [(displayProcessPayments)]="displayProcessPayments"
  [(salesSearch)]="salesSearch">
</app-sales-content>
<app-sales-add [(displayAddSales)]="displayAddSales"></app-sales-add>
<app-sales-upload [(displayUploadSales)]="displayUploadSales"></app-sales-upload>
<app-sales-process-payments
  [(displayProcessPayments)]="displayProcessPayments"
  [(salesSearch)]="salesSearch"
  >
</app-sales-process-payments>

