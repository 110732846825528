import { OnInit, ElementRef } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';
import { environment } from './../../environments/environment';
import { DivisionorderService } from './../divisionorder.service';
import { MessagesService } from '../messages.service';
import { AuthService } from '../auth.service';
var DivisionorderListComponent = /** @class */ (function () {
    function DivisionorderListComponent(divisionOrderService, messagesService, authService) {
        this.divisionOrderService = divisionOrderService;
        this.messagesService = messagesService;
        this.authService = authService;
        this.blockchainExplorerUrl = "" + environment.blockchainExplorerUrl;
        this.sheet = null;
        this.tabStripVisible = false;
        this.spreadBackColor = 'white';
        this.sheetName = 'Division Orders';
        this.hostStyle = {
            height: '100%',
            width: '100%'
        };
        this.autoGenerateColumns = false;
        this.gridColCount = 12;
        this.blockChainColumn = 6;
    }
    DivisionorderListComponent.prototype.ngOnInit = function () {
        this.loggedInUser = this.authService.getUser();
        this.getDivOrders(this.loggedInUser.operator);
    };
    DivisionorderListComponent.prototype.getDivOrders = function (operator) {
        var _this = this;
        // Todo: use .pipe(ngUnsubscribe) for every observable + put a ngUnsubscribe.next()
        // inside of each component ngOnDestroy(). For that you need to create a property for that
        // component call "ngUnsubscribe" with the type BehaviorSubject.
        // Example: private ngUnsubscribe: BehaviorSubject = new BehaviorSubject(null); if (/*is not null*/) { /* do whatever */ }
        this.divisionOrderService.getDivisionOrders(operator.id).subscribe(function (divOrders) {
            console.log({ divOrders: divOrders });
            _this.divOrders = divOrders;
            var filteredDivOrders = JSON.parse(JSON.stringify(divOrders));
            console.log('pre-transformation', { filteredDivOrders: filteredDivOrders });
            filteredDivOrders = _this.setDivisionOrderValues(filteredDivOrders);
            console.log('post transformation', { filteredDivOrders: filteredDivOrders });
            _this.applyDataBinding(filteredDivOrders);
        });
    };
    DivisionorderListComponent.prototype.setDivisionOrderValues = function (filteredDivOrders) {
        filteredDivOrders.forEach(function (divOrder) {
            // console.log('Division Order: ', { divOrder });
            divOrder.ownerId = divOrder.lease.title.owner.id;
            divOrder.ownerName = divOrder.lease.title.owner.fname + ' ' + divOrder.lease.title.owner.lname;
            divOrder.ownerPercent = divOrder.lease.title.ownershipPercent;
            divOrder.decimalInterest = divOrder.lease.royaltyInterest;
        });
        return filteredDivOrders;
    };
    DivisionorderListComponent.prototype.applyDataBinding = function (filteredDivOrders) {
        var _this = this;
        var dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredDivOrders);
        this.sheet.suspendPaint();
        // Lock/Readonly all columns
        this.sheet.options.isProtected = true;
        this.sheet.options.protectionOptions = {
            allowSelectUnlockedCells: true,
            allowSelectLockedCells: false
        };
        // Unlock from column 0 for 6 columns(columns 0 - 5) - [Owner# - Royalty Interest]
        this.sheet.getRange(-1, 0, -1, 6).locked(false);
        // Unlock from columns 8 for 4 columns (columns 8 - 11) - [Working Interest - Action]
        this.sheet.getRange(-1, 8, -1, 4).locked(false);
        // Define columns
        var ownerIdColInfo = { name: 'ownerId', displayName: 'Owner#', size: 60 };
        var ownerNameColInfo = { name: 'ownerName', displayName: 'OwnerName', size: 300 };
        var ownerPercentColInfo = { name: 'ownerPercent', displayName: 'Ownership %', size: 100 };
        var doTermStartColInfo = { name: 'termStart', displayName: 'Term Start', size: 100 };
        var doTermEndColInfo = { name: 'termEnd', displayName: 'Term End', size: 100 };
        var doRoyaltyIntColInfo = { name: 'royaltyInterest', displayName: 'Royalty Interest', formatter: '0.00', size: 120 };
        var doBCImageColInfo = { name: '', displayName: '#', size: 30 };
        var doBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 150 };
        var doWorkingIntColInfo = { name: 'workingInterest', displayName: 'Working Interest', formatter: '0.00', size: 120 };
        var doDeductionColInfo = { name: 'deductions', displayName: 'Deductions', formatter: '0.00', size: 100 };
        var doStatusColInfo = { name: 'status', displayName: 'Status', size: 100 };
        var doActionColInfo = { name: 'action', displayName: 'Action', size: 100 };
        // Generate the Block-chain column definition
        filteredDivOrders.forEach(function (rowDivorderObject, rowIndex) {
            // console.log('Row Index: ' + rowIndex, rowDivorderObject);
            // Remove vertical grid-lines
            _this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
            var column = 6;
            var cell = _this.sheet.getCell(rowIndex, column);
            var imgBlockChain = null;
            if (filteredDivOrders[rowIndex].bcTransId != null) {
                imgBlockChain = './assets/images/blockchain_cell@2x.png';
                // console.log('Blockchain Trans Id not NULL RowIndex: ' + rowIndex + '   TransId: ' + filteredDivOrders[rowIndex].bcTransId);
            }
            cell.text('');
            cell.backgroundImage(imgBlockChain);
        });
        // Bind click-event of Blockchain cell
        this.spread.bind(GC.Spread.Sheets.Events.CellClick, function (e, args) {
            var sheet = args.sheet;
            var row = args.row;
            var col = args.col;
            var cell = _this.sheet.getCell(row, col);
            if (col === 6 || col === 7) {
                if (filteredDivOrders[row].bcTransId != null) {
                    console.log('Cell: (' + row + ', ' + col
                        + ') DivisionOrderId: ' + filteredDivOrders[row].id + '     BC TransId: ' + filteredDivOrders[row].bcTransId);
                    // const url = this.blockchainExplorerUrl + '/id=DivisionOrder_' + filteredDivOrders[row].id;
                    var url = _this.blockchainExplorerUrl;
                    window.open(url);
                }
            }
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.EnterCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColCount; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainColumn) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridHighlight);
                    }
                }
            }
            // console.log('Row Enter Cell: ' + row);
            _this.sheet.resumePaint();
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Un-highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColCount; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainColumn) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridBackground);
                    }
                }
            }
            _this.sheet.getCell(row, -1)
                .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            _this.sheet.getCell(row, -1)
                .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            // console.log('Row Leave Cell: ' + row);
            _this.sheet.resumePaint();
        });
        this.sheet.autoGenerateColumns = true;
        this.sheet.setDataSource(filteredDivOrders);
        this.sheet.bindColumns([
            ownerIdColInfo,
            ownerNameColInfo,
            ownerPercentColInfo,
            doTermStartColInfo,
            doTermEndColInfo,
            doRoyaltyIntColInfo,
            doBCImageColInfo,
            doBCTransIdColInfo,
            doWorkingIntColInfo,
            doDeductionColInfo,
            doStatusColInfo,
            doActionColInfo
        ]);
        this.sheet.setColumnCount(this.sheet.getColumnCount());
        // Highlight first row except blockchain graphic cell
        for (var col = 0; col < this.gridColCount; col++) {
            if (col !== this.blockChainColumn) {
                this.sheet.getCell(0, col).backColor(environment.gridHighlight);
            }
        }
        this.sheet.resumePaint();
    };
    DivisionorderListComponent.prototype.workbookInit = function (args) {
        console.log("Workbook Init", { args: args });
        this.spread = args.spread;
        this.sheet = this.spread.getActiveSheet();
        this.sheetWidth = this.sheet.getViewportWidth(1);
        console.log('SheetWidth: ', this.sheetWidth);
    };
    return DivisionorderListComponent;
}());
export { DivisionorderListComponent };
