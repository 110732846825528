/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./production.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../ticker/ticker.component.ngfactory";
import * as i3 from "../ticker/ticker.component";
import * as i4 from "../messages/messages.component.ngfactory";
import * as i5 from "../messages/messages.component";
import * as i6 from "./production-content/production-content.component.ngfactory";
import * as i7 from "@angular/common";
import * as i8 from "./production-content/production-content.component";
import * as i9 from "@angular/forms";
import * as i10 from "../production.service";
import * as i11 from "../lookup.service";
import * as i12 from "../messages.service";
import * as i13 from "../validator.service";
import * as i14 from "@angular/router";
import * as i15 from "./production-add/production-add.component.ngfactory";
import * as i16 from "./production-add/production-add.component";
import * as i17 from "./production-upload/production-upload.component.ngfactory";
import * as i18 from "./production-upload/production-upload.component";
import * as i19 from "../auth.service";
import * as i20 from "./production.component";
var styles_ProductionComponent = [i0.styles];
var RenderType_ProductionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProductionComponent, data: {} });
export { RenderType_ProductionComponent as RenderType_ProductionComponent };
export function View_ProductionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-ticker", [], null, null, null, i2.View_TickerComponent_0, i2.RenderType_TickerComponent)), i1.ɵdid(1, 114688, null, 0, i3.TickerComponent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-messages", [], null, null, null, i4.View_MessagesComponent_0, i4.RenderType_MessagesComponent)), i1.ɵdid(3, 114688, null, 0, i5.MessagesComponent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "app-production-content", [], null, [[null, "displayAddProductionChange"], [null, "displayUploadProductionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("displayAddProductionChange" === en)) {
        var pd_0 = ((_co.displayAddProduction = $event) !== false);
        ad = (pd_0 && ad);
    } if (("displayUploadProductionChange" === en)) {
        var pd_1 = ((_co.displayUploadProduction = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ProductionContentComponent_0, i6.RenderType_ProductionContentComponent)), i1.ɵprd(512, null, i7.DatePipe, i7.DatePipe, [i1.LOCALE_ID]), i1.ɵdid(6, 114688, null, 0, i8.ProductionContentComponent, [i9.FormBuilder, i10.ProductionService, i11.LookupService, i12.MessagesService, i13.ValidatorService, i7.DatePipe, i7.Location, i14.Router], { displayAddProduction: [0, "displayAddProduction"], displayUploadProduction: [1, "displayUploadProduction"] }, { displayAddProductionChange: "displayAddProductionChange", displayUploadProductionChange: "displayUploadProductionChange" }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-production-add", [], null, [[null, "displayAddProductionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("displayAddProductionChange" === en)) {
        var pd_0 = ((_co.displayAddProduction = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i15.View_ProductionAddComponent_0, i15.RenderType_ProductionAddComponent)), i1.ɵdid(8, 114688, null, 0, i16.ProductionAddComponent, [], { displayAddProduction: [0, "displayAddProduction"] }, { displayAddProductionChange: "displayAddProductionChange" }), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-production-upload", [], null, [[null, "displayUploadProductionChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("displayUploadProductionChange" === en)) {
        var pd_0 = ((_co.displayUploadProduction = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_ProductionUploadComponent_0, i17.RenderType_ProductionUploadComponent)), i1.ɵdid(10, 245760, null, 0, i18.ProductionUploadComponent, [i10.ProductionService, i12.MessagesService, i19.AuthService], { displayUploadProduction: [0, "displayUploadProduction"] }, { displayUploadProductionChange: "displayUploadProductionChange" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); _ck(_v, 3, 0); var currVal_0 = _co.displayAddProduction; var currVal_1 = _co.displayUploadProduction; _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = _co.displayAddProduction; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.displayUploadProduction; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_ProductionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-production", [], null, null, null, View_ProductionComponent_0, RenderType_ProductionComponent)), i1.ɵdid(1, 114688, null, 0, i20.ProductionComponent, [i14.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProductionComponentNgFactory = i1.ɵccf("app-production", i20.ProductionComponent, View_ProductionComponent_Host_0, {}, {}, []);
export { ProductionComponentNgFactory as ProductionComponentNgFactory };
