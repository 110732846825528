<app-ticker *ngIf="tickerDisplayFlag"></app-ticker>
<app-dashboard-search></app-dashboard-search>
<app-dashboard-notifications *ngIf="notificationDisplayFlag"></app-dashboard-notifications>
<div id="content">
  <div class="page_container">
    <div id="content_inner">
      <app-dashboard-quicklinks></app-dashboard-quicklinks>
      <app-dashboard-widgets></app-dashboard-widgets>
    </div>
  </div>
</div>
