import * as tslib_1 from "tslib";
import { ElementRef, OnInit, OnDestroy } from '@angular/core';
import { DatePipe, formatDate, Location } from '@angular/common';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import * as GC from '@grapecity/spread-sheets';
import { environment } from 'src/environments/environment';
import { LookupService } from 'src/app/lookup.service';
import { Payment } from 'src/app/model/payment';
import { PaymentsSummary } from 'src/app/model/payments_summary';
import { PaymentSearch } from 'src/app/model/payment_search';
import { MessagesService } from 'src/app/messages.service';
import { dynamicSort, toggleSort } from 'src/app/shared/dynamic-sort';
import { Bank } from 'src/app/model/bank';
import { options, setValues } from './donutchart';
import * as Highcharts from 'highcharts';
import { map } from 'rxjs/operators';
import { BankTrustManager } from 'src/app/model/bank-trust-manager';
import * as FileSaver from 'file-saver';
import { OperatorPaymentsService } from '../../operator-payments.service';
var clone = function (obj) { return JSON.parse(JSON.stringify(obj)); };
var ɵ0 = clone;
var paymentStatusPending = 1;
var paymentStatusIssued = 2;
var paymentStatusConfirmed = 3;
var OperatorPaymentsContentComponent = /** @class */ (function () {
    function OperatorPaymentsContentComponent(fb, lookupService, paymentsService, messagesService, datePipe, location, router) {
        this.fb = fb;
        this.lookupService = lookupService;
        this.paymentsService = paymentsService;
        this.messagesService = messagesService;
        this.datePipe = datePipe;
        this.location = location;
        this.router = router;
        this.paymentSearchForm = this.fb.group({
            searchStr: [''],
            bank: ['']
        });
        this.blockchainExplorerUrl = "" + environment.blockchainExplorerUrl;
        this.sheet = null;
        this.msg = this.messagesService.messages;
        this.tabStripVisible = false;
        this.spreadBackColor = 'white';
        this.sheetName = 'Payments List';
        this.hostStyle = {
            height: '100%',
            width: '100%'
        };
        this.autoGenerateColumns = false;
        this.bankColumn = 0;
        this.propertyNoColumn = 1;
        this.propertyNameColumn = 2;
        this.divOrderNumberColumn = 3;
        this.ownerNameColumn = 4;
        this.saleDateColumn = 5;
        this.productColumn = 6;
        this.priceColumn = 7;
        this.btuColumn = 8;
        this.volumeColumn = 9;
        this.grossColumn = 10;
        this.ownerNetValueColumn = 11;
        this.deductionsColumn = 12;
        this.deductionTypeColumn = 13;
        this.interestTypeColumn = 14;
        this.decimalInterestColumn = 15;
        this.ownerVolumeColumn = 16;
        this.paymentDateColumn = 17;
        this.statusColumn = 18;
        this.blockChainColumn = 19;
        this.blockChainTransIdColumn = 20;
        this.gridColumnCount = 21;
        this.maxStartDate = false;
        this.maxEndDate = true;
        // 0 = unsorted, 1 = sorted Ascending, -1 = sorted Descending
        this.sortOrderBank = 0;
        this.sortOrderPropertyNo = 0;
        this.sortOrderPropertyName = 0;
        this.sortOrderDivOrderNumber = 0;
        this.sortOrderOwnerName = 0;
        this.sortOrderSaleDate = 0;
        this.sortOrderProduct = 0;
        this.sortOrderPrice = 0;
        this.sortOrderBtu = 0;
        this.sortOrderVolume = 0;
        this.sortOrderGross = 0;
        this.sortOrderSeverence = 0;
        this.sortOrderOwnerNetValue = 0;
        this.sortOrderDeductions = 0;
        this.sortOrderInterestType = 0;
        this.sortOrderDecimalInterest = 0;
        this.sortOrderRoyaltyInterest = 0;
        this.sortOrderOwnerVolume = 0;
        this.sortOrderPaymentDate = 0;
        this.sortOrderStatus = 0;
        this.sortOrderBlockChainTransId = 0;
        this.displayDeductionDetails = false;
        this.expandIndex1 = -1;
        this.expandIndex2 = -1;
        this.enableToExport = false;
        this.lastUpdated = new Date();
    }
    OperatorPaymentsContentComponent.prototype.ngOnInit = function () {
        var _this = this;
        // initialise Search criteria
        var today = new Date();
        this.paymentSearch = new PaymentSearch();
        this.paymentSearch.paymentsType = 0;
        this.paymentSearch.paymentPeriodStep = 1;
        this.paymentSearch.startDateMonth = 1;
        this.paymentSearch.startDateYear = today.getFullYear();
        this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
        this.paymentSearch.endDateMonth = today.getMonth() + 1;
        this.paymentSearch.endDateYear = today.getFullYear();
        this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);
        this.paymentSearch.searchStr = [];
        this.paymentSearch.bankId = '';
        this.paymentsSummary = new PaymentsSummary();
        this.paymentsSummary.confirmedValue = 0.0;
        this.paymentsSummary.pendingValue = 0.0;
        this.paymentsSummary.issuedValue = 0.0;
        this.initChart();
        Promise.all([
            this.getBanks(),
            this.getPaymentsByYear(today.getFullYear())
        ]).then(function (values) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getPayments()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getPaymentsDeductions()];
                    case 2:
                        _a.sent();
                        this.enableToExport = true;
                        // put a small delay on getting payments deductions so that all payments are loaded first
                        // setTimeout(() => this.getPaymentsDeductions(), 3000);
                        console.log('ngInit - complete: ' + new Date().toISOString());
                        return [2 /*return*/];
                }
            });
        }); });
        this.lastUpdated.setDate(today.getDate() - 3);
    };
    OperatorPaymentsContentComponent.prototype.ngOnDestroy = function () {
        this.sheet = null;
        if (this.spread) {
            this.spread.destroy();
            this.spread = undefined;
        }
    };
    OperatorPaymentsContentComponent.prototype.initChart = function () {
        this.options = options;
        this.chart = Highcharts.chart('chart_donut', this.options);
    };
    OperatorPaymentsContentComponent.prototype.getBanks = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, (this.lookupService.getBanks())];
                    case 1:
                        _a.banks = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    OperatorPaymentsContentComponent.prototype.getPaymentsByYear = function (year) {
        var _this = this;
        // Get the operatorId from the user - only want this operators payments
        var user = JSON.parse(localStorage.getItem('user'));
        var operatorId = user.operator.id;
        console.log('Start getPaymentsByYear: ' + new Date().toISOString());
        return this.paymentsService.getOperatorPaymentsByYear(year).pipe(map(function (payments) {
            console.log('Back from API getPaymentsByYear: ' + new Date().toISOString());
            // console.log({ payments });
            var tempPayments = JSON.parse(JSON.stringify(payments));
            // console.log('pre-transformation', { tempPayments });
            tempPayments = _this.setPaymentValues(tempPayments);
            // console.log('post transformation', { tempPayments });
            _this.initialiseGrid();
            _this.sheet.resumePaint();
            _this.payments = tempPayments;
            _this.allPayments = tempPayments;
            console.log(_this.allPayments[0]);
            // Get Payment Summary
            _this.paymentsSummary = new PaymentsSummary();
            _this.paymentsSummary.issuedValue = 0;
            _this.paymentsSummary.confirmedValue = 0;
            _this.paymentsSummary.pendingValue = 0;
            _this.payments.forEach(function (paymentObj, rowIndex) {
                switch (paymentObj.paymentStatus.id) {
                    case paymentStatusPending:
                        _this.paymentsSummary.pendingValue = _this.paymentsSummary.pendingValue + paymentObj.ownerNetValue;
                        break;
                    case paymentStatusIssued:
                        _this.paymentsSummary.issuedValue = _this.paymentsSummary.issuedValue + paymentObj.ownerNetValue;
                        break;
                    default:
                        _this.paymentsSummary.confirmedValue = _this.paymentsSummary.confirmedValue + paymentObj.ownerNetValue;
                }
            });
            // console.dir(this.paymentsSummary);
            setValues(_this.chart, _this.paymentsSummary.confirmedValue, _this.paymentsSummary.pendingValue, _this.paymentsSummary.issuedValue);
            console.log('getPaymentsByYear - complete: ' + new Date().toISOString() + '  No. Records: ' + _this.allPayments.length);
            _this.filter();
        }, function (err) {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    _this.router.navigate(['/login']);
                }
            }
        })).toPromise();
    };
    OperatorPaymentsContentComponent.prototype.getPayments = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, operatorId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                user = JSON.parse(localStorage.getItem('user'));
                operatorId = user.operator.id;
                console.log('Start getPayments: ' + new Date().toISOString());
                return [2 /*return*/, this.paymentsService.getOperatorPayments().pipe(map(function (payments) {
                        console.log('Back from API getPayments: ' + new Date().toISOString());
                        // console.log({ payments });
                        var tempPayments = JSON.parse(JSON.stringify(payments));
                        // console.log('pre-transformation', { tempPayments });
                        tempPayments = _this.setPaymentValues(tempPayments);
                        // console.log('post transformation', { tempPayments });
                        _this.allPayments = tempPayments;
                        console.log('getPayments - complete: ' + new Date().toISOString() + '  No. Records: ' + _this.allPayments.length);
                    }, function (err) {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                _this.router.navigate(['/login']);
                            }
                        }
                    })).toPromise()];
            });
        });
    };
    OperatorPaymentsContentComponent.prototype.getPaymentsTotal = function () {
        return this.paymentsSummary.pendingValue + this.paymentsSummary.issuedValue + this.paymentsSummary.confirmedValue;
    };
    OperatorPaymentsContentComponent.prototype.getPaymentsDeductions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, operatorId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                user = JSON.parse(localStorage.getItem('user'));
                operatorId = user.operator.id;
                console.log('Call getOperatorPaymentsDeductions: ' + new Date().toISOString());
                return [2 /*return*/, this.paymentsService.getOperatorPaymentsDeductions(operatorId).pipe(map(function (deductions) {
                        // console.log({ deductions });
                        var e_1, _a;
                        var tempDeductions = JSON.parse(JSON.stringify(deductions));
                        try {
                            for (var tempDeductions_1 = tslib_1.__values(tempDeductions), tempDeductions_1_1 = tempDeductions_1.next(); !tempDeductions_1_1.done; tempDeductions_1_1 = tempDeductions_1.next()) {
                                var deduction = tempDeductions_1_1.value;
                                _this.addPaymentDeduction(deduction);
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (tempDeductions_1_1 && !tempDeductions_1_1.done && (_a = tempDeductions_1.return)) _a.call(tempDeductions_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        _this.payments = _this.allPayments;
                        _this.filter();
                        // console.log(this.allPayments);
                        console.log('Back from getPaymentsDeductions: ' + new Date().toISOString());
                    }, function (err) {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                _this.router.navigate(['/login']);
                            }
                        }
                    })).toPromise()];
            });
        });
    };
    OperatorPaymentsContentComponent.prototype.getPaymentsDeductionsIndividual = function (id) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, operatorId;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                user = JSON.parse(localStorage.getItem('user'));
                operatorId = user.operator.id;
                return [2 /*return*/, this.paymentsService.getOperatorPaymentsDeductionsIndividual(operatorId, id).pipe(map(function (deductions) {
                        var e_2, _a;
                        console.log({ deductions: deductions });
                        var tempDeductions = JSON.parse(JSON.stringify(deductions));
                        try {
                            for (var tempDeductions_2 = tslib_1.__values(tempDeductions), tempDeductions_2_1 = tempDeductions_2.next(); !tempDeductions_2_1.done; tempDeductions_2_1 = tempDeductions_2.next()) {
                                var deduction = tempDeductions_2_1.value;
                                _this.addPaymentDeduction(deduction);
                            }
                        }
                        catch (e_2_1) { e_2 = { error: e_2_1 }; }
                        finally {
                            try {
                                if (tempDeductions_2_1 && !tempDeductions_2_1.done && (_a = tempDeductions_2.return)) _a.call(tempDeductions_2);
                            }
                            finally { if (e_2) throw e_2.error; }
                        }
                        return tempDeductions;
                    }, function (err) {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status === 401) {
                                _this.router.navigate(['/login']);
                            }
                        }
                    })).toPromise()];
            });
        });
    };
    OperatorPaymentsContentComponent.prototype.addPaymentDeduction = function (deduction) {
        var e_3, _a;
        try {
            for (var _b = tslib_1.__values(this.allPayments), _c = _b.next(); !_c.done; _c = _b.next()) {
                var payment = _c.value;
                if (payment.id === deduction.id) {
                    if (payment.paymentDeductions == null) {
                        payment.paymentDeductions = [];
                    }
                    payment.paymentDeductions.push(deduction);
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    OperatorPaymentsContentComponent.prototype.transformDate = function (date) {
        return this.datePipe.transform(date, 'yyyy-MM-dd');
    };
    OperatorPaymentsContentComponent.prototype.setPaymentValues = function (filteredPayment) {
        var _this = this;
        filteredPayment.forEach(function (paymentRec) {
            // console.log('Payment: ', { paymentRec });
            if (paymentRec.paymentDate != null) {
                paymentRec.propertyId = paymentRec.divisionOrder.lease.title.property.id;
                paymentRec.propertyNo = paymentRec.divisionOrder.lease.title.property.propertyNo;
                paymentRec.propertyName = paymentRec.divisionOrder.lease.title.property.name;
                paymentRec.divOrderNumber = paymentRec.divisionOrder.id;
                paymentRec.ownerName = paymentRec.divisionOrder.lease.title.owner.fname + ' ' + paymentRec.divisionOrder.lease.title.owner.lname;
                paymentRec.bankTrustManager = new BankTrustManager();
                paymentRec.bankTrustManager.bank = new Bank();
                paymentRec.bankTrustManager.bank.id = paymentRec.divisionOrder.bankTrustManager.bank.id;
                paymentRec.bankTrustManager.bank.name = paymentRec.divisionOrder.bankTrustManager.bank.name;
                paymentRec.bankTrustManager.bank.participating = paymentRec.divisionOrder.bankTrustManager.bank.participating;
                paymentRec.bankName = paymentRec.divisionOrder.bankTrustManager.bank.name;
                paymentRec.saleDate = _this.messagesService.monthString(paymentRec.sale.month) + '-' + paymentRec.sale.year;
                paymentRec.saleDateSort = paymentRec.sale.year + '-' + paymentRec.sale.month;
                paymentRec.productId = paymentRec.sale.product.id;
                paymentRec.productName = paymentRec.sale.product.name;
                paymentRec.price = paymentRec.sale.unitPrice.toFixed(2);
                if (paymentRec.sale.btu < 0.001) {
                    paymentRec.btuStr = '';
                }
                else {
                    paymentRec.btuStr = paymentRec.sale.btu.toFixed(2);
                }
                paymentRec.btuValue = paymentRec.sale.btu;
                paymentRec.volume = paymentRec.sale.salesVolume.toFixed(2);
                paymentRec.volumeValue = paymentRec.sale.salesVolume;
                paymentRec.gross = paymentRec.sale.grsValue.toFixed(2);
                paymentRec.grossValue = paymentRec.sale.grsValue;
                paymentRec.ownerNetValueStr = paymentRec.ownerNetValue.toFixed(2);
                paymentRec.deductions = paymentRec.totalDeductions.toFixed(2);
                paymentRec.deductionsValue = paymentRec.totalDeductions;
                paymentRec.interestTypeId = paymentRec.divisionOrder.interestType.id;
                paymentRec.interestTypeAbbr = paymentRec.divisionOrder.interestType.abbr;
                paymentRec.decimalInterest = paymentRec.divisionOrder.decimalInterest.toFixed(6);
                paymentRec.decimalInterestValue = paymentRec.divisionOrder.decimalInterest;
                paymentRec.ownerVolumeStr = paymentRec.ownerVolume.toFixed(4);
                paymentRec.paymentDateStr = formatDate(paymentRec.paymentDate, 'MM/dd/yyyy', 'en');
                paymentRec.paymentDateSort = formatDate(paymentRec.paymentDate, 'yyyy-MM-dd', 'en');
                paymentRec.statusName = paymentRec.paymentStatus.name;
            }
        });
        return filteredPayment;
    };
    OperatorPaymentsContentComponent.prototype.createGridEvents = function () {
        var _this = this;
        // Bind click-event of Blockchain cell
        this.spread.bind(GC.Spread.Sheets.Events.CellClick, function (e, args) {
            var sheet = args.sheet;
            var row = args.row;
            var col = args.col;
            var cell = _this.sheet.getCell(row, col);
            // If Grid header clicked
            if (args.sheetArea === GC.Spread.Sheets.SheetArea.colHeader) {
                // Sort Ascending/Descending by the column clicked
                if (col === _this.bankColumn) {
                    _this.sortOrderBank = toggleSort(_this.sortOrderBank);
                    _this.payments.sort(dynamicSort('bankName', _this.sortOrderBank));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.propertyNoColumn) {
                    _this.sortOrderPropertyNo = toggleSort(_this.sortOrderPropertyNo);
                    _this.payments.sort(dynamicSort('propertyNo', _this.sortOrderPropertyNo));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.propertyNameColumn) {
                    _this.sortOrderPropertyName = toggleSort(_this.sortOrderPropertyName);
                    _this.payments.sort(dynamicSort('propertyName', _this.sortOrderPropertyName));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.divOrderNumberColumn) {
                    _this.sortOrderDivOrderNumber = toggleSort(_this.sortOrderDivOrderNumber);
                    _this.payments.sort(dynamicSort('divOrderNumber', _this.sortOrderDivOrderNumber));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.ownerNameColumn) {
                    _this.sortOrderOwnerName = toggleSort(_this.sortOrderOwnerName);
                    _this.payments.sort(dynamicSort('ownerName', _this.sortOrderOwnerName));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.saleDateColumn) {
                    _this.sortOrderSaleDate = toggleSort(_this.sortOrderSaleDate);
                    _this.payments.sort(dynamicSort('saleDateSort', _this.sortOrderSaleDate));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.productColumn) {
                    _this.sortOrderProduct = toggleSort(_this.sortOrderProduct);
                    _this.payments.sort(dynamicSort('productName', _this.sortOrderProduct));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.priceColumn) {
                    _this.sortOrderPrice = toggleSort(_this.sortOrderPrice);
                    _this.payments.sort(dynamicSort('price', _this.sortOrderPrice));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.btuColumn) {
                    _this.sortOrderBtu = toggleSort(_this.sortOrderBtu);
                    _this.payments.sort(dynamicSort('btuValue', _this.sortOrderBtu));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.volumeColumn) {
                    _this.sortOrderVolume = toggleSort(_this.sortOrderVolume);
                    _this.payments.sort(dynamicSort('volumeValue', _this.sortOrderVolume));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.grossColumn) {
                    _this.sortOrderGross = toggleSort(_this.sortOrderGross);
                    _this.payments.sort(dynamicSort('grossValue', _this.sortOrderGross));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.ownerNetValueColumn) {
                    _this.sortOrderOwnerNetValue = toggleSort(_this.sortOrderOwnerNetValue);
                    _this.payments.sort(dynamicSort('ownerNetValue', _this.sortOrderOwnerNetValue));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.deductionsColumn) {
                    _this.sortOrderDeductions = toggleSort(_this.sortOrderDeductions);
                    _this.payments.sort(dynamicSort('deductionsValue', _this.sortOrderDeductions));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.interestTypeColumn) {
                    _this.sortOrderInterestType = toggleSort(_this.sortOrderInterestType);
                    _this.payments.sort(dynamicSort('interestType', _this.sortOrderInterestType));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.decimalInterestColumn) {
                    _this.sortOrderDecimalInterest = toggleSort(_this.sortOrderDecimalInterest);
                    _this.payments.sort(dynamicSort('decimalInterestValue', _this.sortOrderDecimalInterest));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.ownerVolumeColumn) {
                    _this.sortOrderOwnerVolume = toggleSort(_this.sortOrderOwnerVolume);
                    _this.payments.sort(dynamicSort('ownerVolume', _this.sortOrderOwnerVolume));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.paymentDateColumn) {
                    _this.sortOrderPaymentDate = toggleSort(_this.sortOrderPaymentDate);
                    _this.payments.sort(dynamicSort('paymentDateSort', _this.sortOrderPaymentDate));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.statusColumn) {
                    _this.sortOrderStatus = toggleSort(_this.sortOrderStatus);
                    _this.payments.sort(dynamicSort('statusName', _this.sortOrderStatus));
                    _this.applyDataBinding(_this.payments);
                }
                else if (col === _this.blockChainTransIdColumn) {
                    _this.sortOrderBlockChainTransId = toggleSort(_this.sortOrderBlockChainTransId);
                    _this.payments.sort(dynamicSort('bcTransId', _this.sortOrderBlockChainTransId));
                    _this.applyDataBinding(_this.payments);
                }
            }
            else if (col === _this.blockChainColumn || col === _this.blockChainTransIdColumn) {
                if (_this.payments[row].bcTransId != null) {
                    console.log('Cell: (' + row + ', ' + col
                        + ') PaymentId: ' + _this.payments[row].id + '     BC TransId: ' + _this.payments[row].bcTransId);
                    var url = _this.blockchainExplorerUrl;
                    window.open(url);
                }
            }
            else if (col === _this.deductionsColumn) {
                // console.log('Row: ' + row + '; Col: ' + col);
                _this.onClickDeduction(row);
            }
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.EnterCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColumnCount - 1; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainColumn) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridHighlight);
                    }
                }
            }
            // console.log('Row Enter Cell: ' + row);
            _this.sheet.resumePaint();
        });
        // Because I am setting the background color at cell level all changes must be made at cell level also
        this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, function (e, args) {
            var row = args.row;
            _this.sheet.suspendPaint();
            // Un-highlight all cells of the row except changed cells
            for (var col = 0; col < _this.gridColumnCount - 1; col++) {
                var cell = _this.sheet.getCell(row, col);
                if (col !== _this.blockChainColumn) {
                    var cellBackColor = cell.backColor();
                    if (cellBackColor !== environment.gridCellChanged) {
                        _this.sheet.getCell(row, col).backColor(environment.gridBackground);
                    }
                }
            }
            _this.sheet.getCell(row, -1)
                .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            _this.sheet.getCell(row, -1)
                .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
            // console.log('Row Leave Cell: ' + row);
            _this.sheet.resumePaint();
        });
    };
    OperatorPaymentsContentComponent.prototype.initialiseGrid = function () {
        // Initialise Grid columns, column bindings and column dropdowns, datepickers and buttons
        // And grid events that are NOT data specific
        this.sheet.suspendPaint();
        // Define columns
        var bankNameColInfo = { name: 'bankName', displayName: 'Bank', size: 120 };
        var propertyNoColInfo = { name: 'propertyNo', displayName: 'Property #', size: 120 };
        var propertyNameColInfo = { name: 'propertyName', displayName: 'Property Name', size: 220 };
        var divOrderNoColInfo = { name: 'divOrderNumber', displayName: 'DOI', size: 50 };
        var ownerNameColInfo = { name: 'ownerName', displayName: 'Owner Name', size: 120 };
        var saleDateColInfo = { name: 'saleDate', displayName: 'Sale Date', size: 80 };
        var productColInfo = { name: 'productName', displayName: 'Product', size: 80 };
        var priceColInfo = { name: 'price', displayName: 'Price', size: 80 };
        var btuColInfo = { name: 'btuStr', displayName: 'BTU', size: 80 };
        var volumeColInfo = { name: 'volume', displayName: 'Volume', size: 80 };
        var grossColInfo = { name: 'gross', displayName: 'Gross', size: 80 };
        var ownerNetValueColInfo = { name: 'ownerNetValueStr', displayName: 'Owner Net Value', size: 120 };
        var deductionsColInfo = { name: 'deductions', displayName: 'Deductions', size: 80 };
        // This column is initially hidden
        var salesDeductionTypesColInfo = { name: 'deductionType', displayName: 'Deduction Types', size: 200 };
        var interestTypeColInfo = { name: 'interestTypeAbbr', displayName: 'Int. Type', size: 80 };
        var decimalInterestColInfo = { name: 'decimalInterest', displayName: 'Decimal', size: 80 };
        var royaltyInterestColInfo = { name: 'royaltyInterest', displayName: 'Royalty', size: 80 };
        var ownerVolumeColInfo = { name: 'ownerVolume', displayName: 'Owner Vol.', size: 80 };
        var paymentDateColInfo = { name: 'paymentDateStr', displayName: 'Payment Date', size: 100 };
        var statusColInfo = { name: 'statusName', displayName: 'Status', size: 80 };
        var prodBCTransImgColInfo = { name: '', displayName: '#', size: 30 };
        var prodBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 180 };
        this.sheet.autoGenerateColumns = false;
        this.sheet.setDataSource(this.payments);
        this.sheet.bindColumn(this.bankColumn, bankNameColInfo);
        this.sheet.bindColumn(this.propertyNoColumn, propertyNoColInfo);
        this.sheet.bindColumn(this.propertyNameColumn, propertyNameColInfo);
        this.sheet.bindColumn(this.divOrderNumberColumn, divOrderNoColInfo);
        this.sheet.bindColumn(this.ownerNameColumn, ownerNameColInfo);
        this.sheet.bindColumn(this.saleDateColumn, saleDateColInfo);
        this.sheet.bindColumn(this.productColumn, productColInfo);
        this.sheet.bindColumn(this.priceColumn, priceColInfo);
        this.sheet.bindColumn(this.btuColumn, btuColInfo);
        this.sheet.bindColumn(this.volumeColumn, volumeColInfo);
        this.sheet.bindColumn(this.grossColumn, grossColInfo);
        this.sheet.bindColumn(this.ownerNetValueColumn, ownerNetValueColInfo);
        this.sheet.bindColumn(this.deductionsColumn, deductionsColInfo);
        this.sheet.bindColumn(this.deductionTypeColumn, salesDeductionTypesColInfo);
        this.sheet.bindColumn(this.interestTypeColumn, interestTypeColInfo);
        this.sheet.bindColumn(this.decimalInterestColumn, decimalInterestColInfo);
        this.sheet.bindColumn(this.ownerVolumeColumn, ownerVolumeColInfo);
        this.sheet.bindColumn(this.paymentDateColumn, paymentDateColInfo);
        this.sheet.bindColumn(this.statusColumn, statusColInfo);
        this.sheet.bindColumn(this.blockChainColumn, prodBCTransImgColInfo);
        this.sheet.bindColumn(this.blockChainTransIdColumn, prodBCTransIdColInfo);
        this.sheet.setColumnCount(this.gridColumnCount, GC.Spread.Sheets.SheetArea.viewport);
        // set cell alignment
        this.sheet.getCell(-1, this.propertyNoColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.divOrderNumberColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.ownerNameColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.left);
        this.sheet.getCell(-1, this.saleDateColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.productColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.priceColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.btuColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.volumeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.grossColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.ownerNetValueColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.deductionsColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.interestTypeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        this.sheet.getCell(-1, this.decimalInterestColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.ownerVolumeColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
        this.sheet.getCell(-1, this.statusColumn).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
        // Hide the Deductions Type column
        this.showDeductionType(false);
        // Format currency columns
        this.sheet.getCell(-1, this.grossColumn).formatter('$ ##,###,###0.00');
        this.sheet.getCell(-1, this.ownerNetValueColumn).formatter('$ ##,###,###0.00');
        this.sheet.getCell(-1, this.deductionsColumn).formatter('$ ##,###,###0.00');
        this.sheet.getCell(-1, this.deductionTypeColumn).formatter('$ ##,###,###0.00');
        this.sheet.getCell(-1, this.ownerVolumeColumn).formatter('$ ##,###,###0.00');
        // Lock/Readonly all columns
        this.sheet.suspendPaint();
        this.sheet.options.isProtected = true;
        this.sheet.options.protectionOptions = {
            allowSelectUnlockedCells: true,
            allowSelectLockedCells: true
        };
        // Unlock from columns 6 for 6 columns (columns 6, 7, 8, 9, 10, 11) - [Product - Temperature]
        // this.sheet.getRange(-1, 0, -1, this.gridColumnCount - 1).locked(false);
        // Unlock from columns 13 for 1 columns (columns 13) - [Reason]
        // this.sheet.getRange(-1, 13, -1, 1).locked(false);
        this.createGridEvents();
        this.sheet.resumePaint();
    };
    OperatorPaymentsContentComponent.prototype.showDeductionType = function (hide) {
        this.sheet.getRange(-1, this.deductionTypeColumn, -1, 1).visible(hide);
    };
    OperatorPaymentsContentComponent.prototype.applyDataBinding = function (filteredPayment) {
        // const dataSource = new GC.Spread.Sheets.Bindings.CellBindingSource(filteredProperties);
        var _this = this;
        // console.log('Start data binding');
        if (this.sheet) {
            this.sheet.suspendPaint();
            this.sheet.setDataSource(filteredPayment);
            // console.log('Row Binding - start');
            filteredPayment.forEach(function (rowPaymentObject, rowIndex) {
                // Remove vertical grid-lines
                _this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));
                // Clear text in rowHeaders
                // this.sheet.setValue(rowIndex, 0, '', GC.Spread.Sheets.SheetArea.rowHeader);
                var cell = _this.sheet.getCell(rowIndex, _this.blockChainColumn);
                var imgBlockChain = null;
                if (filteredPayment[rowIndex].bcTransId != null) {
                    imgBlockChain = './assets/images/blockchain_cell@2x.png';
                }
                cell.text('');
                cell.backgroundImage(imgBlockChain);
            });
            // console.log('Row Binding - complete');
            // Highlight first row as selected - except blockchain graphic cell
            for (var col = 0; col < this.gridColumnCount - 1; col++) {
                if (col !== this.blockChainColumn) {
                    this.sheet.getCell(0, col).backColor(environment.gridHighlight);
                }
            }
            var lastRow = this.sheet.getRowCount();
            this.sheet.resumePaint();
            var today = new Date();
            this.maxStartDate =
                this.paymentSearch.startDateMonth === today.getMonth() + 1 &&
                    this.paymentSearch.startDateYear === today.getFullYear();
            this.maxEndDate =
                this.paymentSearch.endDateMonth === today.getMonth() + 1 &&
                    this.paymentSearch.endDateYear === today.getFullYear();
            // console.log('Binding Complete');
        }
    };
    OperatorPaymentsContentComponent.prototype.getLastDayOfMonth = function (year, month) {
        // get first day of month
        var date = new Date(year, month, 1);
        // Add 1 month and subtract 1 day
        date = new Date(date.setMonth(date.getMonth() + 1) - 1);
        return date;
    };
    OperatorPaymentsContentComponent.prototype.filter = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var today, confirmed, issued, pending, startDate, endDate;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                // Filters the "allPayment" based on selected filters from the screen
                this.payments = [];
                today = this.transformDate(new Date());
                confirmed = 0.0;
                issued = 0.0;
                pending = 0.0;
                startDate = new Date(this.paymentSearch.startDateYear, this.paymentSearch.startDateMonth - 1, 1);
                endDate = this.getLastDayOfMonth(this.paymentSearch.endDateYear, this.paymentSearch.endDateMonth - 1);
                console.log('Start Date: ' + this.transformDate(startDate) + '   End Date: ' + this.transformDate(endDate));
                this.allPayments.forEach(function (paymentObj, rowIndex) {
                    // console.log(paymentObj);
                    // If paymentObj is null, undefined or empty - ignore
                    if (paymentObj != null && Object.keys(paymentObj).length !== 0) {
                        var includeProd_1 = true;
                        // console.log('Payment date: ' + this.transformDate(paymentObj.paymentDate) + '   Start: ' + this.transformDate(startDate)
                        //   + '   End Date: ' + this.transformDate(endDate));
                        if (_this.transformDate(paymentObj.paymentDate) < _this.transformDate(startDate)) {
                            includeProd_1 = false;
                        }
                        if (includeProd_1 && _this.transformDate(paymentObj.paymentDate) > _this.transformDate(endDate)) {
                            includeProd_1 = false;
                        }
                        // console.log('this.paymentSearch.bankId: ' + this.paymentSearch.bankId);
                        if (_this.paymentSearch.bankId !== '') {
                            includeProd_1 = (paymentObj.bankTrustManager.bank.id === Number(_this.paymentSearch.bankId));
                        }
                        if (includeProd_1 && _this.paymentSearch.searchStr.length > 0) {
                            // Check property No, lease No, Property Name and Property Desc against any part of the search string
                            includeProd_1 = true; // Assume property to be included
                            _this.paymentSearch.searchStr.forEach(function (searchStr) {
                                includeProd_1 = includeProd_1
                                    && ((paymentObj.divisionOrder.lease.title.property.propertyNo.indexOf(searchStr) !== -1)
                                        || (paymentObj.divisionOrder.lease.title.property.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                        || (paymentObj.divisionOrder.lease.title.property.description.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                        || (paymentObj.divisionOrder.lease.title.property.county.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                        || (paymentObj.divisionOrder.lease.title.property.county.USState.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                        || (paymentObj.bankTrustManager.bank.name.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                        || (paymentObj.divisionOrder.lease.title.owner.fname.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1)
                                        || (paymentObj.divisionOrder.lease.title.owner.lname.toUpperCase().indexOf(searchStr.toUpperCase()) !== -1));
                            });
                        }
                        // console.log('Payment Search Type: ', this.paymentSearch.paymentsType);
                        if (includeProd_1 && _this.paymentSearch.paymentsType > 0) {
                            includeProd_1 = (paymentObj.paymentStatus.id === _this.paymentSearch.paymentsType);
                        }
                        // console.log('IncludePayment: ' + includeProd);
                        if (includeProd_1) {
                            _this.payments.push(paymentObj);
                            switch (paymentObj.paymentStatus.id) {
                                case paymentStatusPending:
                                    pending = pending + paymentObj.ownerNetValue;
                                    break;
                                case paymentStatusIssued:
                                    issued = issued + paymentObj.ownerNetValue;
                                    break;
                                default:
                                    confirmed = confirmed + paymentObj.ownerNetValue;
                            }
                        }
                    }
                });
                this.paymentsSummary.confirmedValue = confirmed;
                this.paymentsSummary.issuedValue = issued;
                this.paymentsSummary.pendingValue = pending;
                setValues(this.chart, this.paymentsSummary.confirmedValue, this.paymentsSummary.pendingValue, this.paymentsSummary.issuedValue);
                this.applyDataBinding(this.payments);
                return [2 /*return*/];
            });
        });
    };
    OperatorPaymentsContentComponent.prototype.doSearch = function () {
        var str = this.paymentSearchForm.get('searchStr').value;
        // console.log('Str: ' + str);
        this.paymentSearch.searchStr = str.split(' ');
        // console.log('searchStr: ', this.paymentSearch.searchStr);
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.onBankChange = function () {
        console.log('Bank changed: ' + this.paymentSearchForm.get('bank').value);
        this.paymentSearch.bankId = this.paymentSearchForm.get('bank').value;
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.btnAllPayments = function () {
        console.log('All Payment');
        this.paymentSearch.paymentsType = 0;
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.btnPendingPayments = function () {
        console.log('Pending');
        this.paymentSearch.paymentsType = 1;
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.btnIssuedPayments = function () {
        console.log('Issued');
        this.paymentSearch.paymentsType = 2;
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.btnConfirmedPayments = function () {
        console.log('Confirmed');
        this.paymentSearch.paymentsType = 3;
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.btnPaymentStepMonthly = function () {
        console.log('Monthly');
        this.paymentSearch.paymentPeriodStep = 1;
    };
    OperatorPaymentsContentComponent.prototype.btnPaymentStepQuarterly = function () {
        console.log('Quarterly');
        this.paymentSearch.paymentPeriodStep = 3;
        this.paymentSearch.startDateMonth = Math.floor((this.paymentSearch.startDateMonth - 1) / 3) * 3 + 1;
        this.paymentSearch.endDateMonth = Math.floor((this.paymentSearch.endDateMonth - 1) / 3) * 3 + 3;
        // If end month is after today's month
        var today = new Date();
        if (this.paymentSearch.endDateYear === today.getFullYear()) {
            while (this.paymentSearch.endDateMonth > today.getMonth() + 1) {
                this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth - 3;
            }
        }
        this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
        this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);
        this.maxStartDate = (this.paymentSearch.startDateMonth === today.getMonth() + 1
            && this.paymentSearch.startDateYear === today.getFullYear());
        this.maxEndDate = (this.paymentSearch.endDateMonth === today.getMonth() + 1 && this.paymentSearch.endDateYear === today.getFullYear());
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.btnPaymentStepAnnually = function () {
        console.log('Annually');
        this.paymentSearch.paymentPeriodStep = 12;
        this.paymentSearch.startDateMonth = 1;
        var today = new Date();
        if (this.paymentSearch.endDateYear === today.getFullYear()) {
            this.paymentSearch.endDateMonth = today.getMonth() + 1;
        }
        else {
            this.paymentSearch.endDateMonth = 12;
        }
        this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
        this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);
        this.maxStartDate = (this.paymentSearch.startDateMonth === today.getMonth() + 1
            && this.paymentSearch.startDateYear === today.getFullYear());
        this.maxEndDate = (this.paymentSearch.endDateMonth === today.getMonth() + 1 && this.paymentSearch.endDateYear === today.getFullYear());
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.dateStr = function (month, year) {
        return this.messagesService.monthString(month) + ' ' + year;
    };
    OperatorPaymentsContentComponent.prototype.onStartDateDown = function () {
        // console.log('Start Date Down: ' + this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear));
        if (this.paymentSearch.paymentPeriodStep === 1) {
            if (this.paymentSearch.startDateMonth === 1) {
                this.paymentSearch.startDateMonth = 12;
                this.paymentSearch.startDateYear = this.paymentSearch.startDateYear - 1;
            }
            else {
                this.paymentSearch.startDateMonth = this.paymentSearch.startDateMonth - 1;
            }
        }
        else if (this.paymentSearch.paymentPeriodStep === 3) {
            if (this.paymentSearch.startDateMonth === 1) {
                this.paymentSearch.startDateMonth = 10;
                this.paymentSearch.startDateYear = this.paymentSearch.startDateYear - 1;
            }
            else {
                this.paymentSearch.startDateMonth = this.paymentSearch.startDateMonth - 3;
            }
        }
        else if (this.paymentSearch.paymentPeriodStep === 12) {
            this.paymentSearch.startDateYear = this.paymentSearch.startDateYear - 1;
            this.paymentSearch.startDateMonth = 1;
        }
        this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
        // console.log('Start Date Down: ' + this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear));
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.onStartDateUp = function () {
        console.log('Start Date Up: ' + this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear));
        if (this.paymentSearch.paymentPeriodStep === 1) {
            if (this.paymentSearch.startDateMonth === 12) {
                this.paymentSearch.startDateMonth = 1;
                this.paymentSearch.startDateYear = this.paymentSearch.startDateYear + 1;
            }
            else {
                this.paymentSearch.startDateMonth = this.paymentSearch.startDateMonth + 1;
            }
        }
        else if (this.paymentSearch.paymentPeriodStep === 3) {
            if (this.paymentSearch.startDateMonth === 10) {
                this.paymentSearch.startDateMonth = 1;
                this.paymentSearch.startDateYear = this.paymentSearch.startDateYear + 1;
            }
            else {
                this.paymentSearch.startDateMonth = this.paymentSearch.startDateMonth + 3;
            }
        }
        else if (this.paymentSearch.paymentPeriodStep === 12) {
            this.paymentSearch.startDateYear = this.paymentSearch.startDateYear + 1;
            this.paymentSearch.startDateMonth = 1;
        }
        console.log('Start Year: ' + this.paymentSearch.startDateYear);
        console.log('Start Month: ' + this.paymentSearch.startDateMonth);
        var today = new Date();
        if (this.paymentSearch.startDateYear === today.getFullYear()) {
            if (this.paymentSearch.startDateMonth > today.getMonth() + 1) {
                this.paymentSearch.startDateMonth = today.getMonth() + 1;
            }
        }
        else if (this.paymentSearch.startDateYear > today.getFullYear()) {
            this.paymentSearch.startDateYear = today.getFullYear();
            this.paymentSearch.startDateMonth = today.getMonth() + 1;
        }
        if (this.paymentSearch.paymentPeriodStep === 3) {
            this.btnPaymentStepQuarterly();
        }
        else if (this.paymentSearch.paymentPeriodStep === 12) {
            this.btnPaymentStepAnnually();
        }
        this.paymentSearch.startStr = this.dateStr(this.paymentSearch.startDateMonth, this.paymentSearch.startDateYear);
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.onEndDateDown = function () {
        // console.log('End Date Down: ' + this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear));
        if (this.paymentSearch.paymentPeriodStep === 1) {
            if (this.paymentSearch.endDateMonth === 1) {
                this.paymentSearch.endDateMonth = 12;
                this.paymentSearch.endDateYear = this.paymentSearch.endDateYear - 1;
            }
            else {
                this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth - 1;
            }
        }
        else if (this.paymentSearch.paymentPeriodStep === 3) {
            if (this.paymentSearch.endDateMonth === 1) {
                this.paymentSearch.endDateMonth = 10;
                this.paymentSearch.endDateYear = this.paymentSearch.endDateYear - 1;
            }
            else {
                this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth - 3;
            }
        }
        else if (this.paymentSearch.paymentPeriodStep === 12) {
            this.paymentSearch.endDateMonth = 12;
            this.paymentSearch.endDateYear = this.paymentSearch.endDateYear - 1;
        }
        this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);
        // console.log('End Date Down: ' + this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear));
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.onEndDateUp = function () {
        // console.log('End Date Up: ' + this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear));
        var today = new Date();
        if (this.paymentSearch.paymentPeriodStep === 1) {
            if (this.paymentSearch.endDateMonth === 12) {
                this.paymentSearch.endDateMonth = 1;
                this.paymentSearch.endDateYear = this.paymentSearch.endDateYear + 1;
            }
            else {
                this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth + 1;
            }
        }
        else if (this.paymentSearch.paymentPeriodStep === 3) {
            if (this.paymentSearch.endDateMonth === 10) {
                this.paymentSearch.endDateMonth = 1;
                this.paymentSearch.endDateYear = this.paymentSearch.endDateYear + 1;
            }
            else {
                this.paymentSearch.endDateMonth = this.paymentSearch.endDateMonth + 3;
            }
        }
        else if (this.paymentSearch.paymentPeriodStep === 12) {
            this.paymentSearch.endDateYear = this.paymentSearch.endDateYear + 1;
            this.paymentSearch.endDateMonth = 12;
        }
        console.log('End Year: ' + this.paymentSearch.endDateYear);
        console.log('End Month: ' + this.paymentSearch.endDateMonth);
        if (this.paymentSearch.endDateYear === today.getFullYear()) {
            if (this.paymentSearch.endDateMonth > today.getMonth() + 1) {
                this.paymentSearch.endDateMonth = today.getMonth() + 1;
            }
        }
        else if (this.paymentSearch.endDateYear > today.getFullYear()) {
            this.paymentSearch.endDateYear = today.getFullYear();
            this.paymentSearch.endDateMonth = today.getMonth() + 1;
        }
        if (this.paymentSearch.paymentPeriodStep === 3) {
            this.btnPaymentStepQuarterly();
        }
        else if (this.paymentSearch.paymentPeriodStep === 12) {
            this.btnPaymentStepAnnually();
        }
        this.paymentSearch.endStr = this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear);
        // console.log('End Date Up: ' + this.dateStr(this.paymentSearch.endDateMonth, this.paymentSearch.endDateYear));
        this.filter();
    };
    OperatorPaymentsContentComponent.prototype.onClickDeduction = function (index) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var payment, _a, i, newPayment;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        // console.log('Index clicked: ' + index);
                        // ignore click on expanded deduction
                        if (this.displayDeductionDetails && index >= this.expandIndex1 + 1 && index <= this.expandIndex2) {
                            console.log('Index clicked: ' + index);
                            return [2 /*return*/];
                        }
                        if (this.displayDeductionDetails) {
                            // Remove previous deduction
                            if (this.expandIndex1 < this.expandIndex2) {
                                this.payments.splice(this.expandIndex1 + 1, this.expandIndex2 - this.expandIndex1);
                            }
                        }
                        // Clicked on the same deduction - hide details
                        if (index === this.expandIndex1) {
                            this.expandIndex1 = -1;
                            this.expandIndex2 = -1;
                            this.displayDeductionDetails = false;
                            this.showDeductionType(false);
                            return [2 /*return*/];
                        }
                        // Adjust the index to indicate we are expanding deductions at the new index location
                        if (index > this.expandIndex1) {
                            index = index - (this.expandIndex2 - this.expandIndex1);
                        }
                        payment = this.payments[index];
                        if (!(payment.paymentDeductions == null)) return [3 /*break*/, 2];
                        console.log('payment: ');
                        console.log(payment);
                        _a = this;
                        return [4 /*yield*/, this.getPaymentsDeductionsIndividual(payment.id)];
                    case 1:
                        _a.deductions = _b.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        this.deductions = payment.paymentDeductions;
                        _b.label = 3;
                    case 3:
                        // console.log('Show deductions:');
                        // console.log(payment);
                        // console.log(this.deductions);
                        // Show DeductionType column
                        this.displayDeductionDetails = true;
                        this.showDeductionType(true);
                        for (i = 0; i < this.deductions.length; i++) {
                            newPayment = new Payment();
                            newPayment.deductionType = this.deductions[i].deductionType.name;
                            newPayment.deductions = this.deductions[i].amount.toString();
                            newPayment.deductionsValue = this.deductions[i].amount;
                            this.payments.splice(index + i + 1, 0, newPayment);
                        }
                        this.applyDataBinding(this.payments);
                        this.expandIndex1 = index;
                        this.expandIndex2 = index + this.deductions.length;
                        return [2 /*return*/];
                }
            });
        });
    };
    OperatorPaymentsContentComponent.prototype.onBack = function () {
        this.location.back();
    };
    OperatorPaymentsContentComponent.prototype.exportReport = function (reportType) {
        this.paymentsService.getReport(this.payments, reportType).subscribe(function (data) {
            var blob = new Blob([data], { type: 'application/octet-stream' });
            var extension = '';
            if (reportType === 1) {
                extension = '.pdf';
            }
            else if (reportType === 2) {
                extension = '.xlsx';
            }
            else if (reportType === 3) {
                extension = '.csv';
            }
            FileSaver.saveAs(blob, "payments" + extension);
        });
    };
    OperatorPaymentsContentComponent.prototype.workbookInit = function (args) {
        // console.log(`Workbook Init`, { args });
        this.spread = args.spread;
        this.sheet = this.spread.getActiveSheet();
        this.sheetWidth = this.sheet.getViewportWidth(1);
        // Hide the row Header column
        // this.sheet.setColumnVisible(0, false, GC.Spread.Sheets.SheetArea.rowHeader);
        // Set the Row Header width
        this.sheet.suspendPaint();
        this.sheet.setColumnWidth(0, 0.0, GC.Spread.Sheets.SheetArea.rowHeader);
        this.sheet.resumePaint();
        // Set Row Header text value
        // this.sheet.setValue(1, 0, 'Row Header', GC.Spread.Sheets.SheetArea.rowHeader);
    };
    return OperatorPaymentsContentComponent;
}());
export { OperatorPaymentsContentComponent };
export { ɵ0 };
