import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-load-file',
  templateUrl: './generic-load-file.component.html',
  styleUrls: ['./generic-load-file.component.css']
})
export class GenericLoadFileComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
