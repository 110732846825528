import { dynamicSort } from '../shared/dynamic-sort';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, formatDate } from '@angular/common';
import * as GC from '@grapecity/spread-sheets';

import { PropertyService } from '../property.service';
import { LookupService } from '../lookup.service';

import { Property } from '../model/property';
import { Operator } from '../model/operator';
import { Purchaser } from '../model/purchaser';
import { Product } from '../model/product';
import { Well } from '../model/well';
import { Deck } from '../model/deck';
import { DivisionOrder } from '../model/division_order';
import { DivisionorderService } from '../divisionorder.service';
import { environment } from 'src/environments/environment';
import { Owner } from '../model/owner';
import { User } from '../model/user';
import * as FileSaver from 'file-saver';
import { InterestType } from '../model/interest-type';
import { FormBuilder, FormGroup } from '@angular/forms';

declare var $: any; // jquery
const clone = obj => JSON.parse(JSON.stringify(obj));

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.css']
})
export class PropertyDetailComponent implements OnInit {
  @ViewChild('spreadContainer', { static: false }) spreadContainer: ElementRef;
  readonly blockchainExplorerUrl = `${environment.blockchainExplorerUrl}`;

  divisionOrderForm: FormGroup = this.fb.group({
    deckId: '',
    interestTypeId: ''
  });

  displayUploadProduction = false;
  displayUploadSales = false;
  displayUploadProperty = false;
  displayAddProperty = false;

  private sheet: GC.Spread.Sheets.Worksheet = null;
  private spread: GC.Spread.Sheets.Workbook;

  private sheetWidth: number;

  tabStripVisible = false;
  spreadBackColor = 'white';
  sheetName = 'Property List';
  hostStyle = {
    height: '100%',
    width: '100%'
  };
  data: any;
  autoGenerateColumns = false;

  interestTypes: InterestType[];

  operators: Operator[];
  purchasers: Purchaser[];
  products: Product[];

  property: Property;
  modifiedDate: string;
  decks: Deck[];
  selectedDeck: Deck;
  divOrders: DivisionOrder[];
  selectedDivOrder: DivisionOrder;

  totalDecimalInterest = 0.0000000000;

  selectedWell = -1;
  selectedProduct = -1;

  ownerIdCol = 0;
  ownerNameCol = 1;
  productCol = 2;
  termSartCol = 3;
  termEndCol = 4;
  interestTypeCol = 5;
  decimalIntCol = 6;
  blockChainImgCol = 7;
  blockChainTransIdCol = 8;
  bankCol = 9;
  statusCol = 10;
  actionCol = 11;
  gridColCount = 12;

  displayDivisionOrderDialog = false;

  chartPeriod = 0;  // 0 = Monthly, 1 = Quarterly, 2 = Annually
  chartType = 0;    // 0 = table, 1 = chart

  gridSort = 0;   // 0 = unsorted, 1 = sorted by Bank Ascending, -1 = sorted by Bank as Descending

  lastUpdated: Date = new Date();
  lastUpdatedminus1: Date = new Date();
  lastUpdatedminus2: Date = new Date();
  lastUpdatedminus3: Date = new Date();
  lastUpdatedminus4: Date = new Date();
  lastUpdatedminus5: Date = new Date();
  lastUpdatedminus6: Date = new Date();
  performanceStart: Date = new Date();
  performanceEnd: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private divisionOrderService: DivisionorderService,
    private lookupService: LookupService,
    private router: Router,
    private location: Location) {

    this.property = new Property();
    this.property.wells = [new Well()];

    this.lastUpdated.setDate(new Date().getDate() - 2);
    this.lastUpdatedminus1.setMonth(this.lastUpdated.getMonth() - 1);
    this.lastUpdatedminus2.setMonth(this.lastUpdated.getMonth() - 2);
    this.lastUpdatedminus3.setMonth(this.lastUpdated.getMonth() - 3);
    this.lastUpdatedminus4.setMonth(this.lastUpdated.getMonth() - 4);
    this.lastUpdatedminus5.setMonth(this.lastUpdated.getMonth() - 5);
    this.lastUpdatedminus6.setMonth(this.lastUpdated.getMonth() - 6);

    this.performanceStart.setMonth(this.lastUpdated.getMonth() - 6);
  }

  ngOnInit() {
    this.selectedDivOrder = new DivisionOrder();
    const propertyStr = this.activatedRoute.snapshot.paramMap.get('id');

    Promise.all([
      this.getOperators(),
      this.getPurchasers(),
      this.getProducts(),
      this.getInterestTypes()
    ]).then(values => {
      const user: User = JSON.parse(localStorage.getItem('user'));
      const operatorId = user.operator.id;

      if (propertyStr.substr(0, 1) === '_') {
        const propertyNo = propertyStr.substr(1);
        console.log('Property No.: ' + propertyNo);
        this.getPropertyByNo(propertyNo);
        // this.getDivOrdersByPropertyNo(operatorId, propertyNo);
        this.getDecksByPropertyNo(operatorId, propertyNo);
      } else {
        const propertyId = +propertyStr;
        console.log('Property Id.: ' + propertyId);
        this.getProperty(propertyId);
        // this.getDivOrders(operatorId, propertyId);
        this.getDecks(operatorId, propertyId);
      }
    });
  }

  getProperty(propertyId) {
    this.propertyService.getProperty(propertyId).subscribe(property => {
      console.log({ property });
      this.property = property as Property;
      // Note: I had to do a lowerCase on the 'AM/PM' part
      this.modifiedDate = formatDate(this.property.modifiedDate, 'd MMM yyyy h:mm', 'en-US')
        + formatDate(this.property.modifiedDate, 'a', 'en-US').toLowerCase();
    });
  }

  getPropertyByNo(propertyNo) {
    this.propertyService.getPropertyByNo(propertyNo).subscribe(property => {
      console.log('Property By No: ', { property });
      this.property = property as Property;
      // Note: I had to do a lowerCase on the 'AM/PM' part
      this.modifiedDate = formatDate(this.property.modifiedDate, 'd MMM yyyy h:mm', 'en-US')
        + formatDate(this.property.modifiedDate, 'a', 'en-US').toLowerCase();
    });
  }

  getDivOrders(operatorId, propertyId): void {
    this.divisionOrderService.getDivisionOrdersByPropertyId(operatorId, propertyId).subscribe(divOrders => {
      console.log({ divOrders });

      let tempDivOrders: DivisionOrder[] = JSON.parse(JSON.stringify(divOrders));
      console.log('pre-transformation', { tempDivOrders });
      tempDivOrders = this.setDivisionOrderValues(tempDivOrders);
      console.log('post transformation', { tempDivOrders });

      this.divOrders = tempDivOrders;
      if (tempDivOrders.length > 0) {
        this.selectedDivOrder = tempDivOrders[0];
      }

      this.initialiseGrid();
      this.applyDataBinding();
    });
  }

  getDivOrdersByPropertyNo(operatorId, propertyNo): void {
    this.divisionOrderService.getDivisionOrdersByPropertyNo(operatorId, propertyNo).subscribe(divOrders => {
      // console.log({ divOrders });

      let tempDivOrders: DivisionOrder[] = JSON.parse(JSON.stringify(divOrders));
      // console.log('pre-transformation', { tempDivOrders });
      tempDivOrders = this.setDivisionOrderValues(tempDivOrders);
      // console.log('post transformation', { tempDivOrders });

      this.divOrders = tempDivOrders;
      if (tempDivOrders.length > 0) {
        this.selectedDivOrder = tempDivOrders[0];
      }

      this.initialiseGrid();
      this.applyDataBinding();
    });
  }

  getDecks(operatorId, propertyId): void {
    this.divisionOrderService.getDecksByPropertyId(operatorId, propertyId).subscribe(decks => {
      console.log({ decks });

      const tempDecks: Deck[] = JSON.parse(JSON.stringify(decks));
      console.log('pre-transformation', { tempDecks });

      tempDecks.forEach(deck => {
        let tempDivOrders: DivisionOrder[] = deck.divisionOrders;
        tempDivOrders = this.setDivisionOrderValues(tempDivOrders);
        deck.divisionOrders = tempDivOrders;
      });

      console.log('post transformation', { tempDecks });

      this.decks = tempDecks;
      if (tempDecks.length > 0) {
        this.selectedDeck = tempDecks[0];
        this.divOrders = tempDecks[0].divisionOrders;
        this.selectedDivOrder = tempDecks[0].divisionOrders[0];
      }
      this.initDivisionOrderSearchForm();
      console.log('divOrders', this.divOrders);

      setTimeout(() => {
        this.initialiseGrid();
        this.applyDataBinding();
      }, 2000);
    });
  }

  getDecksByPropertyNo(operatorId, propertyNo): void {
    this.divisionOrderService.getDecksByPropertyNo(operatorId, propertyNo).subscribe(decks => {
      console.log({ decks });

      const tempDecks: Deck[] = JSON.parse(JSON.stringify(decks));
      console.log('pre-transformation', { tempDecks });

      tempDecks.forEach(deck => {
        let tempDivOrders: DivisionOrder[] = deck.divisionOrders;
        tempDivOrders = this.setDivisionOrderValues(tempDivOrders);
        deck.divisionOrders = tempDivOrders;
      });

      console.log('post transformation', { tempDecks });

      this.decks = tempDecks;
      if (tempDecks.length > 0) {
        this.selectedDeck = tempDecks[0];
        this.divOrders = tempDecks[0].divisionOrders;
        this.selectedDivOrder = tempDecks[0].divisionOrders[0];
      }
      const tempDivOrders2 = this.divOrders;
      this.initDivisionOrderSearchForm();
      console.log('divOrders', { tempDivOrders2 });

      this.initialiseGrid();
      this.applyDataBinding();
    });
  }

  initDivisionOrderSearchForm() {
    this.divisionOrderForm.patchValue({ deckId: this.selectedDeck.id });
    this.divisionOrderForm.patchValue({ interestTypeId: '' });
  }

  setDivisionOrderValues(filteredDivOrders: DivisionOrder[]): DivisionOrder[] {
    filteredDivOrders.forEach(divOrder => {
      // console.log('Division Order: ', { divOrder });

      divOrder.lease.title.owner = new Owner(divOrder.lease.title.owner);

      divOrder.ownerId = divOrder.lease.title.owner.id;
      divOrder.ownerName = divOrder.lease.title.owner.fname + ' ' + divOrder.lease.title.owner.lname;
      divOrder.ownerTel = divOrder.lease.title.owner.tel;
      divOrder.ownerAddr = divOrder.lease.title.owner.getAddress();
      divOrder.ownerEmail = divOrder.lease.title.owner.email;
      divOrder.ownerPercent = divOrder.lease.title.ownershipPercent;
      divOrder.propertyNo = divOrder.lease.title.property.propertyNo;
      divOrder.propertyName = divOrder.lease.title.property.name;
      divOrder.propertyDesc = divOrder.lease.title.property.description;
      divOrder.productName = divOrder.product.name;
      divOrder.interestTypeAbbr = divOrder.interestType.abbr;
      if (divOrder.divisionOrderDate != null) {
        // console.log(divOrder.divisionOrderDate);
        divOrder.doDateStr = formatDate(divOrder.divisionOrderDate, 'MM/dd/yyyy', 'en');
      }
      if (divOrder.termStart != null) {
        // console.log(divOrder.termStart);
        divOrder.startDateStr = formatDate(divOrder.termStart, 'MM/dd/yyyy', 'en');
      }
      if (divOrder.termEnd != null) {
        // console.log(divOrder.endDateStr);
        divOrder.endDateStr = formatDate(divOrder.termEnd, 'MM/dd/yyyy', 'en');
      }
      divOrder.trustManagerName = divOrder.bankTrustManager.name;
      divOrder.bankName = divOrder.bankTrustManager.bank.name;

      divOrder.deckCode = divOrder.deck.deckCode;
      divOrder.deckChangeCode = divOrder.deck.deckChangeCode;
      divOrder.deckVersion = divOrder.deck.deckVersionDate;
    });

    return filteredDivOrders;
  }

  onDeckChange() {
    const deckId = Number(this.divisionOrderForm.get('deckId').value);
    console.log('Deck changed: ' + deckId);

    const filteredDecks = this.decks.filter(deck => {
      return deck.id === deckId;
    });

    if (filteredDecks) {
      this.selectedDeck = filteredDecks[0];
      this.divOrders = filteredDecks[0].divisionOrders;
      this.selectedDivOrder = filteredDecks[0].divisionOrders[0];
    }

    const interestTypeId = this.divisionOrderForm.get('interestTypeId').value;
    if (interestTypeId) {
      this.filterByInterestType(Number(interestTypeId));
    }

    this.initialiseGrid();
    this.applyDataBinding();
  }

  onInterestTypeChange() {
    const interestTypeId = Number(this.divisionOrderForm.get('interestTypeId').value);
    console.log('Interest Type changed: ' + interestTypeId);
    this.filterByInterestType(interestTypeId);

    this.initialiseGrid();
    this.applyDataBinding();
  }

  filterByInterestType(interestTypeId: number) {
    if (interestTypeId > 0) {
      const filteredDivisionOrders = this.selectedDeck.divisionOrders.filter(divisionOrder => {
        return divisionOrder.interestType.id === interestTypeId;
      });

      if (filteredDivisionOrders) {
        this.divOrders = filteredDivisionOrders;
        this.selectedDivOrder = this.divOrders[0];
      }
    } else {
      this.divOrders = this.selectedDeck.divisionOrders;
      this.selectedDivOrder = this.selectedDeck.divisionOrders[0];
    }
  }

  createGridEvents() {

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.EnterCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Highlight all cells of the row
      for (let col = 0; col < this.gridColCount; col++) {
        const cell = this.sheet.getCell(row, col);
        if (col !== this.blockChainImgCol) {
          const cellBackColor = cell.backColor();
          this.sheet.getCell(row, col).backColor(environment.gridHighlight);
        }
      }
      // console.log('Row Enter Cell: ' + row);
      this.sheet.resumePaint();
    });

    // Because I am setting the background color at cell level all changes must be made at cell level also
    this.spread.bind(GC.Spread.Sheets.Events.LeaveCell, (e, args) => {
      const row = args.row;
      this.sheet.suspendPaint();

      // Un-highlight all cells of the row
      for (let col = 0; col < this.gridColCount; col++) {
        const cell = this.sheet.getCell(row, col);

        if (col !== this.blockChainImgCol) {
          const cellBackColor = cell.backColor();
          this.sheet.getCell(row, col).backColor(environment.gridBackground);
        }
      }

      this.sheet.getCell(row, -1)
        .borderTop(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      this.sheet.getCell(row, -1)
        .borderBottom(new GC.Spread.Sheets.LineBorder(environment.gridCellBorder, GC.Spread.Sheets.LineStyle.thin));
      // console.log('Row Leave Cell: ' + row);

      this.sheet.resumePaint();
    });

    // Bind click-event of Blockchain cell
    this.spread.bind(GC.Spread.Sheets.Events.CellClick, (e, args) => {
      const row = args.row;
      const col = args.col;

      this.selectedDivOrder = this.divOrders[row];

      console.log('Row: ' + row);
      // console.table(this.divOrders);
      console.log('Selected DivOrder: ', this.selectedDivOrder);

      // If column Header clicked
      if (args.sheetArea === GC.Spread.Sheets.SheetArea.colHeader) {
        if (col === this.bankCol) {
          if (this.gridSort === 0) {
            this.gridSort = 1;
          } else if (this.gridSort === 1) {
            this.gridSort = -1;
          } else if (this.gridSort === -1) {
            this.gridSort = 1;
          }
          this.divOrders.sort(dynamicSort('bankName', this.gridSort));
          this.applyDataBinding();
        }
      } else if (col === this.blockChainImgCol || col === this.blockChainTransIdCol) {
        if (this.divOrders[row].bcTransId != null) {
          console.log('Cell: (' + row + ', ' + col
            + ') DivisionOrderId: ' + this.divOrders[row].id + '     BC TransId: ' + this.divOrders[row].bcTransId
            + '     BC BlockId: ' + this.divOrders[row].bcBlockId);

          // const url = this.blockchainExplorerUrl + selectedDivOrder.bcBlockId;
          let url = '';
          if (this.selectedDivOrder.bcBlockId != null) {
            url = this.blockchainExplorerUrl + this.selectedDivOrder.bcBlockId;
          } else {
            url = this.blockchainExplorerUrl;
          }
          window.open(url);
        }

        // Add click event to edit owner
      } else if (col === this.ownerIdCol || col === this.ownerNameCol) {
        // alert('/owner/' + this.divOrders[row].ownerId);
        this.router.navigateByUrl('/owner/' + this.divOrders[row].ownerId);

        // Add click event to display Royalty Interest
      } else if (col === this.decimalIntCol) {
        console.log('Decimal Interest clicked - Before - this.displayDivisionOrderDialog: ' + this.displayDivisionOrderDialog);
        this.displayDivisionOrderDialog = true;
        console.log('Decimal Interest clicked - After - this.displayDivisionOrderDialog: ' + this.displayDivisionOrderDialog);
      }
    });

  }

  initialiseGrid() {
    this.sheet.suspendPaint();

    // Lock/Readonly all columns
    this.sheet.options.isProtected = true;
    this.sheet.options.protectionOptions = {
      allowSelectUnlockedCells: true,
      allowSelectLockedCells: false
    };
    // Unlock from column 0 for 6 columns(columns 0 - 5) - [Owner# - Royalty Interest]
    this.sheet.getRange(-1, 0, -1, 6).locked(false);
    // Unlock from columns 8 for 4 columns (columns 8 - 11) - [Working Interest - Action]
    this.sheet.getRange(-1, 8, -1, 4).locked(false);

    // Define columns
    const ownerIdColInfo = { name: 'ownerId', displayName: 'Owner#', size: 60 };
    const ownerNameColInfo = { name: 'ownerName', displayName: 'Owner Name', size: 200 };
    const productColInfo = { name: 'productName', displayName: 'Product', size: 120 };
    const doTermStartColInfo = { name: 'termStart', displayName: 'Term Start', size: 90 };
    const doTermEndColInfo = { name: 'termEnd', displayName: 'Term End', size: 90 };
    const interestTypeColInfo = { name: 'interestTypeAbbr', displayName: 'Interest Type', size: 120 };
    const doDecimalIntColInfo = { name: 'decimalInterest', displayName: 'Decimal Int.', formatter: '0.0000000000', size: 120 };
    const doBCImageColInfo = { name: '', displayName: '#', size: 30 };
    const doBCTransIdColInfo = { name: 'bcTransId', displayName: 'BC #', size: 150 };
    const doBankColInfo = { name: 'bankName', displayName: 'Bank', size: 120 };
    const doStatusColInfo = { name: 'status', displayName: 'Status', size: 70 };
    const doActionColInfo = { name: 'action', displayName: 'Action', size: 70 };

    this.sheet.autoGenerateColumns = true;
    this.sheet.setDataSource(this.divOrders);

    this.sheet.bindColumns([
      ownerIdColInfo,
      ownerNameColInfo,
      productColInfo,
      doTermStartColInfo,
      doTermEndColInfo,
      interestTypeColInfo,
      doDecimalIntColInfo,
      doBCImageColInfo,
      doBCTransIdColInfo,
      doBankColInfo,
      doStatusColInfo,
      doActionColInfo
    ]);

    this.sheet.setColumnCount(this.sheet.getColumnCount());

    this.sheet.getCell(-1, this.productCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.termSartCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.termEndCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.interestTypeCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.decimalIntCol).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
    this.sheet.getCell(-1, this.statusCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);
    this.sheet.getCell(-1, this.actionCol).hAlign(GC.Spread.Sheets.HorizontalAlign.center);

    this.createGridEvents();

    this.sheet.resumePaint();
  }

  applyDataBinding() {

    this.sheet.suspendPaint();

    this.totalDecimalInterest = 0.0;
    // Generate the Block-chain column definition
    this.divOrders.forEach((rowDivorderObject: DivisionOrder, rowIndex: number) => {
      // console.log('Row Index: ' + rowIndex, rowDivorderObject);

      // Remove vertical grid-lines
      this.sheet.getCell(rowIndex, -1).borderLeft(new GC.Spread.Sheets.LineBorder('#FFFFFF', GC.Spread.Sheets.LineStyle.thin));

      const cell = this.sheet.getCell(rowIndex, this.blockChainImgCol);
      let imgBlockChain = null;
      if (this.divOrders[rowIndex].bcTransId != null) {
        imgBlockChain = './assets/images/blockchain_cell@2x.png';
        // console.log('Blockchain Trans Id not NULL RowIndex: ' + rowIndex + '   TransId: ' + this.divOrders[rowIndex].bcTransId);
      }
      cell.text('');
      cell.backgroundImage(imgBlockChain);

      this.totalDecimalInterest += rowDivorderObject.decimalInterest;
    });

    // Highlight first row except blockchain graphic cell
    for (let col = 0; col < this.gridColCount; col++) {
      if (col !== this.blockChainImgCol) {
        this.sheet.getCell(0, col).backColor(environment.gridHighlight);
      }
    }

    this.sheet.resumePaint();
  }

  async getOperators() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    const operatorId = user.operator.id;
    this.operators = (await (this.lookupService.getOperators(operatorId))) as Operator[];
  }

  async getPurchasers() {
    this.purchasers = (await (this.lookupService.getPurchasers())) as Purchaser[];
  }

  async getProducts() {
    this.products = (await (this.lookupService.getProducts())) as Product[];
  }

  async getInterestTypes() {
    this.interestTypes = (await (this.lookupService.getInterestTypes())) as InterestType[];
  }

  public uploadProduction() {
    this.displayUploadProduction = true;
  }

  public uploadSales() {
    this.displayUploadSales = true;
  }

  public uploadDO() {
    this.displayUploadProperty = true;
  }

  onBack(): void {
    this.location.back();
  }

  setChartPeriod(period: number) {
    this.chartPeriod = period;
  }

  setChartType(type: number) {
    this.chartType = type;
  }

  onClose() {
    alert('Close - dialog');
    this.displayDivisionOrderDialog = false;
  }

  // dynamicSort(property, sortOrder) {
  //   return (a, b) => {
  //     /* next line works with strings and numbers,
  //      * and you may want to customize it to your needs
  //      */
  //     const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
  //     return result * sortOrder;
  //   };
  // }

  public exportReport(reportType) {
    this.divisionOrderService.getReport(this.divOrders, reportType).subscribe((data) => {
      const blob = new Blob([data as BlobPart], { type: 'application/octet-stream' });
      let extension = '';
      if (reportType === 1) {
        extension = '.pdf';
      } else if (reportType === 2) {
        extension = '.xlsx';
      } else if (reportType === 3) {
        extension = '.csv';
      }
      FileSaver.saveAs(blob, `division-orders${extension}`);
    });
  }

  public workbookInit(args) {
    console.log(`Workbook Init`, { args });

    this.spread = args.spread;
    this.sheet = this.spread.getActiveSheet();

    this.sheetWidth = this.sheet.getViewportWidth(1);
    console.log('SheetWidth: ', this.sheetWidth);
  }

}
