import { Injectable } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {

  constructor() { }

  public cellNotBlank(col: number, row: number, errorMsg: string) {
    // get the corresponding column letter
    const colLetter = String.fromCharCode(65 + col);

    const notBlankValidator = GC.Spread.Sheets.DataValidation.createFormulaValidator(`NOT(ISBLANK(${colLetter}${row + 1}))`);
    notBlankValidator.ignoreBlank(false);
    notBlankValidator.showErrorMessage(true);
    notBlankValidator.errorMessage(errorMsg);
    notBlankValidator.inputTitle('Tip');

    return notBlankValidator;
    // sheet.setDataValidator(row, col, 1, 1, notBlankValidator);
  }
}
