import { OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { SalesService } from 'src/app/sales.service';
import { MessagesService } from 'src/app/messages.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth.service';
var SalesUploadComponent = /** @class */ (function () {
    function SalesUploadComponent(salesService, authService, messagesService) {
        this.salesService = salesService;
        this.authService = authService;
        this.messagesService = messagesService;
        this.displayUploadSalesChange = new EventEmitter();
        this.selectedFile = null;
        this.fileSelected = false;
        this.message = this.messagesService.noFileSelected;
        this.ngUnsubscribe = new Subject();
    }
    SalesUploadComponent.prototype.ngOnInit = function () { };
    SalesUploadComponent.prototype.onFileSelectedSales = function (event) {
        this.selectedFile = event.target.files[0];
        this.message = this.selectedFile.name;
        this.fileSelected = true;
        console.log('Sales File selected: ' + this.message);
    };
    SalesUploadComponent.prototype.onUpload = function () {
        var _this = this;
        var user = this.authService.getUser();
        var fileName = this.selectedFile.name;
        var fd = new FormData();
        fd.append('file', this.selectedFile, fileName);
        fd.append('userId', user.id.toString());
        fd.append('operatorId', user.operator.id.toString());
        console.log('UserId: ' + user.id);
        console.log('OperatorId: ' + user.operator.id);
        var resultObservable = this.salesService.postSalesFile(fd);
        var resultSubscription = resultObservable.pipe(takeUntil(this.ngUnsubscribe)).subscribe(function (res) {
            console.log('Response: ' + res);
            _this.message = fileName + ' - ' + res;
        });
        this.selectedFile = null;
        // this.message = fileName + ' - ' + this.messagesService.fileUploaded + resultSubscription;
        this.closeSalesDialog();
    };
    SalesUploadComponent.prototype.ngOnDestroy = function () {
        console.log('The component is being destroyed');
        this.ngUnsubscribe.next();
    };
    SalesUploadComponent.prototype.closeSalesDialog = function () {
        this.displayUploadSales = false;
        this.displayUploadSalesChange.emit(false);
    };
    return SalesUploadComponent;
}());
export { SalesUploadComponent };
