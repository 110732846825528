import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Property } from './model/property';

const clone = obj => JSON.parse(JSON.stringify(obj));

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  private readonly propertyUrl = `${environment.apiUrl}/property`;

  constructor(private http: HttpClient) { }

  // GET
  getProperties(operatorId: number) {
    const url = `${this.propertyUrl}/getProperties?operatorId=${operatorId}`;
    console.log('GetProperties Url: ', url);
    return this.http.get(url);
  }

  // POST
  public add(property: Property) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const url = this.propertyUrl + '/add';

    console.log('URL: ' + url);
    console.log('Property: ', { property });

    // console.log(`POST`, { query: clone(property) });

    return this.http.post(url, property, httpOptions).toPromise();
  }

  // POST
  public getReport(properties: Property[], reportType: number) {
    const httpOptions = {
      responseType: 'blob' as 'json',
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const url = `${this.propertyUrl}/propertiesReport/${reportType}`;
    return this.http.post(url, properties, httpOptions);
  }

  // GET
  getProperty(propertyId: number) {
    const url = `${this.propertyUrl}/getProperty?propertyId=${propertyId}`;

    console.log('GetProperty Url: ', url);

    return this.http.get(url);
  }

  // GET
  getPropertyByNo(propertyNo: string) {
    const url = `${this.propertyUrl}/getProperty?propertyNo=${propertyNo}`;

    console.log('GetProperty Url: ', url);

    return this.http.get(url);
  }

}

