import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
var SalesComponent = /** @class */ (function () {
    function SalesComponent(route) {
        this.route = route;
        this.displayAddSales = false;
        this.displayUploadSales = false;
        this.displayProcessPayments = false;
        this.salesSearch = {};
    }
    SalesComponent.prototype.ngOnInit = function () {
        this.action = this.route.snapshot.paramMap.get('action');
        if (this.action === null) {
            this.action = '';
        }
        else if (this.action.toUpperCase() === 'ADD') {
            this.addSales();
        }
        else if (this.action.toUpperCase() === 'UPLOAD') {
            this.uploadSales();
        }
        else if (this.action.toUpperCase() === 'PAYMENTS') {
            this.processPayments();
        }
    };
    SalesComponent.prototype.addSales = function () {
        this.displayAddSales = true;
    };
    SalesComponent.prototype.uploadSales = function () {
        this.displayUploadSales = true;
    };
    SalesComponent.prototype.processPayments = function () {
        this.displayProcessPayments = true;
    };
    return SalesComponent;
}());
export { SalesComponent };
