import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DivisionorderService = /** @class */ (function () {
    function DivisionorderService(http) {
        this.http = http;
        this.divOrderUrl = environment.apiUrl + "/divisionorder";
    }
    // API: Get
    DivisionorderService.prototype.getDivisionOrders = function (operatorId) {
        var url = this.divOrderUrl + "/getDivisionOrders?operatorId=" + operatorId;
        console.log('GetDivisionOrders Url: ', url);
        return this.http.get(url);
    };
    DivisionorderService.prototype.getDivisionOrdersByPropertyId = function (operatorId, propertyId) {
        var url = this.divOrderUrl + "/getDivisionOrders?operatorId=" + operatorId + "&propertyId=" + propertyId;
        console.log('GetDivisionOrdersByPropertyId Url: ', url);
        return this.http.get(url);
    };
    DivisionorderService.prototype.getDivisionOrdersByPropertyNo = function (operatorId, propertyNo) {
        var url = this.divOrderUrl + "/getDivisionOrders?operatorId=" + operatorId + "&propertyNo=" + propertyNo;
        console.log('GetDivisionOrdersByPropertyNo Url: ', url);
        return this.http.get(url);
    };
    DivisionorderService.prototype.getDecksByPropertyId = function (operatorId, propertyId) {
        var url = this.divOrderUrl + "/getDecks?operatorId=" + operatorId + "&propertyId=" + propertyId;
        console.log('GetDecksByPropertyId Url: ', url);
        return this.http.get(url);
    };
    DivisionorderService.prototype.getDecksByPropertyNo = function (operatorId, propertyNo) {
        var url = this.divOrderUrl + "/getDecks?operatorId=" + operatorId + "&propertyNo=" + propertyNo;
        console.log('GetDecksByPropertyNo Url: ', url);
        return this.http.get(url);
    };
    DivisionorderService.prototype.postDivisionOrderFile = function (fd) {
        var url = this.divOrderUrl + "/upload";
        console.log('PostDivisionOrderFile Url: ', url);
        return this.http.post(url, fd, { responseType: 'text' });
    };
    // POST
    DivisionorderService.prototype.getReport = function (divisionOrders, reportType) {
        var httpOptions = {
            responseType: 'blob',
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        var url = this.divOrderUrl + "/divisionOrdersReport/" + reportType;
        return this.http.post(url, divisionOrders, httpOptions);
    };
    /** @nocollapse */ DivisionorderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DivisionorderService_Factory() { return new DivisionorderService(i0.ɵɵinject(i1.HttpClient)); }, token: DivisionorderService, providedIn: "root" });
    return DivisionorderService;
}());
export { DivisionorderService };
