import { options } from './donutchart';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Well } from 'src/app/model/well';
import { Product } from 'src/app/model/product';
import { PerformanceByDate } from 'src/app/model/performance_by_date';
import { PerformanceByWell } from 'src/app/model/performance_by_well';
import { TicketSummary } from 'src/app/model/ticket_summary';
import { LookupService } from 'src/app/lookup.service';
import { DashboardService } from 'src/app/dashboard.service';
import { Bank } from 'src/app/model/bank';
import * as Highcharts from 'highcharts';
import { environment } from '../../../environments/environment';

declare var $: any; // jquery

@Component({
  selector: 'app-dashboard-widgets',
  templateUrl: './dashboard-widgets.component.html',
  styleUrls: ['./dashboard-widgets.component.css']
})

export class DashboardWidgetsComponent implements OnInit {

  performanceWidgetDisplayFlag = environment.showPerformanceWidget;
  summaryWidgetDisplayFlag = environment.showSummaryWidget;
  topWellsWidgetDisplayFlag = environment.showTopWellsWidget;
  paymentsWidgetDisplayFlag = environment.showPaymentsWidget;
  banksWidgetDisplayFlag = environment.showBanksWidget;
  openTicketsWidgetDisplayFlag = environment.showOpenTicketsWidget;
  leasesWidgetDisplayFlag = environment.showLeasesWidget;
  marketsWidgetDisplayFlag = environment.showMarketsWidget;

  wells: Well[];
  products: Product[];
  performanceByDate: PerformanceByDate[];
  performanceByWell: PerformanceByWell[];
  ticketsSummary: TicketSummary[];
  banks: Bank[];

  private options: any;
  private chart: Highcharts.Chart;

  public dashboardForm: FormGroup = this.fb.group({
    performance_well: new FormControl(null),
    performance_product: new FormControl(null),
    top_property_product: new FormControl(null),
    payment_bank: new FormControl(null),
    summary_bank: new FormControl(null)
  });

  selectedPerformanceWell: -1;
  selectedPerformanceProduct: -1;
  selectedTopPropertyProduct: -1;
  selectedBank: -1;
  selectedSummaryBank: -1;

  chartPeriod = 0;  // 0 = Monthly, 1 = Quarterly, 2 = Annually
  chartType = 0;    // 0 = table, 1 = chart
  chartPeriodPayment = 0;   // 0 = Monthly, 1 = Quarterly, 2 = Annually

  lastUpdated: Date = new Date();
  lastUpdatedminus1: Date = new Date();
  lastUpdatedminus2: Date = new Date();
  lastUpdatedminus3: Date = new Date();
  lastUpdatedminus4: Date = new Date();
  lastUpdatedminus5: Date = new Date();
  lastUpdatedminus6: Date = new Date();
  royaltyDue: Date = new Date();
  manualPaymentsDate: Date = new Date();
  performanceStart: Date = new Date();
  performanceEnd: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private lookupService: LookupService,
    private dashboardService: DashboardService) {

    this.lastUpdated.setDate(new Date().getDate() - 2);
    this.lastUpdatedminus1.setMonth(this.lastUpdated.getMonth() - 1);
    this.lastUpdatedminus2.setMonth(this.lastUpdated.getMonth() - 2);
    this.lastUpdatedminus3.setMonth(this.lastUpdated.getMonth() - 3);
    this.lastUpdatedminus4.setMonth(this.lastUpdated.getMonth() - 4);
    this.lastUpdatedminus5.setMonth(this.lastUpdated.getMonth() - 5);
    this.lastUpdatedminus6.setMonth(this.lastUpdated.getMonth() - 6);

    this.royaltyDue.setDate(new Date().getDate() + 20);
    this.manualPaymentsDate.setDate(new Date().getDate() - 15);

    this.performanceStart.setMonth(this.lastUpdated.getMonth() - 6);
  }

  ngOnInit() {

    Promise.all([
      this.getWells(),
      this.getProducts(),
      this.getBanks()
    ]).then(values => {
      // this.getTicketsSummary();
      // this.getPerformanceByDate(),
      // this.getPerformanceByWell();
    });
    this.setFormDefaults();
    this.initChart();

  }

  private initChart(): void {
    console.log('initChart - start');
    this.options = options;
    console.log('initChart - initialize');
    if (this.paymentsWidgetDisplayFlag) {
      this.chart = Highcharts.chart('chart_donut', this.options);
    }
    console.log('initChart - end');
  }

  public onFormChange() {
    this.selectedPerformanceProduct = this.dashboardForm.controls.performance_product.value;
    this.selectedPerformanceWell = this.dashboardForm.controls.performance_well.value;
    this.selectedTopPropertyProduct = this.dashboardForm.controls.top_property_product.value;
    this.selectedBank = this.dashboardForm.controls.payment_bank.value;
    this.selectedSummaryBank = this.dashboardForm.controls.summary_bank.value;


    console.dir(this.selectedBank);

  }

  private setFormDefaults() {
    this.dashboardForm.setValue({
      payment_bank: -1,
      summary_bank: -1,
      performance_well: -1,
      performance_product: -1,
      top_property_product: -1
    });
  }

  async getWells() {
    this.wells = (await (this.lookupService.getWells())) as Well[];
  }

  async getProducts() {
    this.products = (await (this.lookupService.getProducts())) as Product[];
  }

  async getBanks() {
    this.banks = (await (this.lookupService.getBanks())) as Bank[];
  }

  // This should be got from the database through an API call
  async getTicketsSummary() {

  }

  async getPerformanceByDate() {
    this.performanceByDate = (await (this.dashboardService.getPerformanceByDate())) as PerformanceByDate[];
  }

  async getPerformanceByWell() {
    this.performanceByWell = (await (this.dashboardService.getPerformanceByWell())) as PerformanceByWell[];
  }

  setChartPeriod(period: number) {
    this.chartPeriod = period;
  }

  setChartType(type: number) {
    this.chartType = type;
  }

  setChartPeriodPayment(period: number) {
    this.chartPeriodPayment = period;
  }

}
