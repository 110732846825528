var AppComponent = /** @class */ (function () {
    function AppComponent() {
        this.title = 'Pipeline DL';
        this.displayName = '';
        // This is used with HTML part to demo Kendo Modal forms
        // public status = 'open';
        // public openDialog = false;
        // public openDialogWindow() {
        //   this.openDialog = true;
        // }
        // public onClose() {
        //   this.openDialog = false;
        //   this.status = 'closed';
        // }
        // public onAccept() {
        //   this.status = 'accepted';
        // }
        // public onDecline() {
        //   this.status = 'declined';
        // }
    }
    return AppComponent;
}());
export { AppComponent };
