import { OnInit, EventEmitter } from '@angular/core';
var ProductionAddComponent = /** @class */ (function () {
    function ProductionAddComponent() {
        this.displayAddProductionChange = new EventEmitter();
    }
    ProductionAddComponent.prototype.closeDialog = function () {
        this.displayAddProduction = false;
        this.displayAddProductionChange.emit(false);
    };
    ProductionAddComponent.prototype.ngOnInit = function () { };
    return ProductionAddComponent;
}());
export { ProductionAddComponent };
