export const environment = {
  production: true,

  apiUrl: 'http://localhost:8080/pipelinedl/webapi',
  // apiUrl: 'http://35.196.146.231:8080/pipelinedl-app-server/webapi',
  // apiUrl: 'https://www.pipelinedl.com:9443/pipelinedl-app-server/webapi',
  // apiUrl: '/pipelinedl-app-server/webapi',
  // tslint:disable-next-line: max-line-length
  blockchainExplorerUrl: 'https://b1cf68b304374c5f96c310febcf3a3a9-optools.uss02.blockchain.cloud.ibm.com/peer/Peer1Pipel_0/channel/pipelinechannel/block/',
  // blockchainExplorerUrl: 'http://139.59.193.133:8080/#/transactions',

  // tslint:disable-next-line: max-line-length
  // 'Nuarca,444445911338471#B0TuvSd7YDW6Z4VUl6bUhWQsFETh3ENKp7KM3SU4BVYQt6c8E7Rq34N9Q5aZRVWXBlcONUYvgnb5sWc7gVYjF6aFVTYw3yTGZFRhRHSwlzb4R6bXpWRHlHa8dzdR3mR7V6LxYHeWRHdLZVWPtGb4RkSodzKnR6V5MUYl3iVDhWY8h7NmRDSXFmU4VTeGBHM4N6UPBzTzYjZxh5QFdTewRFb8M7T9EXd0NXN5JkaqdVVPBjZBVWSsB5U98kYEVlVWVjM7NUcC3UezR6bllTYpV6YsJXNsJEb4oERwJHOyQHM8okN4kHTiojITJCLiYDM6cTQFRkI0ICSiwiNwkDN5kDOzcTM0IicfJye&Qf35VfikkR9IkI0IyQiwiIyEjL6ByUKBCZhVmcwNlI0IiTis7W0ICZyBlIsICNzATNxADI4AjNwkTMwIjI0ICdyNkIsICNwcDM9EDMyIiOiAHeFJCLiE6YyFWdOJiOiEmTDJCLiEzN4gzMzETM9UDN4QDN4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPBlHNTdTTu9Ee636ZkF7Q93UMJtCUTNVaWRmSINEV6wWUt56YyxkNjRWWDtyNpdmVrcEd5d7Q5AlQy4kQr5WYQ9WWz3mawMzdjVkWIBTMW9EVsJUah3Wdit4Tl3iUmFzc7omYnZjdSl4QDZESiZDDVzU',
  spreadLicense:
    // tslint:disable-next-line: max-line-length
    'Empyrean Solutions,445182643533491#B0ATeV5Y5oke9lGNH3WM7JnVQF7Vzx6Zq3EZoJkQoN5RiF5VplVW8wEVCNmSvhVM5t6KlJnVjVncOpkS7U6YQZWbEBXUwcjZGpXMXZ4UaZWezITN6kUMwtUOLtSM7s4NxBDMohGeTRkR7xUMI9EOTRmQGhlVKRVRUFkbGZTOPRndzMXdpJ5bEFUahFlcBhzaOBDT9czUOZFV094byZmW7QzbFpmNVF4YmVDTvpWR5YUesN5LTVDbExmRzdEUtRDTXtWaSx6QpVTSRdlW7QGWCtiV9dUe63GbPpndwEGS4R4b5N6ZiojITJCLigTMBJ4QyEENiojIIJCLwkzMyUjNzYDN0IicfJye#4Xfd5nIJZUOCJiOiMkIsIiMx8idgMlSgQWYlJHcTJiOi8kI1tlOiQmcQJCLiUjM6ITNwASMwcDM9EDMyIiOiQncDJCLiMnbvlGd5x6bTBibhVmc9BXbFJiOiEmTDJCLiETO4MzM5MDN6IDOxUDN4IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRVXOklzbOdncn9mRmZzRuh4VIFFWJhFaINTbwpmbNNmbR5UQkFWVkNzNJRVYv5GS8UmVTlEaVlVR7J4bTh7bRdzb9dGcDNkNqpVWQVjZHRTVlFnbxYVWrgGWah6d724QI36T4l6K64UTyhFUKJ4KGJrZ3l',

  gridHighlight: '#EDF4FC',
  gridBackground: 'white',
  gridCellChanged: '#FFE6A2',
  gridCellBorder: '#E0E0E0',
  buttonEnabled: '#468EE5',
  buttonEnabledText: 'white',
  buttonDisabled: 'rgba(0,0,0,0.05)',
  buttonDisabledText: '#CCCCCC',

  showTicker: true,
  showNotification: true,
  showPerformanceWidget: true,
  showSummaryWidget: true,
  showTopWellsWidget: true,
  showPaymentsWidget: true,
  showBanksWidget: true,
  showOpenTicketsWidget: true,
  showLeasesWidget: true,
  showMarketsWidget: true
};
