import * as GC from '@grapecity/spread-sheets';
import * as i0 from "@angular/core";
var ValidatorService = /** @class */ (function () {
    function ValidatorService() {
    }
    ValidatorService.prototype.cellNotBlank = function (col, row, errorMsg) {
        // get the corresponding column letter
        var colLetter = String.fromCharCode(65 + col);
        var notBlankValidator = GC.Spread.Sheets.DataValidation.createFormulaValidator("NOT(ISBLANK(" + colLetter + (row + 1) + "))");
        notBlankValidator.ignoreBlank(false);
        notBlankValidator.showErrorMessage(true);
        notBlankValidator.errorMessage(errorMsg);
        notBlankValidator.inputTitle('Tip');
        return notBlankValidator;
        // sheet.setDataValidator(row, col, 1, 1, notBlankValidator);
    };
    /** @nocollapse */ ValidatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ValidatorService_Factory() { return new ValidatorService(); }, token: ValidatorService, providedIn: "root" });
    return ValidatorService;
}());
export { ValidatorService };
