import { Router } from '@angular/router';
import { MessagesService } from './../messages.service';
import { Component, OnInit, ViewChild, ElementRef, SystemJsNgModuleLoader, Input } from '@angular/core';
import * as GC from '@grapecity/spread-sheets';

import { environment } from './../../environments/environment';
import { Property } from './../model/property';
import { PropertyService } from './../property.service';
import * as $ from 'jquery';
import {User} from '../model/user';
import {AuthService} from '../auth.service';
@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.css']
})
export class PropertyComponent implements OnInit {

  @Input() property: Property;

  @ViewChild('spreadContainer', { static: false }) spreadContainer: ElementRef;


  tabStripVisible = false;
  spreadBackColor = 'aliceblue';
  sheetName = 'Property List';
  hostStyle = {
    height: '100%',
    width: '100%'
  };
  data: any;
  autoGenerateColumns = false;

  properties: Property[];

  constructor(
    private propertyService: PropertyService,
    private messagesService: MessagesService,
    private router: Router) {
  }

  ngOnInit() {
    const user: User = JSON.parse(localStorage.getItem('user'));
    this.propertyService.getProperties(user.operator.id)
      .subscribe(properties => {
        console.log('Property Module: ', { properties });
        this.properties = properties as Property[];
      });
  }

}
